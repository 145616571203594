import React, { useEffect, useMemo, useRef } from "react";

import {
	Box,
	Button,
	Card,
	CircularProgress,
	TextField as TextInput,
	Theme,
	Typography,
	useMediaQuery,
	useTheme
} from "@material-ui/core";
import { NoteAdd, Search } from "@material-ui/icons";
import Autocomplete, { createFilterOptions } from "@material-ui/lab/Autocomplete";
import { videoOptions } from "@remar/shared/dist/config";
import { School, User } from "@remar/shared/dist/models";
import { getVideoPlayer } from "@remar/shared/dist/utils/serviceUtils/helpers";
import { format, startOfToday } from "date-fns";
import { Field, Form, Formik } from "formik";
import { TextField as MuiTextField } from "formik-material-ui";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { RootState, useAppSelector } from "store";

import { closeTutorialModal, getIntroLessonVideo, setSchoolId } from "store/features/Auth/authSlice";

import { getSchools } from "store/features/Course/courseSlice";
import { emit } from "store/features/notifications/notifications.slice";
import { schoolsService, usersService } from "store/services";
import videojs from "video.js";
import * as Yup from "yup";

import { routes } from "core/constants";

import { useStyles } from "./studentFormStyle";

// todo: move out as atomic component
const TextField = props => (
	<MuiTextField {...props} InputProps={{ disableUnderline: true }} variant="filled" hiddenLabel />
);

const StudentFormSchema = Yup.object().shape({
	school: Yup.string().min(3).required("Required"),
	timesTakenNCLEX: Yup.number().moreThan(-1, "Number of times exam taken should be positive"),
	examDate: Yup.date().min(startOfToday(), "Exam date can't be in the past")
});

const initialFormValues = {
	school: "",
	timesTakenNCLEX: undefined,
	examDate: undefined
};

const StudentForm = ({ closeModal }): React.ReactElement => {
	const theme = useTheme<Theme>();
	const classes = useStyles();
	const dispatch = useDispatch();
	const history = useHistory();
	const isMobile = useMediaQuery(theme.breakpoints.down("xs"));

	useEffect(() => {
		dispatch(getSchools());
	}, [dispatch]);

	const { introVideo, isLoading, redirectToIntroVIT, user, introVideoNotFound } = useSelector(
		({ auth }: RootState) => auth
	);
	const schools = useAppSelector(({ courses }) => courses.schoolList);
	const approvedSchools = useMemo(() => schools.filter(s => s.approved), [schools]);

	const videoRef = useRef(null);
	const videoPlayer = useRef<videojs.Player>();

	useEffect(() => {
		if (!introVideo) {
			dispatch(getIntroLessonVideo());
			return;
		}

		if (user!.schoolId && !user!.hasTakenIntro) {
			videoPlayer.current =
				videoPlayer.current ||
				getVideoPlayer(videoRef!.current!, introVideo.videoUrl, {
					...videoOptions,
					muted: false,
					loop: true,
					autoplay: false,
					controls: false
				});
		}
	}, [dispatch, introVideo, user]);

	useEffect(() => {
		if (introVideoNotFound && user && user!.schoolId) {
			closeModal();
			return;
		}
	}, [closeModal, introVideoNotFound, user]);

	useEffect(() => {
		if (!introVideo || !redirectToIntroVIT) {
			return;
		}
		history.push(`${routes.lesson.getPath()}/${introVideo.interactiveBlocks![0].lessonId}/0`);
	}, [history, introVideo, redirectToIntroVIT]);

	const getSchoolId = (name: string) => {
		const school = schools.find(obj => obj.name.toLowerCase().trim() === name.toLowerCase().trim());
		return school?.id;
	};

	const addInfo = async (values: { school: string; timesTakenNCLEX: number; examDate: string }, schoolId?: number) => {
		const id = schoolId || getSchoolId(values.school);
		if (id) {
			usersService
				.update({
					data: {
						schoolId: id,
						examInfo: {
							...(values.timesTakenNCLEX && { nclexTimesTaken: values.timesTakenNCLEX }),
							...(values.examDate && { dateOfExam: values.examDate })
						}
					},
					filters: { id: user!.id }
				})
				.then(res => {
					const { schoolId } = (res as { raw: User[] }).raw[0];
					dispatch(setSchoolId(schoolId));
				})
				.catch(() => dispatch(emit({ message: "Something goes wrong when exam data update", color: "error" })));
		}
	};

	const handleSchoolSubmit = async values => {
		if (!getSchoolId(values.school)) {
			const res: School = await schoolsService.create({ name: values.school });
			await addInfo(values, res.id);
		} else {
			await addInfo(values);
		}
		closeModal();
	};

	const handleIntroVITClick = async (redirectToIntroVIT: boolean) => {
		await dispatch(closeTutorialModal(redirectToIntroVIT));
		closeModal();
	};

	const filter = createFilterOptions<unknown>({
		trim: true
	});

	const accountFormDetails = (setFieldValue, values, errors, setFieldError) => {
		const autoCompleteOnChange = newValue => {
			if (typeof newValue === "string") {
				setFieldValue("school", newValue);
			} else if (newValue && newValue.inputValue) {
				// Create a new value from the user input
				setFieldValue("school", newValue.inputValue);
			} else {
				setFieldValue("school", newValue?.name);
			}
		};

		const checkDuplicates = (options, params) =>
			options.find(option => params.inputValue.toLowerCase().trim() === option.name.toLowerCase().trim());

		const autoCompleteFilterOptions = (options, params) => {
			const filtered = filter(options, params);
			// Suggest the creation of a new value
			if (params.inputValue !== "") {
				//Check if the option already exists
				if (!!checkDuplicates(options, params)) {
					return filtered;
				}
				filtered.push({
					inputValue: params.inputValue,
					name: `Add "${params.inputValue}"`
				});
			}
			return filtered;
		};

		const autoCompleteGetOptionLabel = option => {
			// Value selected with enter, right from the input
			if (typeof option === "string") {
				return option;
			}
			// Add "xxx" option created dynamically
			if (option.inputValue) {
				return option.inputValue;
			}
			// Regular option
			return option.name;
		};

		const autoCompleteRenderInput = params => (
			<TextInput
				{...params}
				placeholder="Enter school here"
				name="school"
				variant="filled"
				InputProps={{
					...params.InputProps,
					disableUnderline: true,
					style: { paddingBottom: "10px", paddingTop: "10px" },
					endAdornment: <Search style={{ fill: "#898f9e" }} />
				}}
				onBlur={() => {
					if (values.school.length < 1) setFieldError("school", "Required");
				}}
			/>
		);

		return (
			<Box padding={isMobile ? 2 : 5}>
				<Box className={classes.formHeader}>
					<Box display="flex" justifyContent="center">
						{/*todo: this icon should be changed to handshake icon after upgrading material icons  */}
						<NoteAdd style={{ width: "60px", height: "60px", fill: "#898f9e" }} />
					</Box>
					<Typography variant="h2" className={classes.title}>
						Welcome to ReMar V2!
					</Typography>
					<Typography variant="h3" className={classes.subTitle}>
						We would love if you answered questions below to provide you with better experience
					</Typography>
				</Box>
				<Box padding={isMobile ? 2 : 0}>
					<Box className={classes.inputContainer}>
						<Box className={classes.labelField}>
							<Typography style={{ fontWeight: 600 }}>School*</Typography>
						</Box>
						<Box style={{ flex: 4, display: "flex", alignItems: "flex-start", flexDirection: "column" }}>
							<Box className={classes.inputField}>
								<Autocomplete
									value={values.school}
									onChange={(_, newValue) => autoCompleteOnChange(newValue)}
									filterOptions={(options, params) => autoCompleteFilterOptions(options, params)}
									disableClearable
									clearOnBlur
									handleHomeEndKeys
									id="school"
									options={approvedSchools}
									getOptionLabel={option => autoCompleteGetOptionLabel(option)}
									renderOption={option => option.name}
									style={{ width: 300 }}
									freeSolo
									renderInput={params => autoCompleteRenderInput(params)}
								/>
							</Box>
							{errors && errors.school && <p className={classes.error}>Required</p>}
						</Box>
					</Box>
					<Box className={classes.inputContainer}>
						<Box className={classes.labelField}>
							<Typography style={{ fontWeight: 600, whiteSpace: "nowrap" }}>Times taken NCLEX</Typography>
						</Box>
						<Box className={classes.inputField}>
							<Field
								component={TextField}
								name="timesTakenNCLEX"
								type="number"
								placeholder="Enter Times Here"
								fullWidth
							/>
						</Box>
					</Box>
					<Box className={classes.inputContainer}>
						<Box className={classes.labelField}>
							<Typography style={{ fontWeight: 600, whiteSpace: "nowrap" }}>Date of your exam</Typography>
						</Box>
						<Box className={classes.inputField}>
							<Field
								component={TextField}
								name="examDate"
								type="date"
								inputProps={{ min: format(new Date(), "yyyy-MM-dd") }}
								placeholder=""
								fullWidth
							/>
						</Box>
					</Box>
				</Box>
			</Box>
		);
	};

	let showSchoolForm = false;
	let showIntro = false;
	if (!user!.schoolId) {
		showSchoolForm = true;
	} else if (!user!.hasTakenIntro) {
		showIntro = true;
	}
	return (
		<Card variant="outlined" className={`${classes.card}${showIntro ? " intro-card" : ""}`}>
			{showSchoolForm ? (
				<Formik
					initialValues={initialFormValues}
					validationSchema={StudentFormSchema}
					onSubmit={values => handleSchoolSubmit(values)}
				>
					{({ setFieldValue, isValid, values, errors, setFieldError }) => (
						<>
							<Form className={classes.form}>{accountFormDetails(setFieldValue, values, errors, setFieldError)}</Form>
							<Box className={classes.formFooter}>
								<Button
									color="primary"
									disabled={!isValid || isLoading || values?.school?.length < 1}
									startIcon={isLoading && <CircularProgress size={20} />}
									variant="contained"
									fullWidth={isMobile}
									onClick={() => handleSchoolSubmit(values)}
								>
									Submit
								</Button>
							</Box>
						</>
					)}
				</Formik>
			) : (
				introVideo &&
				showIntro && (
					<div className={classes.videoContainer}>
						<>
							{introVideo && <video ref={videoRef} className="video-js vjs-big-play-centered" />}
							<div className="vjs-text-track-display questions-overlay">
								<div className={classes.videoQuestionContainer}>
									<Box display="flex">
										<Box width="85%">
											<div className="question-head">
												Would you like to take a tutorial to learn how to use the ReMar V2 faster?
											</div>
										</Box>
									</Box>
									<Box display="flex" height="400px" flexDirection="column" flexWrap="wrap" width="80%">
										<div className={classes.videoQuestionOption} onClick={() => handleIntroVITClick(true)}>
											{"Yes, let's go!"}
										</div>
										<div className={classes.videoQuestionOption} onClick={() => handleIntroVITClick(false)}>
											{"No, skip the tutorial."}
										</div>
									</Box>
								</div>
							</div>
						</>
					</div>
				)
			)}
		</Card>
	);
};

export default StudentForm;

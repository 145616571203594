import React, { useEffect, useMemo, useState } from "react";

import { Box, Button, Card, Modal, Typography } from "@material-ui/core";
import Stepper from "@remar/shared/dist/components/Stepper";

import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js";
import { Form, Formik } from "formik";

import ReactPixel from "react-facebook-pixel";

import { useDispatch, useSelector } from "react-redux";

import { useHistory, useParams } from "react-router-dom";

import {
	fetchCountries,
	fetchExternalIntegrationDataItems,
	setError,
	setIsLoading,
	signUp,
	validateDuplicateEmail
} from "store/features/Auth/authSlice";

import { PixelEvent, setTiktokEvent } from "modules/utils";

import { RootState } from "../../../store";

import SignUpLayout from "../SignUpLayout";
import ChangeBook from "../components/ChangeBook";
import FormActions from "../components/FormActions";
import { GuestShippingForm, PaymentForm } from "../components/Forms";
import { GuestSignUpError } from "../components/GuestSignUpError";
import { GuestSignUpSuccessLeft } from "../components/GuestSignUpSuccessLeft";
import { GuestSignUpSuccessRight } from "../components/GuestSignUpSuccessRight";
import GuestSummary from "../components/GuestSummary";
import { GuestShippingSchema, PaymentSchema } from "../components/schemas";
import { CardsContainer, useStyles } from "../components/styles";
import { getValidValue } from "../components/utils";

const guestInitialFormValues = {
	firstName: "",
	lastName: "",
	email: "",
	countryId: "",
	address1: "",
	address2: "",
	city: "",
	state: "",
	zipCode: "",
	phoneNumber: "",
	terms: false
};
const steps = [{ label: "Shipping" }, { label: "Payment" }];
const BookCheckOut = () => {
	const classes = useStyles();
	const dispatch = useDispatch();
	const elements = useElements();
	const stripe = useStripe();
	const history = useHistory();
	const [activeStep, setActiveStep] = useState(0);
	const [signupSuccessUserData, setSignupSuccessUserData] = useState({});
	const { errorMessage, isLoading, subExternalIntegrations, selectedShippingPlan } = useSelector(
		(state: RootState) => state.auth
	);
	const { bookId } = useParams<{ bookId: string }>();

	const [bookPurchaseClaimCode, setBookPurchaseClaimCode] = useState("");
	const [changeBookModal, setChangeBookModal] = useState(false);
	const searchParams = new URLSearchParams(history.location.search);

	const accountClaimCode = searchParams.get("accountClaimCode") as string;

	useEffect(() => {
		if (bookId) {
			dispatch(fetchExternalIntegrationDataItems(Number(bookId)));
			dispatch(fetchCountries(0));
		}
	}, [bookId, dispatch]);

	useEffect(() => {
		if (activeStep === 0) {
			setTiktokEvent(PixelEvent.InitiateCheckout);
			ReactPixel.track(PixelEvent.InitiateCheckout);
		}
		if (activeStep === 1) {
			setTiktokEvent(PixelEvent.AddPaymentInfo);
			ReactPixel.track(PixelEvent.AddPaymentInfo);
		}
	}, [activeStep]);

	const guestValidationSchema = useMemo(() => {
		if (activeStep === 0) return GuestShippingSchema;
		if (activeStep === 1) return PaymentSchema;
	}, [activeStep]);

	const handleGuestSubmit = (values: typeof guestInitialFormValues) => {
		dispatch(
			signUp({
				guest: true,
				CardElement,
				elements,
				stripe,
				values
			})
			// eslint-disable-next-line @typescript-eslint/ban-ts-comment
			// @ts-ignore
		).then(r => {
			if (r.error) {
				dispatch(setError(r.error.message));
				setActiveStep(2);
			} else {
				setBookPurchaseClaimCode(r.payload?.accountClaimCode);
				setSignupSuccessUserData({
					email: r["payload"]["email"],
					firstname: r["payload"]["firstName"],
					lastname: r["payload"]["lastName"],
					shipping: (selectedShippingPlan?.data["price"] || 0).toLocaleString("en-US", {
						currency: "USD",
						style: "currency"
					}),
					books: subExternalIntegrations?.map(x => {
						return {
							quantity: x.quantity,
							name: x.data.name,
							price: x?.data["price"].toLocaleString("en-US", { currency: "USD", style: "currency" })
						};
					})
				});
				setActiveStep(3);
			}
		});
	};

	const handleGuestNext = (values: typeof guestInitialFormValues) => {
		if (activeStep === 0) {
			dispatch(setIsLoading(true));
			const { email } = values;
			// eslint-disable-next-line @typescript-eslint/ban-ts-comment
			// @ts-ignore
			dispatch(validateDuplicateEmail(email)).then(r => {
				dispatch(setIsLoading(false));
				if (r.error) {
					dispatch(setError(r.error.message));
					return;
				}
				dispatch(setError(""));
				setActiveStep(prevActiveStep => (prevActiveStep < 1 ? prevActiveStep + 1 : 1));
			});
		} else {
			handleGuestSubmit(values);
		}
	};

	const handleBack = () => {
		if (accountClaimCode) setActiveStep(0);
		else setActiveStep(prevActiveStep => (prevActiveStep >= 1 ? prevActiveStep - 1 : 0));
	};

	return (
		<>
			<SignUpLayout>
				<Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" mb={5} mt={5}>
					<CardsContainer $activeStep={activeStep === 3 ? "column" : "column-reverse"}>
						<Card className={`${classes.card} ${classes.baseFlex}`}>
							<Box>
								<Box px={6} pt={2} className={classes.stepperForm}>
									{activeStep !== 3 ? <Stepper activeStep={activeStep} steps={steps} /> : null}
									<Formik
										initialValues={guestInitialFormValues}
										validationSchema={guestValidationSchema}
										validateOnChange
										onSubmit={values => {
											handleGuestNext(values);
										}}
									>
										{({ isValid, values, touched, setFieldValue }) => {
											const neverTouched = Object.keys(touched).length === 0;
											let valid;
											if (activeStep === 1) {
												valid = getValidValue(Object.keys(touched)) ? isValid : false;
											} else {
												valid = isValid && !neverTouched;
											}
											return (
												<>
													<Form>
														{activeStep === 0 && <GuestShippingForm />}
														{activeStep === 1 && (
															<PaymentForm setFieldValue={setFieldValue} termValue={values.terms} showTerms />
														)}
														{activeStep === 2 && <GuestSignUpError errorMessage={errorMessage} />}
														{activeStep === 3 && (
															<GuestSignUpSuccessLeft signupSuccessUserData={signupSuccessUserData} />
														)}
													</Form>
													{errorMessage && (
														<Box mt={3}>
															<Typography variant="caption" style={{ color: "red" }}>
																{errorMessage}
															</Typography>
														</Box>
													)}
													{activeStep !== 2 && activeStep !== 3 ? (
														<FormActions
															back={() => handleBack()}
															next={() => handleGuestNext(values)}
															valid={valid}
															loading={isLoading}
															step={activeStep}
															lastStep={1}
														/>
													) : activeStep === 2 ? (
														<Box display="flex" justifyContent="center" mt={6}>
															<Button
																color="primary"
																variant="contained"
																onClick={() => {
																	dispatch(setError(""));
																	setActiveStep(1);
																}}
															>
																{"Try Again"}
															</Button>
														</Box>
													) : null}
												</>
											);
										}}
									</Formik>
								</Box>
							</Box>
						</Card>
						{activeStep === 3 ? (
							<GuestSignUpSuccessRight bookPurchaseClaimCode={bookPurchaseClaimCode} setActiveStep={setActiveStep} />
						) : (
							<GuestSummary setChangeBookModal={setChangeBookModal} />
						)}
					</CardsContainer>
				</Box>
			</SignUpLayout>
			<Modal
				open={changeBookModal}
				onClose={() => {
					setChangeBookModal(false);
				}}
			>
				<ChangeBook onClose={() => setChangeBookModal(false)} />
			</Modal>
		</>
	);
};

export default BookCheckOut;

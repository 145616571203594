import React, { useCallback, useEffect, useMemo, useState } from "react";

import {
	Box,
	CircularProgress,
	FormControl,
	Grid,
	IconButton,
	MenuItem,
	Modal,
	Button as MuiButton,
	Select,
	Typography,
	useTheme
} from "@material-ui/core";
import { Close } from "@material-ui/icons";

import InfoBlock from "@remar/shared/dist/components/InfoBlock";
import { IColumn, MaterialTable } from "@remar/shared/dist/components/MaterialTable";
import { ColumnHeader, StyledCellText, StyledCellWrapper } from "@remar/shared/dist/components/Table/styles";

import { TablePagination } from "@remar/shared/dist/components/TablePagination";
import { Wrapper } from "@remar/shared/dist/layouts";
import { IExtendedTheme } from "@remar/shared/dist/theme/default";
import { formatDate } from "@remar/shared/dist/utils/myAccountUtils";

import { useDispatch, useSelector } from "react-redux";

import { useHistory } from "react-router-dom";

import { RootState } from "store";
import { fetchQuizzes, fetchQuizzesSummary } from "store/features/Test/test.slice";

import { getQuizQuestions, getQuizResult, getUserQuizAttempt } from "store/features/Test/testAttempt.slice";
import { Attempt, TestStatus, Tests as TestType } from "store/services/tests/dto";

import { routes } from "core/constants";
import QuizResults from "modules/Lesson/QuizResults";

import {
	CheckSvgIcon,
	CrossSvgIcon,
	IconWrapper,
	LoadingWrapper,
	QuizResultsContainer,
	QuizResultsWrapper,
	StudentsBox,
	THeaderWrapper,
	TakeTestButtonWrapper,
	TestSvgIcon
} from "./styles";

import { State, getState } from "./utils";

const Tests = () => {
	const dispatch = useDispatch();
	const history = useHistory();
	const theme: IExtendedTheme = useTheme();
	const [status, setStatus] = useState<TestStatus | "">("");
	const [selectedQuizId, setSelectedQuizId] = useState<number | null>(null);
	const {
		items,
		isLoading,
		totalItems,
		perPage,
		page,
		testsSummary: { isSummaryLoading, totalQuizzes, totalFailed, totalPassed, lastAttemptedExam }
	} = useSelector((state: RootState) => state.quiz);
	const { user, canAccessQuiz } = useSelector((state: RootState) => state.auth);
	const getOpacity = useCallback((state, condition?: boolean) => (state === State.Expired || condition ? 0.5 : 1), []);
	const quizResult = useSelector(getQuizResult);
	const userAttemptedQuestions = useSelector(getQuizQuestions);
	const findAttemptForUser = useCallback(
		(attempts: Attempt[]) => attempts.find(a => a.userId === user?.id),
		[user?.id]
	);
	const locationId = useMemo(() => (!!user?.allowedLocations?.length ? user?.allowedLocations[0].id : null), [user]);

	const getDesiredColor = useCallback(
		condition => {
			switch (condition) {
				case State.Active:
				case TestStatus.Passed:
					return theme.palette.colors.success[500];
				case State.Expired:
				case TestStatus.Failed:
					return theme.palette.colors.danger[500];
				case State.ComingSoon:
				case TestStatus.InProgress:
					return theme.palette.colors.warning[500];

				default:
					return theme.palette.colors.basic[500];
			}
		},
		[theme]
	);

	const getStatusFromAttempts = useCallback(
		(attempts: Attempt[]) => {
			const findAttemptForUser = attempts.find(a => a.userId === user?.id);

			if (!findAttemptForUser) {
				return TestStatus.NotTaken;
			} else if (findAttemptForUser.inProgress) {
				return TestStatus.InProgress;
			} else if (findAttemptForUser.passed) {
				return TestStatus.Passed;
			}
			return TestStatus.Failed;
		},
		[user?.id]
	);

	useEffect(() => {
		if (!!selectedQuizId) {
			dispatch(getUserQuizAttempt({ quizId: selectedQuizId }));
		}
	}, [selectedQuizId, dispatch]);

	useEffect(() => {
		if (locationId && canAccessQuiz) {
			dispatch(fetchQuizzes({ page: 1, userId: user?.id, locationId }));
		}
	}, [dispatch, user?.id, locationId, canAccessQuiz]);

	useEffect(() => {
		if (canAccessQuiz) {
			dispatch(fetchQuizzesSummary());
		}
	}, [dispatch, canAccessQuiz]);

	const handleSelectQuizStatus = useCallback(
		status => {
			setStatus(status);
			dispatch(fetchQuizzes({ page: 1, userId: user?.id, locationId, status }));
		},
		[dispatch, user?.id, locationId]
	);
	const SeeResultsButton = ({ id }) => (
		<MuiButton variant={"contained"} color={"primary"} onClick={() => setSelectedQuizId(id)}>
			Results
		</MuiButton>
	);
	const ActionButton = ({ text, disabled = false, id }: { text: string; disabled?: boolean; id: number }) => (
		<MuiButton
			variant={"contained"}
			color={"primary"}
			onClick={() => history.push(`${routes.test.getPath()}/${id}`)}
			disabled={disabled}
		>
			{text}
		</MuiButton>
	);

	const tableColumns: Array<IColumn<TestType>> = useMemo(
		() => [
			{
				alignment: "left",
				label: <ColumnHeader>Test Name</ColumnHeader>,
				width: 160,
				Cell: ({ rowData: { name } }) => (
					<StyledCellWrapper>
						<StyledCellText>{name}</StyledCellText>
					</StyledCellWrapper>
				),
				dataKey: "name"
			},
			{
				alignment: "center",
				label: <ColumnHeader>Available From</ColumnHeader>,
				width: 30,
				Cell: ({ rowData: { liveAfter } }) => (
					<StyledCellWrapper center>
						<StyledCellText>{liveAfter ? formatDate(liveAfter) : ""}</StyledCellText>
					</StyledCellWrapper>
				),
				dataKey: "liveAfter"
			},
			{
				alignment: "center",
				label: <ColumnHeader>Available Until</ColumnHeader>,
				width: 60,
				Cell: ({ rowData: { expiresOn } }) => (
					<StyledCellWrapper center>
						<StyledCellText>{expiresOn ? formatDate(expiresOn) : ""}</StyledCellText>
					</StyledCellWrapper>
				),
				dataKey: "expiresOn"
			},
			{
				alignment: "center",
				label: <ColumnHeader>State</ColumnHeader>,
				width: 100,
				Cell: ({ rowData: { liveAfter, expiresOn } }) => (
					<StyledCellWrapper center>
						<StyledCellText color={getDesiredColor(getState(liveAfter, expiresOn))}>
							{getState(liveAfter, expiresOn)}
						</StyledCellText>
					</StyledCellWrapper>
				),
				dataKey: "state"
			},
			{
				alignment: "center",
				label: <ColumnHeader>Questions</ColumnHeader>,
				width: 20,
				Cell: ({ rowData: { data } }) => (
					<StyledCellWrapper center>
						<StyledCellText>{data.questionCount || 0}</StyledCellText>
					</StyledCellWrapper>
				),
				dataKey: "questionCount"
			},
			{
				alignment: "center",
				label: <ColumnHeader>Attempts</ColumnHeader>,
				width: 20,
				Cell: ({ rowData: { attempts } }) => {
					const yourAttempt = findAttemptForUser(attempts);
					return (
						<StyledCellWrapper center>
							<StyledCellText>{yourAttempt?.attemptCount || 0}</StyledCellText>
						</StyledCellWrapper>
					);
				},
				dataKey: "timesTaken"
			},
			{
				alignment: "center",
				label: <ColumnHeader>Max Attempts</ColumnHeader>,
				width: 20,
				Cell: ({ rowData: { data } }) => (
					<StyledCellWrapper center>
						<StyledCellText>{data?.maxAttempts || "-"}</StyledCellText>
					</StyledCellWrapper>
				),
				dataKey: "maxAttempts"
			},
			{
				alignment: "center",
				label: <ColumnHeader>Last Score</ColumnHeader>,
				width: 60,
				Cell: ({ rowData: { attempts } }) => {
					const yourAttempt = findAttemptForUser(attempts);
					const validateStatus = [TestStatus.NotTaken, TestStatus.InProgress].includes(getStatusFromAttempts(attempts));
					return (
						<StyledCellWrapper center>
							<StyledCellText color={getDesiredColor(!validateStatus)}>
								{!validateStatus ? `${Math.round((yourAttempt?.percentageGrade || 0) * 100)}%` : "-"}
							</StyledCellText>
						</StyledCellWrapper>
					);
				},
				dataKey: "lastScore"
			},
			{
				alignment: "center",
				label: <ColumnHeader>Status</ColumnHeader>,
				width: 60,
				Cell: ({ rowData: { attempts } }) => {
					const status = getStatusFromAttempts(attempts);
					return (
						<StyledCellWrapper center>
							<StyledCellText color={getDesiredColor(status)}>{status}</StyledCellText>
						</StyledCellWrapper>
					);
				},
				dataKey: "status"
			},
			{
				alignment: "center",
				label: <ColumnHeader></ColumnHeader>,
				width: 80,
				Cell: ({ rowData: { id, attempts, liveAfter, expiresOn } }) => {
					const yourAttempt = findAttemptForUser(attempts);
					const isAttemptedCountExceeded = yourAttempt
						? yourAttempt?.data.data.maxAttempts === yourAttempt?.attemptCount
						: false;
					const attemptStatus = getStatusFromAttempts(attempts);
					const testState = getState(liveAfter, expiresOn);

					const getActionButton = attemptStatus => {
						const hasExpired = testState === State.Expired;
						const isScheduled = testState === State.ComingSoon;
						switch (attemptStatus) {
							case TestStatus.Passed: {
								return <SeeResultsButton id={id} />;
							}
							case TestStatus.InProgress: {
								return <ActionButton text={"Resume Test"} disabled={hasExpired} id={id} />;
							}
							case TestStatus.Failed: {
								if (isAttemptedCountExceeded || hasExpired) {
									return <SeeResultsButton id={id} />;
								}
								return <ActionButton text={"Retake"} id={id} />;
							}
							default: {
								return <ActionButton text={"Take Test"} disabled={hasExpired || isScheduled} id={id} />;
							}
						}
					};

					return (
						<StyledCellWrapper center>
							<TakeTestButtonWrapper>{getActionButton(attemptStatus)}</TakeTestButtonWrapper>
						</StyledCellWrapper>
					);
				},
				dataKey: "takeQuiz"
			}
		],
		[getDesiredColor, theme, getOpacity, findAttemptForUser, getStatusFromAttempts, history]
	);

	return (
		<Wrapper heading="Your Tests">
			<Box
				mt={5}
				mb={isSummaryLoading ? 5 : 3}
				display={"flex"}
				flexWrap={"wrap"}
				justifyContent={isSummaryLoading ? "center" : "start"}
			>
				{isSummaryLoading ? (
					<CircularProgress size="3rem" color="primary" thickness={5} variant="indeterminate" />
				) : (
					<Grid container spacing={3}>
						<Grid item xs={12} lg={3} md={6}>
							<InfoBlock
								customBgColor={theme.palette.background.paper}
								title={"Last exam attempted:"}
								text={lastAttemptedExam}
								titleCustomStyle={{ color: theme.palette.text.primary }}
								textCustomStyle={{ fontSize: 18, textOverflow: "ellipsis", overflow: "hidden" }}
								textContainerCustomStyle={{ display: "grid", flexDirection: "column", justifyContent: "space-between" }}
								containerCustomStyle={{ margin: 0, maxWidth: "initial" }}
							/>
						</Grid>
						<Grid item xs={12} lg={3} md={6}>
							<InfoBlock
								customBgColor={theme.palette.background.paper}
								title={"Total Quizzes"}
								text={totalQuizzes}
								IconSvg={() => (
									<IconWrapper $bgColor={theme.palette.colors.primary[500]}>
										<TestSvgIcon />
									</IconWrapper>
								)}
								containerCustomStyle={{ margin: 0, maxWidth: "initial" }}
							/>
						</Grid>
						<Grid item xs={12} lg={3} md={6}>
							<InfoBlock
								customBgColor={theme.palette.background.paper}
								title={"Tests Passed"}
								text={totalPassed}
								IconSvg={() => (
									<IconWrapper $bgColor={theme.palette.colors.success[800]}>
										<CheckSvgIcon />
									</IconWrapper>
								)}
								containerCustomStyle={{ margin: 0, maxWidth: "initial" }}
							/>
						</Grid>
						<Grid item xs={12} lg={3} md={6}>
							<InfoBlock
								customBgColor={theme.palette.background.paper}
								title={"Tests Failed"}
								text={totalFailed}
								IconSvg={() => (
									<IconWrapper $bgColor={theme.palette.colors.danger[800]}>
										<CrossSvgIcon />
									</IconWrapper>
								)}
								containerCustomStyle={{ margin: 0, maxWidth: "initial" }}
							/>
						</Grid>
					</Grid>
				)}
			</Box>
			<StudentsBox mt={1}>
				<THeaderWrapper>
					<Typography>Test History</Typography>
					<FormControl hiddenLabel size="small" variant="filled">
						<Select
							disableUnderline
							value={status}
							onChange={event => handleSelectQuizStatus(event.target.value)}
							displayEmpty
							MenuProps={{
								anchorOrigin: {
									vertical: "bottom",
									horizontal: "left"
								},
								transformOrigin: {
									vertical: "top",
									horizontal: "left"
								},
								getContentAnchorEl: null
							}}
						>
							<MenuItem value={""}>All Statuses</MenuItem>
							{Object.entries(TestStatus).map(([key, value]) => (
								<MenuItem key={key} value={key}>
									{value}
								</MenuItem>
							))}
						</Select>
					</FormControl>
				</THeaderWrapper>
				{isLoading ? (
					<LoadingWrapper>
						<CircularProgress size="7rem" color="primary" thickness={5} variant="indeterminate" />
					</LoadingWrapper>
				) : (
					<>
						<MaterialTable
							columns={tableColumns}
							customStyle={{
								borderRadius: "0 0 4px 4px"
							}}
							disableRow={({ liveAfter, attempts, expiresOn }) => {
								const yourAttempt = findAttemptForUser(attempts);
								const attemptStatus = getStatusFromAttempts(attempts);
								const isAttemptedCountExceeded = yourAttempt
									? yourAttempt?.data.data.maxAttempts === yourAttempt?.attemptCount
									: false;
								return (
									(isAttemptedCountExceeded && attemptStatus !== TestStatus.InProgress) ||
									getState(liveAfter, expiresOn) === State.Expired
								);
							}}
							data={items}
							height={900}
						/>
						<TablePagination
							count={totalItems || 0}
							page={page}
							onChange={(_, page) => dispatch(fetchQuizzes({ page, userId: user?.id, locationId }))}
							rowsPerPage={perPage}
						/>
					</>
				)}
				{!!selectedQuizId && (
					<Modal disableEnforceFocus disableAutoFocus open={true} onClose={() => setSelectedQuizId(null)}>
						<QuizResultsContainer>
							<IconButton aria-label="close" onClick={() => setSelectedQuizId(null)} style={{ alignSelf: "flex-end" }}>
								<Close />
							</IconButton>
							{quizResult && userAttemptedQuestions ? (
								<QuizResultsWrapper>
									<QuizResults
										results={quizResult}
										userAttemptedQuestions={userAttemptedQuestions}
										onClose={() => setSelectedQuizId(null)}
										onNextLesson={() => {}}
										showNextLesson={false}
									/>
								</QuizResultsWrapper>
							) : (
								<Box display="flex" alignItems="center" justifyContent="center" height={200} width="100%">
									<CircularProgress size="7rem" color="primary" thickness={5} variant="indeterminate" />
								</Box>
							)}
						</QuizResultsContainer>
					</Modal>
				)}
			</StudentsBox>
		</Wrapper>
	);
};

export default Tests;

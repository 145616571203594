import { BaseApiService, BaseDeleteDto, BaseFindDto, BaseFindOneDto } from "@remar/shared/dist/api/baseApiService";
import { request } from "@remar/shared/dist/api/request";
import {
	PaymentHistory,
	User,
	UserCertificate,
	UserHelpRequest,
	UserPaymentProviderAccount
} from "@remar/shared/dist/models";

import {
	GuestSignUpDto,
	UpdateBillingAddressDto,
	UpdatePaymentMethodDto,
	UserChangePasswordDto,
	UserCreateSubscriptionDto,
	UserCreateSubscriptionResponseDto,
	UserGetCurrentSubscriptionPaymentMethodDataDto,
	UserGetSubscriptionInfoResponseDto,
	UserInvitationDto,
	UserInvitationResponseDto,
	UserLoginDto,
	UserLoginResponseDto,
	UserSendHelpRequestDto,
	UserSignUpDto,
	UserSignUpInvitation,
	UserSignUpResponseDto,
	UserSignUpVerificationDto,
	UserSocialSignUpDto,
	UserUpdateDto,
	UserValidateDuplicateEmailResponseDto
} from "./dto";

import { UserUpdateSubscriptionDto } from "./dto/users.updateSubscription.dto";

import { URLS } from "../services.constants";

export class UsersService extends BaseApiService<
	User,
	Record<string, unknown>,
	BaseFindDto,
	BaseFindOneDto,
	UserUpdateDto,
	BaseDeleteDto
> {
	constructor(baseUrl: string) {
		super(baseUrl);
	}

	createSubscription(data: UserCreateSubscriptionDto): Promise<UserCreateSubscriptionResponseDto> {
		return request(
			`${this.baseUrl}/subscription`,
			"POST",
			this.emptyStringToNull(data) as Record<string, unknown>
		) as Promise<UserCreateSubscriptionResponseDto>;
	}

	cancelSubscription(id: number): Promise<unknown> {
		return request(
			`${this.baseUrl}/subscription`,
			"DELETE",
			this.emptyStringToNull({ userSubscriptionId: id }) as Record<string, unknown>
		) as Promise<unknown>;
	}

	deleteAccount(password: string): Promise<unknown> {
		return request(`${this.baseUrl}/`, "DELETE", { password }) as Promise<unknown>;
	}
	buyCATTests({
		quantity,
		paymentProviderPaymentMethodIdentifier,
		catPaymentId
	}: {
		quantity: number;
		paymentProviderPaymentMethodIdentifier: string;
		catPaymentId: number;
	}): Promise<{ amount?: number }> {
		return request(
			`${this.baseUrl}/payment-cat`,
			"POST",
			this.emptyStringToNull({
				paymentProviderId: 1,
				paymentProviderPaymentMethodIdentifier,
				catPaymentId,
				quantity
			}) as Record<string, unknown>
		) as Promise<{ amount?: number }>;
	}
	changeSubscriptionDate(data: UserUpdateSubscriptionDto): Promise<unknown> {
		return request(
			`${this.baseUrl}/subscription/update`,
			"POST",
			this.emptyStringToNull(data) as Record<string, unknown>
		) as Promise<unknown>;
	}

	forgotPassword(email: string): Promise<unknown> {
		return request(
			`${this.baseUrl}/forgot-password`,
			"POST",
			this.emptyStringToNull({ email }) as Record<string, unknown>
		) as Promise<unknown>;
	}

	forgotVerification(code: string): Promise<unknown> {
		return request(
			`${this.baseUrl}/forgot-verification`,
			"POST",
			this.emptyStringToNull({ code }) as Record<string, unknown>
		) as Promise<unknown>;
	}

	getAccountInfo(): Promise<{ user: User }> {
		return request(`${this.baseUrl}/account`, "GET") as Promise<{ user: User }>;
	}

	getCurrentSubscriptionPaymentMethodData(
		data: UserGetCurrentSubscriptionPaymentMethodDataDto
	): Promise<UserPaymentProviderAccount> {
		return request(
			`${this.baseUrl}/payment-method${this.serializeQuery(data as Record<string, unknown>, { isTopLevel: true })}`,
			"GET"
		) as Promise<UserPaymentProviderAccount>;
	}

	getSubscriptionInfo(): Promise<UserGetSubscriptionInfoResponseDto> {
		return request(`${this.baseUrl}/subscription`, "GET") as Promise<UserGetSubscriptionInfoResponseDto>;
	}

	getPaymentHistory(data: Record<string, unknown>): Promise<PaymentHistory> {
		return request(
			`${this.baseUrl}/payment-history${this.serializeQuery(data as Record<string, unknown>, { isTopLevel: true })}`,
			"GET"
		) as Promise<PaymentHistory>;
	}

	updatePaymentMethod(data: UpdatePaymentMethodDto): Promise<unknown> {
		return request(
			`${this.baseUrl}/payment-method`,
			"PATCH",
			this.emptyStringToNull(data) as Record<string, unknown>,
			{},
			false,
			true
		) as Promise<unknown>;
	}

	updateBillingAddress(data: UpdateBillingAddressDto): Promise<unknown> {
		return request(
			`${this.baseUrl}/billing-address`,
			"PATCH",
			this.emptyStringToNull(data) as Record<string, unknown>,
			{},
			false,
			true
		) as Promise<unknown>;
	}

	login(data: UserLoginDto): Promise<UserLoginResponseDto> {
		return request(`${this.baseUrl}/login`, "POST", this.emptyStringToNull(data) as Record<string, unknown>, {
			credentials: "include"
		}) as Promise<UserLoginResponseDto>;
	}

	loginByToken(data: { sessionId: string }): Promise<UserLoginResponseDto> {
		return request(`${this.baseUrl}/login-token`, "POST", this.emptyStringToNull(data) as Record<string, unknown>, {
			credentials: "include"
		}) as Promise<UserLoginResponseDto>;
	}

	logout(): Promise<void> {
		return request(`${this.baseUrl}/logout`, "POST", {}, { credentials: "include" }, false, true) as Promise<void>;
	}

	resendEmailVerification(email: string): Promise<UserValidateDuplicateEmailResponseDto> {
		return request(
			`${this.baseUrl}/resend-email-verification`,
			"POST",
			this.emptyStringToNull(email) as Record<string, unknown>
		) as Promise<UserValidateDuplicateEmailResponseDto>;
	}

	resetPassword(data: { code: string; password: string }): Promise<unknown> {
		return request(
			`${this.baseUrl}/reset-password`,
			"POST",
			this.emptyStringToNull(data) as Record<string, unknown>
		) as Promise<unknown>;
	}

	changePassword(data: UserChangePasswordDto): Promise<unknown> {
		return request(
			`${this.baseUrl}/change-password`,
			"PATCH",
			this.emptyStringToNull(data) as Record<string, unknown>
		) as Promise<unknown>;
	}

	resumeSubscription(id: number): Promise<unknown> {
		return request(
			`${this.baseUrl}/subscription/resume`,
			"POST",
			this.emptyStringToNull({
				subscriptionId: id
			}) as Record<string, unknown>
		) as Promise<unknown>;
	}

	sendHelpRequest(data: UserSendHelpRequestDto): Promise<UserHelpRequest> {
		return request(
			`${this.baseUrl}/help`,
			"POST",
			this.emptyStringToNull(data) as Record<string, unknown>,
			{},
			false,
			true
		) as Promise<UserHelpRequest>;
	}

	signUp(data: UserSignUpDto): Promise<UserSignUpResponseDto> {
		return request(
			`${this.baseUrl}/signup`,
			"POST",
			this.emptyStringToNull(data) as Record<string, unknown>
		) as Promise<UserSignUpResponseDto>;
	}

	guestCheckout(data: GuestSignUpDto): Promise<UserSignUpResponseDto> {
		return request(
			`${this.baseUrl}/guest-checkout`,
			"POST",
			this.emptyStringToNull(data) as Record<string, unknown>
		) as Promise<UserSignUpResponseDto>;
	}

	guestSignUp(data: GuestSignUpDto): Promise<UserSignUpResponseDto> {
		return request(
			`${this.baseUrl}/guest-signup`,
			"POST",
			this.emptyStringToNull(data) as Record<string, unknown>
		) as Promise<UserSignUpResponseDto>;
	}

	guestSignUpVerification(accountClaimCode: string): Promise<UserSignUpVerificationDto> {
		return request(
			`${this.baseUrl}/guest-signup-verification`,
			"POST",
			this.emptyStringToNull({ accountClaimCode }) as Record<string, unknown>
		) as Promise<UserSignUpVerificationDto>;
	}

	validateDuplicateEmail(email: string): Promise<UserValidateDuplicateEmailResponseDto> {
		return request(
			`${this.baseUrl}/validate-duplicate-email`,
			"POST",
			this.emptyStringToNull({ email }) as Record<string, unknown>
		) as Promise<UserValidateDuplicateEmailResponseDto>;
	}

	whoami(): Promise<{ user: User }> {
		return request(`${this.baseUrl}/whoami`, "GET") as Promise<{ user: User }>;
	}

	getUserCertificate(): Promise<UserCertificate> {
		return request(`${this.baseUrl}/create-certificate`, "POST") as Promise<UserCertificate>;
	}

	facebookLogin(tokenId: string): Promise<UserLoginResponseDto> {
		return request(
			`${this.baseUrl}/login-facebook`,
			"POST",
			this.emptyStringToNull({ tokenId }) as Record<string, unknown>,
			{
				credentials: "include"
			}
		) as Promise<UserLoginResponseDto>;
	}

	facebookSignUp(data: UserSocialSignUpDto): Promise<UserSignUpResponseDto> {
		return request(
			`${this.baseUrl}/signup-facebook`,
			"POST",
			this.emptyStringToNull(data) as Record<string, unknown>
		) as Promise<UserSignUpResponseDto>;
	}

	googleLogin(tokenId: string): Promise<UserLoginResponseDto> {
		return request(
			`${this.baseUrl}/login-google`,
			"POST",
			this.emptyStringToNull({ tokenId }) as Record<string, unknown>,
			{
				credentials: "include"
			}
		) as Promise<UserLoginResponseDto>;
	}
	googleSignUp(data: UserSocialSignUpDto): Promise<UserSignUpResponseDto> {
		return request(
			`${this.baseUrl}/signup-google`,
			"POST",
			this.emptyStringToNull(data) as Record<string, unknown>
		) as Promise<UserSignUpResponseDto>;
	}
	getInvitationDetails(data: UserInvitationDto): Promise<UserInvitationResponseDto> {
		return request(
			`${this.baseUrl}/institution-invite-verification`,
			"POST",
			this.emptyStringToNull(data) as Record<string, unknown>
		) as Promise<UserInvitationResponseDto>;
	}

	studentSignUpInvitation(data: UserSignUpInvitation): Promise<UserSignUpResponseDto> {
		return request(
			`${this.baseUrl}/institution-invite-signup`,
			"POST",
			this.emptyStringToNull(data) as Record<string, unknown>
		) as Promise<UserSignUpResponseDto>;
	}

	confirmAcceptedTermsAndConditions(
		acceptedTermsAndConditions: boolean
	): Promise<{ acceptedTermsAndConditions: boolean }> {
		return request(`${this.baseUrl}/confirm-terms-and-conditions`, "PATCH", {
			acceptedTermsAndConditions
		}) as Promise<{ acceptedTermsAndConditions: boolean }>;
	}
}

export const usersService = new UsersService(URLS.USERS);

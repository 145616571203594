import React from "react";

import { Radio } from "@material-ui/core";

import { TestQuestionStyledContainer, TestQuizQuestionText } from "modules/QuestionBank/styles";

import { QuizQuestionAnswerOption, StyledText } from "./style";

const SingleChoiceQuestion = ({ question, onChange, userAnswers }) => {
	const answerOptions = question.data.answerOptions;

	const handleChangeAnswer = answerId => {
		onChange([{ id: answerId }]);
	};

	return (
		<>
			<TestQuestionStyledContainer>
				<TestQuizQuestionText>{question?.text}</TestQuizQuestionText>
			</TestQuestionStyledContainer>

			{answerOptions?.map(({ id, text }) => (
				<QuizQuestionAnswerOption key={id}>
					<Radio
						color="default"
						checked={userAnswers.some(item => item.id == id)}
						onChange={() => handleChangeAnswer(id)}
					/>
					<StyledText>{text}</StyledText>
				</QuizQuestionAnswerOption>
			))}
		</>
	);
};

export default SingleChoiceQuestion;

import React, { FC } from "react";

import { Box, Button, Card, Container, IconButton, Typography } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";

import { useDispatch } from "react-redux";

import { RootState, useAppSelector } from "store";
import { changeSubscribedIntegrations } from "store/features/Auth/authSlice";

import { useChangeCourseStyles } from "./styles";
interface Props {
	onClose: () => void;
}

const ChangeBook: FC<Props> = ({ onClose }) => {
	const classes = useChangeCourseStyles();
	const { subExternalIntegrations, externalIntegrations }: RootState["auth"] = useAppSelector(
		(store: RootState) => store.auth
	);
	const dispatch = useDispatch();

	const selectedIntegrationIds = subExternalIntegrations?.map(x => x.id);

	return (
		<Container maxWidth="md">
			<Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" height="100vh">
				<Card className={classes.modalContainer}>
					<Box>
						<Box display="flex" alignItems="center" justifyContent={"space-between"}>
							<Typography className={classes.changeCourseTitle} variant={"h5"}>
								Change Book
							</Typography>
							<IconButton onClick={onClose}>
								<CloseIcon color="disabled" />
							</IconButton>
						</Box>
					</Box>

					<Box display="flex" justifyContent="center">
						<Box className={classes.containerClass}>
							{externalIntegrations
								?.filter(item => !item.parentId)
								?.map(addon => {
									return (
										<Box
											className={`${classes.itemClass} ${
												selectedIntegrationIds?.includes(addon.id) ? classes.selectedBook : ""
											}`}
											key={addon.id}
										>
											<Box>
												<Typography className={classes.changeCourseAmount} variant="h6">
													{(addon?.data["price"] || 0).toLocaleString("en-US", {
														currency: "USD",
														style: "currency"
													})}
												</Typography>
												<Typography className={classes.changeCourseName} variant="h6">
													{addon?.data["name"] as string}
												</Typography>
											</Box>

											<Box display="flex" justifyContent="center">
												<img className={classes.bookImage} src={addon?.mainImageUrl} />
											</Box>

											<Box display="flex" justifyContent="center" mt={3} mr={1} mb={2.5}>
												{selectedIntegrationIds?.includes(addon.id) ? (
													<Box className={classes.selectedCourse}>
														<span>Selected</span>
													</Box>
												) : (
													<Button
														variant="contained"
														color="primary"
														size="small"
														className={classes.selectCourse}
														onClick={() => {
															dispatch(changeSubscribedIntegrations(addon.id));
														}}
													>
														Select Book
													</Button>
												)}
											</Box>
										</Box>
									);
								})}
						</Box>
					</Box>

					<Box display="flex" justifyContent="flex-end" mt={3}>
						<Button
							variant="contained"
							color="primary"
							className={classes.selectCourse}
							onClick={() => onClose()}
							disabled={!subExternalIntegrations?.length}
						>
							{"Close"}
						</Button>
					</Box>
				</Card>
			</Box>
		</Container>
	);
};

export default ChangeBook;

import React, { useEffect, useState } from "react";

import { Box, Button, Card, Checkbox, CircularProgress, FormControlLabel, Typography } from "@material-ui/core";
import { FullScreenWithLogoLayout } from "@remar/shared/dist/layouts";
import { Form, Formik } from "formik";

import { useDispatch, useSelector } from "react-redux";

import { useParams } from "react-router-dom";

import { RootState, useAppSelector } from "store";
import {
	changeSubscriptionType,
	setError,
	setSubscriptionTypesForSignup,
	setUserSubscriptionTypeId,
	socialTrialSignUp
} from "store/features/Auth/authSlice";

import AuthBackground from "assets/images/signup-background.png";

import { AccountFormDetails } from "../components/Forms";
import SignUpSuccessLeft from "../components/SignUpSuccessLeft";
import SignUpSuccessRight from "../components/SignUpSuccessRight";
import { SocialTrialSignupSchema } from "../components/schemas";
import { useStyles } from "../components/styles";

const initialFormValues = {
	firstName: "",
	lastName: "",
	email: sessionStorage.getItem("email"),
	tokenId: sessionStorage.getItem("tokenId"),
	terms: false
};

const TrialSignUp = () => {
	const classes = useStyles();
	const dispatch = useDispatch();
	const { courseId } = useParams<{ courseId?: string }>();
	const [signUpCompleted, setSignUpCompleted] = useState(false);
	const { errorMessage: error, isLoading: loading } = useSelector((state: RootState) => state.auth);
	const { subscriptionTypes }: RootState["auth"] = useAppSelector((store: RootState) => store.auth);

	const activeSubscription = subscriptionTypes[0];

	useEffect(() => {
		return () => {
			dispatch(setUserSubscriptionTypeId(0));
		};
	}, [dispatch]);

	useEffect(() => {
		dispatch(
			setSubscriptionTypesForSignup({
				courseId: courseId as unknown as number,
				isTrial: true
			})
		);
	}, [courseId, dispatch]);

	useEffect(() => {
		if (activeSubscription) {
			dispatch(changeSubscriptionType(activeSubscription?.id as number));
		}
	}, [activeSubscription, dispatch]);

	const socialSignUp = async (values: typeof initialFormValues) => {
		// eslint-disable-next-line @typescript-eslint/ban-ts-comment
		// @ts-ignore
		dispatch(socialTrialSignUp({ values: { ...values } })).then(r => {
			if (r.error) {
				dispatch(setError(r.error.message));
			} else {
				setSignUpCompleted(true);
			}
		});
	};

	const handleSubmit = (values: typeof initialFormValues) => {
		socialSignUp(values);
	};

	const finalScreen = (
		<Box display="flex" width="80em" className={classes.trialSuccessContainer}>
			<Card className={classes.cardLeft}>
				<SignUpSuccessLeft trial={true} />
			</Card>
			<Card className={classes.cardRight}>
				<SignUpSuccessRight />
			</Card>
		</Box>
	);

	return (
		<FullScreenWithLogoLayout customBgImage={AuthBackground}>
			{signUpCompleted ? (
				finalScreen
			) : (
				<Box maxWidth={570} width="100%" margin="auto">
					<Card className={classes.cardTrial}>
						<Formik
							initialValues={initialFormValues}
							validationSchema={SocialTrialSignupSchema}
							onSubmit={values => {
								handleSubmit(values);
							}}
						>
							{({ isValid, values, touched, setFieldValue, errors }) => {
								const { terms } = values;
								const neverTouched = Object.keys(touched).length === 0;
								const valid = isValid && !neverTouched && terms;

								return (
									<Box px={9} pt={6} pb={4}>
										<Form>
											<AccountFormDetails title="Trial Sign Up" password={false} disabledField />
										</Form>
										{error && (
											<Box mt={3}>
												<Typography variant="caption" style={{ color: "red" }}>
													{error}
												</Typography>
											</Box>
										)}

										<Box mt={1}>
											<FormControlLabel
												control={
													<Checkbox
														checked={values.terms}
														onChange={() => setFieldValue("terms", !values.terms)}
														color="primary"
														name="terms"
													/>
												}
												label={
													<span className={classes.termsAndConditionsCheck}>
														I agree to the <span className={classes.termsLink}>Terms & Conditions</span>
													</span>
												}
											/>
											{errors.terms && (
												<>
													<br />
													<Typography variant="caption" style={{ color: "red" }}>
														{errors.terms}
													</Typography>
												</>
											)}
										</Box>
										<Box mt={1} display="flex" justifyContent="flex-end">
											<Button
												size="large"
												color="primary"
												disabled={!valid || loading || !activeSubscription}
												startIcon={loading && <CircularProgress size={20} />}
												variant="contained"
												onClick={() => handleSubmit(values)}
											>
												Create Account
											</Button>
										</Box>
									</Box>
								);
							}}
						</Formik>
					</Card>
				</Box>
			)}
		</FullScreenWithLogoLayout>
	);
};

export default TrialSignUp;

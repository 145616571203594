import React, { useEffect, useMemo, useState } from "react";

import { Box, Card, CircularProgress, Typography } from "@material-ui/core";
import Stepper from "@remar/shared/dist/components/Stepper";
import useStateWithCallback from "@remar/shared/dist/hooks/useStateWithCallback";
import { Country } from "@remar/shared/dist/models";

import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js";
import { Form, Formik } from "formik";
import { useDispatch, useSelector } from "react-redux";

import { useHistory, useParams } from "react-router-dom";
import { AppDispatch, RootState } from "store";

import { fetchCountries, getInvitationDetails, studentSignUpInvitation } from "store/features/Auth/authSlice";

import { getFullState } from "store/features/Theme/theme.slice";

import logo from "assets/images/logo.svg";

import { routes } from "core/constants";

import Summary from "./Summary";
import { accountInitialValue, paymentInitValue, shippingInitValue } from "./types";

import SignUpLayout from "../SignUpLayout";
import FormActions from "../components/FormActions";
import { AccountFormDetails, PaymentForm, ShippingForm } from "../components/Forms";
import SignUpFollowUpScreen from "../components/SignUpFollowUpScreen";

import { AccountSchema, PaymentSchema, ShippingSchema } from "../components/schemas";

import { CardsContainer, useStyles } from "../components/styles";
import { getValidValue } from "../components/utils";

export enum Steps {
	ACCOUNT_STEP,
	SHIPPING_STEP,
	PAYMENT_STEP,
	SUCCESS_STEP,
	ERROR_STEP
}
const steps = [{ label: "Account" }, { label: "Shipping" }, { label: "Payment" }];
const Logo: React.ReactNode = () => {
	const { logoImageUrl, isLoading } = useSelector(getFullState);
	return (
		<Box display={"flex"} justifyContent={"center"} mt={4}>
			{isLoading && <img alt="institution logo" src={logoImageUrl || logo} width={160} height={80} />}
		</Box>
	);
};

const InvitationSingUp = () => {
	const { invitationId } = useParams<{ invitationId: string }>();
	const { isLoading, invitationDetails, invitationDetailsLoading, errorMessage } = useSelector(
		({ auth }: RootState) => auth
	);
	const classes = useStyles();
	const dispatch = useDispatch<AppDispatch>();
	const history = useHistory();
	const elements = useElements();
	const stripe = useStripe();
	const [activeStep, setActiveStep] = useStateWithCallback(Steps.ACCOUNT_STEP);
	const [country, setCountry] = useState<Country>();

	const initialValues = useMemo(() => {
		const updatedInitialValue = {
			...accountInitialValue,
			email: invitationDetails?.user.email,
			firstName: invitationDetails?.user.firstName,
			lastName: invitationDetails?.user.lastName
		};
		return {
			...updatedInitialValue,
			...shippingInitValue,
			...paymentInitValue
		};
	}, [invitationDetails]);
	const schema = useMemo(() => {
		if (activeStep === Steps.ACCOUNT_STEP) return AccountSchema;
		if (activeStep === Steps.SHIPPING_STEP) return ShippingSchema;
		if (activeStep === Steps.PAYMENT_STEP) return PaymentSchema;
	}, [activeStep]);

	useEffect(() => {
		if (invitationId) {
			dispatch(getInvitationDetails({ inviteCode: invitationId }));
		}
	}, [dispatch, history, invitationId]);
	useEffect(() => {
		dispatch(fetchCountries(0));
	}, [dispatch]);

	const handleNext = (values, validateForm) => {
		if (activeStep === Steps.PAYMENT_STEP) {
			handleSubmit(values);
		} else {
			setActiveStep(
				prevState => prevState + 1,
				() => validateForm()
			);
		}
	};
	const handleSubmit = values => {
		dispatch(
			studentSignUpInvitation({
				CardElement,
				elements,
				stripe,
				values: { invitationId, ...values }
			})
		).then(resposne => {
			if (resposne.error) {
				setActiveStep(Steps.ERROR_STEP);
			} else {
				setActiveStep(Steps.SUCCESS_STEP);
			}
		});
	};

	const handleClickFollowUp = () => {
		if (activeStep === 3) {
			// continue clicked
			history.replace(routes.signIn.getPath());
		} else {
			// try again clicked
			setActiveStep(Steps.PAYMENT_STEP);
		}
	};

	return invitationDetailsLoading ? (
		<Box display="flex" alignItems="center" justifyContent="center" height={450}>
			<CircularProgress size="7rem" color="primary" thickness={5} variant="indeterminate" />
		</Box>
	) : (
		<SignUpLayout logo={Logo}>
			{errorMessage ? (
				<Box
					display="flex"
					flexDirection="column"
					alignItems="center"
					justifyContent="center"
					padding="16px 24px"
					borderRadius={4}
					mb={5}
					mt={30}
				>
					<Typography className={classes.errorTitle}>Error</Typography>
					<Box display="flex" justifyContent="center" mt={5}>
						<Typography className={classes.errorDescription}>{errorMessage}</Typography>
					</Box>
				</Box>
			) : (
				<Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" mb={5} mt={5}>
					<CardsContainer $activeStep={"column"} display="flex" justifyContent="center">
						<Formik
							initialValues={initialValues}
							validationSchema={schema}
							validateOnChange
							onSubmit={values => {
								handleNext(values);
							}}
						>
							{({ isValid, dirty, setErrors, validateForm, values, setFieldValue, touched, setTouched }) => {
								let valid;
								if (activeStep === Steps.SHIPPING_STEP) {
									valid = getValidValue(Object.keys(touched)) ? isValid && dirty : false;
								} else {
									valid = isValid && dirty;
								}
								switch (activeStep) {
									case Steps.ACCOUNT_STEP:
									case Steps.SHIPPING_STEP:
									case Steps.PAYMENT_STEP: {
										return (
											<>
												<Card className={`${classes.card} ${classes.baseFlex}`}>
													<Box>
														<Box px={6} pt={2} className={classes.stepperForm}>
															<Stepper activeStep={activeStep} steps={steps} />
															<Form>
																{activeStep === Steps.ACCOUNT_STEP && (
																	<AccountFormDetails showPasswordOption={true} disabledField />
																)}
																{activeStep === Steps.SHIPPING_STEP && (
																	<ShippingForm setCountry={setCountry} country={country} />
																)}
																{activeStep === Steps.PAYMENT_STEP && (
																	<PaymentForm
																		setFieldValue={setFieldValue}
																		touched={touched}
																		setTouched={setTouched}
																		termValue={values.terms}
																		showTerms={true}
																	/>
																)}
																<FormActions
																	back={() => {
																		if (activeStep === Steps.PAYMENT_STEP) {
																			setFieldValue("validCardDetails", false);
																		}
																		setErrors({});
																		setActiveStep(
																			prevState =>
																				prevState > Steps.ACCOUNT_STEP ? prevState - 1 : Steps.ACCOUNT_STEP,
																			() => {
																				validateForm();
																			}
																		);
																	}}
																	next={() => {
																		setErrors({});
																		handleNext(values, validateForm);
																	}}
																	valid={valid}
																	loading={isLoading}
																	step={activeStep}
																	showSignIn={false}
																/>
															</Form>
														</Box>
													</Box>
												</Card>
												<Summary
													invitedBy={invitationDetails?.invitedBy}
													subscriptionData={invitationDetails?.subscriptionData}
													country={country}
												/>
											</>
										);
									}
									case Steps.SUCCESS_STEP:
									case Steps.ERROR_STEP:
										return (
											<SignUpFollowUpScreen
												success={activeStep === Steps.SUCCESS_STEP}
												error={activeStep === Steps.ERROR_STEP}
												onClick={handleClickFollowUp}
											/>
										);
									default:
										return;
								}
							}}
						</Formik>
					</CardsContainer>
				</Box>
			)}
		</SignUpLayout>
	);
};

export default InvitationSingUp;

import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";

import { CircularProgress, Theme, useMediaQuery, useTheme } from "@material-ui/core";

import { useHistory } from "react-router-dom";
import { useAppSelector } from "store";
import { selectIsCourseComplete } from "store/features/Course/courseSlice";

import { AutoplayContainer, NextLessonTimer, Play, PlayNextLesson, Progress, ProgressCircle } from "./style";

interface AutoplayProps {
	handleNextLesson: () => void;
	setShowCompletionCertificate: (param) => void;
	isVideoOnlyLesson?: boolean;
	certificateAlreadyDisplayed: boolean;
	redirectOnEnd: boolean;
}

export const Autoplay = ({
	isVideoOnlyLesson,
	handleNextLesson,
	setShowCompletionCertificate,
	certificateAlreadyDisplayed,
	redirectOnEnd
}: AutoplayProps) => {
	const theme = useTheme<Theme>();
	const history = useHistory();
	const timer = useRef(5);
	const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
	const { isCourseComplete, isCourseCompleteLoading } = useAppSelector(selectIsCourseComplete);
	const [, forceRender] = useState({});

	const action = useCallback(() => {
		if (redirectOnEnd) {
			return history.push("/");
		}

		if (isCourseComplete && !certificateAlreadyDisplayed) {
			return setShowCompletionCertificate(true);
		}

		return handleNextLesson();
	}, [
		certificateAlreadyDisplayed,
		handleNextLesson,
		history,
		isCourseComplete,
		redirectOnEnd,
		setShowCompletionCertificate
	]);

	useEffect(() => {
		if (!isCourseCompleteLoading) {
			const interval = setInterval(() => {
				timer.current--;
				if (timer.current === 0) {
					action();
					clearInterval(interval);
				} else {
					forceRender({});
				}
			}, 1000);
			return () => clearInterval(interval);
		}
	}, [isCourseCompleteLoading, action]);

	const prefix = useMemo(() => {
		if (redirectOnEnd) {
			return "Redirecting to Course";
		}

		return isCourseComplete && !certificateAlreadyDisplayed ? "Completion Certificate Appearing" : "Next Lesson Starts";
	}, [certificateAlreadyDisplayed, isCourseComplete, redirectOnEnd]);

	return (
		<>
			<AutoplayContainer>
				<PlayNextLesson onClick={action}>
					<Play />
					<Progress>
						<ProgressCircle r={isMobile ? "50" : "66"}></ProgressCircle>
					</Progress>
				</PlayNextLesson>
			</AutoplayContainer>
			<NextLessonTimer isVideoOnlyLesson={isVideoOnlyLesson}>
				{isCourseCompleteLoading ? (
					<CircularProgress size="1rem" color="inherit" thickness={2} variant="indeterminate" />
				) : (
					<>{`${prefix} in ${timer.current}`}</>
				)}
			</NextLessonTimer>
		</>
	);
};

import React, { memo } from "react";

import { useMediaQuery, useTheme } from "@material-ui/core";
import { HelpRounded } from "@material-ui/icons";

import { TopNav as SharedTopNav } from "@remar/shared/dist/layouts/BaseLayout";
import useAnalyticsEventTracker from "hooks/googleAnalytics";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { RootState, useAppSelector } from "store";
import { logout } from "store/features/Auth/authSlice";

import { selectUserInfo } from "store/features/MyAccount/myAccountSlice";

import { routes } from "core/constants";

import Banner from "./Banner";
import { HelpIcon } from "./styles";

const TopNav = () => {
	const theme = useTheme();
	const history = useHistory();
	const location = useLocation();
	const dispatch = useDispatch();
	const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
	const { subscription } = useAppSelector((state: RootState) => state.auth);
	const userInfo = useSelector(selectUserInfo);

	const analytics = useAnalyticsEventTracker("User");

	return (
		<SharedTopNav
			userInfo={userInfo}
			banner={subscription.showBanner && <Banner bannerMessage={subscription.bannerMessage} isMobile={isMobile} />}
			accountPath={routes.myaccount.getPath()}
			onLogOut={() => {
				dispatch(logout());
				analytics({ eventName: "logout" });
			}}
			topMenuItems={
				<>
					{/*	<MessageIcon>
						<Icon name="message-linear" height={19} width={22} fill="#2a2e37" />
					</MessageIcon>
					<NotificationIcon>
						<Icon fill="#2a2e37" name="bell" height={19} width={22} group="filled" />
					</NotificationIcon>*/}
					<HelpIcon
						onClick={() => {
							if (location.pathname === routes.help.getPath()) history.go(0);
							else history.push(routes.help.getPath());
						}}
					>
						<HelpRounded style={{ width: "20px", height: "20px", fill: "hsl(224,16%,41%)" }} />
					</HelpIcon>
				</>
			}
		/>
	);
};

export default memo(TopNav);

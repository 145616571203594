import React, { LegacyRef, useEffect, useMemo, useState } from "react";

import { DiscussionBoard } from "@remar/shared/dist/components/Comments";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { selectUserId } from "store/features/Auth/authSlice";
import {
	createLessonComment,
	deleteCommentVote,
	deleteLessonComment,
	getFullDiscussionBoardState,
	getLessonComments,
	lessonCommentsVoting,
	mockUpdateLessonComment,
	resetLessonComments,
	setCommentsOrder,
	updateLessonComment
} from "store/features/Lesson/lesson.slice";

import { selectUserInfo } from "store/features/MyAccount/myAccountSlice";

const Comments = ({ isTrial, isLockedForCurrentUser, isLockedByPayment }) => {
	const dispatch = useDispatch();
	const { id: lessonId } = useParams<{ id: string }>();
	const userInfo = useSelector(selectUserInfo);
	const _currentUserId = useSelector(selectUserId);

	const { comments, scrollTo, isLoading, totalItems, more, page, order } = useSelector(getFullDiscussionBoardState);

	const refs: { [k: string]: LegacyRef<HTMLDivElement> } = comments.reduce((acc, value) => {
		acc[value.id] = React.createRef<LegacyRef<HTMLDivElement>>();
		return acc;
	}, {});

	useEffect(() => {
		if (scrollTo) {
			const commentId = comments[scrollTo]?.id;
			if (refs && commentId) {
				refs[commentId]?.["current"].scrollIntoView({ behavior: "smooth" });
			}
		}
	}, [scrollTo]);

	const fullName = [userInfo?.firstName, userInfo?.lastName].join(" ");

	const isLocked = useMemo(
		() => (isTrial && isLockedByPayment) || isLockedForCurrentUser,
		[isTrial, isLockedByPayment, isLockedForCurrentUser]
	);

	const [isExpanded, setIsExpanded] = useState(!isLocked);

	useEffect(() => {
		setIsExpanded(!isLocked);
	}, [lessonId, isLocked]);

	useEffect(() => {
		lessonId && dispatch(getLessonComments({ page, lessonId: +lessonId, viewMore: false, order }));
		return () => {
			dispatch(resetLessonComments());
		};
	}, [dispatch, lessonId]);

	const handleChange = event => {
		dispatch(setCommentsOrder(event.target.value));
		dispatch(getLessonComments({ lessonId: +lessonId, viewMore: false, order: event.target.value }));
	};

	const handleAccordion = (event, isExpanded) => {
		setIsExpanded(isLocked ? false : isExpanded);
	};

	const handleViewMore = () => {
		dispatch(getLessonComments({ page: page + 1, lessonId: +lessonId, viewMore: true, order }));
	};

	const onVoteDelete = (commentId, voteId, isLike, isTopLevel) => {
		dispatch(
			deleteCommentVote({
				commentId,
				voteId,
				isLike,
				isTopLevel
			})
		);
	};

	const onDelete = commentId => {
		dispatch(deleteLessonComment(commentId));
	};

	const onVote = (commentId: number | undefined, isLike: boolean) => {
		dispatch(
			lessonCommentsVoting({
				commentId,
				isLike
			})
		);
	};

	const onCreate = (text, lessonId, parentId) => {
		dispatch(
			createLessonComment({
				text,
				lessonId,
				parentId
			})
		);
	};

	const onMockDelete = (parentId, commentId, text) => {
		dispatch(
			mockUpdateLessonComment({
				parentId,
				commentId,
				text
			})
		);
	};

	const onEdit = (text, lessonId, parentId, commentId) => {
		dispatch(
			updateLessonComment({
				data: {
					text,
					lessonId,
					parentId
				},
				filters: { id: commentId }
			})
		);
	};

	return (
		<DiscussionBoard
			comments={comments}
			currentUserId={_currentUserId}
			fullName={fullName}
			handleAccordion={handleAccordion}
			handleChange={handleChange}
			handleViewMore={handleViewMore}
			isExpanded={isExpanded}
			isLoading={isLoading}
			lessonId={lessonId}
			more={more}
			onCreate={onCreate}
			onDelete={onDelete}
			onEdit={onEdit}
			onMockDelete={onMockDelete}
			onVote={onVote}
			onVoteDelete={onVoteDelete}
			order={order}
			profileImageUrl={userInfo?.profileImageUrl}
			refs={refs}
			isLocked={isLocked}
			totalItems={totalItems}
		/>
	);
};

export default Comments;

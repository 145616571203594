import React from "react";

import { Theme, useMediaQuery, useTheme } from "@material-ui/core";
import { EntryScreenLayout as SharedEmptyScreen } from "@remar/shared/dist/layouts";

import { useDispatch, useSelector } from "react-redux";

import { setError } from "store/features/Auth/authSlice";

import { getFullState } from "store/features/Theme/theme.slice";

import LoginBgImage from "assets/images/login-background.png";

import Logo from "assets/images/logo.svg";
import { routes } from "core/constants";

type Props = {
	title?: string;
	disableOnMaintenanceMode?: boolean;
	isLeftBlockTransparent?: boolean;
};
const EntryScreenLayout: React.FC<Props> = ({ children, disableOnMaintenanceMode = true, ...rest }) => {
	const theme = useTheme<Theme>();
	const { logoImageUrl, isLoading } = useSelector(getFullState);
	const dispatch = useDispatch();
	const isMobile = useMediaQuery(theme.breakpoints.down("xs"));

	return (
		<SharedEmptyScreen
			logo={!isLoading && <img alt="institution logo" src={logoImageUrl || Logo} width={isMobile ? 100 : 150} />}
			signUpRoute={`${routes.signUp.getPath()}/course`}
			refreshError={() => dispatch(setError(""))}
			disableOnMaintenanceMode={disableOnMaintenanceMode}
			customBgImage={LoginBgImage}
			{...rest}
		>
			{children}
		</SharedEmptyScreen>
	);
};

export default EntryScreenLayout;

// const smDown = useMediaQuery(theme.breakpoints.down("sm"));
// return (
// 	<Box className={classes.root}>
// 		{smDown && <Box className={classes.overlay} />}
// 		<Box
// 			display="flex"
// 			flexDirection="column"
// 			alignItems={title === "Forgot Password?" ? "initial" : "flex-start"}
// 			justifyContent="center"
// 			height="100vh"
// 		>
// 			<Box className={classes.card}>
// 				<Box px={9} pt={2} className={classes.cardContainer} maxWidth={600}>
// 					<Box
// 						display="flex"
// 						justifyContent="center"
// 						alignItems="center"
// 						flexDirection="column"
// 						marginTop={isMobile ? "25px" : "0px"}
// 					>
// 						{!isLoading && <img alt="institution logo" src={logoImageUrl || Logo} width={isMobile ? 100 : 150} />}
// 						{title && (
// 							<Typography variant="h1" className={classes.title}>
// 								{title}
// 							</Typography>
// 						)}
// 					</Box>
// 					{children}
// 					{!disableOnMaintenanceMode && !logoImageUrl && (
// 						<Box mt={2} textAlign="center">
// 							<Typography variant="caption" style={{ color: "#8f9bb3" }}>
// 								Don`t have an account yet?
// 							</Typography>
//
// 							<Link
// 								to={`${routes.signUp.getPath()}/course`}
// 								className={classes.link}
// 								onClick={() => {
// 									dispatch(setError(""));
// 								}}
// 							>
// 								<Typography color={"primary"} variant={"subtitle1"} style={{ display: "inline" }}>
// 									Sign Up
// 								</Typography>
// 							</Link>
// 						</Box>
// 					)}
// 				</Box>
// 			</Box>
// 			<FooterNav />
// 		</Box>
// 	</Box>
// );

const { palette: dark_defaultPalette } = createTheme();

import { alpha, createTheme, darken } from "@material-ui/core/styles";
import * as colors from "@remar/shared/dist/colors";

import { DoubleExtendedPalette } from "@remar/shared/dist/models/theme.types";

const themeClors = {
	primary: {
		2000: alpha("hsl(220,19%,63%)", 0.16),
		2100: "hsl(213,28%,19%)"
	},
	green: {
		2000: "hsl(81,81%,35%)"
	},
	orange: {
		2000: "hsl(36,100%,50%)",
		2100: "hsl(28,100%,97%)"
	},
	red: {
		2000: "hsl(12,100%,61%)"
	}
};

const colorPalette: Partial<DoubleExtendedPalette> = {
	colors,
	background: {
		default: colors.basic[1000],
		paper: colors.basic[900]
	},
	customBackground: {
		shade1: colors.basic[800]
	},
	divider: colors.basic[700],
	common: {
		black: colors.basic[1000], // todo: add into colorset *uniq
		white: colors.basic[100],
		whiteShade1: colors.basic[150],
		grey: colors.basic[600]
	},
	disabled: {
		contrastText: colors.basic[600],
		main: themeClors.primary[2000]
	},
	type: "dark",
	simple: {
		contrastText: colors.basic[800],
		main: "transparent"
	},
	simpleQuaternary: {
		contrastText: colors.primary[1400],
		main: "transparent"
	},
	simpleLabel: {
		contrastText: colors.basic[600],
		main: "transparent"
	},
	primary: {
		contrastText: colors.basic[100],
		dark: dark_defaultPalette.primary.dark,
		light: dark_defaultPalette.primary.light,
		main: colors.primary[500],
		tabs: {
			border: colors.basic[500],
			contrastText: colors.basic[800],
			main: colors.basic[300]
		}
	},
	secondary: {
		contrastText: dark_defaultPalette.secondary.contrastText,
		dark: dark_defaultPalette.secondary.dark,
		light: dark_defaultPalette.secondary.light,
		main: themeClors.primary[2100],
		tabs: {
			contrastText: dark_defaultPalette.secondary.contrastText,
			dark: colors.info[500],
			main: themeClors.primary[2100],
			border: colors.info[600]
		}
	},
	tertiary: {
		border: colors.basic[500],
		contrastText: colors.basic[600],
		main: colors.basic[100]
	},
	quaternary: {
		contrastText: colors.basic[100],
		main: colors.primary[1400]
	},
	info: {
		contrastText: colors.basic[100],
		dark: dark_defaultPalette.info.dark,
		light: dark_defaultPalette.info.light,
		main: colors.primary[500],
		tabs: {
			contrastText: colors.info[500],
			dark: colors.info[500],
			main: colors.info[100],
			border: colors.basic[300]
		}
	},
	error: {
		...dark_defaultPalette.error,
		tabs: dark_defaultPalette.error
	},
	secondaryLight: {
		dark: "",
		light: "",
		contrastText: colors.basic[800],
		main: colors.basic[300]
	},
	greenLight: {
		contrastText: themeClors.green[2000],
		main: "hsl(230,30%,96%)" // todo: add into colorset *uniq
	},
	infoInvert: {
		contrastText: colors.basic[600],
		main: colors.basic[100]
	},
	// blue
	infoLight: {
		contrastText: colors.primary[1400],
		main: colors.primary[1500]
	},
	infoWarning: {
		main: "hsl(35,100%,95%)", // todo: add into colorset *uniq
		contrastText: themeClors.orange[2000]
	},
	// green
	success: {
		contrastText: themeClors.green[2000],
		dark: dark_defaultPalette.success.dark,
		light: dark_defaultPalette.success.light,
		main: "hsl(122,39%,49%)", // todo: add into colorset *uniq
		tabs: {
			contrastText: themeClors.green[2000],
			dark: dark_defaultPalette.success.dark,
			main: "hsl(80,60%,94%)", // todo: add into colorset *uniq
			border: dark_defaultPalette.success.dark
		}
	},
	// red
	warning: {
		contrastText: themeClors.red[2000],
		dark: dark_defaultPalette.warning.dark,
		light: dark_defaultPalette.warning.light,
		main: themeClors.orange[2100],
		tabs: {
			contrastText: themeClors.red[2000],
			dark: dark_defaultPalette.warning.dark,
			main: themeClors.orange[2100],
			border: dark_defaultPalette.warning.dark
		}
	},
	deprecated: {
		contrastText: "hsl(46,100%,50%)", // todo: add into colorset *uniq
		main: colors.basic[100]
	},
	tangerine: {
		contrastText: themeClors.orange[2000],
		main: themeClors.orange[2100]
	},
	errorInvert: {
		contrastText: "hsl(4,90%,58%)" // todo: add into colorset  //mui colors red[500] *uniq
	},
	solidSuccess: {
		contrastText: colors.basic[100],
		main: "hsl(79,78%,43%)" // todo: add into colorset *uniq
	},
	solidWarning: {
		contrastText: themeClors.orange[2000],
		main: themeClors.red[2000]
	},
	solidPrimary: {
		contrastText: themeClors.orange[2000],
		main: colors.basic[100]
	},
	solidTangerine: {
		contrastText: themeClors.orange[2000],
		main: themeClors.orange[2100]
	},
	solidInfoLight: {
		contrastText: themeClors.orange[2000],
		main: themeClors.orange[2100]
	},
	contrastThreshold: 3,
	tonalOffset: 0.2,
	text: {
		disabled: dark_defaultPalette.text.disabled,
		hint: dark_defaultPalette.text.hint,
		primary: colors.basic[100],
		secondary: colors.basic[200]
	},
	fabArrowDark: {
		contrastText: colors.basic[600],
		dark: colors.basic[300],
		main: colors.basic[300]
	},
	drawer: {
		background: themeClors.primary[2100],
		foreground: "hsl(216,36%,15%)", // todo: add into colorset *uniq
		titleColor: colors.basic[100],
		titleFontSize: "24px"
	},
	sidebar: {
		main: "hsl(140,1%,50%)", // todo: add into colorset *uniq *uniq
		active: colors.basic[100],
		background: "hsl(215,48%,11%)", // todo: add into colorset *uniq
		contentBackground: "hsl(214,47%,12%)" // todo: add into colorset *uniq
	},
	logo: {
		dark: "hsl(213,55%,52%)", // todo: add into colorset *uniq
		main: colors.basic[100]
	},
	customSelectionBox: {
		backgroundColor: colors.primary[500],
		textColor: colors.basic[100]
	},
	Avatar: {
		// todo
		bgColor: colors.info[900],
		textColor: "hsl(199, 79%, 47%)"
	},
	RadialChart: {
		sucess: {
			primaryColor: colors.success[800],
			secondaryColor: darken(colors.success[800], 0.7)
		},
		warning: {
			primaryColor: colors.warning[600],
			secondaryColor: darken(colors.warning[600], 0.7)
		},
		danger: {
			primaryColor: colors.danger[600],
			secondaryColor: darken(colors.danger[600], 0.7)
		}
	},
	BannerColors: {
		basic: {
			backgroundColor: colors.basic[500],
			textColor: colors.basic[100]
		},
		primary: {
			backgroundColor: colors.primary[500],
			textColor: colors.basic[100]
		},
		success: {
			backgroundColor: colors.success[500],
			textColor: colors.basic[100]
		},
		info: {
			backgroundColor: colors.info[500],
			textColor: colors.basic[100]
		},
		warning: {
			backgroundColor: colors.warning[500],
			textColor: colors.basic[100]
		},
		danger: {
			backgroundColor: colors.danger[500],
			textColor: colors.basic[100]
		}
	},
	InfoCard: {
		// todo
		backgroundColor: colors.basic[1550],
		backgroundColorSuccess: colors.basic[100],
		backgroundColorWarning: colors.basic[100],
		backgroundColorDanger: colors.basic[100],
		backgroundColorInfo: colors.basic[100],
		borderColor: colors.basic[400],
		iconColor: colors.basic[600],
		iconColorSuccess: "hsl(108, 87%, 91%)",
		iconColorWarning: "hsl(50, 100%, 85%)",
		iconColorDanger: "hsl(12,100%,85%)",
		iconColorInfo: "hsl(202, 95%, 93%)",
		titleColor: colors.basic[100],
		textColor: colors.basic[100]
	},
	StepItem: {
		activeText: colors.info[500],
		activeBackground: colors.basic[100],
		disabledBackground: colors.basic[200],
		disabledText: colors.basic[600],
		disabledBox: colors.basic[300],
		activeSuccess: colors.success[600],
		disabledBasic: colors.basic[400]
	},
	RawColorPicker: {
		borderColor: colors.basic[500],
		titleColor: colors.basic[800]
	},
	Select: {
		defaultBorder: colors.basic[400],
		activeBorder: colors.info[500],
		successBorder: colors.success[500],
		errorBorder: colors.danger[500],
		defaultText: colors.basic[600],
		ghostBorder: colors.basic[100],
		filledText: colors.info[800],
		menuItemColor: colors.basic[300]
	},
	TagColors: {
		basic: {
			backgroundColor: colors.basic[300],
			backgroundColorOnHover: colors.basic[400],
			backgroundColorOnPressed: colors.basic[500],
			color: colors.basic[800]
		},
		success: {
			backgroundColor: colors.success[100],
			backgroundColorOnHover: colors.success[200],
			backgroundColorOnPressed: colors.success[300],
			color: colors.success[600]
		},
		info: {
			backgroundColor: colors.info[100],
			backgroundColorOnHover: colors.info[200],
			backgroundColorOnPressed: colors.info[300],
			color: colors.info[500]
		},
		danger: {
			backgroundColor: colors.danger[100],
			backgroundColorOnHover: colors.danger[200],
			backgroundColorOnPressed: colors.danger[300],
			color: colors.danger[500]
		},
		control: {
			backgroundColor: colors.basic[100],
			backgroundColorOnHover: colors.basic[200],
			backgroundColorOnPressed: colors.basic[300],
			color: colors.basic[600]
		},
		primary: {
			backgroundColor: colors.primary[100],
			backgroundColorOnHover: colors.primary[200],
			backgroundColorOnPressed: colors.primary[300],
			color: colors.primary[500]
		}
	},
	lightButtonColors: {
		basic: {
			backgroundColor: colors.basic[900],
			backgroundColorOnHover: colors.basic[400],
			backgroundColorOnPressed: colors.basic[500],
			color: colors.basic[400]
		},
		success: {
			backgroundColor: colors.success[100],
			backgroundColorOnHover: colors.success[200],
			backgroundColorOnPressed: colors.success[300],
			color: colors.success[600]
		},
		info: {
			backgroundColor: colors.info[100],
			backgroundColorOnHover: colors.info[200],
			backgroundColorOnPressed: colors.info[300],
			color: colors.info[500]
		},
		danger: {
			backgroundColor: colors.danger[100],
			backgroundColorOnHover: colors.danger[200],
			backgroundColorOnPressed: colors.danger[300],
			color: colors.danger[500]
		},
		control: {
			backgroundColor: colors.basic[100],
			backgroundColorOnHover: colors.basic[200],
			backgroundColorOnPressed: colors.basic[300],
			color: colors.basic[600]
		},
		primary: {
			backgroundColor: colors.primary[100],
			backgroundColorOnHover: colors.primary[200],
			backgroundColorOnPressed: colors.primary[300],
			color: colors.primary[500]
		}
	},
	ghostButtonColors: {
		basic: {
			backgroundColor: colors.basic[100],
			backgroundColorOnHover: colors.basic[200],
			backgroundColorOnPressed: colors.basic[300],
			color: colors.basic[800]
		},
		success: {
			backgroundColor: colors.basic[100],
			backgroundColorOnHover: colors.basic[200],
			backgroundColorOnPressed: colors.basic[300],
			color: colors.success[600]
		},
		info: {
			backgroundColor: colors.basic[100],
			backgroundColorOnHover: colors.basic[200],
			backgroundColorOnPressed: colors.basic[300],
			color: colors.info[500]
		},
		danger: {
			backgroundColor: colors.basic[100],
			backgroundColorOnHover: colors.basic[200],
			backgroundColorOnPressed: colors.basic[300],
			color: colors.danger[500]
		},
		control: {
			backgroundColor: colors.basic[100],
			backgroundColorOnHover: colors.basic[200],
			backgroundColorOnPressed: colors.basic[300],
			color: colors.basic[600]
		},
		primary: {
			backgroundColor: colors.basic[100],
			backgroundColorOnHover: colors.basic[200],
			backgroundColorOnPressed: colors.basic[300],
			color: colors.primary[500]
		}
	},
	mainButtonColors: {
		info: {
			backgroundColor: colors.info[500],
			backgroundColorOnHover: colors.info[400],
			backgroundColorOnPressed: colors.info[600],
			color: colors.basic[100]
		},
		primary: {
			backgroundColor: colors.primary[500],
			backgroundColorOnHover: colors.primary[400],
			backgroundColorOnPressed: colors.primary[600],
			color: colors.basic[100]
		}
	},
	outlineButtonColors: {
		color: colors.basic[600],
		backgroundColor: colors.basic[100],
		backgroundColorOnHover: colors.basic[200],
		backgroundColorOnPressed: colors.basic[300],
		boxShadowColor: colors.basic[500],
		iconColor: colors.basic[500]
	},
	disabledButtonColors: {
		color: "hsla(0,0%,100%,0.56) !important",
		backgroundColor: themeClors.primary[2000],
		backgroundColorOnHover: themeClors.primary[2000],
		backgroundColorOnPressed: themeClors.primary[2000],
		labelOpacity: 0.48
	},
	inputColors: {
		disabledColor: colors.basic[600],
		disabledBackgroundColor: colors.basic[200],
		focusedColor: colors.info[500],
		focusedOutlinedBorderColor: colors.info[500],
		outlinedBorderColor: colors.success[500]
	},
	controlPrimaryBackgroundColor: colors.primary[200],
	controlPrimaryColor: colors.primary[500],
	controlSecondaryColor: colors.basic[400],
	controlSecondaryBackgroundColor: colors.basic[600],
	controlFillColor: colors.basic[600],
	controlLabelColor: colors.basic[800],
	labelColorPrimary: colors.primary[500],
	status: {
		basic: colors.basic[500],
		primary: colors.primary[500],
		success: colors.success[500],
		info: colors.info[500],
		warning: colors.warning[500],
		danger: colors.danger[500]
	},
	labelColorSecondary: colors.basic[500],
	frame: {
		blankContentColor: colors.basic[600],
		outerBorderColor: colors.basic[500],
		innerBorderColor: colors.basic[500],
		highlightBorderColor: colors.basic[800],
		glowBorderColor: colors.primary[500],
		deviceColor: colors.basic[100],
		contentBackgroundColor: colors.basic[200],
		glowBackgroundColor: colors.primary[100]
	},
	imagePickerBackgroundColor: colors.basic[400],
	imagePickerCloseIconColor: colors.basic[600],
	snackbar: {
		backgroundColor: colors.basic[1000],
		textColor: colors.basic[100]
	},
	contentBox: {
		backgroundColor: colors.basic[100],
		borderColor: colors.basic[500]
	},
	formFooterTextColor: colors.basic[600],
	inputField: {
		background: colors.basic[800],
		hoverBackground: colors.basic[750]
	},
	checkMark: {
		validColor: "#76b8fb",
		color: "#d8d8d8"
	}
};

export default colorPalette;

import { Button, Modal, Typography, alpha, createStyles, makeStyles } from "@material-ui/core";

import { CustomInput as Input } from "@remar/shared/dist/components/CustomInput/CustomInput";
import styled from "styled-components";

export const useStyles = makeStyles(() =>
	createStyles({
		optionsIconsGap: {
			gap: "24px"
		},
		iconClass: {
			cursor: "pointer"
		}
	})
);

export const StyledDateTitleCellText = styled(Typography)`
	opacity: 0.87;
	font-size: ${props => props.theme.typography.pxToRem(13)};
	font-weight: 600;
	line-height: 1.85;
	color: ${props => props.theme.palette.text.secondary};
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
`;

export const StyledTimeTitleCellText = styled(Typography)`
	opacity: 0.87;
	font-size: ${props => props.theme.typography.pxToRem(12)};
	line-height: 1.33;
	color: ${props => props.theme.palette.text.secondary};
`;

export const FieldLabel = styled.label`
	font-family: var(--openSans);
	font-size: ${props => props.theme.typography.pxToRem(13)};
	font-weight: 600;
	line-height: 1.85;
	color: ${props => alpha(props.theme.palette.text.primary, 0.6)};
	margin: 20px 24px 0 0;
	display: flex;
	flex-direction: column;

	${props => props.theme.breakpoints.down("sm")} {
		margin-right: 0;
	}
`;

export const ButtonsRow = styled.div`
	display: flex;
	flex-flow: row nowrap;
	justify-content: end;
	border-top: solid 1px #2a2e37;
	padding: 15px 20px 0;
	gap: 16px;
	${props => props.theme.breakpoints.down("sm")} {
		flex-direction: column-reverse;
		> button {
			width: 100%;
		}
	}
`;

export const CustomInput = styled(Input)`
	width: 100%;
	margin: 5px 0 0;
`;

export const ColumnHeader = styled(Typography)`
	font-size: ${props => props.theme.typography.pxToRem(12)};
	font-weight: 500;
	color: ${props => alpha(props.theme.palette.text.primary, 0.6)};
`;

export const DeleteModalTitle = styled.h3`
	opacity: 0.87;
	font-family: var(--poppins);
	font-size: ${props => props.theme.typography.pxToRem(18)};
	font-weight: bold;
	line-height: 1.33;
	color: ${props => props.theme.palette.primary.main};
	padding-left: 10px;
	margin: 0;
	text-align: left;
	text-transform: capitalize;
	${props => props.theme.breakpoints.down("sm")} {
		padding-left: 20px;
	}
`;

export const DeleteConfirmContent = styled.div`
	width: 480px;
	position: relative;
	background: ${props => props.theme.palette.background.paper};
	padding: 16px 8px;
	overflow: hidden;
`;

export const DeleteCancelButton = styled(Button)`
	background: transparent;
	border-radius: 4px;
	border: solid 1px ${props => props.theme.palette.colors.basic[500]};
	color: ${props => props.theme.palette.colors.basic[500]};
`;

export const DeleteConfirmButton = styled(Button)`
	background: ${props => props.theme.palette.primary.main};
	color: ${props => props.theme.palette.common.white};
	font-family: var(--poppins);
	font-size: ${props => props.theme.typography.pxToRem(16)};
	font-weight: bold;
	line-height: 1.25;
	width: 144px;
	box-sizing: border-box;
`;

export const StyledButtonTop = styled(Button)`
	color: ${props => alpha(props.theme.palette.text.primary, 0.6)};
	background-color: ${props => props.theme.palette.background.paper};
	margin-right: 8px;
	&:hover {
		background-color: rgba(203, 219, 245);
	}
`;

export const NoteTextContainer = styled.div`
	height: 75px;
	filter: grayscale(1);
	display: flex;
	flex-flow: column;
	align-items: flex-start;
	justify-content: center;
	font-size: ${props => props.theme.typography.pxToRem(15)};
	color: ${props => alpha(props.theme.palette.text.primary, 0.6)};
	padding-left: 10px;
	${props => props.theme.breakpoints.down("sm")} {
		padding-left: 20px;
	}
`;

export const FormModal = styled(Modal)`
	display: flex;
	justify-content: center;
	align-items: center;
`;

import React, { useEffect, useRef, useState } from "react";

import HotspotHighlightInput, {
	HighlightedNode
} from "@remar/shared/dist/components/HotspotHighlightInput/HotspotHighlightInput";

import { HighlightTable, HightlightTableContainer, HotspotHighlightQuestionBox, TestQuizQuestionText } from "./styles";

const HighlightTableQuestion = ({ question: { data, id, text }, onChange, key: uniqueIdentifier, userAnswers }) => {
	const valueRef = useRef<{
		[key: string]: HighlightedNode[];
	}>({});

	const [value, setValue] = useState<{
		[key: string]: HighlightedNode[];
	}>(valueRef.current);

	const disableInput = event => event.preventDefault();

	const handleUpdate = (groupId, highlighted) => {
		const newValue = { ...valueRef.current, [groupId]: highlighted };
		valueRef.current = newValue;
		setValue(newValue);

		const preparedData: { groupId: string; text: string }[] = [];
		for (const groupId in newValue) {
			preparedData.push(
				...newValue[groupId].filter(({ highlighted }) => highlighted).map(({ text }) => ({ text, groupId }))
			);
		}

		onChange(preparedData);
	};

	useEffect(() => {
		const newValue = {};

		data.groups.forEach(group => (newValue[group.id] = [{ text: group.text, highlighted: false }]));

		setValue(newValue);
		valueRef.current = newValue;
	}, [data.groups, id]);

	useEffect(() => {
		const newValue = {};

		data.groups.forEach(group => {
			let text = group.text;
			const options = userAnswers.filter(({ groupId }) => groupId === group.id);

			const groupValue: HighlightedNode[] = [];

			options.forEach(option => {
				const startIndex = text.indexOf(option.text);

				if (startIndex > 0) {
					groupValue.push({ highlighted: false, text: text.slice(0, startIndex) });
				} else if (startIndex === -1) {
					return false;
				}

				const endIndex = startIndex + option.text.length;
				groupValue.push({ highlighted: true, text: text.slice(startIndex, endIndex) });

				text = text.slice(endIndex);
			});

			if (text.length > 0) {
				groupValue.push({ text, highlighted: false });
			}

			newValue[group.id] = groupValue;
		});

		setValue(newValue);
		valueRef.current = newValue;
	}, [data.groups, userAnswers]);

	return (
		<>
			<TestQuizQuestionText key={uniqueIdentifier}>{text}</TestQuizQuestionText>
			<HightlightTableContainer>
				<HighlightTable>
					<thead>
						<tr>
							<td>{data.tableLabel}</td>
							<td>{data.answerOptionLabel}</td>
						</tr>
					</thead>
					<tbody>
						{data.groups.map((group, index) => (
							<tr key={`group-${group.id}`}>
								<td>{group.title}</td>
								<td>
									<HotspotHighlightQuestionBox
										onKeyPress={disableInput}
										onKeyDown={disableInput}
										onPaste={disableInput}
									>
										<HotspotHighlightInput
											value={value[group.id] ?? []}
											currentIndex={index}
											onChange={newValue => handleUpdate(group.id, newValue)}
											student
										/>
									</HotspotHighlightQuestionBox>
								</td>
							</tr>
						))}
					</tbody>
				</HighlightTable>
			</HightlightTableContainer>
		</>
	);
};
export default HighlightTableQuestion;

import React from "react";

import { Box, Theme, useMediaQuery, useTheme } from "@material-ui/core";

import { AnswerOptionRow, AnswerOptionsIndication, AnswerOptionsText, useStyles } from "../styles";
const ClozeDropDownResult = ({
	result,
	question: {
		data: { groups },
		text
	}
}) => {
	const theme = useTheme<Theme>();
	const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
	const classes = useStyles();

	const selectedAnswers = {};

	result?.selectedAnswers.forEach(
		({ questionAnswerOptionId }) => (selectedAnswers[questionAnswerOptionId] = questionAnswerOptionId)
	);

	return (
		<>
			<Box className={classes.questionText} mb={1}>
				{text}
			</Box>
			<Box>
				{groups?.map(({ text, answerOptions }, gIndex) => {
					const isSelectedCorrectOption = !!(
						answerOptions && selectedAnswers[answerOptions.find(item => item.isCorrect).id]
					);
					return (
						<Box key={gIndex} display="flex" width="100%" mb={8}>
							<Box alignItems="center" width="100%">
								<Box width="100%">
									<Box className={classes.questionText} mb={1}>
										{text}
									</Box>
								</Box>
								<Box width="100%">
									{answerOptions &&
										answerOptions.map((item, _index) => {
											const correctAnswer = item.isCorrect;
											const yourAnswer = selectedAnswers[item.id] === item.id;
											let color = "";
											let bgColor = "";
											let text = " ";
											if (isSelectedCorrectOption) {
												if (correctAnswer) {
													text = "Correct Answer";
													color = "#23b34a";
													bgColor = "#C4E3D2";
												}
											} else {
												if (correctAnswer && !yourAnswer) {
													text = "Unmarked correct answer";
													color = "#264fae";
													bgColor = "#c0c9e5";
												} else if (!correctAnswer && yourAnswer) {
													text = "Incorrect answer";
													color = "#772a34";
													bgColor = "#faccd0";
												} else {
													text = "";
													color = "";
													bgColor = "";
												}
											}

											return (
												<Box
													className={`${correctAnswer ? classes.correct : ""} ${yourAnswer ? classes.your : ""}`}
													key={_index}
													display="flex"
													justifyContent="start"
													alignItems="start"
													flex={1}
												>
													<AnswerOptionRow bgColor={bgColor} isMobile={isMobile}>
														<AnswerOptionsIndication
															color={color}
															className={classes.openSansFontFamily}
															ml={1}
															display="flex"
															isMobile={isMobile}
															theCorrectAnswer={correctAnswer}
															yourAnswer={yourAnswer}
														>
															{text}&#160;
														</AnswerOptionsIndication>
														<AnswerOptionsText className={classes.openSansFontFamily}>{item.text}</AnswerOptionsText>
													</AnswerOptionRow>
												</Box>
											);
										})}
								</Box>
							</Box>
						</Box>
					);
				})}
			</Box>
		</>
	);
};

export default ClozeDropDownResult;

import React, { useEffect, useMemo, useState } from "react";

import { Box, Button, Card, Typography } from "@material-ui/core";

import Stepper from "@remar/shared/dist/components/Stepper";
import { ExternalIntegrationIds } from "@remar/shared/dist/constants";
import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js";
import { Form, Formik } from "formik";

import useAnalyticsEventTracker from "hooks/googleAnalytics";

import { usePostAffiliateProTrack } from "hooks/usePostAffiliateProTrack";
import ReactPixel from "react-facebook-pixel";

import { useDispatch, useSelector } from "react-redux";

import { useHistory, useParams } from "react-router-dom";

import { RootState, useAppSelector } from "store";
import {
	changeSubscriptionType,
	fetchCountries,
	getCourses,
	setError,
	setIsLoading,
	setSubscriptionTypesForSignup,
	signUp,
	validateDuplicateEmail
} from "store/features/Auth/authSlice";
import { UserSignUpVerificationDto } from "store/services";

import { PixelEvent, getCallbackUrl, setTiktokEvent } from "modules/utils";

import SignUpLayout from "../SignUpLayout";
import FormActions from "../components/FormActions";
import { AccountFormDetails, PaymentForm, ShippingForm } from "../components/Forms";
import { GuestSignUpError } from "../components/GuestSignUpError";
import SignUpSuccessLeft from "../components/SignUpSuccessLeft";
import SignUpSuccessRight from "../components/SignUpSuccessRight";
import Summary from "../components/Summary";
import { PaymentSchema, ShippingSchema, payedSignupSchema } from "../components/schemas";

import { useStyles } from "../components/styles";
import { getValidValue } from "../components/utils";

const initialFormValues = {
	firstName: "",
	lastName: "",
	email: "",
	password: "",
	countryId: "",
	address1: "",
	address2: "",
	city: "",
	state: "",
	zip: "",
	phoneNumber: "",
	terms: false
};
const steps = [{ label: "Account" }, { label: "Shipping" }, { label: "Payment" }];
const PaidSignUp = () => {
	const classes = useStyles();
	const dispatch = useDispatch();
	const elements = useElements();
	const stripe = useStripe();
	const { errorMessage, isLoading, courses, guestSignUpData, selectedShippingPlan } = useSelector(
		(state: RootState) => state.auth
	);
	const { paymentProviderAccount } = guestSignUpData as UserSignUpVerificationDto;
	const { paymentSource } = paymentProviderAccount || {};
	const {
		subscriptionTypes,
		userSubscriptionTypeId,
		userSubscriptionTypeAddonIds,
		shippingApplicable
	}: RootState["auth"] = useAppSelector((store: RootState) => store.auth);

	const [activeStep, setActiveStep] = useState(0);
	const [papCookie, setPapCookie] = useState("");
	const [startDate, setStartDate] = useState(new Date());
	const [signupSuccessUserData, setSignupSuccessUserData] = useState({});
	const [, setFreeTrial] = useState(false);
	const analytics = useAnalyticsEventTracker("User");
	const { courseId } = useParams<{ courseId?: string }>();
	const activeSubscription = subscriptionTypes[0];
	const history = useHistory();
	const callBackUrl = useMemo(() => getCallbackUrl(history.location.search), [history.location.search]);
	useEffect(() => {
		if (activeSubscription) {
			dispatch(changeSubscriptionType(activeSubscription?.id as number));
			dispatch(fetchCountries(activeSubscription?.id as number));
			dispatch(getCourses());
		}
	}, [activeSubscription, dispatch]);

	useEffect(() => {
		dispatch(
			setSubscriptionTypesForSignup({
				courseId: +courseId!,
				isTrial: false
			})
		);
	}, [courseId, dispatch]);

	usePostAffiliateProTrack(v => setPapCookie(v));

	useEffect(() => {
		if (activeStep === 0) {
			setTiktokEvent(PixelEvent.AddToCart);
			ReactPixel.track(PixelEvent.AddToCart);
		}
		if (activeStep === 1) {
			setTiktokEvent(PixelEvent.InitiateCheckout);
			ReactPixel.track(PixelEvent.InitiateCheckout);
		}
		if (activeStep === 2) {
			setTiktokEvent(PixelEvent.AddPaymentInfo);
			ReactPixel.track(PixelEvent.AddPaymentInfo);
		}
	}, [activeStep]);

	const validationSchema = useMemo(() => {
		if (activeStep === 0) return payedSignupSchema;
		if (activeStep === 1) return ShippingSchema;
		if (activeStep === 2) return PaymentSchema;
	}, [activeStep]);

	const setSignUpSuccessUser = response => {
		const mainSubTypeEIDItems = activeSubscription?.subTypeEIDItems!.find(
			i => i.integrationId == ExternalIntegrationIds.Stripe && i.parentId === null
		);
		const selectedCourse = subscriptionTypes.find(type => type.id === userSubscriptionTypeId)?.allowedCourses?.[0];
		const price = mainSubTypeEIDItems?.data["price"] || 0;
		const addOnsSub = activeSubscription?.subTypeEIDItems!.filter(
			item => item.parentId !== null && item.integrationId === ExternalIntegrationIds.Stripe
		);

		const _addOnsSub = addOnsSub
			.filter(x => userSubscriptionTypeAddonIds?.includes(x.id))
			.map(y => {
				return {
					name: y.data.name,
					price: (y.data["price"] || 0).toLocaleString("en-US", { currency: "USD", style: "currency" })
				};
			});

		setSignupSuccessUserData({
			email: response["payload"]["email"],
			fullName: `${response["payload"]["firstName"]} ${response["payload"]["lastName"]}`,
			courseName: selectedCourse?.name,
			price: price.toLocaleString("en-US", { currency: "USD", style: "currency" }),
			shippingApplicable,
			shipping: (selectedShippingPlan?.data["price"] || 0).toLocaleString("en-US", {
				currency: "USD",
				style: "currency"
			}),
			...(_addOnsSub.length && {
				addOnsSub: _addOnsSub
			})
		});
	};

	const handleSubmit = (values: typeof initialFormValues) => {
		dispatch(
			signUp({
				CardElement,
				elements,
				stripe,
				values: { startDate, ...values, papCookie }
			})
			// eslint-disable-next-line @typescript-eslint/ban-ts-comment
		) // @ts-ignore
			.then(r => {
				if (r.error) {
					dispatch(setError(r.payload));
					setActiveStep(3);
				} else {
					analytics({ eventName: "sign_up_email_finish" });
					setSignUpSuccessUser(r);
					setActiveStep(4);
					if (callBackUrl) {
						window.location.replace(callBackUrl);
					}
				}
			});
	};

	const handleBack = () => {
		setActiveStep(prevActiveStep => (prevActiveStep >= 1 ? prevActiveStep - 1 : 0));
	};

	const handleNext = (values: typeof initialFormValues, isTrial?: boolean) => {
		if (isTrial) handleSubmit(values);
		else {
			if (activeStep === 0) {
				dispatch(setIsLoading(true));
				const { email } = values;
				// eslint-disable-next-line @typescript-eslint/ban-ts-comment
				// @ts-ignore
				dispatch(validateDuplicateEmail(email)).then(r => {
					dispatch(setIsLoading(false));
					if (r.error) {
						dispatch(setError(r.payload));
						return;
					}
					dispatch(setError(""));
					localStorage.setItem("analyticsId", `${email}-normal`);
					analytics({ eventName: "sign_up_email_start" });
					setActiveStep(prevActiveStep => (prevActiveStep <= 1 ? prevActiveStep + 1 : 2));
				});
			} else {
				setActiveStep(prevActiveStep => (prevActiveStep <= 1 ? prevActiveStep + 1 : 2));
				if (activeStep === 2) {
					handleSubmit(values);
				}
			}
		}
	};

	return (
		<SignUpLayout>
			{activeStep !== 4 && (
				<Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" mb={5} pb={6} mt={5}>
					<Box className={classes.cardsContainer}>
						<Card className={classes.card}>
							<Box>
								<Box px={7} pt={2} className={classes.stepperForm}>
									<Stepper activeStep={activeStep} steps={steps} />

									<Formik
										initialValues={{
											...initialFormValues
										}}
										validationSchema={validationSchema}
										validateOnChange
										onSubmit={values => {
											handleSubmit(values);
										}}
									>
										{({ isValid, values, setErrors, setFieldValue, touched, setTouched }) => {
											const neverTouched = Object.keys(touched).length === 0;
											let valid;
											if (activeStep === 1) {
												valid = getValidValue(Object.keys(touched)) ? isValid : false;
											} else {
												valid = isValid && !neverTouched;
											}
											return (
												<>
													<Form>
														{activeStep === 0 && (
															<AccountFormDetails
																setFieldValue={setFieldValue}
																termValue={values.terms}
																confirmPassword
															/>
														)}
														{activeStep === 1 && <ShippingForm />}
														{activeStep === 2 && (
															<PaymentForm
																paymentSource={paymentSource}
																setFieldValue={setFieldValue}
																touched={touched}
																setTouched={setTouched}
																termValue={values.terms}
																showTerms
															/>
														)}
														{activeStep === 3 && <GuestSignUpError errorMessage={errorMessage} />}
													</Form>
													{errorMessage && (
														<Box mt={3}>
															<Typography variant="caption" style={{ color: "red" }}>
																{errorMessage}
															</Typography>
														</Box>
													)}

													{activeStep !== 3 ? (
														<FormActions
															back={() => {
																setErrors({});
																handleBack();
															}}
															next={isTrial => handleNext(values, isTrial)}
															valid={valid}
															disabled={!isValid && !activeSubscription}
															loading={isLoading}
															step={activeStep}
														/>
													) : activeStep === 3 ? (
														<Box display="flex" justifyContent="center" mt={6}>
															<Button
																color="primary"
																variant="contained"
																onClick={() => {
																	dispatch(setError(""));
																	setActiveStep(2);
																}}
															>
																{"Try Again"}
															</Button>
														</Box>
													) : null}
												</>
											);
										}}
									</Formik>
								</Box>
							</Box>
						</Card>
						<Summary
							setFreeTrial={setFreeTrial}
							courses={courses}
							startDate={startDate}
							setStartDate={setStartDate}
							showCrossButton={false}
						/>
					</Box>
				</Box>
			)}
			{activeStep === 4 && (
				<Box display="flex" flexDirection="column" alignItems="center" justifyContent="center">
					<Box className={classes.successCardsContainer}>
						<Card className={classes.cardLeft}>
							<SignUpSuccessLeft signupSuccessUserData={signupSuccessUserData} trial={false} />
						</Card>
						<Card className={classes.cardRight}>
							<SignUpSuccessRight />
						</Card>
					</Box>
				</Box>
			)}
		</SignUpLayout>
	);
};

export default PaidSignUp;

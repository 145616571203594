import React, { FC, ReactNode, useEffect, useState } from "react";

import { Box, Typography, useTheme } from "@material-ui/core";
import { HelpRounded } from "@material-ui/icons";

import { UserQuestionAnswerDto } from "@remar/shared/dist/services/userQuestionAnswers/dto";
import { IExtendedTheme } from "@remar/shared/dist/theme/default";
import { isMobile } from "react-device-detect";

import { useDrag, useDrop } from "react-dnd";

import { useDispatch } from "react-redux";

import { ReactComponent as DragIcon } from "assets/icons/drag-icon.svg";

import { dragCollect, getTouchBackendStyle } from "modules/QuestionBank/Test/utils/dragAndDrop";

import { TestQuizQuestionText } from "modules/QuestionBank/styles";

import {
	DragContainer,
	DragEqualDiv,
	GroupingCategoryContainer,
	GroupingContainer,
	GroupingInfoContainer,
	GroupingInfoText,
	useStyles
} from "./style";

type DragType = "dragger" | "reorder" | null;
const AnswerOption: FC<{
	answerOptionIndex: number;
	dropZoneIndex?: number;
	questionIndex: number;
	id: string;
	text: string | undefined;
	key: string;
}> = ({ answerOptionIndex, dropZoneIndex, text, id, key }) => {
	const [canDrag, setCanDrag] = useState(false);
	const [touchType, setTouchType] = useState<DragType>(null);
	const [{ position, isDragging, opacity }, drag] = useDrag({
		item: { dropZoneIndex, index: answerOptionIndex, touchType, id },
		type: "group",
		collect: monitor => dragCollect(monitor),
		canDrag: () => canDrag,
		end: () => {
			setTouchType(null);
			setCanDrag(false);
		}
	});
	const theme = useTheme<IExtendedTheme>();
	const classes = useStyles();
	const style = { backgroundColor: theme.palette.common.white, padding: "0 17px 0 13px" };

	return (
		<DragContainer ref={drag}>
			<Box
				key={key}
				display="flex"
				flexDirection="row"
				width="100%"
				borderRadius="4px"
				style={{ ...style, ...(isMobile && isDragging ? getTouchBackendStyle(position) : { opacity }) }}
				minHeight={"48px"}
			>
				<Box display="flex" alignItems="center" flexDirection="row" width={"100%"}>
					<Typography className={classes.dragItem}>{text}</Typography>
				</Box>
				<Box display="flex" alignItems="center" flexDirection="row">
					<DragEqualDiv
						onTouchStart={() => {
							setTouchType("dragger");
							setCanDrag(true);
						}}
						onMouseDown={() => {
							setTouchType("dragger");
							setCanDrag(true);
						}}
					>
						=
					</DragEqualDiv>

					<DragIcon
						style={{ cursor: "move" }}
						onTouchStart={() => {
							setTouchType("reorder");
							setCanDrag(true);
						}}
						onMouseDown={() => {
							setTouchType("reorder");
							setCanDrag(true);
						}}
					/>
				</Box>
			</Box>
		</DragContainer>
	);
};

const AnswerOptionDropZone: FC<{
	canAlwaysDrop?: boolean;
	children: ReactNode | ReactNode[];
	dropZoneIndex?: number;
	index: number;
	onDrop: (_options: {
		newDropZoneIndex?: number;
		oldDropZoneIndex?: number;
		order: number;
		id: string;
		touchType?: string;
	}) => unknown;
	style?: { [fieldName: string]: unknown };
}> = ({ canAlwaysDrop, children, dropZoneIndex, index, onDrop, style }) => {
	const [dragObjectData, drop] = useDrop({
		accept: "group",
		drop: item => {
			onDrop({
				newDropZoneIndex: dropZoneIndex,
				oldDropZoneIndex: (item as unknown as Record<string, unknown>).dropZoneIndex as number,
				order: (item as unknown as Record<string, unknown>).index as number,
				id: (item as unknown as Record<string, unknown>).id as string,
				touchType: (item as unknown as Record<string, unknown>).touchType as string
			});
		},
		collect: monitor => ({
			isOver: monitor.isOver(),
			canDrop: monitor.canDrop()
		}),
		canDrop: dropItem =>
			canAlwaysDrop ||
			(((dropItem as unknown as Record<string, unknown>).index as number) !== index &&
				(dropItem as unknown as Record<string, unknown>).index !== index - 1)
	});
	const { isOver, canDrop } = dragObjectData as Record<string, boolean>;
	const overStyles = {
		border: "1px solid #264fae",
		padding: "1px"
	};
	return (
		<div ref={drop} style={isOver && canDrop ? { ...style, ...overStyles } : style}>
			{children}
		</div>
	);
};

const getAnswers = groups => {
	if (groups) {
		const answers: UserQuestionAnswerDto[] = [];
		groups?.forEach(x => {
			x.answerOptions?.forEach(y => {
				answers.push({ id: y.id, groupId: x.id });
			});
		});
		return answers;
	}
};

const setRefAnswers = (answersRef, answers, questionId) => {
	if (!answersRef?.current[questionId]?.length) {
		answersRef.current[questionId] = answers;
	}
};

const GroupingQuestion = ({
	question,
	questionIndex,
	uniqueIdentifier,
	dispatchAction,
	setUserAnswers,
	answersRef
}) => {
	const theme = useTheme<IExtendedTheme>();
	const dispatch = useDispatch();

	useEffect(() => {
		const answers = getAnswers(question.data.groups);
		setUserAnswers(answers);
		setRefAnswers(answersRef, answers, question.id);
	}, []);

	useEffect(() => {
		const answers = getAnswers(question.data.groups);
		setUserAnswers(answers);
		setRefAnswers(answersRef, answers, question.id);
	}, [question]);

	return (
		<>
			<TestQuizQuestionText>{question?.text}</TestQuizQuestionText>
			<GroupingInfoContainer>
				<HelpRounded style={{ width: "20px", height: "20px", fill: "#898f9e" }} />
				<GroupingInfoText>By dragging </GroupingInfoText>
				<DragIcon />
				<GroupingInfoText>you can move the answers between the categories.</GroupingInfoText>
			</GroupingInfoContainer>

			<GroupingContainer>
				{question.data.groups?.map((groups, groupIndex) => {
					const { answerOptions, text } = groups;
					return (
						<GroupingCategoryContainer
							key={`${uniqueIdentifier}-createLesson-qestionItem-${questionIndex}-answerGroup-${groupIndex}`}
						>
							<Box
								display="flex"
								flexDirection="row"
								width="100%"
								alignItems="center"
								minHeight="40px"
								borderRadius="4px"
								style={{
									backgroundColor: theme.palette.common.white,
									padding: "0 17px 0 13px",
									marginBottom: "22px"
								}}
							>
								<Typography
									style={{
										fontSize: `"${theme.typography.pxToRem(15)}"`,
										fontWeight: 600,
										lineHeight: 1.6,
										color: theme.palette.common.black,
										cursor: "default"
									}}
								>
									{text}
								</Typography>
							</Box>

							<AnswerOptionDropZone
								canAlwaysDrop={true}
								index={0}
								onDrop={({ oldDropZoneIndex, order, touchType, id }) => {
									dispatch(
										dispatchAction({
											questionIndex: questionIndex,
											newGroupIndex: groupIndex!,
											oldGroupIndex: oldDropZoneIndex!,
											newOptionIndex: 0,
											oldOptionIndex: order,
											id,
											touchType
										})
									);
								}}
							>
								<Box style={{ minHeight: 10 }} />
							</AnswerOptionDropZone>

							{answerOptions?.map((answerOption, answerOptionIndex) => {
								const { text, id } = answerOption;
								return (
									<AnswerOptionDropZone
										key={`${uniqueIdentifier}-${groupIndex}-${answerOptionIndex}`}
										canAlwaysDrop={true}
										dropZoneIndex={groupIndex}
										index={groupIndex}
										onDrop={({ oldDropZoneIndex, newDropZoneIndex, order, touchType, id }) => {
											dispatch(
												dispatchAction({
													questionIndex: questionIndex,
													newGroupIndex: newDropZoneIndex!,
													oldGroupIndex: oldDropZoneIndex!,
													newOptionIndex: answerOptionIndex,
													oldOptionIndex: order,
													id,
													touchType
												})
											);
										}}
										style={{
											flex: "1",
											marginTop: "8px"
										}}
									>
										<AnswerOption
											answerOptionIndex={answerOptionIndex}
											dropZoneIndex={groupIndex}
											key={`${uniqueIdentifier}-createLesson-qestionItem-${questionIndex}-answerGroup-${groupIndex}-answerOption-${answerOptionIndex}`}
											questionIndex={questionIndex}
											text={text}
											id={id}
										/>
									</AnswerOptionDropZone>
								);
							})}
						</GroupingCategoryContainer>
					);
				})}
			</GroupingContainer>
		</>
	);
};

export default GroupingQuestion;

import React from "react";

import {
	Box,
	Button,
	Card,
	Container,
	IconButton,
	Theme,
	Typography,
	useMediaQuery,
	useTheme
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { CustomInput } from "@remar/shared/dist/components/CustomInput/CustomInput";
import { useDispatch, useSelector } from "react-redux";
import { editMyAccountDetails, selectMainImageKey, setStateValue } from "store/features/MyAccount/myAccountSlice";
import { _emit } from "store/features/notifications/notifications.slice";
import { genericService } from "store/services";

import { useEditProfilePictureStyles } from "./styles";

const EditProfilePicture = ({ onClose }) => {
	const theme = useTheme<Theme>();
	const classes = useEditProfilePictureStyles();
	const dispatch = useDispatch();
	const mainImageKey = useSelector(selectMainImageKey);
	const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

	const defaultCustomInputOptions = { _emit, dispatch, setStateValue };
	return (
		<Container maxWidth="md">
			<Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" height="100vh">
				<Card className={classes.card}>
					<Box>
						<Box display="flex" alignItems="center" justifyContent="space-between">
							<Typography className={classes.title} variant="h6">
								Update Profile Picture
							</Typography>
							<IconButton onClick={onClose}>
								<CloseIcon color="disabled" />
							</IconButton>
						</Box>
						<Box display="flex" mt={3}>
							<CustomInput
								width={246}
								height={246}
								inputProps={{
									fileUploaderDescription: (
										<>
											Drag and Drop File Here or Browse to Choose a File
											<br />
											File type: PNG, JPEG
											<br />
											Recommended Size 1440x1000
										</>
									)
								}}
								loaderStatePath={"isLoadingAccountDetails"}
								options={{
									...defaultCustomInputOptions,
									genericApiService: genericService,
									inputData: mainImageKey
								}}
							/>
						</Box>
						{mainImageKey.value && (
							<Box
								onClick={() => {
									dispatch(setStateValue({ key: "mainImageKey.value", value: null }));
									dispatch(setStateValue({ key: "mainImageKey.error", value: "" }));
									dispatch(setStateValue({ key: "mainImageKey.pristine", value: true }));
								}}
								style={{ cursor: "pointer", color: "#4a99f9" }}
								mt={2}
							>
								Remove Photo
							</Box>
						)}

						<Box display="flex" justifyContent="flex-end" mt={3}>
							<Button className={classes.cancel} variant="outlined" fullWidth={isMobile} onClick={() => onClose()}>
								Cancel
							</Button>
							<Button
								variant="contained"
								color="primary"
								className={classes.save}
								fullWidth={isMobile}
								onClick={() => {
									dispatch(
										editMyAccountDetails({
											data: { profileImageKey: mainImageKey?.value },
											sideEffect: () => onClose(),
											successMsg: "Avatar Changed successfully."
										})
									);
								}}
							>
								Save
							</Button>
						</Box>
					</Box>
				</Card>
			</Box>
		</Container>
	);
};

export default EditProfilePicture;

// noinspection HtmlUnknownBooleanAttribute

import React, { FC, useEffect, useRef, useState } from "react";

import { Modal } from "@material-ui/core";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import { CourseChapterSectionLesson } from "@remar/shared/dist/models";
import { getVideoPlayer } from "@remar/shared/dist/utils/serviceUtils/helpers";
import cn from "classnames";
import { useUpgradeForm } from "hooks/useUpgradeForm";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";

import { getUserData, selectInactiveSubscription } from "store/features/Auth/authSlice";
import videojs from "video.js";

import { routes } from "core/constants";

import UpgradeSubscription from "modules/MyAccount/UpgradeSubscription";

import {
	TrailerContainer,
	TrailerHeading,
	TrailerText,
	TrailerUpgradeSubscription,
	TrailerWatchAgain,
	useStyles
} from "./style";

interface Props {
	url: string;
	sectionLesson: CourseChapterSectionLesson;
	allLessons: CourseChapterSectionLesson[];
}

export const Trailer: FC<Props> = ({ url, sectionLesson: { isLockedByPayment, isLockedByProgress }, allLessons }) => {
	const classes = useStyles();
	const history = useHistory();
	const dispatch = useDispatch();
	const videoRef = useRef(null);
	const player = useRef<videojs.Player>();
	const { isRenewEnabled } = useSelector(selectInactiveSubscription);

	const [modal, setModal] = useState(false);

	const onSubmit = () => {
		setModal(false);
		dispatch(getUserData());
		history.push(`${routes.course.getPath()}?refetch=true`);
	};

	const { closeModal, ...upgradeFormProps } = useUpgradeForm({
		setModal,
		onSubmit,
		dispatch,
		isRenewSubscription: isRenewEnabled
	});

	useEffect(() => {
		player.current = player.current || getVideoPlayer(videoRef!.current!, url, { controls: false });
		return () => {
			player.current = undefined;
		};
	}, [url]);

	const getLatestLesson = () => {
		const lockedLessonIndex = allLessons.findIndex(({ isLockedForCurrentUser }) => isLockedForCurrentUser);
		const latestLessonIndex =
			lockedLessonIndex == -1
				? allLessons.length - 1
				: lockedLessonIndex === 0
				? lockedLessonIndex
				: lockedLessonIndex - 1;
		return allLessons[latestLessonIndex];
	};

	const handleRoute = () => {
		if (isLockedByPayment) {
			setModal(true);
		} else {
			const currentUnlockedLesson = getLatestLesson();
			history.push(`${routes.lesson.getPath()}/${currentUnlockedLesson.lessonId}/${currentUnlockedLesson.sectionId}`);
		}
	};

	return (
		<div data-vjs-player className={classes.trailerVideoDiv}>
			<TrailerContainer>
				<TrailerHeading>Lesson Locked</TrailerHeading>
				<TrailerText>
					To unlock this lesson, you need to{" "}
					{isLockedByPayment ? "upgrade your subscription" : "complete your previous lesson"}
				</TrailerText>
				<div>
					<TrailerUpgradeSubscription onClick={handleRoute}>
						{isLockedByProgress && !isLockedByPayment && (
							<span>
								<ChevronLeft />
							</span>
						)}
						<span
							className={cn({
								[classes.trailerProgress]: isLockedByProgress && !isLockedByPayment,
								[classes.trailerSubscription]: isLockedByPayment
							})}
						>
							{isLockedByPayment ? "Upgrade Subscription" : "Complete Previous Lesson"}
						</span>
					</TrailerUpgradeSubscription>
				</div>
				<div>
					<TrailerWatchAgain onClick={() => player.current?.play()}>Watch Again</TrailerWatchAgain>
				</div>
			</TrailerContainer>
			<Modal open={modal} onClose={closeModal}>
				<UpgradeSubscription {...upgradeFormProps} closeModal={closeModal} />
			</Modal>
			<video autoPlay ref={videoRef} className="video-js vjs-big-play-centered" />
		</div>
	);
};

import React, { useState } from "react";

import { Box, Button, CircularProgress, Theme, useMediaQuery, useTheme } from "@material-ui/core";
import { SimpleModal } from "@remar/shared/dist/components/SimpleModal";

import classnames from "classnames";

import { useDispatch } from "react-redux";
import { cancelSubscription } from "store/features/MyAccount/myAccountSlice";

import { useStyles } from "./styles";

const CancelSubscriptionButton = ({ id, sideEffect = () => {} }: { id: number; sideEffect: () => void }) => {
	const theme = useTheme<Theme>();
	const classes = useStyles();
	const [cancelModalOpen, setCancelModalOpen] = useState(false);
	const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
	const [isLoading, setIsLoading] = useState(false);
	const dispatch = useDispatch();
	const onClose = () => {
		if (!isLoading) {
			setCancelModalOpen(false);
		}
	};
	const onConfirm = () => {
		setIsLoading(true);
		dispatch(
			cancelSubscription({
				id,
				sideEffect: () => {
					sideEffect();
					setCancelModalOpen(false);
					setIsLoading(false);
				}
			})
		);
	};
	return (
		<>
			<Button className={classes.cancelSubscriptionBtn} onClick={() => setCancelModalOpen(true)}>
				Cancel Subscription
			</Button>
			<SimpleModal
				theme={theme}
				title="Cancel Subscription"
				open={cancelModalOpen}
				onClose={onClose}
				text="Are you sure you want to cancel your subscription?"
				footer={
					<>
						<Button
							className={classnames(classes.keepBtn, classes.button)}
							size="medium"
							disabled={isLoading}
							onClick={onClose}
						>
							{isLoading ? (
								<Box width={50}>
									<CircularProgress size="1rem" color="inherit" thickness={2} variant="indeterminate" />
								</Box>
							) : (
								<>{`No, Keep ${isMobile ? "it" : "Subscription"}`}</>
							)}
						</Button>
						<Button
							className={classnames(classes.cancelBtn, classes.button)}
							disabled={isLoading}
							size="medium"
							onClick={() => onConfirm()}
						>
							{isLoading ? (
								<Box width={50}>
									<CircularProgress size="1rem" color="inherit" thickness={2} variant="indeterminate" />
								</Box>
							) : (
								<>Yes, Cancel</>
							)}
						</Button>
					</>
				}
			/>
		</>
	);
};

export default CancelSubscriptionButton;

import React, { useEffect, useMemo, useRef, useState } from "react";

import { Box, SvgIcon } from "@material-ui/core";
import { ReactComponent as IconEquals } from "@remar/shared/dist/assets/icons/icon-equals.svg";
import { useDraggedScroll } from "@remar/shared/dist/utils/useDraggedScroll";

import isEmpty from "lodash/isEmpty";

import { isMobile } from "react-device-detect";

import { useDrag, useDrop } from "react-dnd";

import { MainDragOptions, QuestionRationalInfo } from "./styles";

import {
	AnswerDropZoneGapContainer,
	DragOptionContainer,
	DragOptionContainerText,
	Gap,
	GroupContainer,
	TestQuestionStyledContainer,
	TestQuizQuestionText
} from "../../styles";
import { dragCollect, getTouchBackendStyle } from "../utils/dragAndDrop";

interface Option {
	id: string;
	text: string;
}

interface SelectedAnswer {
	option: Option;
	groupId?: string;
}

const DragOption = ({ option, type, sideEffect }: { type: string; option: Option; sideEffect?: () => void }) => {
	const [{ position, isDragging, opacity }, drag] = useDrag({
		item: { option, ...(sideEffect && { sideEffect: sideEffect }) },
		type: type,
		collect: monitor => dragCollect(monitor)
	});

	return (
		<DragOptionContainer
			type={type}
			id={option?.id}
			ref={drag}
			style={isMobile && isDragging ? getTouchBackendStyle(position) : { opacity }}
		>
			<DragOptionContainerText>{option?.text}</DragOptionContainerText>
			<SvgIcon fontSize="large">
				<IconEquals />
			</SvgIcon>
		</DragOptionContainer>
	);
};

const AnswerDropZone = ({ onDropped, groupId, userAnswer }) => {
	const [selectedAnswer, setSelectedAnswer] = useState<SelectedAnswer>();

	const ref = useRef({
		selectedAnswer
	});

	useEffect(() => {
		if (userAnswer && userAnswer.option.id !== selectedAnswer?.option.id && userAnswer.option.text) {
			setSelectedAnswer({
				groupId: userAnswer.option.groupId,
				option: { id: userAnswer.option.id, text: userAnswer.option.text }
			});
		}
	}, [userAnswer]);

	const [{ isOver }, drop] = useDrop(() => ({
		accept: "group",
		drop: (item: SelectedAnswer) => {
			const selectedAnswer = { ...item, groupId };
			if (!isEmpty(selectedAnswer)) {
				if (selectedAnswer?.option.id !== userAnswer?.option.id) {
					onDropped({ item: selectedAnswer, prevItem: ref.current.selectedAnswer });
				}
				ref.current.selectedAnswer = selectedAnswer;
			}
		},
		collect: monitor => ({
			isOver: monitor.isOver({ shallow: true })
		})
	}));

	const cleanData = () => {
		ref.current.selectedAnswer = undefined;
		setSelectedAnswer(undefined);
	};

	return (
		<AnswerDropZoneGapContainer ref={drop} $isOver={isOver}>
			<Gap className={selectedAnswer ? "filled" : ""} $isOver={isOver}>
				{selectedAnswer && <DragOption type="standalone" sideEffect={cleanData} option={selectedAnswer.option} />}
			</Gap>
		</AnswerDropZoneGapContainer>
	);
};

const GroupDropZone = ({ answerOptions, onDropped }) => {
	const [{ isOver, canDrop }, drop] = useDrop(() => ({
		accept: "standalone",
		drop: (item: { option: Record<string, unknown>; sideEffect: () => void }) => {
			onDropped({ option: item.option as Record<string, unknown> });
			item?.sideEffect();
		},
		collect: monitor => ({
			isOver: monitor.isOver(),
			canDrop: monitor.canDrop()
		})
	}));

	return (
		<MainDragOptions ref={drop} changeOpacity={isOver && canDrop} isShowBorder={isOver && answerOptions.length == 0}>
			{answerOptions.map((dragOption, i) => (
				<Box mt={2} key={i}>
					<DragOption type="group" option={dragOption} />
				</Box>
			))}
		</MainDragOptions>
	);
};

const DragAndDropQuestion = ({ question, onChange, userAnswers, answersRef }) => {
	const { answerOptions, groups } = question.data;

	useDraggedScroll();

	const onDroppedHandle = ({ item, prevItem }) => {
		const {
			groupId,
			option: { id }
		} = item;
		const _item = { groupId, id };

		if (item.option.id === prevItem?.option.id) {
			return;
		}
		const ActiveCSQuestionId = answersRef.current.questionId;
		const existedAnswerOpts = [
			...answersRef.current[ActiveCSQuestionId || question.id].filter(({ groupId }) => groupId !== item.groupId)
		];
		const answers = prevItem
			? existedAnswerOpts.filter(x => !(x.id === prevItem.option.id && x.groupId === prevItem.groupId))
			: existedAnswerOpts;
		onChange([...answers, _item]);
	};

	const onRemoveAnswer = value => {
		if (!isEmpty(value)) {
			const { option } = value as SelectedAnswer;
			const ActiveCSQuestionId = answersRef.current.questionId;
			const arr = [...answersRef.current[ActiveCSQuestionId || question.id]];
			const index = arr.findIndex(({ id }) => id === option.id);
			arr.splice(index, 1);
			onChange([...arr]);
		}
	};

	const filteredAnswerOptions = useMemo(
		() => answerOptions.filter(item => !userAnswers.some(({ id }) => id === item.id)),
		[answerOptions, userAnswers]
	);

	return (
		<>
			<TestQuestionStyledContainer>
				<TestQuizQuestionText>
					<QuestionRationalInfo
						dangerouslySetInnerHTML={{
							__html: question?.description ?? ""
						}}
					></QuestionRationalInfo>
				</TestQuizQuestionText>
			</TestQuestionStyledContainer>
			<TestQuestionStyledContainer mt={2}>
				<Box display="flex" flexWrap="wrap" bgcolor="white">
					{groups?.map(({ id: groupId, text, selectedAnswerOptions }) => {
						const answer = { ...(userAnswers.find(answer => answer.groupId === groupId) ?? {}) };

						if (answer && !answer.text) {
							answer.text = answerOptions.find(({ id }) => id === answer.id)?.text;
						}
						return (
							<GroupContainer key={groupId}>
								{text}
								{selectedAnswerOptions && selectedAnswerOptions.length !== 0 && (
									<AnswerDropZone
										onDropped={onDroppedHandle}
										groupId={groupId}
										userAnswer={answer ? { option: answer } : undefined}
									/>
								)}
							</GroupContainer>
						);
					})}
				</Box>
			</TestQuestionStyledContainer>
			<TestQuestionStyledContainer>
				<h4>Word Choices</h4>
				<GroupDropZone onDropped={onRemoveAnswer} answerOptions={filteredAnswerOptions} />
			</TestQuestionStyledContainer>
		</>
	);
};

export default DragAndDropQuestion;

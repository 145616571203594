import React, { FC } from "react";

import { Box, Typography } from "@material-ui/core";

import { Add, Remove } from "@material-ui/icons";

import { useDispatch } from "react-redux";

import { RootState, useAppDispatch, useAppSelector } from "store";

import {
	addSubscriptionIntegrations,
	additionSubscriptionIntegrations,
	removeSubscriptionIntegrations
} from "store/features/Auth/authSlice";

import { GuestPaidShippingContainer, useStyles } from "./styles";
interface Props {
	setChangeBookModal?: (_) => void;
	bookId?: number;
}

const GuestSummary: FC<Props> = ({ setChangeBookModal }) => {
	const classes = useStyles();
	const { selectedShippingPlan, externalIntegrations, subExternalIntegrations }: RootState["auth"] = useAppSelector(
		(store: RootState) => store.auth
	);
	const dispatch = useDispatch();
	const appDispatch = useAppDispatch();

	const bookPriceSum = subExternalIntegrations?.reduce(function (lastSum, currentItem) {
		return lastSum + (currentItem?.data["price"] * (currentItem?.quantity as number) || 0);
	}, 0);

	const getBookPriceTotal = booksPrice => {
		let total;
		if (booksPrice && selectedShippingPlan?.data["price"]) {
			total = booksPrice + selectedShippingPlan?.data["price"];
			total = total.toLocaleString("en-US", { currency: "USD", style: "currency" });
			return total;
		} else {
			return booksPrice ? booksPrice.toLocaleString("en-US", { currency: "USD", style: "currency" }) : 0;
		}
	};

	return (
		<Box className={`${classes.summaryRoot} ${classes.customFlex}`}>
			<Box>
				<Box mb={4}>
					<Typography className={classes.title}>You Might Also Like</Typography>
					{externalIntegrations
						?.filter(item => !item.parentId)
						?.map(
							addon =>
								addon && (
									<Box
										key={addon.id}
										display="flex"
										justifyContent="space-between"
										alignItems="center"
										mt={2}
										bgcolor="#393e4b"
										p={1}
										pr={2}
										borderRadius={5}
									>
										<Box display="flex" alignItems="center">
											<Box borderRadius={5} overflow="hidden" width={40} height={55}>
												<img className={classes.bookImg} src={addon?.mainImageUrl} />
											</Box>
											<Box ml={1}>
												<Typography className={classes.summaryText}>{addon?.data["name"] as string}</Typography>
												<Typography>
													{(addon?.data["price"] || 0).toLocaleString("en-US", { currency: "USD", style: "currency" })}
												</Typography>
											</Box>
										</Box>
										<Box>
											<Box
												bgcolor={"#c0c9e5"}
												p={0.5}
												display="flex"
												borderRadius={5}
												justifyContent="center"
												alignItems="center"
												className={classes.cursorPointer}
												onClick={() => {
													appDispatch(additionSubscriptionIntegrations(addon.id));
												}}
											>
												<Add className={classes.addBookCard} />
											</Box>
										</Box>
									</Box>
								)
						)}
				</Box>

				<Box>
					<Typography className={classes.title}>Order Summary</Typography>

					{subExternalIntegrations?.map((addon, i) => (
						<>
							<Box
								display="flex"
								alignItems="center"
								key={addon?.data["name"] as string}
								justifyContent="space-between"
								mt={2}
								p={1}
								pr={2}
							>
								<Box display="flex" width={"100%"} alignItems="center">
									<Box borderRadius={5} overflow="hidden" width={40} height={55}>
										<img className={classes.bookImg} src={addon?.mainImageUrl} />
									</Box>
									<Box ml={1} width={"calc(100% - 40px)"} flexDirection="column" display="flex">
										<Box alignItems="center" display="flex">
											<Typography className={classes.summaryText}>{addon?.data["name"] as string}&nbsp;</Typography>
											{subExternalIntegrations.length === 1 && (
												<span className={classes.changeBook} onClick={() => setChangeBookModal!(true)}>
													{"(Change)"}
												</span>
											)}

											{subExternalIntegrations.length > 1 && (
												<span
													className={classes.removeBook}
													onClick={() => dispatch(removeSubscriptionIntegrations(addon.id))}
												>
													{"(Remove)"}
												</span>
											)}
										</Box>
										<Box width={"100%"} display="flex" justifyContent="space-between" alignItems="center">
											<Box display="flex">
												<Typography>
													{(addon?.data["price"] || 0).toLocaleString("en-US", {
														currency: "USD",
														style: "currency"
													})}
												</Typography>
											</Box>

											<Box display="flex" alignItems="center">
												<Box
													bgcolor={"#393e4b"}
													display="flex"
													padding={0.3}
													borderRadius={5}
													justifyContent="center"
													alignItems="center"
													style={{
														cursor: (addon?.quantity as number) > 1 ? "pointer" : "not-allowed",
														...((addon?.quantity as number) === 1 && { pointerEvents: "none" })
													}}
													onClick={() => {
														appDispatch(
															addSubscriptionIntegrations({
																id: addon.id,
																index: i,
																quantity: (addon?.quantity as number) - 1
															})
														);
													}}
												>
													<Remove
														className={classes.removeBookIcon}
														style={{
															color: (addon?.quantity as number) > 1 ? "#eceff4" : "#586179"
														}}
													/>
												</Box>
												<Box className={classes.quantityClass}>{addon?.quantity as number}</Box>
												<Box
													bgcolor={"#393e4b"}
													display="flex"
													padding={0.3}
													borderRadius={5}
													justifyContent="center"
													alignItems="center"
													className={classes.cursorPointer}
													onClick={() => {
														appDispatch(
															addSubscriptionIntegrations({
																id: addon.id,
																index: i,
																quantity: (addon?.quantity as number) + 1
															})
														);
													}}
												>
													<Add className={classes.addBook} />
												</Box>
											</Box>
										</Box>
									</Box>
								</Box>
							</Box>
						</>
					))}
				</Box>
			</Box>

			<Box>
				{selectedShippingPlan && (
					<GuestPaidShippingContainer>
						<Typography className={classes.summaryText} align="right">
							{selectedShippingPlan?.name}
						</Typography>
						<Typography className={classes.summaryCostSmall} align="right">
							{(selectedShippingPlan?.data["price"] || 0).toLocaleString("en-US", {
								currency: "USD",
								style: "currency"
							})}{" "}
						</Typography>
					</GuestPaidShippingContainer>
				)}
				<Box>
					<Typography className={classes.summaryTextLight} align="right">
						Your total
					</Typography>
					<Typography className={classes.summaryCostBig} align="right">
						{getBookPriceTotal(bookPriceSum) ?? "N/A"}
					</Typography>
				</Box>
			</Box>
		</Box>
	);
};

export default GuestSummary;

import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { clearJwt } from "@remar/shared/dist/api/jwt";
import { CustomInputModel, CustomInputType } from "@remar/shared/dist/components/CustomInput/customInput.model";
import { IBaseState, User, UserInfo } from "@remar/shared/dist/models";

import { pendingReducer } from "@remar/shared/dist/utils/reducerHelpers";
import { setStateValue as utilsSetStateValue } from "@remar/shared/dist/utils/stateUtils";
import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js";

import { isSameDay } from "date-fns";
import { RootState } from "store";

import { UserGetSubscriptionInfoResponseDto, UserUpdateDataDto, usersService } from "store/services";

import { UserUpdateSubscriptionDto } from "store/services/users/dto/users.updateSubscription.dto";

import { handleStripePayment, setUser } from "../Auth/authSlice";
import { emit } from "../notifications/notifications.slice";

interface MyAccountState extends Omit<IBaseState, "error"> {
	userInfo: UserInfo | null;
	subscriptionInfo: UserGetSubscriptionInfoResponseDto | null;
	errorMessage: string;
	isLoadingSubscription: boolean;
	isLoadingAccountDetails: boolean;
	mainImageKey: CustomInputModel<string>;
	isLoadingSubscriptionUpdate: boolean;
	isDeleteError: boolean;
	accountDeleted: boolean;
}

const initialState: MyAccountState = {
	isLoading: false,
	userInfo: null,
	subscriptionInfo: null,
	errorMessage: "",
	isLoadingSubscription: false,
	isLoadingSubscriptionUpdate: false,
	isLoadingAccountDetails: false,
	isDeleteError: false,
	accountDeleted: false,
	mainImageKey: {
		imageExtensionName: "png, jpeg, jpg",
		isImageFile: true,
		label: "Upload Photo",
		placeholder: "Upload Photo",
		type: CustomInputType.File,
		uploaderIconName: "cloud-upload",
		uploaderStateLoaderKey: "fileIsUploading",
		error: "",
		pristine: true,
		statePath: "mainImageKey"
	}
};

export const getUserInfo = createAsyncThunk("myAccount/getUserInfo", async (_, { rejectWithValue, dispatch }) => {
	try {
		const { user } = (await usersService.getAccountInfo()) as {
			user: User;
		};
		dispatch(setUser({ user }));
		return user;
	} catch {
		return rejectWithValue("Error in getting user info");
	}
});

export const getSubscriptionInfo = createAsyncThunk("myAccount/getSubscriptionInfo", async (_, { rejectWithValue }) => {
	return await usersService.getSubscriptionInfo().catch(() => rejectWithValue("Error in getting subscription info"));
});

export const cancelSubscription = createAsyncThunk(
	"myAccount/cancelSubscription",
	async (
		{ id, sideEffect = () => {} }: { id: number; sideEffect: () => void },
		{ rejectWithValue, dispatch }
	): Promise<void> => {
		await usersService
			.cancelSubscription(id)
			.then(() => dispatch(getSubscriptionInfo()))
			.catch(() => rejectWithValue("Error in cancelling subscription"))
			.finally(sideEffect);
	}
);

export const deleteAccount = createAsyncThunk(
	"myAccount/deleteAccount",
	async (
		{ password, sideEffect = () => {} }: { password: string; sideEffect: () => void },
		{ rejectWithValue, dispatch }
	) => {
		await usersService
			.deleteAccount(password)
			.then(() => {
				dispatch(setStateValue({ key: "isDeleteError", value: false }));
				dispatch(setStateValue({ key: "accountDeleted", value: true }));
				clearJwt();
				dispatch(emit({ message: "User account has been deleted, you are going to be logout.", color: "success" }));
			})
			.catch(error => {
				dispatch(setStateValue({ key: "isDeleteError", value: true }));
				rejectWithValue("Error in deleting account");
				dispatch(emit({ message: error.message, color: "error" }));
			})
			.finally(sideEffect);
	}
);

export const resumeSubscription = createAsyncThunk(
	"myAccount/resumeSubscription",
	async (
		{ id, sideEffect = () => {} }: { id: number; sideEffect: () => void },
		{ rejectWithValue, dispatch }
	): Promise<void> => {
		await usersService
			.resumeSubscription(id)
			.then(() => {
				dispatch(
					emit({
						message: "Subscription start date updated!",
						color: "success"
					})
				);
				dispatch(getSubscriptionInfo());
				sideEffect();
			})
			.catch(() => rejectWithValue("Error in resuming subscription"));
	}
);

export const changeSubscriptionDate = createAsyncThunk(
	"myAccount/changeSubscriptionDate",
	async (
		{ data, sideEffect = () => {} }: { data: UserUpdateSubscriptionDto; sideEffect: () => void },
		{ rejectWithValue, dispatch }
	): Promise<void> => {
		await usersService
			.changeSubscriptionDate(data)
			.then(() => {
				dispatch(
					emit({
						message: "Subscription start date has been updated",
						color: "success"
					})
				);
				dispatch(getSubscriptionInfo());
			})
			.catch(e => {
				dispatch(emit({ message: e.message, color: "error" }));
				rejectWithValue("Error in updating subscription date");
			})
			.finally(sideEffect);
	}
);

export const renewSubscription = createAsyncThunk(
	"myAccount/renewSubscription",
	async (
		{
			id,
			subscriptionBody,
			dispatch,
			CardElement,
			elements,
			stripe,
			sideEffect,
			handleError
		}: {
			id: number;
			subscriptionBody;
			dispatch;
			handleError;
			CardElement;
			elements;
			stripe;
			sideEffect: () => void;
		},
		{ rejectWithValue }
	): Promise<void> => {
		try {
			const payload = {
				data: {
					...subscriptionBody,
					fullName: subscriptionBody.fullName
				},
				filters: { id: subscriptionBody!.userId }
			};
			const cardElement = elements?.getElement(CardElement);
			if (stripe && cardElement) {
				const paymentMethod = await handleStripePayment(stripe, cardElement);
				if (paymentMethod) {
					await usersService.updatePaymentMethod({
						paymentProviderPaymentMethodIdentifier: paymentMethod!.id
					});
				}
			}
			await usersService.updateBillingAddress(payload);
			await usersService.resumeSubscription(id);
			sideEffect();
		} catch (e) {
			dispatch(handleError(e.message));
			rejectWithValue(`Error in resuming subscription: ${e.message}}`);
		}
	}
);
interface SubscriptionThunk {
	CardElement: typeof CardElement;
	elements: ReturnType<typeof useElements>;
	stripe: ReturnType<typeof useStripe>;
	values;
	// eslint-disable-next-line no-unused-vars
	sideEffect: (values) => void;
}

async function subscribeUser(
	userSubscriptionTypeId: number,
	values,
	paymentMethodId: string,
	sideEffect: (values: unknown) => void,
	stripe,
	userSubscriptionTypeAddonIds?: number[]
) {
	try {
		const { address1, address2, city, countryId, phoneNumber, startDate, state, zip } = values;
		const subscriptionBody = {
			paymentProviderId: 1,
			userSubscriptionTypeId,
			userSubscriptionTypeAddonIds,
			userSubscriptionStartDate: startDate.toISOString(),
			paymentProviderPaymentMethodIdentifier: paymentMethodId,
			address: {
				phoneNumber,
				address1,
				address2,
				countryId,
				city,
				state,
				zipCode: zip
			}
		};
		const user = await usersService.createSubscription(subscriptionBody);

		const paymentNeedsConfirmation = !user?.subscriptionHasStarted && user?.subscriptionPaymentIntentClientSecret;
		sideEffect && sideEffect(values);
		const notDelayed = isSameDay(values.startDate, new Date());
		if (paymentNeedsConfirmation && notDelayed) {
			await stripe!.confirmCardPayment(user!.subscriptionPaymentIntentClientSecret!);
			return;
		}
		return;
	} catch (e) {
		throw new Error(e.message);
	}
}

export const upgradeSubscription = createAsyncThunk(
	"auth/upgradeSubscription",
	async (
		{ CardElement, elements, stripe, values, sideEffect }: SubscriptionThunk,
		{ getState, dispatch }
	): Promise<void> => {
		dispatch(setError(""));
		const {
			auth: { userSubscriptionTypeAddonIds, userSubscriptionTypeId }
		} = getState() as RootState;
		const cardElement = elements?.getElement(CardElement);
		const { paymentMethodId } = values;
		// Sign up with payment
		if (stripe && cardElement) {
			const { error, paymentMethod } = await stripe!.createPaymentMethod({ type: "card", card: cardElement! });
			if (error) {
				throw new Error("Please check your card details");
			} else if (paymentMethod) {
				return await subscribeUser(
					userSubscriptionTypeId,
					values,
					paymentMethod!.id,
					sideEffect,
					stripe,
					userSubscriptionTypeAddonIds
				);
			}
		} else if (paymentMethodId) {
			return await subscribeUser(
				userSubscriptionTypeId,
				values,
				paymentMethodId,
				sideEffect,
				stripe,
				userSubscriptionTypeAddonIds
			);
		}
	}
);

export const editMyAccountDetails = createAsyncThunk(
	"myAccount/editMyAccountDetails",
	async (
		{
			data,
			sideEffect = () => {},
			successMsg,
			successSideEffect
		}: { data: UserUpdateDataDto; sideEffect: () => void; successMsg: string; successSideEffect },
		{ rejectWithValue, dispatch }
	): Promise<void> => {
		await usersService
			.update({ data, filters: {} })
			.then(() => {
				successSideEffect && successSideEffect();
				dispatch(
					emit({
						message: successMsg,
						color: "success"
					})
				);
				dispatch(getUserInfo()); // todo: catch the exception
			})
			.catch(e => {
				dispatch(emit({ message: e.message, color: "error" }));
				return rejectWithValue(e.message);
			})
			.finally(sideEffect);
	}
);

export const changePassword = createAsyncThunk(
	"myAccount/changeUserPassword",
	async (
		{
			newPassword,
			currentPassword,
			sideEffect = () => {},
			successSideEffect
		}: { newPassword: string; currentPassword: string; sideEffect: () => void; successSideEffect },
		{ rejectWithValue, dispatch }
	) =>
		await usersService
			.changePassword({ newPassword, currentPassword })
			.then(() => {
				successSideEffect && successSideEffect();
				dispatch(
					emit({
						message: "Account Password changed successfully.",
						color: "success"
					})
				);
			})
			.catch(e => {
				dispatch(emit({ message: e.message, color: "error" }));
				return rejectWithValue(e.message);
			})
			.finally(sideEffect)
);

export const myAccountSlice = createSlice({
	name: "myAccount",
	initialState,
	reducers: {
		setError: (state, { payload }: PayloadAction<string>) => {
			state.errorMessage = payload;
		},
		setStateValue: utilsSetStateValue
	},
	extraReducers: {
		[getUserInfo.pending.type]: state => pendingReducer(state, "isLoading"),
		[getUserInfo.fulfilled.type]: (state, { payload }) => {
			if (!payload) {
				state.isLoading = false;
				return;
			}

			const {
				email,
				firstName,
				lastName,
				phoneNumber,
				profileImageUrl,
				school,
				userShippingDetails,
				acceptedTermsAndConditions
			} = payload;
			const schoolName = school?.name || undefined;

			state.userInfo = {
				firstName,
				lastName,
				profileImageUrl,
				email,
				schoolName,
				phoneNumber,
				acceptedTermsAndConditions,
				address:
					userShippingDetails?.address1 &&
					`${userShippingDetails?.address1}${
						userShippingDetails?.address2 ? `${`, ${userShippingDetails?.address2}`}` : " "
					}`,
				shippingDetails: userShippingDetails
			};
			state.isLoading = false;
		},
		[getSubscriptionInfo.pending.type]: state => pendingReducer(state, "isLoading"),
		[getSubscriptionInfo.fulfilled.type]: (state, { payload }) => {
			state.subscriptionInfo = payload;
			state.isLoading = false;
		},
		[getSubscriptionInfo.rejected.type]: state => {
			state.isLoading = false;
		},
		// upgradeSubscription flow
		[upgradeSubscription.pending.type]: state => {
			state.isLoadingSubscription = true;
		},
		[upgradeSubscription.fulfilled.type]: state => {
			state.isLoadingSubscription = false;
		},
		[upgradeSubscription.rejected.type]: (state, { payload }) => {
			state.errorMessage = payload;
			state.isLoadingSubscription = false;
		},
		// edit My Account Details flow
		[editMyAccountDetails.pending.type]: state => {
			state.isLoadingAccountDetails = true;
		},
		[editMyAccountDetails.fulfilled.type]: state => {
			state.isLoadingAccountDetails = false;
		},
		[editMyAccountDetails.rejected.type]: (state, { payload }) => {
			state.errorMessage = payload;
			state.isLoadingAccountDetails = false;
		},
		//update start subscription data
		[changeSubscriptionDate.pending.type]: state => {
			state.isLoadingSubscriptionUpdate = true;
		},
		[changeSubscriptionDate.fulfilled.type]: state => {
			state.isLoadingSubscriptionUpdate = false;
		},
		[changeSubscriptionDate.rejected.type]: (state, { payload }) => {
			state.errorMessage = payload;
			state.isLoadingSubscriptionUpdate = false;
		},
		[renewSubscription.pending.type]: state => {
			state.isLoadingSubscription = true;
		},
		[renewSubscription.fulfilled.type]: state => {
			state.isLoadingSubscription = false;
		},
		[renewSubscription.rejected.type]: state => {
			state.isLoadingSubscription = false;
		}
	}
});

export const { setError, setStateValue } = myAccountSlice.actions;

export const selectMyAccountIsLoading = ({ myAccount }: RootState): boolean => myAccount.isLoading;
export const selectUserInfo = ({ myAccount }: RootState): UserInfo | null => myAccount.userInfo;
export const selectSubscriptionInfo = ({ myAccount }: RootState): UserGetSubscriptionInfoResponseDto | null =>
	myAccount.subscriptionInfo;
export const selectSubcriptionIsLoading = ({ myAccount }: RootState): boolean => myAccount.isLoadingSubscription;
export const selectUpdateSubcriptionIsLoading = ({ myAccount }: RootState): boolean =>
	myAccount.isLoadingSubscriptionUpdate;
export const selectError = ({ myAccount }: RootState): string => myAccount.errorMessage;
export const selectMyAccountDetailsLoading = ({ myAccount }: RootState): boolean => myAccount.isLoadingAccountDetails;
export const selectMainImageKey = ({ myAccount }: RootState): CustomInputModel<string> => myAccount.mainImageKey;
export const selectFullState = ({ myAccount }: RootState): MyAccountState => myAccount;

export default myAccountSlice.reducer;

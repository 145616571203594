import React from "react";

import { Box, Button, Card, Theme, Typography, useMediaQuery, useTheme } from "@material-ui/core";

import { ReactComponent as IconCheck } from "assets/icons/icon-checkmark.svg";
import { ReactComponent as IconError } from "assets/icons/icon-circle-cross.svg";

import { useStyles } from "./styles";

const SignUpFollowUpScreen = ({
	success,
	error,
	onClick
}: {
	success: boolean;
	error: boolean;
	onClick: () => void;
}) => {
	const theme = useTheme<Theme>();
	const classes = useStyles();
	const isMobile = useMediaQuery(theme.breakpoints.down("xs"));
	const BoxPadding = isMobile ? 0 : 22;
	return (
		<Card>
			<Box display="flex" justifyContent="center" p={5} pl={BoxPadding} pr={BoxPadding} mt={5}>
				<Box display="flex" justifyContent="center" flexDirection="column">
					<Box display="flex" justifyContent="center" mb={5} mt={4}>
						{success && <IconCheck width={70} height={70} />}
						{error && <IconError width={70} height={70} />}
					</Box>
					<Typography
						className={`${classes.signUpTitleFollowUp} ${success && classes.successTitleFollowUp} ${
							error && classes.errorTitleFollowUp
						}`}
					>
						{success && "Successful Onboarding!"}
						{error && "Something Went Wrong"}
					</Typography>
					<Typography className={classes.signUpSubTitleTextFollowUp}>
						{success && "Welcome to ReMar!"} {error && "We are not able to process your payment. Please try again."}
					</Typography>

					<Box display="flex" justifyContent="center" mt={4}>
						<Button color="primary" variant="contained" onClick={onClick} style={{ width: "90%" }}>
							{success && "Continue"}
							{error && "Try Again"}
						</Button>
					</Box>
				</Box>
			</Box>
		</Card>
	);
};

export default SignUpFollowUpScreen;

import React, { useState } from "react";

import {
	Box,
	Button,
	Card,
	CircularProgress,
	IconButton,
	Theme,
	Typography,
	makeStyles,
	useTheme
} from "@material-ui/core";
import { CalendarTodaySharp, Close as CloseIcon } from "@material-ui/icons";
import { DatePicker } from "@remar/shared/dist/components/DatePicker";
import { SimpleModal } from "@remar/shared/dist/components/SimpleModal";
import { formatDate } from "@remar/shared/dist/utils/myAccountUtils";
import { addMonths } from "date-fns";
import { useSelector } from "react-redux";
import { selectUpdateSubcriptionIsLoading } from "store/features/MyAccount/myAccountSlice";

const useStyles = makeStyles({
	title: {
		fontWeight: "bold",
		fontSize: 22,
		marginBottom: 24,
		marginLeft: 45
	},
	root: {},
	card: {
		width: 450
	},
	cancelBtn: {
		backgroundColor: "transparent",
		color: "rgba(255, 255, 255, 0.6)",
		border: "1px solid #6b6e75",
		marginRight: "10px",
		fontWeight: "bold"
	}
});

const ChangeSubscription = ({ onClose, subscriptionStartDate, changeSubscriptionDate }) => {
	const theme = useTheme<Theme>();
	const classes = useStyles();
	const isLoading = useSelector(selectUpdateSubcriptionIsLoading);

	const [date, setDate] = useState(subscriptionStartDate);
	const [modal, setModal] = useState(false);

	const onSubmit = () => {
		changeSubscriptionDate(date);
	};

	return (
		<Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" height="100vh">
			<Card className={classes.card}>
				<Box display="flex" justifyContent="flex-end" mb={3}>
					<IconButton onClick={onClose}>
						<CloseIcon color="disabled" />
					</IconButton>
				</Box>
				<Box className={classes.root}>
					<Typography className={classes.title} variant="h4">
						Change The Start Date
					</Typography>
					<DatePicker
						autoOk
						open={false}
						disablePast
						disableToolbar
						orientation="portrait"
						variant="static"
						openTo="date"
						maxDate={addMonths(new Date(subscriptionStartDate), 3)}
						value={date}
						onChange={setDate}
					/>
				</Box>
				<Box display="flex" justifyContent="center" alignItems="center" mt={3}>
					<Button className={classes.cancelBtn} variant="contained" onClick={() => setModal(true)}>
						{`Start on ${formatDate(date)}`}
					</Button>
					<Button
						color="primary"
						variant="contained"
						onClick={() => {
							setDate(new Date());
							setModal(true);
						}}
					>
						Start Today
					</Button>
				</Box>
			</Card>
			<SimpleModal
				theme={theme}
				title=""
				icon={<CalendarTodaySharp style={{ width: "50px", height: "50px", fill: "#586179" }} />}
				open={modal}
				onClose={() => setModal(false)}
				text={`Are you sure you want to start the course on ${formatDate(date)}? 
				If you choose to do so, the course will begin immediately.`}
				footer={
					<>
						<Button
							className={classes.cancelBtn}
							variant="contained"
							disabled={isLoading}
							onClick={() => setModal(false)}
						>
							<>Cancel</>
						</Button>
						<Button color="primary" variant="contained" onClick={onSubmit}>
							{isLoading ? (
								<Box width={50}>
									<CircularProgress size="1rem" color="inherit" thickness={2} variant="indeterminate" />
								</Box>
							) : (
								<>{`Start on ${formatDate(date)}`}</>
							)}
						</Button>
					</>
				}
			/>
		</Box>
	);
};

export default ChangeSubscription;

import React from "react";

import { ReactComponent as Discover } from "assets/images/cards/discover.svg";
import { ReactComponent as Express } from "assets/images/cards/express.svg";
import { ReactComponent as Master } from "assets/images/cards/master.svg";
import { ReactComponent as Visa } from "assets/images/cards/visa.svg";

export const getValidValue = values => {
	const activeStep1Values = ["address1", "address2", "countryId", "city", "phoneNumber", "state", "zip"];
	return values.some(val => activeStep1Values.includes(val));
};

export const getCardIcon = type => {
	switch (type) {
		case "visa":
			return <Visa />;
		case "discover":
			return <Discover />;
		case "mastercard":
			return <Master />;
		case "amex":
			return <Express />;
		default:
			return <Visa />;
	}
};

import React, { useCallback, useEffect, useState } from "react";

import { ReactComponent as DownloadIconSvg } from "@remar/shared/dist/assets/icons/icon-download.svg";
import { IColumn } from "@remar/shared/dist/components/MaterialTable";
import { EmptyState } from "@remar/shared/dist/layouts";
import WithTableContentLayout from "@remar/shared/dist/layouts/TableContentLayout";
import { Asset } from "@remar/shared/dist/models";
import { downloadExternalFile } from "@remar/shared/dist/utils/serviceUtils/helpers";
import { useAppDispatch, useAppSelector } from "store";
import { getAssets, getFullState } from "store/features/Assets/AssetsSlice";

import { routes } from "core/constants";

import { ColumnHeader, DownloadIcon, StyledCellText, StyledCellWrapper } from "./styles";

const tableColumns: Array<IColumn<Asset>> = [
	{
		alignment: "left",
		label: <ColumnHeader>Number</ColumnHeader>,
		Cell: ({ rowIndex }) => (
			<StyledCellWrapper>
				<StyledCellText>{rowIndex + 1}</StyledCellText>
			</StyledCellWrapper>
		),
		dataKey: "id"
	},
	{
		alignment: "left",
		label: <ColumnHeader>Name</ColumnHeader>,
		Cell: ({ rowData: { name } }) => (
			<StyledCellWrapper>
				<StyledCellText>{name}</StyledCellText>
			</StyledCellWrapper>
		),
		dataKey: "name"
	},
	{
		alignment: "left",
		label: <ColumnHeader>Assets Type</ColumnHeader>,
		Cell: ({
			rowData: {
				type: { name }
			}
		}) => (
			<StyledCellWrapper>
				<StyledCellText>{name}</StyledCellText>
			</StyledCellWrapper>
		),
		dataKey: "assetsType"
	},
	{
		alignment: "left",
		label: <ColumnHeader>Download</ColumnHeader>,
		Cell: ({ rowData: { userAssetUrl } }) => (
			<StyledCellWrapper>
				<DownloadIcon>
					<DownloadIconSvg
						onClick={() => {
							downloadExternalFile(userAssetUrl);
						}}
					/>
				</DownloadIcon>
			</StyledCellWrapper>
		),
		dataKey: "userAssetUrl"
	}
];

const Assets = () => {
	const dispatch = useAppDispatch();
	const {
		assets: { isLoading, assets, totalItems, totalCount, page, perPage }
	} = useAppSelector(getFullState);

	const [searchText, setSearchText] = useState("");
	const handleSearchBarChange = useCallback(searchText => dispatch(getAssets({ searchText })), [dispatch]);

	useEffect(() => {
		dispatch(getAssets({}));
	}, [dispatch]);
	const breadcrumbs = [
		{ title: "Dashboard", key: 0, link: "/" },
		{ title: "My Assets", key: 1, link: routes.assets.getPath() }
	];
	return (
		<WithTableContentLayout
			heading="Books and Certificates"
			isLoading={isLoading}
			breadcrumb={breadcrumbs}
			emptyState={<EmptyState description="No assets found" />}
			onChangePage={page => dispatch(getAssets({ page }))}
			totalItems={totalItems}
			totalEntities={totalCount}
			tableTitle="My Assets"
			perPage={perPage}
			page={page}
			searchText={searchText}
			setSearchText={setSearchText}
			handleSearchBarChange={handleSearchBarChange}
			tableColumns={tableColumns}
			data={assets as Asset[]}
		/>
	);
};

export default Assets;

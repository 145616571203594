import React, { useState } from "react";

import { Box, Button, IconButton, Modal, Typography } from "@material-ui/core";
import { Close } from "@material-ui/icons";
import { useUpgradeForm } from "hooks/useUpgradeForm";
import { useDispatch, useSelector } from "react-redux";

import { useHistory } from "react-router-dom";

import { getUserData, selectInactiveSubscription, selectIsTrial, setShowBanner } from "store/features/Auth/authSlice";

import { routes } from "core/constants";

import { BannerContainer, useStyles } from "./styles";

import UpgradeSubscription from "../../MyAccount/UpgradeSubscription";

type BannerProps = { isMobile: boolean; bannerMessage: string };
const Banner = ({ isMobile, bannerMessage }: BannerProps) => {
	const classes = useStyles();
	const dispatch = useDispatch();
	const history = useHistory();
	const trial = useSelector(selectIsTrial);
	const { isRenewEnabled } = useSelector(selectInactiveSubscription);

	const [modal, setModal] = useState(false);
	const onSubmit = () => {
		setModal(false);
		dispatch(getUserData());
		history.push(`${routes.course.getPath()}?refetch=true`);
	};

	const { closeModal, ...upgradeFormProps } = useUpgradeForm({ setModal, onSubmit, dispatch });
	return (
		<Box className={classes.trialNotice}>
			<BannerContainer>
				<Typography className={classes.bannerMessage}>
					{trial && !isRenewEnabled ? (
						<>
							{!isMobile && "Click"}
							<Button variant="text" onClick={() => setModal(true)} className={classes.manageSubscriptionBtn}>
								{isMobile ? "Trial mode. Upgrade Subscription" : "Here"}
							</Button>
							{!isMobile && "to manage your subscription"}
						</>
					) : (
						bannerMessage
					)}
				</Typography>
			</BannerContainer>
			<Modal open={modal} onClose={closeModal} className={classes.upgradeModal}>
				<UpgradeSubscription {...upgradeFormProps} closeModal={closeModal} />
			</Modal>
			{!trial && (
				<IconButton size="small" className={classes.closeNotice} onClick={() => dispatch(setShowBanner(false))}>
					<Close />
				</IconButton>
			)}
		</Box>
	);
};

export default Banner;

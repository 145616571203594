import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { clearJwt, getJwt, setJwt } from "@remar/shared/dist/api/jwt";
import {
	BookExternalIntegrationDataItem,
	Country,
	Course,
	LessonVideo,
	ShippingPlan,
	User,
	UserSubscription,
	UserSubscriptionType,
	UserSubscriptionTypeExternalIntegrationDataItem
} from "@remar/shared/dist/models";
import { ThemeNames, convertThemeName } from "@remar/shared/dist/utils/convertThemeName";
import { setStateValue as utilsSetStateValue } from "@remar/shared/dist/utils/stateUtils";
import { getCurrentSubscription } from "@remar/shared/dist/utils/subscriptionUtils";
import * as Sentry from "@sentry/react";

import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js";
import { PaymentMethod } from "@stripe/stripe-js";
import { differenceInDays, formatDistanceToNowStrict, isSameDay } from "date-fns";
import { AppThunk, RootState } from "store";

import {
	UserInvitationDto,
	UserInvitationResponseDto,
	UserLoginDto,
	UserLoginResponseDto,
	UserSocialSignUpDto,
	countriesService,
	coursesService,
	genericService,
	lessonVideosService,
	userSubscriptionTypesService,
	usersService
} from "store/services";

import { InactiveSubscription } from "./auth.model";
import { ERROR_MESSAGES, UserSubscriptionTypeCategories } from "./constants";

import { setImageLogo, switchColorShade } from "../Theme/theme.slice";
import { emit } from "../notifications/notifications.slice";

interface AuthState {
	token: string | null;
	user: User | null;
	userLoading: boolean;
	subscription: {
		showBanner: boolean;
		isTrial: boolean;
		expiresIn?: string | null;
		bannerMessage: string;
	};
	countries: Country[];
	courses: Course[];
	inactiveSubscription: InactiveSubscription;
	subscriptionTypes: UserSubscriptionType[];
	loadingSubscriptionTypes: boolean;
	userSubscriptionTypeId: number;
	userSubscriptionTypeAddonIds?: number[];
	isLoading: boolean;
	externalIntegrations?: UserSubscriptionTypeExternalIntegrationDataItem[];
	subExternalIntegrations?: BookExternalIntegrationDataItem[];
	isLoggedIn: boolean;
	canAppendRedirectQueryParam: boolean;
	errorMessage: string;
	resendEmail: string;
	isPasswordResetLinkSent: boolean;
	passwordResetEmail: string;
	passwordReset: boolean;
	selectedCountryId?: number;
	selectedShippingPlan?: ShippingPlan;
	shippingApplicable: boolean;
	introVideo?: LessonVideo;
	guestSignUpData?: {
		user?: Record<string, unknown>;
		paymentProviderAccount?: Record<string, unknown>;
	};
	redirectToIntroVIT?: boolean;
	introVideoNotFound?: boolean;
	invitationDetailsLoading: boolean;
	invitationDetails?: UserInvitationResponseDto | null;
	canAccessCourse: boolean;
	canAccessQuiz: boolean;
	userAccountStatus: string;
}

export interface SocialSignUpUser {
	accessToken: string;
	email: string;
	first_name: string;
	last_name: string;
	name: string;
}

const initialToken = getJwt();

const initialState: AuthState = {
	token: initialToken || null,
	user: null,
	userLoading: true,
	subscription: {
		showBanner: false,
		bannerMessage: "",
		isTrial: false,
		expiresIn: "15 days"
	},
	inactiveSubscription: {
		isRenewEnabled: false
	},
	subscriptionTypes: [],
	loadingSubscriptionTypes: false,
	externalIntegrations: [],
	// RN With materials TODO: Will be dynamic afterwards
	userSubscriptionTypeId: 0,
	isLoading: false,
	canAppendRedirectQueryParam: true,
	isLoggedIn: !!initialToken,
	errorMessage: "",
	resendEmail: "",
	isPasswordResetLinkSent: false,
	passwordResetEmail: "",
	passwordReset: false,
	shippingApplicable: false,
	userSubscriptionTypeAddonIds: [],
	countries: [],
	guestSignUpData: {},
	courses: [],
	invitationDetailsLoading: true,
	invitationDetails: null,
	canAccessCourse: false,
	canAccessQuiz: false,
	userAccountStatus: ""
};

interface SignUpThunk {
	CardElement: typeof CardElement;
	guest?: boolean;
	accountClaimCode?: string;
	skipEmailVerification?: boolean;
	elements: ReturnType<typeof useElements>;
	stripe: ReturnType<typeof useStripe>;
	values;
}
interface SignUpInvitations {
	CardElement: typeof CardElement;
	elements: ReturnType<typeof useElements>;
	stripe: ReturnType<typeof useStripe>;
	values;
}

interface SocialTrialSignUpThunk {
	values: UserSocialSignUpDto;
}

export const handleStripePayment = async (
	stripe: ReturnType<typeof useStripe>,
	cardElement: ReturnType<typeof useElements>
): PaymentMethod | undefined => {
	if (stripe && cardElement) {
		const { error, paymentMethod } = await stripe.createPaymentMethod({
			type: "card",
			card: cardElement
		});

		if (error) {
			throw new Error("Please check your card details");
		}
		return paymentMethod;
	}
	throw new Error("Please check your card details");
};
export const signUp = createAsyncThunk(
	"auth/signUp",
	async (
		{
			skipEmailVerification,
			guest,
			accountClaimCode,
			CardElement,
			elements,
			stripe,
			values: {
				address1,
				address2,
				city,
				countryId,
				email,
				firstName,
				fullName,
				lastName,
				password,
				phoneNumber,
				startDate,
				state,
				zip,
				zipCode,
				papCookie
			}
		}: SignUpThunk,
		{ getState, dispatch, rejectWithValue }
	) => {
		dispatch(setError(""));
		const {
			auth: { subExternalIntegrations, userSubscriptionTypeAddonIds, userSubscriptionTypeId }
		} = getState() as RootState;
		const cardElement = elements?.getElement(CardElement);
		let paymentMethod;
		if (stripe && cardElement) {
			paymentMethod = await handleStripePayment(stripe, cardElement);
		}
		// Sign up with payment
		if (!guest && !accountClaimCode && stripe && cardElement) {
			const signUpBody = {
				paymentProviderId: 1,
				userTypeId: 2,
				userSubscriptionTypeId,
				userSubscriptionTypeAddonIds,
				firstName,
				lastName,
				email,
				startDate: startDate.toISOString(),
				password,
				paymentProviderPaymentMethodIdentifier: paymentMethod!.id,
				address: {
					phoneNumber,
					address1,
					address2,
					countryId,
					city,
					state,
					zipCode: zip
				},
				customerDescription: papCookie
			};
			if (paymentMethod) {
				try {
					const user = await usersService.signUp(signUpBody);
					const paymentNeedsConfirmation = !user!.subscriptionHasStarted && user?.subscriptionPaymentIntentClientSecret;
					const nonDelayed: boolean = isSameDay(startDate, new Date());
					if (paymentNeedsConfirmation && nonDelayed) {
						await stripe.confirmCardPayment(user!.subscriptionPaymentIntentClientSecret!);
						return;
					}

					return user;
				} catch (e) {
					return rejectWithValue(e.message);
				}
			}
		} else if (guest && stripe && cardElement) {
			// eslint-disable-next-line no-unused-vars
			if (paymentMethod) {
				const payload = {
					paymentProviderId: 1,
					paymentProviderPaymentMethodIdentifier: paymentMethod.id,
					firstName,
					lastName,
					userTypeId: 3,
					email: email,
					books: subExternalIntegrations?.map(({ id, quantity }) => ({ id, quantity })),
					address: {
						fullName,
						phoneNumber,
						address1,
						address2,
						countryId,
						city,
						state,
						zipCode
					}
				};
				return usersService.guestCheckout(payload).catch(e => rejectWithValue(e.message));
			}
		} else if (accountClaimCode) {
			let paymentProviderPaymentMethodIdentifier = "";
			if (stripe && cardElement) {
				paymentProviderPaymentMethodIdentifier = paymentMethod!.id;
			}
			const payload = {
				accountClaimCode,
				triggerEmailVerfication: !skipEmailVerification, // todo <- fix typo "triggerEmailVerfication" -> "triggerEmailVerification
				userSubscriptionTypeId,
				userTypeId: 2,
				password,
				...(paymentProviderPaymentMethodIdentifier && {
					paymentProviderId: 1,
					paymentProviderPaymentMethodIdentifier
				})
			};
			return usersService.guestSignUp(payload).catch(e => rejectWithValue(e.message));
		} else {
			// Sign Up for trial
			const signUpBody = {
				firstName,
				lastName,
				email,
				password,
				paymentProviderId: 1,
				userSubscriptionTypeId,
				userTypeId: 2
			};

			return usersService.signUp(signUpBody).catch(e => rejectWithValue(e.message));
		}
	}
);
export const getInvitationDetails = createAsyncThunk(
	"auth/getInvitationDetails",
	async (options: UserInvitationDto, { rejectWithValue, dispatch }) => {
		try {
			dispatch(setStateValue({ key: "invitationDetailsLoading", value: true }));
			const invitation = await usersService.getInvitationDetails(options).catch(e => {
				dispatch(setError(e.message));
			});
			dispatch(setStateValue({ key: "invitationDetails", value: invitation }));

			if (invitation.theme) {
				dispatch(switchColorShade(convertThemeName(<ThemeNames>invitation.theme.name)));
				dispatch(setImageLogo(invitation.logoImageUrl));
			}

			return invitation;
		} catch {
			dispatch(emit({ message: "Failed to fetch invitation details", color: "error" }));
			return rejectWithValue("Failed to fetch invitation details");
		} finally {
			dispatch(setStateValue({ key: "invitationDetailsLoading", value: false }));
		}
	}
);
export const studentSignUpInvitation = createAsyncThunk(
	"auth/studentSignUpInvitation",
	async ({ CardElement, elements, stripe, values }: SignUpInvitations, { dispatch }) => {
		try {
			dispatch(setIsLoading(true));
			const cardElement = elements?.getElement(CardElement);
			const paymentMethod = await handleStripePayment(stripe, cardElement);
			if (paymentMethod) {
				const {
					firstName,
					lastName,
					password,
					phoneNumber,
					state,
					city,
					countryId,
					zip,
					address1,
					address2,
					invitationId
				} = values;
				const body = {
					inviteCode: invitationId,
					paymentProviderId: 1,
					paymentProviderPaymentMethodIdentifier: paymentMethod!.id,
					firstName: firstName,
					lastName: lastName,
					password: password,
					address: {
						fullName: `${firstName} ${lastName}`,
						phoneNumber: phoneNumber,
						address1: address1,
						address2: address2,
						countryId: countryId,
						city: city,
						state: state,
						zipCode: zip
					}
				};
				try {
					return await usersService.studentSignUpInvitation(body);
				} catch {
					throw new Error("Failed to complete signUp");
				}
			}
		} finally {
			dispatch(setIsLoading(false));
		}
	}
);

export const validateDuplicateEmail = createAsyncThunk(
	"auth/validateduplicateemail",
	(email: string, { rejectWithValue }) => {
		return usersService
			.validateDuplicateEmail(email)
			.then(({ isValid, message }) => {
				if (!isValid) {
					throw new Error(message);
				}
			})
			.catch(e => rejectWithValue(e.message));
	}
);

export const getIntroLessonVideo = createAsyncThunk(
	"auth/getIntroLessonVideo",
	async (_, { dispatch, rejectWithValue }) => {
		dispatch(setIsLoading(true));
		try {
			const { items } = await lessonVideosService.find({
				filters: { "interactiveBlocks.lesson.isIntro": true },
				perPage: 1
			});
			return items[0];
		} catch (e) {
			return rejectWithValue(e.message);
		}
	}
);

export const resendVerificationEmail = createAsyncThunk(
	"auth/resendverificationemail",
	(email: string, { dispatch, rejectWithValue }) => {
		dispatch(setIsLoading(true));
		return usersService
			.resendEmailVerification(email)
			.then(({ isValid, message }) => {
				if (!isValid) {
					throw new Error(message);
				}
			})
			.catch(e => rejectWithValue(e.message));
	}
);

export const signIn = createAsyncThunk("auth/signIn", async (user: UserLoginDto, { rejectWithValue, dispatch }) => {
	const res = await usersService.login(user).catch(error => {
		let email = "";
		let errorMessage = error.message;

		if (error.message === ERROR_MESSAGES.EMAIL_VERIFY_BUTTON_DISPLAY) {
			email = user.email;
			errorMessage = "We sent you an email to verify, Do you want a ";
		}
		dispatch(setResendEmail(email));
		return rejectWithValue(errorMessage);
	});
	Sentry.setUser({
		username: user.email
	});
	if ((res as UserLoginResponseDto)?.user) {
		const { allowedLocations } = (res as UserLoginResponseDto).user;
		if (allowedLocations && allowedLocations.length > 0 && allowedLocations[0].theme) {
			dispatch(switchColorShade(convertThemeName(<ThemeNames>allowedLocations[0]?.theme.name)));
			allowedLocations[0]?.logoImage && dispatch(setImageLogo(allowedLocations[0]?.logoImageUrl));
		}
	}

	return res;
});

export const signInByToken = createAsyncThunk(
	"auth/signInByToken",
	async (user: { sessionId: string; sideEffect?: () => void }, { rejectWithValue, dispatch }) => {
		const { sideEffect, ...restUserData } = user;
		setIsLoading(true);
		const res = await usersService.loginByToken(restUserData).catch(error => {
			const errorMessage = error.message;
			return rejectWithValue(errorMessage);
		});

		if ((res as UserLoginResponseDto)?.user) {
			const { allowedLocations } = (res as UserLoginResponseDto).user;
			if (allowedLocations && allowedLocations.length > 0 && allowedLocations[0].theme) {
				dispatch(switchColorShade(convertThemeName(<ThemeNames>allowedLocations[0]?.theme.name)));
				allowedLocations[0]?.logoImage && dispatch(setImageLogo(allowedLocations[0]?.logoImageUrl));
			}
		}
		sideEffect && sideEffect();
		return res;
	}
);

// duplicate it and then change
export const receiveSubscriptionTypes = createAsyncThunk(
	"auth/getSubscriptionTypes",
	async (
		{
			courseId,
			isActive,
			isTrial,
			userSubscriptionTypeCategoryId
		}: { courseId?: number; isActive?: boolean; isTrial?: boolean; userSubscriptionTypeCategoryId?: number },
		{ rejectWithValue, dispatch }
	) => {
		const filters = {};
		if (userSubscriptionTypeCategoryId) {
			filters["userSubscriptionTypeCategoryId"] = userSubscriptionTypeCategoryId;
		}
		if (courseId) {
			filters["allowedCourses.id"] = courseId;
		}
		if (isActive) {
			filters["isActive"] = isActive;
		}
		if (isTrial) {
			filters["isTrial"] = isTrial;
		}
		return userSubscriptionTypesService
			.find({ filters, findAll: true, include: ["subTypeEIDItems.counterparts", "allowedCourses"] })
			.then(r => dispatch(setSubscriptionTypes(r.items!)))
			.catch(error => {
				return rejectWithValue(error.message);
			});
	}
);

export const setSubscriptionTypesForSignup = createAsyncThunk(
	"auth/setSubscriptionTypesForSignup",
	async ({ courseId, isTrial }: { courseId?: number; isTrial?: boolean }, { rejectWithValue, dispatch }) => {
		const filters = {
			isRecurring: false,
			isActive: true,
			userSubscriptionTypeCategoryId: UserSubscriptionTypeCategories.Course
		};
		if (courseId) {
			filters["allowedCourses.id"] = courseId;
		}
		if (typeof isTrial !== "undefined") {
			filters["isTrial"] = isTrial;
		}
		userSubscriptionTypesService
			.find({ filters, findAll: true, include: ["subTypeEIDItems.counterparts", "allowedCourses"] })
			.then(({ items }) => dispatch(setSubscriptionTypes(items!)))
			.catch(error => rejectWithValue(error.message));
	}
);

export const fetchCountries = createAsyncThunk(
	"auth/fetchCountries",
	async (subscriptionTypeId: number, { rejectWithValue }) => {
		return countriesService
			.find({
				...(subscriptionTypeId && { filters: { "shippingPlans.subscriptionTypes.id": subscriptionTypeId } }),
				orderBy: { name: "ASC" },
				findAll: true
			})
			.catch(error => rejectWithValue(error.message));
	}
);

export const getCourses = createAsyncThunk("auth/getCourses", async (_, { rejectWithValue }) => {
	return coursesService
		.getCourses({
			filters: { "allowedForUST.isActive": true },
			findAll: true
		})
		.catch(error => rejectWithValue(error.message));
});

export const fetchExternalIntegrationDataItems = createAsyncThunk(
	"auth/externalIntegrationDataItems",
	async (bookId: number, { rejectWithValue }): Promise<unknown> =>
		genericService.getBooks().catch(error => rejectWithValue(error.message))
);

export const forgotPassword = createAsyncThunk(
	"auth/forgotPassword",
	async (email: string, { dispatch, rejectWithValue }) =>
		await usersService
			.forgotPassword(email)
			.then(() => {
				dispatch(setPasswordResetEmail(email));
			})
			.catch(e => rejectWithValue(e.message))
);

export const guestSignUpVerification = createAsyncThunk(
	"auth/guestSignUpVerification",
	async (
		{ accountClaimCode, sideEffect = () => {} }: { accountClaimCode: string; sideEffect: () => void },
		{ rejectWithValue }
	) => {
		return await usersService.guestSignUpVerification(accountClaimCode).catch(e => {
			sideEffect();
			return rejectWithValue(e.message);
		});
	}
);

export const forgotVerification = createAsyncThunk(
	"auth/forgotVerification",
	async (
		{ code, sideEffect = () => {} }: { code: string; sideEffect: () => void },
		{ rejectWithValue }
	): Promise<unknown> =>
		await usersService.forgotVerification(code).catch(e => {
			sideEffect();
			return rejectWithValue(e.message);
		})
);

export const resetPassword = createAsyncThunk(
	"auth/resetPassword",
	async (
		{ code, password, successSideEffect }: { code: string; password: string; successSideEffect },
		{ rejectWithValue }
	) =>
		await usersService
			.resetPassword({ code, password })
			.then(() => {
				if (successSideEffect) {
					successSideEffect(code);
				}
			})
			.catch(e => rejectWithValue(e.message))
);

export const closeTutorialModal = createAsyncThunk(
	"auth/closeTutorialModal",
	async (redirectToIntroVIT: boolean, { rejectWithValue }) => {
		try {
			await usersService.update({ data: { hasTakenIntro: true }, filters: {} });
			return redirectToIntroVIT;
		} catch (e) {
			return rejectWithValue(e.message);
		}
	}
);

export const facebookLogin = createAsyncThunk(
	"auth/facebookLogin",
	async (
		{
			facebookData,
			sideEffect = () => {},
			successSideEffect
		}: { facebookData: SocialSignUpUser; sideEffect: () => void; successSideEffect },
		{ rejectWithValue }
	) => {
		try {
			const tokenId = facebookData.accessToken;
			const res = await usersService.facebookLogin(tokenId);
			if (successSideEffect) {
				successSideEffect(facebookData.email, "facebook");
			}
			Sentry.setUser({
				username: facebookData.email
			});
			return res;
		} catch (e) {
			if (e.message === "User does not exists") {
				sessionStorage.setItem("firstName", facebookData.first_name);
				sessionStorage.setItem("lastName", facebookData.last_name);
				sessionStorage.setItem("email", facebookData.email);
				sessionStorage.setItem("tokenId", facebookData.accessToken);
				sessionStorage.setItem("platform", "facebook");
				sessionStorage.setItem("analyticsId", `${facebookData.email}-facebook`);
				sideEffect();
			}
			return rejectWithValue(e.message);
		}
	}
);

export const googleLogin = createAsyncThunk(
	"auth/googleLogin",
	async (
		{
			googleUser,
			sideEffect = () => {},
			successSideEffect
		}: {
			googleUser: SocialSignUpUser;
			sideEffect: () => void;
			successSideEffect;
		},
		{ rejectWithValue }
	) => {
		try {
			const tokenId = googleUser.accessToken;
			const res = await usersService.googleLogin(tokenId);
			if (successSideEffect) {
				successSideEffect(googleUser.email, "google");
			}
			Sentry.setUser({
				username: googleUser.email
			});
			return res;
		} catch (e) {
			if (e.message === "User does not exists") {
				sessionStorage.setItem("firstName", googleUser.first_name);
				sessionStorage.setItem("lastName", googleUser.last_name);
				sessionStorage.setItem("email", googleUser.email);
				sessionStorage.setItem("tokenId", googleUser.accessToken);
				sessionStorage.setItem("platform", "google");
				sessionStorage.setItem("analyticsId", `${googleUser.email}-google`);
				sideEffect();
			}
			return rejectWithValue(e.message);
		}
	}
);
const mapSignUpBody = (userSubscriptionTypeId, userSubscriptionTypeAddonIds, paymentMethod, values) => ({
	paymentProviderId: 1,
	userTypeId: 2,
	userSubscriptionTypeId,
	userSubscriptionTypeAddonIds,
	firstName: values.firstName || sessionStorage.getItem("firstName"),
	lastName: values.lastName || sessionStorage.getItem("lastName"),
	email: values.email || sessionStorage.getItem("email"),
	startDate: values.startDate.toISOString(),
	paymentProviderPaymentMethodIdentifier: paymentMethod!.id,
	tokenId: values.tokenId || sessionStorage.getItem("tokenId"),
	address: {
		phoneNumber: values.phoneNumber,
		address1: values.address1,
		address2: values.address2,
		countryId: values.countryId,
		city: values.city,
		state: values.state,
		zipCode: values.zip
	}
});
export const facebookSignUp = createAsyncThunk(
	"auth/facebookSignUp",
	async ({ CardElement, elements, stripe, values }: SignUpThunk, { getState, dispatch }): Promise<User | void> => {
		dispatch(setError(""));
		const {
			auth: { userSubscriptionTypeAddonIds, userSubscriptionTypeId }
		} = getState() as RootState;

		const cardElement = elements?.getElement(CardElement);
		const paymentMethod = await handleStripePayment(stripe, cardElement);

		const signUpBody = mapSignUpBody(userSubscriptionTypeId, userSubscriptionTypeAddonIds, paymentMethod, values);

		if (paymentMethod) {
			const user = await usersService.facebookSignUp(signUpBody);
			const paymentNeedsConfirmation = !user!.subscriptionHasStarted && user?.subscriptionPaymentIntentClientSecret;

			const nonDelayed = isSameDay(values.startDate, new Date());
			if (paymentNeedsConfirmation && nonDelayed) {
				await stripe.confirmCardPayment(user!.subscriptionPaymentIntentClientSecret!);
				return;
			}

			return user;
		}
	}
);

export const googleSignUp = createAsyncThunk(
	"auth/googleSignUp",
	async ({ CardElement, elements, stripe, values }: SignUpThunk, { getState, dispatch }): Promise<User | void> => {
		dispatch(setError(""));
		const {
			auth: { userSubscriptionTypeAddonIds, userSubscriptionTypeId }
		} = getState() as RootState;
		const cardElement = elements?.getElement(CardElement);
		const paymentMethod = await handleStripePayment(stripe, cardElement);

		const signUpBody = mapSignUpBody(userSubscriptionTypeId, userSubscriptionTypeAddonIds, paymentMethod, values);
		if (paymentMethod) {
			const user = await usersService.googleSignUp(signUpBody);
			const paymentNeedsConfirmation = !user!.subscriptionHasStarted && user?.subscriptionPaymentIntentClientSecret;

			const nonDelayed = isSameDay(values.startDate, new Date());
			if (paymentNeedsConfirmation && nonDelayed) {
				await stripe.confirmCardPayment(user!.subscriptionPaymentIntentClientSecret!);
				return;
			}
			return user;
		}
	}
);

export const socialTrialSignUp = createAsyncThunk(
	"auth/socialTrialSignUp",
	async ({ values: { firstName, lastName } }: SocialTrialSignUpThunk, { getState, dispatch }): Promise<User | void> => {
		dispatch(setError(""));
		const {
			auth: { userSubscriptionTypeId }
		} = getState() as RootState;
		const signUpBody = {
			userTypeId: 2,
			userSubscriptionTypeId,
			paymentProviderId: 1,
			firstName,
			lastName,
			email: sessionStorage.getItem("email") as string,
			tokenId: sessionStorage.getItem("tokenId") as string
		};
		return sessionStorage.getItem("platform") === "facebook"
			? await usersService.facebookSignUp(signUpBody)
			: await usersService.googleSignUp(signUpBody);
	}
);
export const getUserData = createAsyncThunk("auth/getUserData", async (_, { dispatch, rejectWithValue }) => {
	return usersService
		.whoami()
		.then(({ user }) => {
			dispatch(setUser({ user }));

			if (user.allowedLocations?.length) {
				dispatch(switchColorShade(convertThemeName(<ThemeNames>user.allowedLocations[0]?.theme?.name)));
				user?.allowedLocations[0]?.logoImage && dispatch(setImageLogo(user?.allowedLocations[0]?.logoImageUrl));
			}

			Sentry.setUser({
				username: user.email
			});
			const activatedSubs = getCurrentSubscription(user.subscriptions);
			let expiresIn: string | null = null;
			let showBanner = false;
			let isTrial = false;
			let bannerMessage = "";
			if (activatedSubs.type?.isTrial) {
				showBanner = true;
				bannerMessage = "You are in trial mode.";
				isTrial = true;
				dispatch(setTrialData({ isTrial, showBanner, bannerMessage }));
				const expiredSubscriptions = user.expiredSubscriptions;
				const expiredSubscription = expiredSubscriptions!.find(i => !i.type?.isTrial && !i.type?.isRecurring);
				const isRenewEnabled = expiredSubscriptions?.some(item => !item.type?.isTrial);
				dispatch(
					setInactiveSubscription({
						isRenewEnabled,
						subscriptionId: isRenewEnabled ? expiredSubscription?.id : 0,
						typeId: isRenewEnabled ? expiredSubscription?.typeId : 0
					})
				);
			} else {
				const activeSub = activatedSubs;
				if (activeSub.isCancelled) {
					expiresIn = formatDistanceToNowStrict(new Date(activeSub.expiresOn));
					bannerMessage = `Your account will turn back to trial in ${expiresIn}.`;
					const _expiresIn = differenceInDays(new Date(activeSub.expiresOn), new Date());
					const hideBannerForUser = Number(localStorage.getItem("showBanner")) === user.id;
					showBanner = hideBannerForUser ? Number(_expiresIn) < 11 : true;
				}
				dispatch(setInactiveSubscription({ isRenewEnabled: false }));
			}
			dispatch(setTrialData({ expiresIn, showBanner, isTrial, bannerMessage }));
			return user;
		})
		.catch(error => rejectWithValue(error.message));
});

export const confirmAcceptedTermsAndConditions = createAsyncThunk(
	"auth/confirmacceptedTermsAndConditions",
	async (isAgreed: boolean, { rejectWithValue }) => {
		return await usersService.confirmAcceptedTermsAndConditions(isAgreed).catch(rejectWithValue);
	}
);

const signInReducer = (
	state: AuthState,
	{ payload: { refreshToken, sessionToken, user } }: PayloadAction<UserLoginResponseDto>
) => {
	state.errorMessage = "";
	state.isLoading = false;
	state.isLoggedIn = true;
	state.user = user;
	state.canAccessCourse = user.canAccessCourse;
	state.canAccessQuiz = user.canAccessQuiz;
	state.token = sessionToken;
	state.canAppendRedirectQueryParam = true;
	setJwt(sessionToken, "", refreshToken as string);
	const activatedSubs = getCurrentSubscription(user.subscriptions);
	if (activatedSubs?.type?.isTrial) {
		state.subscription.showBanner = true;
		state.subscription.bannerMessage = "You are in trial mode.";
		state.subscription.isTrial = true;
		const expiredSubscriptions = state.user!.expiredSubscriptions;
		const expiredSubscription = expiredSubscriptions!.find(i => !i.type?.isTrial && !i.type?.isRecurring);
		const isRenewEnabled = expiredSubscriptions?.some(item => !item.type?.isTrial);
		state.inactiveSubscription = {
			isRenewEnabled,
			subscriptionId: isRenewEnabled ? expiredSubscription?.id : 0,
			typeId: isRenewEnabled ? expiredSubscription?.typeId : 0
		};
	} else {
		if (activatedSubs?.isCancelled) {
			const expiresIn = differenceInDays(new Date(activatedSubs.expiresOn), new Date());
			const hideBannerForUser = Number(localStorage.getItem("showBanner")) === state.user?.id;
			state.subscription.showBanner = hideBannerForUser ? Number(expiresIn) < 11 : true;
			state.subscription.expiresIn = formatDistanceToNowStrict(new Date(activatedSubs.expiresOn));
			state.subscription.bannerMessage = `Your account will turn back to trial in ${state.subscription.expiresIn}.`;
		} else {
			state.subscription.expiresIn = null;
			state.subscription.showBanner = false;
			state.subscription.isTrial = false;
		}
	}
};

export const authSlice = createSlice({
	name: "auth",
	initialState,
	reducers: {
		setShowBanner: (state, { payload }: PayloadAction<boolean>) => {
			state.subscription.showBanner = payload;
			const currentSub = getCurrentSubscription(state.user?.subscriptions);
			if (currentSub && currentSub.isCancelled) {
				localStorage.setItem("showBanner", state.user!.id.toString());
			}
		},
		setSelectedCountry: (state, { payload }: PayloadAction<number>) => {
			state.selectedCountryId = payload;
			const selectedCountry = state.countries?.find(({ id }) => id == payload);
			const freeShippingPlan = selectedCountry?.shippingPlans?.find(sp => sp.freeShipping);
			state.selectedShippingPlan = freeShippingPlan
				? freeShippingPlan
				: selectedCountry?.shippingPlans?.reduce((prev, curr) => (prev.data.price < curr.data.price ? prev : curr));
		},
		clearAuth: state => {
			state.canAppendRedirectQueryParam = false;
			state.isLoggedIn = false;
			state.token = null;
			state.user = null;
		},
		setUser: (state, { payload: { user } }: PayloadAction<{ user: User }>) => {
			state.user = { ...state.user, ...user };
		},
		setSchoolId: (state, { payload }: PayloadAction<number>) => {
			state.user = { ...state.user, schoolId: payload } as User;
		},
		setError: (state, { payload }: PayloadAction<string>) => {
			state.errorMessage = payload;
		},
		setTrialData: (
			state,
			{
				payload
			}: PayloadAction<{
				showBanner: boolean;
				isTrial: boolean;
				expiresIn?: string | null;
				bannerMessage: string;
			}>
		) => {
			state.subscription = payload;
		},
		changeSubscriptionType: (state, { payload }: PayloadAction<number>) => {
			state.userSubscriptionTypeId = payload;
		},
		addUserSubscriptionTypeAddonIds: (state, { payload }: PayloadAction<number>) => {
			if (!state.userSubscriptionTypeAddonIds?.includes(payload)) {
				state.userSubscriptionTypeAddonIds?.push(payload);
			}
		},
		setInitUserSubscriptionTypeAddonIds: (state, { payload }: PayloadAction<number[]>) => {
			state.userSubscriptionTypeAddonIds = [];
			for (let index = 0; index < payload.length; index++) {
				// todo: refactor
				state.userSubscriptionTypeAddonIds.push(payload[index]);
			}
		},
		removeUserSubscriptionTypeAddonIds: (state, { payload }: PayloadAction<number>) => {
			state.userSubscriptionTypeAddonIds = state.userSubscriptionTypeAddonIds?.filter(v => v !== payload);
		},
		setSubscriptionTypes: (state, { payload }: PayloadAction<UserSubscriptionType[]>) => {
			state.subscriptionTypes = payload;
		},
		setShippingApplicable: (state, { payload }: PayloadAction<boolean>) => {
			state.shippingApplicable = payload;
		},
		// eslint-disable-next-line no-unused-vars
		addSubscriptionIntegrations: (
			state,
			{ payload: { index, quantity } }: PayloadAction<{ index: number; id: number; quantity: number }>
		) => {
			state.subExternalIntegrations![index].quantity = quantity;
		},
		additionSubscriptionIntegrations: (state, { payload }: PayloadAction<number>) => {
			const index = state.subExternalIntegrations?.findIndex(({ id }) => id === payload) as number;

			if (index > -1) {
				state.subExternalIntegrations![index].quantity = (state.subExternalIntegrations![index].quantity as number) + 1;
			} else {
				const newSubIntegration = state.externalIntegrations?.find(
					({ id }) => id === payload
				) as UserSubscriptionTypeExternalIntegrationDataItem;
				const { id, data, mainImageUrl } = newSubIntegration;
				state.subExternalIntegrations?.push({ id, data, mainImageUrl, quantity: 1 });
			}
		},

		changeSubscribedIntegrations: (state, { payload }: PayloadAction<number>) => {
			const newSubIntegration = state.externalIntegrations?.find(({ id }) => id === payload);
			const { id, data, mainImageUrl } = newSubIntegration as UserSubscriptionTypeExternalIntegrationDataItem;
			state.subExternalIntegrations = [{ id, data, mainImageUrl, quantity: 1 }];
		},

		removeSubscriptionIntegrations: (state, { payload }: PayloadAction<number>) => {
			state.subExternalIntegrations = state.subExternalIntegrations?.filter(({ id }) => id != payload);
		},

		setIsLoading: (state, { payload }: PayloadAction<boolean>) => {
			state.isLoading = payload;
		},
		setUserLoading: (state, action: PayloadAction<boolean>) => {
			state.userLoading = action.payload;
		},
		setResendEmail: (state, { payload }: PayloadAction<string>) => {
			state.resendEmail = payload;
		},
		setInactiveSubscription: (state, { payload }: PayloadAction<InactiveSubscription>) => {
			state.inactiveSubscription = payload;
		},
		setPasswordResetEmail: (state, { payload }: PayloadAction<string>) => {
			state.passwordResetEmail = payload;
		},
		clearForgotPassword: state => {
			state.isPasswordResetLinkSent = false;
			state.passwordResetEmail = "";
			state.errorMessage = "";
		},
		clearPasswordReset: state => {
			state.passwordReset = false;
			state.errorMessage = "";
		},
		setUserSubscriptionTypeId: (state, { payload }: PayloadAction<number>) => {
			state.userSubscriptionTypeId = payload;
		},
		setStateValue: utilsSetStateValue
	},
	extraReducers: {
		// Sign Up flow
		[signUp.pending.type]: state => {
			state.isLoading = true;
		},
		[signUp.fulfilled.type]: state => {
			state.isLoading = false;
		},
		[signUp.rejected.type]: (state, { payload }) => {
			state.errorMessage = payload;
			state.isLoading = false;
		},
		// Social Trial Sign Up flow
		[socialTrialSignUp.pending.type]: state => {
			state.isLoading = true;
		},
		[socialTrialSignUp.fulfilled.type]: state => {
			state.isLoading = false;
		},
		[socialTrialSignUp.rejected.type]: (state, { payload }) => {
			state.errorMessage = payload;
			state.isLoading = false;
		},
		// Facebook Sign Up flow
		[facebookSignUp.pending.type]: state => {
			state.isLoading = true;
		},
		[facebookSignUp.fulfilled.type]: state => {
			state.isLoading = false;
		},
		[facebookSignUp.rejected.type]: (state, { payload }) => {
			state.errorMessage = payload;
			state.isLoading = false;
		},
		// Google Sign Up flow
		[googleSignUp.pending.type]: state => {
			state.isLoading = true;
		},
		[googleSignUp.fulfilled.type]: state => {
			state.isLoading = false;
		},
		[googleSignUp.rejected.type]: (state, { payload }) => {
			state.errorMessage = payload;
			state.isLoading = false;
		},
		// Sign In flow
		[signIn.pending.type]: state => {
			state.isLoading = true;
			//Reset fetched subscription types
			state.subscriptionTypes = [];
		},
		[signIn.rejected.type]: (state, { payload }) => {
			state.errorMessage = payload;
			state.isLoading = false;
		},
		[signIn.fulfilled.type]: signInReducer,
		// Sign In by token flow
		[signInByToken.pending.type]: state => {
			state.isLoading = true;
			//Reset fetched subscription types
			state.subscriptionTypes = [];
		},
		[signInByToken.rejected.type]: (state, { payload }) => {
			state.errorMessage = payload;
			state.isLoading = false;
		},
		[signInByToken.fulfilled.type]: signInReducer,
		// Facebook Sign In flow
		[facebookLogin.pending.type]: state => {
			state.isLoading = true;
			//Reset fetched subscription types
			state.subscriptionTypes = [];
		},
		[facebookLogin.rejected.type]: state => {
			state.isLoading = false;
		},
		[facebookLogin.fulfilled.type]: signInReducer,
		// Google Sign In flow
		[googleLogin.pending.type]: state => {
			state.isLoading = true;
			//Reset fetched subscription types
			state.subscriptionTypes = [];
		},
		[googleLogin.rejected.type]: state => {
			state.isLoading = false;
		},
		[googleLogin.fulfilled.type]: signInReducer,

		// Forgot Password Flow
		[forgotPassword.pending.type]: state => {
			state.isLoading = true;
		},
		[forgotPassword.fulfilled.type]: state => {
			state.isPasswordResetLinkSent = true;
			state.isLoading = false;
		},
		[forgotPassword.rejected.type]: (state, { payload }) => {
			state.errorMessage = payload;
			state.isLoading = false;
		},

		[guestSignUpVerification.pending.type]: state => {
			state.isLoading = true;
		},
		[guestSignUpVerification.fulfilled.type]: (state, { payload }) => {
			state.guestSignUpData = payload;
			state.isLoading = false;
		},
		[guestSignUpVerification.rejected.type]: (state, { payload }) => {
			state.errorMessage = payload;
			state.isLoading = false;
		},

		// Forgot Password Link Verification Flow
		[forgotVerification.pending.type]: state => {
			state.isLoading = true;
		},
		[forgotVerification.fulfilled.type]: (state, { payload }) => {
			state.passwordResetEmail = payload?.email;
			state.isLoading = false;
		},
		[forgotVerification.rejected.type]: (state, { payload }) => {
			state.errorMessage = payload;
			state.isLoading = false;
		},

		// Reset Password Flow
		[resetPassword.pending.type]: state => {
			state.isLoading = true;
		},
		[resetPassword.fulfilled.type]: state => {
			state.passwordReset = true;
			state.isLoading = false;
		},
		[resetPassword.rejected.type]: (state, { payload }) => {
			state.errorMessage = payload;
			state.isLoading = false;
		},
		[fetchCountries.pending.type]: state => {
			state.isLoading = true;
			// Reset shippingPlan state
			state.selectedCountryId = undefined;
			state.selectedShippingPlan = undefined;
		},
		[fetchCountries.fulfilled.type]: (state, { payload }) => {
			const countries = payload.items as Country[];
			const USA = countries.find(({ code }) => code === "US")!;
			countries.unshift(USA);
			state.countries = countries;
			state.isLoading = false;
		},
		[fetchCountries.rejected.type]: (state, { payload }) => {
			state.errorMessage = payload;
			state.isLoading = false;
		},
		[getCourses.pending.type]: state => {
			state.isLoading = true;
		},
		[getCourses.fulfilled.type]: (state, { payload }) => {
			const filteredCourses = payload.items.filter(({ allowedForUST }) => allowedForUST?.length);
			state.courses = filteredCourses as Course[];
			state.isLoading = false;
		},
		[getCourses.rejected.type]: (state, { payload }) => {
			state.errorMessage = payload;
			state.isLoading = false;
		},

		[fetchExternalIntegrationDataItems.pending.type]: state => {
			state.isLoading = true;
		},
		[fetchExternalIntegrationDataItems.fulfilled.type]: (state, { meta, payload }) => {
			const bookId = meta.arg;
			const book = payload.find(({ id }) => id === bookId);
			state.externalIntegrations = payload;
			if (book) {
				const { id, data, mainImageUrl } = book;
				state.subExternalIntegrations = [{ id, data, mainImageUrl, quantity: 1 }];
			} else {
				state.subExternalIntegrations = [];
			}
			state.isLoading = false;
		},
		[fetchExternalIntegrationDataItems.rejected.type]: (state, { payload }) => {
			state.errorMessage = payload;
			state.isLoading = false;
		},

		[getIntroLessonVideo.fulfilled.type]: (state, { payload }) => {
			state.errorMessage = "";
			state.introVideo = payload;
			if (!state.introVideo) {
				state.introVideoNotFound = true;
			}
			state.isLoading = false;
		},
		[getIntroLessonVideo.rejected.type]: (state, { payload }) => {
			state.errorMessage = payload;
			state.isLoading = false;
		},
		[closeTutorialModal.fulfilled.type]: (state, { payload }) => {
			state.errorMessage = "";
			state.redirectToIntroVIT = payload;
			state.user!.hasTakenIntro = true;
		},
		[closeTutorialModal.rejected.type]: (state, action) => {
			state.errorMessage = action.payload;
		},
		[receiveSubscriptionTypes.pending.type]: state => {
			state.loadingSubscriptionTypes = true;
		},
		[receiveSubscriptionTypes.fulfilled.type]: state => {
			state.loadingSubscriptionTypes = false;
		},
		[receiveSubscriptionTypes.rejected.type]: state => {
			state.loadingSubscriptionTypes = false;
		},
		[getUserData.pending.type]: state => {
			state.userLoading = true;
		},
		[getUserData.rejected.type]: state => {
			state.userLoading = false;
		},
		[getUserData.fulfilled.type]: (state, action) => {
			const { accountStatus, canAccessCourse, canAccessQuiz } = action.payload;
			state.userAccountStatus = accountStatus?.label ?? "";
			state.canAccessCourse = canAccessCourse;
			state.canAccessQuiz = canAccessQuiz;
			state.userLoading = false;
		},
		[confirmAcceptedTermsAndConditions.pending.type]: state => {
			state.isLoading = true;
		},
		[confirmAcceptedTermsAndConditions.fulfilled.type]: (state, { payload: { acceptedTermsAndConditions } }) => {
			state.isLoading = false;
			state.user!.acceptedTermsAndConditions = acceptedTermsAndConditions;
		},
		[confirmAcceptedTermsAndConditions.rejected.type]: (state, { payload: { message } }) => {
			state.errorMessage = message;
			state.isLoading = false;
		}
	}
});

export const {
	setShowBanner,
	setSelectedCountry,
	clearAuth,
	setUser,
	setSchoolId,
	setTrialData,
	changeSubscriptionType,
	addUserSubscriptionTypeAddonIds,
	additionSubscriptionIntegrations,
	removeSubscriptionIntegrations,
	changeSubscribedIntegrations,
	addSubscriptionIntegrations,
	removeUserSubscriptionTypeAddonIds,
	setInitUserSubscriptionTypeAddonIds,
	setSubscriptionTypes,
	setShippingApplicable,
	setError,
	setIsLoading,
	setUserLoading,
	setResendEmail,
	setInactiveSubscription,
	setPasswordResetEmail,
	setUserSubscriptionTypeId,
	clearForgotPassword,
	clearPasswordReset,
	setStateValue
} = authSlice.actions;

export const logout = (): AppThunk => dispatch => {
	usersService
		.logout()
		.then(() => {
			clearJwt();
			dispatch(clearAuth());
			localStorage.clear();
		})
		.catch(() => {
			console.log("Error logging out");
		});
};

export const selectAuth = (
	state: RootState
): {
	errorMessage: string;
	isLoading: boolean;
	isLoggedIn: boolean;
	isPasswordResetLinkSent: boolean;
	passwordResetEmail: string;
	passwordReset: boolean;
} => {
	const { errorMessage, isLoading, isLoggedIn, isPasswordResetLinkSent, passwordResetEmail, passwordReset } =
		state.auth;
	return {
		errorMessage,
		isLoading,
		isLoggedIn,
		isPasswordResetLinkSent,
		passwordResetEmail,
		passwordReset
	};
};

export const selectCurrentlyAllowedTrialCourses = (state: RootState): Course[] | undefined =>
	state.auth.user?.currentlyAllowedTrialCourses;
export const selectCurrentlyAllowedFullCourses = (state: RootState): Course[] | undefined =>
	state.auth.user?.currentlyAllowedFullCourses;
export const selectSubscriptions = (state: RootState): UserSubscription[] | undefined => state.auth.user?.subscriptions;
export const selectUser = (state: RootState): User | null => state.auth.user;
export const selectIsTrialUser = (state: RootState): boolean => !!state.auth.user?.trialAccess;
export const selectUserId = (state: RootState): number => state.auth.user?.id as number;
export const selectResendEmail = (state: RootState): string => state.auth.resendEmail;
export const selectIsTrial = (state: RootState): boolean | undefined => state.auth.subscription.isTrial;
export const selectInactiveSubscription = (state: RootState): InactiveSubscription => state.auth.inactiveSubscription;

export default authSlice.reducer;

import { Box, Button, alpha, createStyles, makeStyles } from "@material-ui/core";
import { primary } from "@remar/shared/dist/colors";

import * as colors from "@remar/shared/dist/colors";

import { IExtendedTheme } from "@remar/shared/dist/theme/default";

import styled, { css } from "styled-components";

import { ReactComponent as CheckMarkSvg } from "assets/icons/icon-checkmark.svg";
import { ReactComponent as CrossSvg } from "assets/icons/icon-cross.svg";

export const useButtonStyles = makeStyles((theme: IExtendedTheme) => ({
	disabledButton: {
		backgroundColor: `${theme.palette.colors.primary[200]} !important`
	},
	testContainedPrimary: {
		"&:hover": {
			backgroundColor: `${theme.palette.colors.primary[800]} !important`
		}
	},
	root: {
		color: alpha(theme.palette.text.primary, 0.6),
		fontWeight: "bold"
	}
}));

export const useStyles = makeStyles((theme: IExtendedTheme) =>
	createStyles({
		modal: {
			display: "flex",
			alignItems: "center"
		},
		openSansFontFamily: {
			fontFamily: "var(--poppins)"
		},
		leftCol: {
			padding: "10px 8px 10px 0"
		},
		rightCol: {
			padding: "10px 0 10px 8px",
			[theme.breakpoints.down("sm")]: {
				padding: "10px 0px"
			}
		},
		scroll: {
			overflowY: "auto",
			maxHeight: "100vh",
			width: "100%",
			padding: "20px 0",
			display: "flex",
			flexFlow: "row nowrap",
			alignItems: "start",
			justifyContent: "center"
		},
		content: {
			maxWidth: "1140px",
			width: "100%",
			margin: "0 5%",
			background: "#eceff4"
		},
		header: {
			display: "flex",
			flexFlow: "row nowrap",
			alignItems: "center",
			justifyContent: "space-between",
			background: primary["600"],
			color: "#fff"
		},
		title: {
			lineHeight: "56px",
			padding: "0 16px",
			opacity: 0.87,
			fontFamily: "var(--poppins)",
			fontSize: `"${theme.typography.pxToRem(18)}"`,
			fontWeight: "bold"
		},
		row: {
			display: "flex",
			flexFlow: props => `${props["isMobile"] ? "column" : "row nowrap"}`,
			width: "100%",
			gap: "16px"
		},
		questionNumber: {
			fontSize: `"${theme.typography.pxToRem(15)}"`,
			fontWeight: 600,
			lineHeight: 1.6,
			fontStretch: "normal",
			fontStyle: "normal",
			letterSpacing: "normal",
			color: "#4d5058",
			fontFamily: "var(--openSans)"
		},
		col: {
			flexBasis: "50%",
			margin: props => `${props["isMobile"] ? "0 24px" : 0}`
		},
		halfCol: {
			flexBasis: "50%",
			margin: props => `${props["isMobile"] ? "0 24px" : 0}`
		},
		fullCol: {
			flexBasis: "100%",
			margin: props => `${props["isMobile"] ? "0 24px" : 0}`
		},
		tutoredTestResultCol: {
			flexBasis: "50%",
			padding: props => `${props["isMobile"] ? "16px 16px 0 16px" : 0}`
		},
		resultInfo: {
			flexBasis: "50%"
		},
		text: {
			fontSize: `${theme.typography.pxToRem(13)}`,
			fontWeight: 600,
			lineHeight: 1.85
		},
		questionText: {
			fontFamily: "var(--openSans)",
			fontSize: `"${theme.typography.pxToRem(13)}"`,
			fontWeight: 600,
			fontStretch: "normal",
			fontStyle: "normal",
			lineHeight: 1.85,
			letterSpacing: "normal",
			color: "#4d5058"
		},
		questionContainer: {
			gap: "16px",
			display: "block",
			padding: "24px 0",
			margin: props => `${props["isMobile"] ? 0 : "0 24px"}`,
			color: "#4d5058",
			"&:not(:last-of-type)": {
				borderBottom: "solid 1px #b1b6c3"
			}
		},
		correct: {
			"& $radioLabel, & svg": {
				color: "#264fae"
			},
			"&$your $radioLabel, &$your svg": {
				color: "#23b34a"
			}
		},
		correctBg: {
			backgroundColor: "#C4E3D2",
			borderRadius: "4px",
			height: "60px",
			[theme.breakpoints.down("sm")]: {
				height: "auto"
			}
		},
		yourBg: {
			backgroundColor: "#faccd0",
			borderRadius: "4px",
			height: "60px",
			[theme.breakpoints.down("sm")]: {
				height: "auto"
			}
		},
		gap5: {
			gap: "5px"
		},
		correctOptBg: {
			borderRadius: "4px",
			height: "60px",
			backgroundColor: "#c0c9e5",
			[theme.breakpoints.down("sm")]: {
				height: "auto"
			}
		},
		correctAnsText: {
			paddingLeft: "7px",
			color: "#23b34a",
			fontFamily: "var(--poppins)",
			fontSize: `"${theme.typography.pxToRem(15)}"`,
			fontWeight: 600,
			lineHeight: 1.6
		},
		correctOptText: {
			fontFamily: "var(--poppins)",
			fontSize: `"${theme.typography.pxToRem(15)}"`,
			fontWeight: 600,
			lineHeight: 1.6,
			color: "#264fae",
			paddingLeft: "7px"
		},
		yourAnsText: {
			paddingLeft: "7px",
			fontFamily: "var(--poppins)",
			fontSize: `"${theme.typography.pxToRem(15)}"`,
			fontWeight: 600,
			lineHeight: 1.6,
			color: "#772a34"
		},
		optText: {
			fontFamily: "var(--poppins)",
			fontSize: `"${theme.typography.pxToRem(15)}"`,
			paddingLeft: "7px",
			fontWeight: 600,
			lineHeight: 1.6,
			color: "#4d5058",
			overflow: "hidden",
			textOverflow: "ellipsis",
			whiteSpace: "nowrap",
			maxWidth: "calc(100% - 10px)"
		},
		your: {
			"& svg, $radioLabel": {
				color: "#772a34"
			}
		},
		radioLabel: {
			flexBasis: "100%",
			textAlign: "center",
			fontSize: `"${theme.typography.pxToRem(15)}"`,
			fontWeight: 600,
			paddingBottom: "8px",
			fontFamily: "var(--poppins)",
			color: "#264fae",
			[theme.breakpoints.down("sm")]: {
				paddingRight: "2px",
				paddingLeft: "2px",
				height: "100%"
			}
		},
		rationaleContainer: {
			borderRadius: "3px",
			background: "#fff",
			padding: "16px"
		},
		rationaleContainerTutoredTest: {
			borderRadius: "3px",
			background: "#fff",
			padding: "16px",
			height: "100%"
		},
		rationaleHeader: {
			fontSize: `"${theme.typography.pxToRem(15)}"`,
			fontWeight: 600,
			lineHeight: 1.6,
			display: "flex",
			alignItems: "center",
			"&:first-child": {
				marginBottom: "16px"
			}
		},
		rationaleImage: {
			borderRadius: "5px",
			height: props => `${props["isMobile"] ? "150px" : "120px"}`
		},
		icon: {
			marginRight: "8px"
		},
		timer: {
			fontSize: `"${theme.typography.pxToRem(13)}"`,
			lineHeight: 1.23,
			color: "#4d5058",
			display: "flex",
			alignItems: "center"
		},
		textArea: {
			width: "100%",
			border: "solid 1px #edf1f7",
			fontFamily: "var(--poppins)",
			fontSize: `"${theme.typography.pxToRem(13)}"`
		},
		notesTypography: {
			fontSize: `"${theme.typography.pxToRem(15)}"`,
			fontWeight: 600,
			flexGrow: 1
		},
		purple: {
			backgroundColor: "#c0c9e5"
		},
		green: {
			backgroundColor: "#C4E3D2"
		},
		grey: {
			backgroundColor: "#faccd0;"
		},
		transparent: {
			backgroundColor: "transparent"
		},
		card: {
			borderRadius: "5px"
		}
	})
);

export const CATButtonRow = styled.div`
	display: flex;
	justify-content: end;
	margin-top: 24px;
	${({ fullWidth }) => (fullWidth ? "flex-direction: column" : "")}
`;

export const CATExpand = styled.span`
	color: #4a99f9;
	font-weight: bold;
`;

export const CATContentAreaStatus = styled.div`
	font-family: var(--poppins);
	font-size: ${props => props.theme.typography.pxToRem(18)};
	font-weight: bold;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.33;
	letter-spacing: normal;
	white-space: nowrap;
	flex: 0;
	color: ${({ passed, medium }) => (passed ? "#23b34a" : medium ? "#e47e11" : "#ed5465")};
`;

export const CATTestStatus = styled.span`
	font-family: var(--poppins);
	font-size: ${props => props.theme.typography.pxToRem(18)};
	font-weight: bold;
	line-height: 1.33;
	color: ${({ passed }) => (passed ? "#23b34a" : "#ed5465")};
`;

export const CATResultsTypography = styled.p`
	font-family: var(--openSans);
	font-size: ${props => props.theme.typography.pxToRem(15)};
	line-height: 1.6;
	color: #4d5058;
	padding: 8px 0;
	margin: 0;
	${({ extraBottomPadding }) => extraBottomPadding && "padding-bottom: 24px"}

	${({ flexColumn }) =>
		flexColumn ? "display: flex; flex-flow: column nowrap; align-items: center; justify-content: center" : ""}

	${({ fullWidth }) => (fullWidth ? "width: 100%" : "")}
`;

export const CATContentAreas = styled(CATResultsTypography)`
	font-weight: bold;
`;

export const CATResultsContainer = styled.div`
	padding: 24px;
`;
export const CATContentAreaContainer = styled.div`
	display: flex;
	flex-flow: row wrap;
	align-items: center;

	${CATResultsTypography} {
		flex-grow: 1;
	}
`;

export const CATContentAreaDesc = styled(CATResultsTypography)`
	width: 100%;
	flex-shrink: 0;
	padding: 0;
`;
export const CATResultsHeader = styled.h1`
	padding: 0;
	margin: 0 0 ${({ noMargin }) => (noMargin ? 0 : "16px")};
	font-family: var(--poppins);
	font-size: ${props => props.theme.typography.pxToRem(18)};
	font-weight: bold;
	line-height: 1.33;
	color: #4d5058;
`;

export const OKButton = styled(Button)`
	font-size: ${props => props.theme.typography.pxToRem(16)};
	color: ${props => props.theme.palette.text.primary};
`;

export const CorrectAnswer = styled(CheckMarkSvg)`
	margin-right: 10px;
	width: 24px;
	height: 24px;
`;

export const WrongAnswer = styled(CrossSvg)`
	margin-right: 10px;
	width: 24px;
	height: 24px;
`;

export const AnswerOption = styled(Box)`
	display: flex;
	min-height: 48px;
	border-radius: 4px;
	margin: 10px 10px 10px 0;
	font-family: var(--openSans);
`;

export const NotesContainer = styled.div`
	display: flex;
	font-size: ${props => props.theme.typography.pxToRem(13)};
	align-items: center;
	margin-bottom: 10px;
`;

export const PercentageContainer = styled.div`
	display: flex;
	font-size: ${props => props.theme.typography.pxToRem(13)};
	align-items: center;
	margin-bottom: 10px;
	margin-left: 3px;
	> span {
		padding-left: 2px;
	}
`;

export const AnswerOptionRow = styled(Box)<{ color: string; bgColor: string; opacity: number }>`
	padding: 15px 0 15px 0;
	font-family: var(--poppins);
	font-size: ${props => props.theme.typography.pxToRem(15)};
	font-weight: bold;
	font-stretch: normal;
	width: 100%;
	font-style: normal;
	line-height: 1.6;
	letter-spacing: normal;
	border-radius: 4px;
	opacity: ${({ opacity }) => opacity};
	color: ${({ color }) => color};
	background-color: ${({ bgColor }) => bgColor};
	display: flex;
	${({ $highlightTable, isMobile }) =>
		$highlightTable
			? css`
					margin: 10px 1px 0;
			  `
			: css`
					margin: ${isMobile ? "10px 0px 0" : "10px 5px 0"};
			  `}
`;

export const HighlightedContent = styled.div`
	> .highlighted {
		background: #4c69ba;
		color: ${colors.basic[100]};
		padding: 3px;
		border-radius: 3px;

		&.selected {
			background: #189646;
		}

		> i {
			background: #002378;
			margin: 0 -3px;
			color: ${colors.basic[100]};
		}
	}
	> .selected:not(.highlighted) {
		background: #faccd0;
		color: #772a34;
	}

	i {
		font-style: normal;
		padding: 3px;
		border-radius: 3px;

		> .highlighted {
			background: #002378;
			margin: 0 -3px;
			color: ${colors.basic[100]};
			padding: 3px;
			border-radius: 3px;
		}
	}
`;

export const AnswerOptionsIndication = styled(Box)<{ color: string }>`
	color: ${({ color }) => color};
	width: ${({ isMobile, theCorrectAnswer, yourAnswer }) =>
		isMobile && !theCorrectAnswer && !yourAnswer ? "0%" : "50%"};
	font-size: ${props => props.theme.typography.pxToRem(15)};
	font-weight: 600;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.6;
	letter-spacing: normal;
	font-family: var(--poppins);
	padding-left: ${({ isMobile }) => (isMobile ? 0 : "8px")};
`;

export const AnswerOptionsText = styled(Box)`
	color: #4d5058;
	font-size: ${props => props.theme.typography.pxToRem(15)};
	font-weight: 600;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.6;
	letter-spacing: normal;
	width: 50%;
	padding-right: 8px;
	font-family: var(--poppins);
`;

export const CancelButton = styled(Button)`
	font-size: ${props => props.theme.typography.pxToRem(16)};
	color: ${props => props.theme.palette.text.primary};
	font-weight: bold;
	margin-right: 15px;
`;

export const NoResults = styled(Box)`
	height: 300px;
	display: flex;
	justify-content: center;
	align-items: center;
	color: #4d5058;
	font-size: 2rem;
`;

export const QuestionResultInfoContainer = styled.div`
	margin: ${({ isMobile }) => (isMobile ? "0px 24px" : "0px")};
	flex: 50%;
	overflow: hidden;
`;

import React, { useEffect, useState } from "react";

import { Box, Button, Theme, useMediaQuery, useTheme } from "@material-ui/core";
import { Themes } from "@remar/shared/dist/models/theme.types";
import { downloadExternalFile } from "@remar/shared/dist/utils/serviceUtils/helpers";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

import { getFullState } from "store/features/Theme/theme.slice";
import { usersService } from "store/services";
import styled from "styled-components";

import { ReactComponent as CertificateSvg } from "assets/icons/certificate-illustration.svg";
import { ReactComponent as CertificateBlueSvg } from "assets/icons/certificate-illustration_blue.svg";
import { ReactComponent as CertificateGreenSvg } from "assets/icons/certificate-illustration_green.svg";
import { ReactComponent as CertificateOrangeSvg } from "assets/icons/certificate-illustration_orange.svg";
import { ReactComponent as CertificatePurpleSvg } from "assets/icons/certificate-illustration_purple.svg";
import { ReactComponent as CertificateRedSvg } from "assets/icons/certificate-illustration_red.svg";
import { ReactComponent as CertificateYellowSvg } from "assets/icons/certificate-illustration_yellow.svg";
import { ReactComponent as RemarLogoSvg } from "assets/icons/icon-logo-remar.svg";

import { routes } from "core/constants";

import { CompletionCertificateLink, CompletionCertificateText } from "./style";

const CompletionCertificateContainer = styled(Box)`
	margin-left: -64px;

	${props => props.theme.breakpoints.down("xs")} {
		margin-left: 0;
	}
`;

const getThemedSvg = (colorShade: Themes, isMobile: boolean) => {
	const width = isMobile ? "100%" : "auto";
	switch (colorShade) {
		case Themes.DARK_CYAN_BLUE:
		case Themes.LIGHT_CYAN_BLUE:
			return <CertificateBlueSvg width={width} />;
		case Themes.DARK_RED:
		case Themes.LIGHT_RED:
			return <CertificateRedSvg width={width} />;
		case Themes.DARK_YELLOW:
		case Themes.LIGHT_YELLOW:
			return <CertificateYellowSvg width={width} />;
		case Themes.DARK_ORANGE:
		case Themes.LIGHT_ORANGE:
			return <CertificateOrangeSvg width={width} />;
		case Themes.DARK_GREEN:
		case Themes.LIGHT_GREEN:
			return <CertificateGreenSvg width={width} />;
		case Themes.DARK_BLUE:
		case Themes.LIGHT_BLUE:
			return <CertificateBlueSvg width={width} />;
		case Themes.DARK_PURPLE:
		case Themes.LIGHT_PURPLE:
			return <CertificatePurpleSvg width={width} />;
		default:
			return <CertificateSvg width={width} />;
	}
};

const CompletionCertificate = ({ course }: { course: string }) => {
	const theme = useTheme<Theme>();
	const { colorShade } = useSelector(getFullState);
	const [certificateUrl, setCertificateUrl] = useState("");
	const isMobile = useMediaQuery(theme.breakpoints.down("xs"));

	const getCertificate = async () => {
		try {
			const certificate = await usersService.getUserCertificate();
			setCertificateUrl(certificate?.certificatePdfUrl);
		} catch (error) {
			console.log(error);
		}
	};

	useEffect(() => {
		getCertificate();
	}, []);

	const handleDownload = () => {
		certificateUrl && downloadExternalFile(certificateUrl);
	};

	return (
		<CompletionCertificateContainer>
			<Box display="flex" justifyContent="center" mt={2} flexDirection={"column"} alignItems={"center"}>
				<RemarLogoSvg />
				{getThemedSvg(colorShade, isMobile)}
				<Box mt={4}>
					<CompletionCertificateText variant="h1" fontSize={isMobile ? "1.5rem" : "3rem"}>
						Congratulations
					</CompletionCertificateText>
				</Box>
				<Box mt={4}>
					<CompletionCertificateText variant="h1" fontSize={isMobile ? "1.3em" : "1.5em"}>
						You have successfully completed
					</CompletionCertificateText>
				</Box>
				<Box mt={4}>
					<CompletionCertificateText variant="h1" fontSize={isMobile ? "1.2em" : "1.4em"}>
						{course ?? "N/A"}
					</CompletionCertificateText>
				</Box>
				<Box display="flex" flexDirection={"column"} mt={8} width={isMobile ? "85%" : "auto"}>
					<Button variant="contained" color="primary" onClick={handleDownload}>
						Download Certificate
					</Button>
					<Link to={`${routes.course.getPath()}`}>
						<CompletionCertificateLink>Go to Homepage</CompletionCertificateLink>
					</Link>
				</Box>
			</Box>
		</CompletionCertificateContainer>
	);
};

export default CompletionCertificate;

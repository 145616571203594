import React, { FC, useEffect, useMemo, useState } from "react";

import { Box, Button, IconButton, Modal, Typography, useMediaQuery, useTheme } from "@material-ui/core";

import { Add, Delete } from "@material-ui/icons";
import ArrowForwardRoundedIcon from "@material-ui/icons/ArrowForwardRounded";
import CloseIcon from "@material-ui/icons/Close";

import { DatePicker } from "@remar/shared/dist/components/DatePicker";
import { ExternalIntegrationIds } from "@remar/shared/dist/constants";
import { Course } from "@remar/shared/dist/models";
import { IExtendedTheme } from "@remar/shared/dist/theme/default";
import { formatUSD } from "@remar/shared/dist/utils/formatUSD";
import { addMonths, format } from "date-fns";
import pluralize from "pluralize";
import { useHistory, useParams } from "react-router-dom";

import { RootState, useAppDispatch, useAppSelector } from "store";
import {
	addUserSubscriptionTypeAddonIds,
	removeUserSubscriptionTypeAddonIds,
	setInitUserSubscriptionTypeAddonIds,
	setShippingApplicable
} from "store/features/Auth/authSlice";

import { ReactComponent as TodayIcon } from "assets/icons/icon-today.svg";

import ChangeOrSelectCourse from "./ChangeOrSelectCourse";

import {
	DelayStartDateBox,
	PaidShippingContainer,
	SummaryCloseButton,
	SummaryCloseButtonContainer,
	useStyles
} from "./styles";

interface Props {
	setFreeTrial?: (_) => void;
	closeModal?: (_) => void;
	courses?: Course[];
	startDate?: Date;
	showCrossButton?: boolean;
	setStartDate?: (date: Date) => void;
}

const getDuration = duration => {
	return (
		duration &&
		Object.entries(duration)
			.filter(([, dur]) => !!dur)
			.map(([period, dur]) => `${dur} ${pluralize(period, period)}`)
	);
};

const Summary: FC<Props> = ({
	setFreeTrial,
	courses,
	startDate = new Date(),
	setStartDate,
	closeModal,
	showCrossButton = true
}) => {
	const theme = useTheme<IExtendedTheme>();
	const classes = useStyles();
	const history = useHistory();
	const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
	const [changeCourseModal, setChangeCourseModal] = useState(false);
	const [openPicker, setOpenPicker] = useState(false);
	const { accountClaimCode } = useParams<{ accountClaimCode?: string }>();

	const {
		userSubscriptionTypeId,
		inactiveSubscription: { isRenewEnabled, typeId },
		subscription: { isTrial },
		subscriptionTypes,
		userSubscriptionTypeAddonIds,
		selectedShippingPlan
	}: RootState["auth"] = useAppSelector((store: RootState) => store.auth);
	const dispatch = useAppDispatch();
	const activeSubscription = useMemo(
		() => subscriptionTypes?.find(us => us.id === userSubscriptionTypeId),
		[subscriptionTypes, userSubscriptionTypeId]
	);

	const addOnsSub = activeSubscription?.subTypeEIDItems!.filter(
		item => item.parentId !== null && item.integrationId === ExternalIntegrationIds.Stripe
	);
	const initialBook = activeSubscription?.subTypeEIDItems!.find(
		item => item.parentId === null && item.integrationId === ExternalIntegrationIds.Stripe
	);

	const initialShippingApplicable = !initialBook?.counterparts!.find(
		item => item.integrationId === ExternalIntegrationIds.DigitalAsset
	);

	const addonsShippingApplicable = (addOnsSub || [])
		?.filter(addon => userSubscriptionTypeAddonIds?.includes(addon.id))
		.reduce((acc, cur) => {
			const isDigital = !!cur.counterparts!.find(item => item.integrationId === ExternalIntegrationIds.DigitalAsset);
			return acc || !isDigital;
		}, false);

	const shippingApplicable = initialShippingApplicable || !!addonsShippingApplicable;

	useEffect(() => {
		dispatch(setShippingApplicable(shippingApplicable));
	}, [dispatch, shippingApplicable]);

	useEffect(() => {
		if (!!setFreeTrial) {
			setFreeTrial!(history.location.search.includes("trial=true"));
		}
	}, [history.location.search]);
	useEffect(() => {
		if (activeSubscription && addOnsSub && addOnsSub.length) {
			const ids = [];
			for (let index = 0; index < addOnsSub.length; index++) {
				const addonsSub = addOnsSub[index];
				if (addonsSub.data.isDefault === true) {
					ids.push(addonsSub.id as never);
				}
			}
			dispatch(setInitUserSubscriptionTypeAddonIds(ids));
		}
	}, [activeSubscription]);

	const subscriptionString = useMemo(() => {
		const name = activeSubscription?.name;
		const duration = getDuration(activeSubscription?.duration);
		return (name && `${duration ?? ""} ${name}`) || "N/A";
	}, [userSubscriptionTypeId, subscriptionTypes]);

	const renewSubscription = useMemo(() => {
		let subscription = subscriptionTypes.find(type => type.id === typeId);
		if (!subscription) {
			subscription = activeSubscription;
		}
		let nextSubscription = subscription;
		if (subscription?.nextSubscriptionTypeId) {
			nextSubscription = subscriptionTypes.find(type => type.id === subscription?.nextSubscriptionTypeId);
		}
		const name = nextSubscription?.name;
		const duration = getDuration(nextSubscription?.duration);
		if (duration && duration.length !== 0) {
			duration[0] =
				duration.length !== 0 && duration[0].includes("1") ? duration[0].replace("months", "month") : duration[0];
		}
		const subscriptionName = (name && `${duration ?? ""} ${name}`) || "N/A";
		const price = nextSubscription?.subTypeEIDItems?.[0].data["price"] || 0;
		return { subscriptionName, price };
	}, [typeId, subscriptionTypes]);

	//Getting sum of addons
	const addOnsSum = addOnsSub?.reduce(function (lastSum, currentItem) {
		if (userSubscriptionTypeAddonIds?.includes(currentItem.id)) {
			return lastSum + (currentItem?.data["price"] || 0);
		}
		return lastSum;
	}, 0);

	const mainSubTypeEIDItems = activeSubscription?.subTypeEIDItems!.find(
		i => i.integrationId == ExternalIntegrationIds.Stripe && i.parentId === null
	);
	const coursePrice = mainSubTypeEIDItems?.data["price"] || 0;

	const totalPrice =
		(mainSubTypeEIDItems?.data["price"] || 0) +
		addOnsSum +
		((shippingApplicable && selectedShippingPlan?.data["price"]) || 0);

	const handleDateChange = date => {
		setStartDate && setStartDate!(date);
	};

	return !openPicker ? (
		<>
			<Box display="flex" flexDirection="column">
				<Box className={classes.summaryRoot} pb={0}>
					{showCrossButton && isMobile && (
						<SummaryCloseButtonContainer>
							<SummaryCloseButton onClick={closeModal}>
								<CloseIcon color="disabled" />
							</SummaryCloseButton>
						</SummaryCloseButtonContainer>
					)}
					<Box>
						{!isRenewEnabled && (
							<Box mb={4}>
								<Typography className={classes.title}>You Might Also Like</Typography>
								{addOnsSub
									?.filter(addon => !userSubscriptionTypeAddonIds?.includes(addon.id))
									.map(addon => (
										<Box
											display="flex"
											justifyContent="space-between"
											alignItems="center"
											mt={2}
											bgcolor={theme.palette.background.default}
											p={1}
											key={`addon-${addon.id}`}
											borderRadius={5}
										>
											<Box display="flex" alignItems="center">
												<Box borderRadius={5} overflow="hidden" width={40} height={55}>
													<img className={classes.bookImg} src={addon?.mainImageUrl} alt={addon?.data["name"]} />
												</Box>
												<Box ml={1}>
													<Typography className={classes.summaryText}>{addon?.data["name"] as string}</Typography>
													<Typography>{formatUSD(addon?.data["price"] || 0)}</Typography>
												</Box>
											</Box>
											<Box>
												<Box
													bgcolor={theme.palette.colors.primary[500]}
													p={1}
													display="flex"
													borderRadius={5}
													justifyContent="center"
													alignItems="center"
													style={{ cursor: "pointer" }}
													onClick={() => dispatch(addUserSubscriptionTypeAddonIds(addon.id))}
												>
													<Add
														style={{
															backgroundColor: theme.palette.colors.primary[500],
															color: theme.palette.colors.basic[1500]
														}}
													/>
												</Box>
											</Box>
										</Box>
									))}
							</Box>
						)}

						<Box>
							<Typography className={classes.title}>Order Summary</Typography>
							<Box className={classes.orderSummary} mt={2}>
								<Typography className={classes.summaryText}>
									{isRenewEnabled ? renewSubscription.subscriptionName : subscriptionString}
									<span onClick={() => setChangeCourseModal(true)}>
										{subscriptionString !== "N/A" && !isRenewEnabled && !isTrial && "(Change)"}
									</span>
								</Typography>
								<Typography className={classes.summaryCostSmall}>
									{isRenewEnabled
										? renewSubscription.price && formatUSD(renewSubscription.price)
										: !isRenewEnabled
										? coursePrice && formatUSD(coursePrice)
										: "N/A"}{" "}
								</Typography>
							</Box>
							{!isRenewEnabled &&
								addOnsSub
									?.filter(addon => userSubscriptionTypeAddonIds?.includes(addon.id))
									.map(addon => (
										<Box key={addon?.data["name"] as string} className={classes.addon} mt={2}>
											<Box display={"flex"} alignItems={"center"}>
												<img className={classes.addonImage} src={addon?.mainImageUrl} alt={addon?.data["name"]} />
												<Typography className={classes.summaryText}>{addon?.data["name"] as string}</Typography>
											</Box>
											<Box display={"flex"}>
												<Typography className={classes.summaryCostSmall}>
													{formatUSD(addon?.data["price"] || 0)}
												</Typography>
												<Delete
													className={classes.removeAddOnIcon}
													onClick={() => dispatch(removeUserSubscriptionTypeAddonIds(addon.id))}
												/>
											</Box>
										</Box>
									))}
						</Box>
					</Box>

					<Box>
						{!isRenewEnabled && shippingApplicable && selectedShippingPlan && (
							<PaidShippingContainer>
								<Typography className={classes.summaryText} align="right">
									{selectedShippingPlan?.name}
								</Typography>
								<Typography className={classes.summaryCostSmall} align="right">
									{formatUSD(selectedShippingPlan?.data["price"] || 0)}
								</Typography>
							</PaidShippingContainer>
						)}
						<Box mt={3}>
							<Typography className={classes.summaryTextLight} align="right">
								Your total
							</Typography>
							<Typography className={classes.summaryCostBig} align="right">
								{isRenewEnabled
									? renewSubscription.price && formatUSD(renewSubscription.price)
									: !isRenewEnabled
									? totalPrice && formatUSD(totalPrice)
									: "N/A"}{" "}
							</Typography>
							<Typography className={classes.formSubtitle} style={{ textAlign: "right" }}>
								Your subscription will start on {format(startDate as Date, "MMM dd, yyyy.")}
							</Typography>
						</Box>
					</Box>
				</Box>
				{!isRenewEnabled && (
					<DelayStartDateBox p={2} className={classes.changeDateText} onClick={() => setOpenPicker(true)}>
						<Box className="today-icon">
							<TodayIcon />
						</Box>
						Click here to delay your start date.
						<Box className="arrow-icon">
							<ArrowForwardRoundedIcon />
						</Box>
					</DelayStartDateBox>
				)}
			</Box>
			<Modal
				open={changeCourseModal}
				onClose={() => {
					setChangeCourseModal(false);
				}}
			>
				<ChangeOrSelectCourse
					onClose={() => setChangeCourseModal(false)}
					courses={courses}
					accountClaimCode={accountClaimCode}
				/>
			</Modal>
		</>
	) : (
		<Box className={classes.summaryRoot}>
			<Box>
				<Box mb={4} display="flex" justifyContent="space-between">
					<Typography className={classes.changeTheStartDate}>Change The Start Date</Typography>
					<Box display="flex" alignItems="center" justifyContent="flex-end">
						<IconButton onClick={() => setOpenPicker(false)} className={classes.closeIcon}>
							<CloseIcon color="disabled" />
						</IconButton>
					</Box>
				</Box>
				<div className={classes.datepickerInputContainer}>
					<DatePicker
						autoOk
						open={false}
						disablePast
						disableToolbar
						orientation="portrait"
						variant="static"
						openTo="date"
						maxDate={addMonths(new Date(), 3)}
						value={startDate}
						onChange={handleDateChange}
					/>
				</div>
				<Box display="flex" justifyContent="flex-end" mt={2}>
					<Button variant={"contained"} color={"primary"} onClick={() => setOpenPicker(false)}>
						Done
					</Button>
				</Box>
			</Box>
		</Box>
	);
};

export default Summary;

import React, { useCallback, useEffect, useMemo } from "react";

import { Box, Button, Card, Modal, useTheme } from "@material-ui/core";
import { ErrorSharp } from "@material-ui/icons";
import { unwrapResult } from "@reduxjs/toolkit";
import { IExtendedTheme } from "@remar/shared/dist/theme/default";
import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { getFullState, getPaymentDetails, setError, updatePaymentMethod } from "store/features/Billing/BillingSlice";

import { getCardIcon } from "modules/Auth/components/utils";

import { getCallbackUrl } from "modules/utils";

import EditPaymentMethod from "./EditPaymentMethod";

import { CardName, ManageText, PaymentDescription, PaymentExpiredText, PaymentTitle } from "./styles";

const PaymentMethods = ({ onEdit, modal, setModal, showPrefilledInfo = false, showRecurringInfo = false }) => {
	const theme = useTheme<IExtendedTheme>();
	const dispatch = useDispatch();
	const stripe = useStripe();
	const elements = useElements();
	const history = useHistory();
	const callBackUrl = useMemo(() => getCallbackUrl(history.location.search), [history.location.search]);

	const { isLoading, paymentMethods } = useSelector(getFullState);
	useEffect(() => {
		dispatch(getPaymentDetails());
	}, [dispatch]);

	const sideEffectWithCallBack = useCallback(
		async (error: string) => {
			if (error) {
				dispatch(setError(error));
			} else {
				setModal(false);
				dispatch(getPaymentDetails());
				if (callBackUrl) {
					window.location.replace(callBackUrl);
				}
			}
		},
		[callBackUrl, dispatch, setModal]
	);

	const onSubmit = async () => {
		await unwrapResult(
			(await dispatch(
				updatePaymentMethod({ CardElement, elements, stripe, sideEffect: sideEffectWithCallBack })
			)) as unknown as {
				payload: { error?: string };
				meta?: unknown;
				error?: unknown;
			}
		);
	};

	return (
		<>
			<Card style={{ marginTop: 10 }}>
				<PaymentTitle>Payment Method</PaymentTitle>
				{!isLoading && !!paymentMethods && (
					<Box bgcolor={theme.palette.background.default} mt={2} p={2} borderRadius="4px">
						<Box display="flex" mb={2} alignItems="center">
							{getCardIcon(paymentMethods.brand)}
							<Box ml={2}>
								<Box display="flex" flexWrap="wrap">
									<CardName>{paymentMethods?.brand}</CardName>
									<CardName>{`.... ${paymentMethods?.last4}`}</CardName>
								</Box>
								<PaymentExpiredText>{`Expiration date: ${paymentMethods?.exp_month}/${paymentMethods?.exp_year}`}</PaymentExpiredText>
							</Box>
						</Box>
						<Button onClick={onEdit}>
							<ManageText>{showPrefilledInfo ? "Change Card" : "Edit Card"}</ManageText>
						</Button>
						{showRecurringInfo && (
							<>
								<Box height="1px" bgcolor="#22252d" mt={1} mb={1} />
								<Box display="flex" mb={1}>
									<ErrorSharp style={{ fill: "#898f9e" }} />
									<PaymentDescription>
										Recurring payment is a payment model where the customers authorize the merchant to pull funds from
										their accounts automatically at regular intervals.
									</PaymentDescription>
								</Box>
							</>
						)}
					</Box>
				)}
				{!paymentMethods && (
					<Box display="flex" mb={1} mt={1} style={{ border: "solid", padding: "8px" }}>
						<ErrorSharp style={{ fill: "#898f9e" }} />
						<PaymentDescription>Your payment method will show up here once added</PaymentDescription>
					</Box>
				)}
			</Card>
			<Modal open={modal} onClose={() => setModal(false)}>
				<EditPaymentMethod handleSubmit={onSubmit} closeModal={() => setModal(false)} />
			</Modal>
		</>
	);
};
export default PaymentMethods;

import { useEffect } from "react";

import { GLOBAL_CONSTANTS } from "../constants";

interface PostAffTracker {
	setAccountId: (arg0: string) => void;
	track: () => void;
	executeOnResponseFinished: (() => void)[];
	_getAccountId: () => string;
	_cmanager: { getVisitorIdOrSaleCookieValue: () => string };
}

export const usePostAffiliateProTrack = (cb: (value) => void): void => {
	const target = window as typeof window & { PostAffTracker: PostAffTracker };
	useEffect(() => {
		if (GLOBAL_CONSTANTS.ENVIRONMENT == "dev") {
			if (target.PostAffTracker) {
				target.PostAffTracker.setAccountId("default1");
				try {
					target.PostAffTracker.track();
				} catch (err) {}
				target.PostAffTracker.executeOnResponseFinished.push(function () {
					const cookie =
						target.PostAffTracker._getAccountId() + target.PostAffTracker._cmanager.getVisitorIdOrSaleCookieValue();
					cb(cookie);
				});
			} else {
				console.log("Post Affiliate Pro: Not Tracking");
			}
		}
	}, [cb]);
};

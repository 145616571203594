import {
	BaseApiService,
	BaseDeleteDto,
	BaseFindDto,
	BaseFindOneDto,
	BaseUpdateDto
} from "@remar/shared/dist/api/baseApiService";
import { request } from "@remar/shared/dist/api/request";
import { Course } from "@remar/shared/dist/models";

import { URLS } from "../services.constants";

export class CoursesService extends BaseApiService<
	Course,
	Record<string, unknown>,
	BaseFindDto,
	BaseFindOneDto,
	BaseUpdateDto<Record<string, unknown>>,
	BaseDeleteDto
> {
	constructor(baseUrl: string) {
		super(baseUrl);
	}
	getCourses(data: Record<string, unknown>): Promise<Course> {
		return request(
			`${this.baseUrl}/get-courses${this.serializeQuery(data as Record<string, unknown>, { isTopLevel: true })}`,
			"GET"
		) as Promise<Course>;
	}
}

export const coursesService = new CoursesService(URLS.COURSES);

import React, { useEffect, useRef, useState } from "react";

import { Button, useTheme } from "@material-ui/core";
import { VITLessonAppearance } from "@remar/shared/dist/components/VITLessonAppearance";
import { Lesson } from "@remar/shared/dist/models";

import { IExtendedTheme } from "@remar/shared/dist/theme/default";
import useAnalyticsEventTracker from "hooks/googleAnalytics";

import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import {
	completeUserLessonAttempt,
	createLastViewedSectionId,
	createUserLessonAttempt,
	createUserQuestionAttempt,
	getInteractiveBlocksByQuestionId,
	getIsLessonFinished,
	getQuizError,
	getQuizLessonAttemptId,
	getQuizLessonQuestions,
	getQuizQuestionAnswers,
	getUserLessonAttempt
} from "store/features/Lesson/lesson.slice";

import "video.js/dist/video-js.min.css";

import { Autoplay } from "./Autoplay";

import {
	BackgroundImage,
	ContentContainer,
	FullWidthContainer,
	StartLessonBtnContainer,
	VITLessonContainer,
	VITLessonDescription,
	VITQuizLessonTitle
} from "./style";

interface VITLessonProps {
	lesson: Lesson;
	sectionLessonId?: number;
	handleNextLesson: () => void;
	setShowCompletionCertificate: (param) => void;
	sideEffect: () => void;
	certificateAlreadyDisplayed: boolean;
	hasNextLesson: boolean;
}

export const VITLesson = ({
	lesson,
	sectionLessonId,
	sideEffect,
	handleNextLesson,
	setShowCompletionCertificate,
	certificateAlreadyDisplayed,
	hasNextLesson
}: VITLessonProps) => {
	const theme = useTheme<IExtendedTheme>();
	const { name, description, isIntro, interactiveBlocks: _interactiveBlocks } = lesson;
	const [isStarted, setIsStarted] = useState(false);
	const dispatch = useDispatch();
	const history = useHistory();
	const savedUserAnswers = useSelector(getQuizQuestionAnswers);
	const quizError = useSelector(getQuizError);
	const questions = useSelector(getQuizLessonQuestions);
	const attemptId = useSelector(getQuizLessonAttemptId);
	const interactiveBlocks = useSelector(getInteractiveBlocksByQuestionId) || {};
	const isFinished = useSelector(getIsLessonFinished);
	const analytics = useAnalyticsEventTracker("Lesson");

	useEffect(() => {
		if (sectionLessonId) {
			dispatch(getUserLessonAttempt({ sectionLessonId, inProgress: true }));
			return;
		}
		if (isIntro) {
			dispatch(getUserLessonAttempt({ introLessonId: lesson.id, inProgress: true }));
		}
	}, [dispatch, sectionLessonId, isIntro, lesson.id]);

	useEffect(() => {
		analytics({ eventName: isIntro ? "intro_vit_Finished" : "complite_vit_lesson", eventIdentifier: `${lesson.id}` });
	}, [isFinished]);

	useEffect(() => {
		if (isFinished && isIntro) {
			history.replace("/");
		}
	}, [history, isFinished, isIntro]);

	useEffect(() => {
		!attemptId && questions.length === 0 && setIsStarted(false);
	}, [attemptId, questions.length]);

	const containerRef = useRef<HTMLDivElement>(null);

	const handleStart = () => {
		if (!attemptId) {
			if (sectionLessonId) {
				dispatch(createUserLessonAttempt({ sectionLessonId }));
				analytics({ eventName: "start_vit_lesson", eventIdentifier: `${sectionLessonId}` });
			} else if (isIntro) {
				dispatch(createUserLessonAttempt({ introLessonId: lesson.id }));
				analytics({ eventName: "intro_vit_begin", eventIdentifier: `${lesson.id}` });
			}
		}
		if (sectionLessonId) {
			// set latest watched lesson
			dispatch(createLastViewedSectionId({ sectionLessonId }));
		}
		setIsStarted(true);
	};

	const handleComplete = () => dispatch(completeUserLessonAttempt({ id: attemptId, sideEffect }));

	const handleSubmit = ({ questionId, userAnswers }) => {
		if (userAnswers.length > 0) {
			dispatch(
				createUserQuestionAttempt({
					userLessonAttemptId: attemptId,
					questionId: questionId,
					userAnswers
				})
			);
		}
	};

	const handleFullscreen = () => {
		containerRef?.current?.requestFullscreen && containerRef?.current?.requestFullscreen();
		containerRef?.current?.webkitRequestFullscreen && containerRef?.current?.webkitRequestFullscreen();
	};

	if (isStarted && questions.length === 0 && !quizError) {
		return null;
	}

	return (
		<>
			{!isStarted ? (
				<FullWidthContainer>
					<BackgroundImage imgUrl={_interactiveBlocks ? _interactiveBlocks![0].video.thumbnailUrl : ""} />
					<ContentContainer>
						<VITQuizLessonTitle>{isIntro ? "Intro VIT" : name}</VITQuizLessonTitle>
						<VITLessonDescription>{description}</VITLessonDescription>
						<StartLessonBtnContainer>
							<Button variant="contained" color="primary" onClick={handleStart}>
								Start the Lesson
							</Button>
						</StartLessonBtnContainer>
					</ContentContainer>
				</FullWidthContainer>
			) : quizError ? (
				quizError
			) : (
				<VITLessonContainer ref={containerRef}>
					<VITLessonAppearance
						theme={theme}
						lesson={lesson}
						isFinished={isFinished}
						interactiveBlocks={interactiveBlocks}
						questions={questions}
						savedUserAnswers={savedUserAnswers}
						onSubmit={handleSubmit}
						onComplete={handleComplete}
						handleFullscreen={handleFullscreen}
					/>
					{isFinished && !isIntro && (
						<Autoplay
							redirectOnEnd={!hasNextLesson && certificateAlreadyDisplayed}
							certificateAlreadyDisplayed={certificateAlreadyDisplayed}
							handleNextLesson={handleNextLesson}
							setShowCompletionCertificate={setShowCompletionCertificate}
						/>
					)}
				</VITLessonContainer>
			)}
		</>
	);
};

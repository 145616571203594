import * as colors from "@remar/shared/dist/colors";

import { DoubleExtendedPalette } from "@remar/shared/dist/models/theme.types";

import defaultColorPalette from "./dark_defaultPalette";

const colorPalette: Partial<DoubleExtendedPalette> = {
	type: "dark",
	...defaultColorPalette,
	primary: {
		contrastText: colors.basic[100],
		dark: colors.cyanBlue[800],
		light: colors.cyanBlue[200],
		main: colors.cyanBlue[600],
		tabs: {
			border: colors.cyanBlue[500],
			contrastText: colors.cyanBlue[800],
			main: colors.cyanBlue[300]
		}
	},
	secondary: {
		contrastText: colors.cyanBlue[600],
		dark: colors.cyanBlue[800],
		light: colors.cyanBlue[200],
		main: colors.cyanBlue[200],
		tabs: {
			contrastText: colors.cyanBlue[800],
			dark: colors.cyanBlue[500],
			main: colors.cyanBlue[200],
			border: colors.cyanBlue[600]
		}
	}
};

export default colorPalette;

import React, { useEffect, useState } from "react";

import { Box, Button, CircularProgress, Grid, Typography } from "@material-ui/core";

import SvgIcon from "@material-ui/core/SvgIcon";

import { ReactComponent as CheckBoxSvg } from "@remar/shared/dist/assets/icons/checkbox.svg";
import { ReactComponent as IconEyeSlash } from "@remar/shared/dist/assets/icons/icon-eye-slash.svg";
import { ReactComponent as IconEye } from "@remar/shared/dist/assets/icons/icon-eye.svg";
import { passwordREGEX } from "@remar/shared/dist/utils/serviceUtils";
import { Field, Form, Formik } from "formik";
import { TextField as MuiTextField } from "formik-material-ui";

import useAnalyticsEventTracker from "hooks/googleAnalytics";

import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory, useParams } from "react-router-dom";

import { clearPasswordReset, forgotVerification, resetPassword, selectAuth } from "store/features/Auth/authSlice";
import * as Yup from "yup";

import { routes } from "core/constants";

import EntryScreenLayout from "./EntryScreenLayout";
import { useStyles } from "./resetPassword.styles";

const TextField = props => <MuiTextField {...props} variant="filled" hiddenLabel />;

const ResetPasswordForm = ({ handleSubmit, errorMessage, isLoading }) => {
	const [showPass, setShowPass] = useState(false);

	return (
		<Formik
			initialValues={{
				password: "",
				confirmPassword: ""
			}}
			validationSchema={Yup.object().shape({
				password: Yup.string()
					.required("Required")
					.matches(
						passwordREGEX,
						"The password must contain at least 8 characters, 1 uppercase letter, 1 lowercase letter and 1 number."
					),
				confirmPassword: Yup.string()
					.oneOf([Yup.ref("password"), null], "Passwords must match")
					.required("Required")
			})}
			onSubmit={values => handleSubmit(values)}
		>
			{({ isValid, values }) => (
				<>
					{errorMessage && (
						<Box mt={2}>
							<Typography variant="caption" style={{ color: "red" }}>
								{errorMessage}
							</Typography>
						</Box>
					)}
					<Form>
						<Box mt={3}>
							<Grid container spacing={2}>
								<Grid item xs={12}>
									<Typography variant="subtitle1">Create a new password that is at least 8 characters long.</Typography>
									<Field
										component={TextField}
										type={showPass ? "text" : "password"}
										name="password"
										placeholder="New Password"
										InputProps={{
											endAdornment: (
												<SvgIcon fontSize="small" cursor="pointer" onClick={() => setShowPass(!showPass)}>
													{showPass ? <IconEye /> : <IconEyeSlash />}
												</SvgIcon>
											),
											disableUnderline: true
										}}
										fullWidth
									/>
								</Grid>
								<Grid item xs={12}>
									<Field
										component={TextField}
										type={showPass ? "text" : "password"}
										name="confirmPassword"
										placeholder="Confirm New Password"
										InputProps={{ disableUnderline: true }}
										fullWidth
									/>
								</Grid>
							</Grid>
						</Box>
					</Form>
					<Box mt={1}>
						<Button
							fullWidth
							color="primary"
							disabled={!isValid || isLoading}
							startIcon={isLoading && <CircularProgress size={20} />}
							variant="contained"
							onClick={() => handleSubmit(values)}
						>
							Save The New Password
						</Button>
					</Box>
				</>
			)}
		</Formik>
	);
};

const ResetPassword = () => {
	const classes = useStyles();
	const history = useHistory();
	const { code } = useParams<{ code: string }>();
	const dispatch = useDispatch();
	const analytics = useAnalyticsEventTracker("User");

	const { errorMessage, isLoading, passwordReset, passwordResetEmail } = useSelector(selectAuth);

	const successSideEffect = code => {
		analytics({ contentIdentifier: `${code}`, eventName: "password-reset" });
	};

	const handleSubmit = values => {
		dispatch(resetPassword({ code, password: values.password, successSideEffect }));
	};

	useEffect(() => {
		dispatch(forgotVerification({ code, sideEffect: () => console.log(history) }));
	}, [code, dispatch, history]);
	useEffect(
		() => () => {
			dispatch(clearPasswordReset());
		},
		[dispatch]
	);

	const handleRoute = e => {
		e.preventDefault();
		history.replace(routes.signIn.getPath());
	};

	return isLoading ? (
		<Box display="flex" alignItems="center" justifyContent="center" width="100%" height="100vh">
			<CircularProgress size="7rem" color="primary" thickness={5} variant="indeterminate" />
		</Box>
	) : passwordReset ? (
		<EntryScreenLayout title="Password changed">
			<Box display="flex" flexDirection="column" justifyContent="center" alignItems="center">
				<Box mb={5}>
					<Typography className={classes.description}>Your password has been successfully changed.</Typography>
					<Typography className={classes.description}>
						A confirmation email has been sent to <b style={{ marginLeft: "10px" }}>{`[${passwordResetEmail}]`}</b>
					</Typography>
				</Box>
				<Box display="flex" flexDirection="column" alignItems="center">
					<CheckBoxSvg className={classes.checkbox} />
					<Link to={"#"} onClick={handleRoute} className={classes.linkText}>
						Return to Log In
					</Link>
				</Box>
			</Box>
		</EntryScreenLayout>
	) : (
		<EntryScreenLayout title="Set New Password">
			<ResetPasswordForm handleSubmit={handleSubmit} isLoading={isLoading} errorMessage={errorMessage} />
			<Box mt={4} textAlign="center">
				<Link to={routes.signIn.getPath()} className={classes.linkText}>
					Return to Log In
				</Link>
			</Box>
		</EntryScreenLayout>
	);
};

export default ResetPassword;

import React, { useEffect, useRef, useState } from "react";

import { Box, Button, CircularProgress, Theme, Tooltip, useTheme } from "@material-ui/core";
import { Notes } from "@material-ui/icons";
import { QuizCalculator } from "@remar/shared/dist/components/QuizCalculator";
import { SimpleModal } from "@remar/shared/dist/components/SimpleModal";
import { WritingPad } from "@remar/shared/dist/components/WritingPad";
import { QuestionTypes } from "@remar/shared/dist/constants";
import { useCountdown } from "@remar/shared/dist/hooks/useCountdown";
import { useTimer } from "@remar/shared/dist/hooks/useTimer";
import { CourseChapterSectionLesson, Lesson, Question } from "@remar/shared/dist/models";

import { UserQuestionAnswerDto } from "@remar/shared/dist/services/userQuestionAnswers/dto";
import useAnalyticsEventTracker from "hooks/googleAnalytics";

import { useDispatch, useSelector } from "react-redux";
import { Prompt } from "react-router";
import { useParams } from "react-router-dom";

import { RootState } from "store";
import {
	clearNotes,
	completeCaseStudyQuestion,
	completeUserLessonAttempt,
	createUserLessonAttempt,
	createUserQuestionAttempt,
	getAttemptedCSQuestions,
	getFeedback,
	getIsLessonFinished,
	getIsQuizPassed,
	getNextLoadingState,
	getQuestionNotes,
	getQuizError,
	getQuizLessonAttempt,
	getQuizLessonAttemptId,
	getQuizLessonQuestions,
	getQuizPercentage,
	getQuizQuestionAnswers,
	getQuizResult,
	getQuizTestAnswersAlreadyAttempted,
	getUserLessonAttempt,
	moveGroupQuestionAnswerOption,
	retakeTest,
	selectFeedback,
	selectFeedbackAllowed,
	selectOptionsErrorState,
	selectQuestionNotes,
	setCSQuestions,
	setLessonFeedback,
	setOptionsError,
	setQuestionNotes,
	updateQuestionNotes,
	updateUserQuestionAttempt
} from "store/features/Lesson/lesson.slice";

import {
	BowTieQuestion,
	CaseStudyQuestion,
	ClozeDropDownQuestion,
	DragAndDropQuestion,
	DropDownTableQuestion,
	HighlightTableQuestion,
	HotspotHighlightQuestion,
	MatrixMultipleChoiceQuestion,
	MatrixSingleChoiceQuestion,
	MultipleResponseGroupQuestion
} from "modules/QuestionBank/Test";

import { OKButton, useButtonStyles } from "modules/QuestionBank/Test/styles";

import GroupingQuestion from "./GroupingQuestion";
import MultipleChoiceQuestion from "./MultipleChoiceQuestion";
import QuizResults from "./QuizResults";
import SequencingQuestion from "./SequencingQuestion";
import SingleChoiceQuestion from "./SingleChoiceQuestion";

import {
	CalculatorIcon,
	CorrectAnswersIcon,
	CountdownIcon,
	FeedbackBtn,
	FeedbackIcon,
	IncorrectAnswersIcon,
	NavigationBarLeftContainer,
	NavigationBarRightContainer,
	NextButton,
	NotesBtn,
	QuizButtonsRow,
	QuizCalculatorButton,
	QuizCountdown,
	QuizCountdownText,
	QuizLessonContainer,
	QuizLessonDescription,
	QuizLessonLessonNumber,
	QuizLessonNavigationBar,
	QuizLessonTestTitle,
	QuizLessonTitle,
	QuizPercentage,
	QuizQuestionContainer,
	QuizQuestionContent,
	QuizQuestionTitle,
	QuizResult,
	QuizResultButtons,
	QuizResultHeader,
	QuizResultSummary,
	QuizResultSummaryRow,
	QuizResultTitle
} from "./style";

interface QuizLessonProps {
	lesson: Lesson;
	sectionLessonId?: number;
	nextLesson: CourseChapterSectionLesson;
	isQuizExit?: boolean;
	isTrial?: boolean;
	isCourseComplete: boolean;
	sideEffect: () => void;
	setShowCompletionCertificate: (param) => void;
	handleNextLesson: () => void;
	certificateAlreadyDisplayed: boolean;
}

type VisibleUtility = "calculator" | "notes" | "feedback" | null;

const IsSequencingOrGrouping = questionTypeId => {
	return [QuestionTypes.Grouping, QuestionTypes.Sequencing].includes(questionTypeId);
};

const getLessonTimeRemaining = (attempt, durationInMinutes) => {
	const startDateTime = new Date(attempt?.createdAt);
	const endTime = startDateTime?.getTime() + durationInMinutes * 60 * 1000;
	const currentTime = Date.now();
	return +((endTime - currentTime) / 1000).toFixed(0);
};

const QuizLesson = ({
	lesson: { description, manualTimeLengthComponent: durationInMinutes, name },
	sectionLessonId,
	nextLesson,
	isQuizExit,
	isTrial,
	isCourseComplete,
	sideEffect,
	setShowCompletionCertificate,
	handleNextLesson,
	certificateAlreadyDisplayed
}: QuizLessonProps) => {
	const theme = useTheme<Theme>();
	const classes = useButtonStyles();

	const ref = useRef({});
	const csRef = useRef({});
	const [questionIndex, setQuestionIndex] = useState(0);
	const [caseStudyCurrentIndex, setCaseStudyCurrentIndex] = useState(0);

	const questions = useSelector(getQuizLessonQuestions);
	const [question, setQuestion] = useState<Question | null>(null);
	const [typeId, setTypeId] = useState<number | null>(null);
	const [questionId, setQuestionId] = useState<number>(0);

	const isLessonFinished = useSelector(getIsLessonFinished);
	const [isStarted, setIsStarted] = useState(true);
	const [isFinished, setIsFinished] = useState(isLessonFinished);
	const savedUserAnswers = useSelector(getQuizQuestionAnswers);
	const [userAnswers, setUserAnswers] = useState<UserQuestionAnswerDto[]>([]);
	const [showResults, setShowResults] = useState(false);
	const [resetCountdown, setResetCountdown] = useState(false);
	const [FailureModal, setFailureModal] = useState<boolean>(false);
	const quizError = useSelector(getQuizError);
	const { id: lessonId } = useParams<{ id: string; sectionId: string }>();

	const quizResult = useSelector(getQuizResult);
	const userAttemptedQuestions = useSelector(getQuizLessonQuestions);
	const dispatch = useDispatch();

	const attemptId = useSelector(getQuizLessonAttemptId);
	const lessonAttempt = useSelector(getQuizLessonAttempt);
	const quizPassed = useSelector(getIsQuizPassed);
	const quizPercentage = useSelector(getQuizPercentage);
	const attemptedCSQuestions = useSelector(getAttemptedCSQuestions);
	const isNextLoading = useSelector(getNextLoadingState);
	const [timeSpentOnLatestQuestion, setTimeSpentOnLatestQuestion] = useState(0);
	const quizTestAnswersForRef = useSelector(getQuizTestAnswersAlreadyAttempted);

	const { text, questionId: notesQuestionId, id: notesId } = useSelector(selectQuestionNotes);
	const { isAttemptLoading, isLoading, isLessonResultLoading } = useSelector(({ lesson }: RootState) => lesson);
	const [notes, setNotes] = useState(text);
	const [visibleUtility, setVisibleUtility] = useState<VisibleUtility>(null);
	const analytics = useAnalyticsEventTracker("Lesson");

	const alertUser = e => {
		e.preventDefault();
		e.returnValue = "";
	};

	useEffect(() => {
		if (isCourseComplete && !nextLesson && !certificateAlreadyDisplayed) {
			setShowCompletionCertificate(true);
		}
	}, [certificateAlreadyDisplayed, isCourseComplete, nextLesson, setShowCompletionCertificate]);

	useEffect(() => {
		if (
			!isFinished &&
			quizTestAnswersForRef &&
			ref.current !== quizTestAnswersForRef &&
			Object.keys(quizTestAnswersForRef).length > 0
		) {
			const currentIndex = Object.keys(quizTestAnswersForRef).length - 1;
			for (let i = 0; i < questions.length; i++) {
				ref.current[questions[i].id] = quizTestAnswersForRef[questions[i].id] ?? [];
			}
			setQuestionIndex(currentIndex);
			if (currentIndex == 0) {
				setQuestionId(questions[questionIndex]?.id);
				setQuestion(questions[questionIndex]);
				setTypeId(questions[questionIndex]?.typeId);
			}
		}
	}, [quizTestAnswersForRef]);

	useEffect(() => {
		if (sectionLessonId) {
			dispatch(getUserLessonAttempt({ sectionLessonId }));
		}
		setIsFinished(false);
		setShowResults(false);

		return () => {
			setOptionsError("");
			setShowResults(false);
		};
	}, [dispatch, sectionLessonId]);

	useEffect(() => {
		if (questionId) {
			ref.current[questionId] = userAnswers;
		}
	}, [userAnswers]);

	useEffect(() => {
		if (questionId) {
			setUserAnswers(ref.current[questionId] ?? []);
		}
	}, [questionId]);

	useEffect(() => {
		if (!questions.length) return;
		setQuestionId(questions[questionIndex]?.id);
		setQuestion(questions[questionIndex]);
		setTypeId(questions[questionIndex]?.typeId);
	}, [questionIndex, questions]);

	useEffect(() => {
		if (!isFinished && !resetCountdown) {
			window.onbeforeunload = alertUser;
			return () => {
				window.onbeforeunload = () => null;
			};
		} else {
			window.onbeforeunload = () => null;
		}
	}, [isFinished, resetCountdown]);

	useEffect(() => {
		if (!quizPassed && isFinished) {
			analytics({ eventName: "failed_quiz", eventIdentifier: `${sectionLessonId}` });
		} else if (quizPassed && isFinished) {
			analytics({ eventName: "completed_test_only_lesson", eventIdentifier: `${sectionLessonId}` });
		}
	}, [quizPassed, isFinished]);

	const { seconds, startOrResetTimer, stopTimer } = useTimer();

	const onExpire = () => {
		if (getLessonTimeRemaining(lessonAttempt, durationInMinutes) <= 0) {
			lessonAttempt && lessonAttempt.inProgress && dispatch(completeUserLessonAttempt({ id: attemptId, sideEffect }));
			setIsFinished(true);
			setShowFeedbackSuccess(false);
			setVisibleUtility(null);
			stopTimer();
		} else {
			startCountdown();
		}
	};

	const { secondsToDisplay, minutesToDisplay, hoursToDisplay, startCountdown, stopCountdown, handleReset } =
		useCountdown({
			totalTimeInMinutes: durationInMinutes,
			startTime: lessonAttempt?.createdAt,
			onExpire
		});

	const _feedback = useSelector(selectFeedback);
	const isFeedbackAllowed = useSelector(selectFeedbackAllowed);
	const optionError = useSelector(selectOptionsErrorState);
	const [feedback, setFeedback] = useState(_feedback);
	const [showFeedbackSuccess, setShowFeedbackSuccess] = useState(false);
	const [isNextDisabled, setIsNextDisabled] = useState(typeId === QuestionTypes.CaseStudy);

	useEffect(() => {
		if (!attemptId) {
			setIsStarted(false);
			setIsFinished(false);
			setQuestionIndex(0);
		} else {
			!resetCountdown && startCountdown();
		}
	}, [attemptId]);

	useEffect(() => {
		if (secondsToDisplay === 0 && lessonAttempt) {
			stopCountdown();
		}
	}, [secondsToDisplay, lessonAttempt]);

	useEffect(() => {
		if (attemptId) {
			dispatch(getFeedback(attemptId));
		}
	}, [attemptId, dispatch]);

	useEffect(() => {
		if (getLessonTimeRemaining(lessonAttempt, durationInMinutes) > 0 && isStarted && !isFinished) {
			startCountdown();
		}
	}, [lessonId, isStarted, isFinished, lessonAttempt?.createdAt]);

	useEffect(() => {
		setShowResults(false);
	}, [lessonId]);

	useEffect(() => {
		setNotes(text);
	}, [text, notesQuestionId]);

	useEffect(() => {
		setFeedback(_feedback);
	}, [text, attemptId]);

	useEffect(() => {
		if (attemptId && !isStarted) {
			if (
				getLessonTimeRemaining(lessonAttempt, durationInMinutes) < 0 ||
				lessonAttempt?.inProgress === false ||
				isLessonFinished
			) {
				setIsStarted(false);
				setIsFinished(true);
			} else {
				setIsStarted(true);
				setIsFinished(false);
			}
		}
	}, [
		attemptId,
		isStarted,
		typeId,
		lessonAttempt?.createdAt,
		questionId,
		lessonAttempt,
		durationInMinutes,
		isLessonFinished
	]);

	useEffect(() => {
		if (questions.length > 0) {
			const lastQuestionId = questions[questions.length - 1].id;
			if (attemptId && isStarted && savedUserAnswers![lastQuestionId]) {
				if (lessonAttempt && lessonAttempt.inProgress && !isFinished) {
					dispatch(
						completeUserLessonAttempt({
							id: attemptId,
							sideEffect
						})
					);
				}
				setIsFinished(true);
				setShowFeedbackSuccess(false);
				setVisibleUtility(null);
				stopCountdown();
			}
		}
	}, [attemptId, isStarted, savedUserAnswers, isFinished, lessonAttempt, questions, dispatch]);

	useEffect(() => {
		if (questions.length && questionIndex >= 0) {
			const questionId = questions[questionIndex]?.id;
			questionId && dispatch(getQuestionNotes(questionId));
		}
	}, [questions, questionIndex, dispatch]);

	useEffect(() => {
		if (attemptId) {
			const questionAttemptTimeSpent =
				(savedUserAnswers && savedUserAnswers[questionId]?.timeSpent) || timeSpentOnLatestQuestion;
			startOrResetTimer(questionAttemptTimeSpent);
		}
	}, [questionIndex, savedUserAnswers, questionId, attemptId]);

	useEffect(() => {
		if (!FailureModal) {
			dispatch(setOptionsError(""));
		}
	}, [FailureModal, dispatch]);

	useEffect(() => {
		if (optionError !== "") {
			setFailureModal(true);
		}
	}, [optionError]);

	useEffect(() => {
		IsSequencingOrGrouping(question?.typeId) && setIsNextDisabled(false);
	}, [question?.typeId, question?.id]);

	useEffect(() => {
		question?.typeId !== QuestionTypes.CaseStudy &&
			!IsSequencingOrGrouping(question?.typeId) &&
			getQuestionTypeValidation(question?.typeId, question, userAnswers);
	}, [question?.typeId, userAnswers, question]);

	useEffect(() => {
		ref.current[questionId] = userAnswers;
	}, [userAnswers]);

	useEffect(() => {
		if (questionId) {
			setUserAnswers(ref.current[questionId] ?? []);
		}
	}, [questionId]);

	const handleStart = () => {
		setIsStarted(true);
		if (resetCountdown) {
			window.onbeforeunload = alertUser;
			handleReset();
			setResetCountdown(resetCountdown => !resetCountdown);
		}
		!attemptId && sectionLessonId && dispatch(createUserLessonAttempt({ sectionLessonId }));
		analytics({ eventName: "started_test_only_lesson", eventIdentifier: `${sectionLessonId}` });
	};

	const moveToNextQuestion = () => {
		if (questionIndex < questions.length - 1) {
			setQuestionIndex(questionIndex + 1);
		}
	};

	const handleNext = () => {
		if (userAnswers.length > 0 || question.typeId === QuestionTypes.CaseStudy) {
			dispatch(clearNotes());
			if (question.typeId === QuestionTypes.CaseStudy) {
				const { caseStudyQuestions } = question;
				const orderedQuestions = [...caseStudyQuestions!].sort((a, b) => a.order - b.order);
				const lastQuestion = orderedQuestions[caseStudyCurrentIndex];
				const csLastQuestionAnser = csRef.current[`${caseStudyCurrentIndex}-${question.id}`];
				if (attemptId && !savedUserAnswers![lastQuestion.id]) {
					setTimeSpentOnLatestQuestion(0);
					dispatch(
						createUserQuestionAttempt({
							userLessonAttemptId: attemptId,
							questionId: question.id,
							subQuestionId: lastQuestion.id,
							userAnswers: [...csLastQuestionAnser],
							timeSpent: seconds,
							caseStudySideEffect: isError => {
								if (isError) return;
								questions[questions.length - 1].id === question.id &&
									dispatch(completeCaseStudyQuestion({ questionId: question.id }));
								if (questionIndex < questions.length - 1 && !optionError) {
									setQuestionIndex(questionIndex + 1);
								}
							}
						})
					);
				} else if (attemptId) {
					dispatch(
						updateUserQuestionAttempt({
							questionUserAttemptId: savedUserAnswers![lastQuestion.id]?.id,
							userAnswers: [...csLastQuestionAnser],
							timeSpent: seconds,
							caseStudySideEffect: isError => {
								if (isError) return;
								questions[questions.length - 1].id === question.id &&
									dispatch(completeCaseStudyQuestion({ questionId: question.id }));
								if (questionIndex < questions.length - 1 && !optionError) {
									setQuestionIndex(questionIndex + 1);
								}
							}
						})
					);
				}
			} else if (attemptId && !savedUserAnswers![questionId]) {
				setTimeSpentOnLatestQuestion(0);
				dispatch(
					createUserQuestionAttempt({
						userLessonAttemptId: attemptId,
						questionId: question.id,
						userAnswers: [...userAnswers],
						timeSpent: seconds,
						sideEffect: moveToNextQuestion
					})
				);
			} else if (attemptId) {
				dispatch(
					updateUserQuestionAttempt({
						questionUserAttemptId: savedUserAnswers![questionId]?.id,
						userAnswers: [...userAnswers],
						timeSpent: seconds,
						sideEffect: moveToNextQuestion
					})
				);
			}
		}
	};

	const handlePrev = () => {
		if (savedUserAnswers![questionId]?.id) {
			dispatch(
				updateUserQuestionAttempt({
					questionUserAttemptId: savedUserAnswers![questionId]?.id,
					userAnswers: [...userAnswers],
					timeSpent: seconds
				})
			);
		} else {
			setTimeSpentOnLatestQuestion(seconds);
		}
		dispatch(clearNotes());
		setQuestionIndex(questionIndex - 1);
	};

	if ((isStarted && questions.length === 0 && !quizError) || isAttemptLoading) {
		return null;
	}

	const resetRefIds = (ids, currentRef) => {
		ids.forEach(questionId => {
			currentRef.current[questionId] = [];
		});
	};

	const resetUserAnswers = () => {
		const questionIds = Object.keys(ref.current);
		const csQuestionIds = Object.keys(csRef.current);
		setQuestion(null);
		setTypeId(0);
		setQuestionId(0);
		setQuestionIndex(0);
		setUserAnswers([]);
		resetRefIds(questionIds, ref);
		resetRefIds(csQuestionIds, csRef);
	};

	const handleRetest = () => {
		setResetCountdown(resetCountdown => !resetCountdown);
		dispatch(retakeTest());
		resetUserAnswers();
	};

	const handleSaveNotes = () => {
		if (notes?.length > 0)
			if (notesQuestionId === questions[questionIndex].id && text.length > 0) {
				dispatch(updateQuestionNotes({ data: { text: notes }, filters: { id: notesId } }));
				analytics({ eventName: "took_notes_during_test_only_lesson", eventIdentifier: `${notesId}` });
			} else {
				dispatch(setQuestionNotes({ text: notes, questionId: questions[questionIndex].id, customTestId: undefined }));
			}
	};

	const handleSaveFeedback = () => {
		setShowFeedbackSuccess(true);
		dispatch(setLessonFeedback({ text: feedback, userLessonAttemptId: attemptId }));
	};

	const checkAllSelection = (question, options, customUserAnswers) => {
		const {
			data: { groups }
		} = question;
		const filteredGroups = groups.filter(group => group[options]);
		return filteredGroups.length !== customUserAnswers.length;
	};

	function getQuestionTypeValidation(id, question, customUserAnswers) {
		switch (id) {
			case QuestionTypes.ClozeDropDown:
			case QuestionTypes.RationalScoringDropDown:
			case QuestionTypes.DropDownTable:
				setIsNextDisabled(checkAllSelection(question, "answerOptions", customUserAnswers));
				break;
			case QuestionTypes.DragAndDrop:
			case QuestionTypes.RationalScoringDragAndDrop:
			case QuestionTypes.MatrixSingleChoice:
				setIsNextDisabled(checkAllSelection(question, "selectedAnswerOptions", customUserAnswers));
				break;
			case QuestionTypes.MatrixMultipleChoice: {
				const isAllColumnsSelected = question.data.groups.every(group =>
					customUserAnswers.some(answer => answer.groupId === group.id)
				);
				setIsNextDisabled(!isAllColumnsSelected);
				break;
			}
			case QuestionTypes.HotspotHighlight:
				setIsNextDisabled(customUserAnswers.length <= 0);
				break;
			case QuestionTypes.MultipleResponseGroup:
			case QuestionTypes.HighlightTable:
				const isAllSelected = question.data.groups.every(group =>
					customUserAnswers.some(answer => answer.groupId === group.id)
				);
				setIsNextDisabled(!isAllSelected);
				break;
			case QuestionTypes.BowTie:
				setIsNextDisabled(customUserAnswers.length < 5);
				break;
			default:
				setIsNextDisabled(customUserAnswers.length === 0);
				break;
		}
	}

	const getQuestionType = id => {
		switch (id) {
			case QuestionTypes.DropDownTable:
				return (
					<DropDownTableQuestion
						question={question}
						onChange={setUserAnswers}
						userAnswers={userAnswers}
						key={question.id}
					/>
				);
			case QuestionTypes.BowTie:
				return (
					<BowTieQuestion
						question={question}
						onChange={setUserAnswers}
						userAnswers={userAnswers}
						key={question.id}
						answersRef={ref}
					/>
				);
			case QuestionTypes.MultipleResponseGroup:
				return (
					<MultipleResponseGroupQuestion
						question={question}
						onChange={setUserAnswers}
						userAnswers={userAnswers}
						key={question.id}
					/>
				);
			case QuestionTypes.HighlightTable:
				return (
					<HighlightTableQuestion
						question={question}
						onChange={setUserAnswers}
						key={question.id}
						userAnswers={userAnswers}
					/>
				);
			case QuestionTypes.MatrixMultipleChoice:
				return (
					<MatrixMultipleChoiceQuestion
						key={question.id}
						question={question}
						onChange={setUserAnswers}
						userAnswers={userAnswers}
					/>
				);
			case QuestionTypes.DragAndDrop:
			case QuestionTypes.RationalScoringDragAndDrop:
				return (
					<DragAndDropQuestion
						question={question}
						onChange={setUserAnswers}
						userAnswers={userAnswers}
						answersRef={ref}
						key={question.id}
					/>
				);
			case QuestionTypes.HotspotHighlight:
				return (
					<HotspotHighlightQuestion
						question={question}
						onChange={setUserAnswers}
						key={question.id}
						userAnswers={userAnswers}
					/>
				);
			case QuestionTypes.ClozeDropDown:
			case QuestionTypes.RationalScoringDropDown:
				return <ClozeDropDownQuestion question={question} onChange={setUserAnswers} userAnswers={userAnswers} />;
			case QuestionTypes.CaseStudy:
				return (
					<CaseStudyQuestion
						question={question}
						key={`case-study-${questionIndex}`}
						getCSValidation={getQuestionTypeValidation}
						questionIndex={questionIndex}
						csRef={csRef}
						attemptId={attemptId}
						setCSQuestions={setCSQuestions}
						attemptedCSQuestions={attemptedCSQuestions}
						loading={isNextLoading}
						setCaseStudyCurrentIndex={setCaseStudyCurrentIndex}
					/>
				);
			case QuestionTypes.MatrixSingleChoice:
				return (
					<MatrixSingleChoiceQuestion
						question={question}
						onChange={setUserAnswers}
						userAnswers={userAnswers}
						key={question.id}
					/>
				);
			case QuestionTypes.SingleChoice:
				return <SingleChoiceQuestion question={question} onChange={setUserAnswers} userAnswers={userAnswers} />;
			case QuestionTypes.MultipleChoiceSN:
			case QuestionTypes.MultipleChoiceSATA:
			case QuestionTypes.MultipleChoice:
				return <MultipleChoiceQuestion question={question} onChange={setUserAnswers} userAnswers={userAnswers} />;
			case QuestionTypes.Grouping:
				return (
					<GroupingQuestion
						question={question}
						questionIndex={questionIndex}
						uniqueIdentifier={`${isStarted}-${attemptId}-${id}`}
						dispatchAction={moveGroupQuestionAnswerOption}
						setUserAnswers={setUserAnswers}
						answersRef={ref}
					/>
				);
			case QuestionTypes.Sequencing:
				return (
					<SequencingQuestion
						question={question}
						onChange={setUserAnswers}
						userAnswers={userAnswers}
						answersRef={ref}
					/>
				);
			default:
				return null;
		}
	};

	if (isLoading || isAttemptLoading || isLessonResultLoading) {
		return (
			<Box display="flex" alignItems="center" justifyContent="center" height={500} width="100%">
				<CircularProgress size="7rem" color="primary" thickness={5} variant="indeterminate" />
			</Box>
		);
	}

	return (
		<>
			<QuizLessonContainer>
				<Prompt when={!isFinished && isQuizExit} message="A test is in progress. Are you sure you want to leave?" />
				{showResults ? (
					<QuizResults
						results={quizResult}
						onClose={() => {
							analytics({ eventName: "hit_close_extended_results", eventIdentifier: lessonId });
							setShowResults(false);
						}}
						onNextLesson={handleNextLesson}
						showNextLesson={!!nextLesson}
						userAttemptedQuestions={userAttemptedQuestions}
					/>
				) : !isStarted && !isFinished ? (
					<>
						<QuizLessonTitle>{name}</QuizLessonTitle>
						<QuizLessonDescription>{description}</QuizLessonDescription>
						<Button variant="contained" color="primary" onClick={handleStart}>
							Start the Quiz
						</Button>
					</>
				) : quizError ? (
					quizError
				) : !isFinished ? (
					<>
						<QuizLessonTestTitle>{name}</QuizLessonTestTitle>
						<QuizLessonNavigationBar>
							<NavigationBarLeftContainer>
								<NotesBtn onClick={() => setVisibleUtility("notes")}>
									<Notes style={{ width: "20px", height: "20px", fill: "#b6e0fe" }} />
									Notes
								</NotesBtn>
								<QuizCalculatorButton onClick={() => setVisibleUtility("calculator")}>
									<CalculatorIcon /> Calculator
								</QuizCalculatorButton>
								<FeedbackBtn onClick={() => setVisibleUtility("feedback")} disabled={!isFeedbackAllowed}>
									<Tooltip title="Feedback was already sent" disableHoverListener={!isFeedbackAllowed}>
										<>
											<FeedbackIcon /> Feedback
										</>
									</Tooltip>
								</FeedbackBtn>
							</NavigationBarLeftContainer>
							<NavigationBarRightContainer>
								<QuizLessonLessonNumber>
									{questionIndex + 1}
									<span> / {questions.length}</span>
								</QuizLessonLessonNumber>
								<QuizCountdown>
									<CountdownIcon />
									<QuizCountdownText>Time Left</QuizCountdownText> {hoursToDisplay}:
									{minutesToDisplay.toString().length < 2
										? String(minutesToDisplay).padStart(2, "0")
										: minutesToDisplay}
									:{String(secondsToDisplay).padStart(2, "0")}
								</QuizCountdown>
							</NavigationBarRightContainer>
						</QuizLessonNavigationBar>
						<QuizQuestionContainer>
							<QuizQuestionContent>
								<QuizQuestionTitle>Question {questionIndex + 1}</QuizQuestionTitle>
								{question && getQuestionType(question.typeId)}
							</QuizQuestionContent>
							{visibleUtility === "calculator" && (
								<QuizCalculator onClose={() => setVisibleUtility(null)} backgroundColor={"#eceff4"} />
							)}
							{visibleUtility === "notes" && (
								<WritingPad
									title="Notes"
									placeholder="Write Your Notes Here..."
									text={notes}
									onClose={() => setVisibleUtility(null)}
									onTextChange={setNotes}
									onSave={handleSaveNotes}
									saveOnBlur={true}
									backgroundColor={"#eceff4"}
								/>
							)}
							{visibleUtility === "feedback" && isFeedbackAllowed && (
								<WritingPad
									title="Feedback"
									placeholder="Write Your Feedback Here..."
									text={feedback}
									onClose={() => setVisibleUtility(null)}
									onTextChange={setFeedback}
									onSave={() => handleSaveFeedback()}
									disabled={showFeedbackSuccess}
									buttonText="Send"
									backgroundColor={"#eceff4"}
								/>
							)}
						</QuizQuestionContainer>
						<QuizButtonsRow>
							{questionIndex > 0 && (
								<Button variant="contained" onClick={handlePrev}>
									Previous Question
								</Button>
							)}
							<NextButton
								variant="contained"
								color="primary"
								onClick={handleNext}
								disabled={isNextDisabled || isNextLoading}
								classes={{ disabled: classes.disabledButton, containedPrimary: classes.testContainedPrimary }}
							>
								{isNextLoading && <CircularProgress size={20} color="inherit" />}
								{questionIndex === questions.length - 1 ? "Finish" : "Next Question"}
							</NextButton>
						</QuizButtonsRow>
					</>
				) : (
					<QuizResult>
						<QuizResultHeader>{quizPassed ? "Test passed" : "Test failed"}</QuizResultHeader>
						<QuizResultTitle>
							{quizPassed ? (
								<>
									Congrats!
									<br />
									You have Passed the test
								</>
							) : (
								<>
									Try Again!
									<br />
									You have Failed the test
								</>
							)}
						</QuizResultTitle>
						<QuizResultSummary>
							<QuizPercentage passed={quizPassed}>
								<div>Your score:</div>
								{quizPercentage}%
							</QuizPercentage>
							<div>
								<QuizResultSummaryRow>
									<CorrectAnswersIcon /> {quizResult.filter(({ isCorrect }) => isCorrect).length} questions correct
								</QuizResultSummaryRow>
								<QuizResultSummaryRow>
									<IncorrectAnswersIcon /> {quizResult.filter(({ isCorrect }) => !isCorrect).length} questions incorrect
								</QuizResultSummaryRow>
							</div>
						</QuizResultSummary>
						<QuizResultButtons>
							{quizPassed && (
								<Box display="flex" flexDirection="row" justifyContent="center">
									<Box>
										<Button
											variant="contained"
											color="secondary"
											onClick={() => {
												setShowResults(true);
												analytics({ eventName: "check_results_after_test", eventIdentifier: `${sectionLessonId}` });
											}}
										>
											View results
										</Button>
									</Box>
									{isCourseComplete && quizPassed && !isTrial && (
										<Box ml={2}>
											<Button variant="contained" color="primary" onClick={() => setShowCompletionCertificate(true)}>
												View Completion Certificate
											</Button>
										</Box>
									)}
									{nextLesson && (
										<Box ml={2}>
											<Button
												variant="contained"
												color="primary"
												onClick={() => {
													handleNextLesson();
													analytics({
														eventName: "click_next_lesson_from_results",
														eventIdentifier: `${nextLesson.id}`
													});
												}}
											>
												Next lesson
											</Button>
										</Box>
									)}
								</Box>
							)}
							{!quizPassed && (
								<Button
									variant="contained"
									color="primary"
									onClick={() => {
										analytics({
											eventName: "retake_test_after_failiure",
											eventIdentifier: `${lessonId}`
										});
										handleRetest();
									}}
								>
									Retake test
								</Button>
							)}
						</QuizResultButtons>
					</QuizResult>
				)}
			</QuizLessonContainer>
			<SimpleModal
				theme={theme}
				title="Please select options again"
				open={FailureModal}
				onClose={() => setFailureModal(false)}
				text={optionError}
				footer={
					<OKButton size="medium" onClick={() => setFailureModal(false)}>
						Ok
					</OKButton>
				}
			/>
		</>
	);
};

export default QuizLesson;

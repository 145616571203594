import React, { RefObject, useEffect, useRef } from "react";

import {
	Box,
	Button,
	Card,
	CircularProgress,
	Container,
	Grid,
	IconButton,
	MenuItem,
	Typography,
	useMediaQuery,
	useTheme
} from "@material-ui/core";

import CloseIcon from "@material-ui/icons/Close";
import { IExtendedTheme } from "@remar/shared/dist/theme/default";
import { validZipCodeCharacter } from "@remar/shared/dist/utils/serviceUtils/validators";
import { Field, FieldInputProps, FieldMetaProps, Form, Formik, FormikProps } from "formik";
import { TextField as MuiTextField } from "formik-material-ui";
import { useDispatch, useSelector } from "react-redux";
import { RootState, useAppSelector } from "store";

import { fetchCountries } from "store/features/Auth/authSlice";

import { ModalTitleText } from "modules/MyAccount/styles";

import { BillingAddressSchema } from "./schemas";
import { useChangePasswordStyles } from "./styles";

const TextField = ({
	inputRef,
	...props
}: {
	field: FieldInputProps<unknown>;
	form: FormikProps<unknown>;
	meta: FieldMetaProps<unknown>;
	[fieldName: string]: unknown;
}) => (
	<MuiTextField
		InputProps={{ disableUnderline: true, ref: inputRef as RefObject<unknown> }}
		variant="filled"
		{...props}
	/>
);

const CountrySelect = ({
	inputRef,
	...props
}: {
	field: FieldInputProps<unknown>;
	form: FormikProps<unknown>;
	meta: FieldMetaProps<unknown>;
	[fieldName: string]: unknown;
}) => {
	const { countries, isLoading } = useSelector((state: RootState) => state.auth);
	return (
		<MuiTextField
			InputProps={{ disableUnderline: true, ref: inputRef as RefObject<unknown> }}
			variant="filled"
			select
			onChange={e => {
				props.field.onChange(e);
			}}
			{...props}
			label={props.field.value ? "" : "Country"}
		>
			{isLoading || countries.length === 0 ? (
				<MenuItem key={""} value={""} disabled>
					{isLoading ? "Loading..." : "Countries"}
				</MenuItem>
			) : null}
			{countries.map(country => (
				<MenuItem key={country?.code} value={country?.id}>
					{country?.name}
				</MenuItem>
			))}
		</MuiTextField>
	);
};

const ChangeBillingAddress = ({ handleSubmit, closeModal }) => {
	const theme = useTheme<IExtendedTheme>();
	const { userInfo } = useAppSelector((store: RootState) => store.myAccount);
	const classes = useChangePasswordStyles();
	const { error, isLoading } = useSelector((state: RootState) => state.billing);
	const dispatch = useDispatch();
	const isMobile = useMediaQuery(theme.breakpoints.down("xs"));

	const ref = useRef<HTMLDivElement>(null);

	const initialFormValues = {
		fullName: userInfo!.shippingDetails?.fullName || userInfo!.firstName + userInfo!.lastName || "",
		countryId: userInfo!.shippingDetails?.country?.id || "",
		address1: userInfo!.shippingDetails?.address1 || "",
		address2: userInfo!.shippingDetails?.address2 || "",
		city: userInfo!.shippingDetails?.city || "",
		state: userInfo!.shippingDetails?.state || "",
		zipCode: userInfo!.shippingDetails?.zipCode || ""
	};
	const { subscriptionTypes, userSubscriptionTypeId }: RootState["auth"] = useAppSelector(
		(store: RootState) => store.auth
	);

	useEffect(() => {
		const subType = subscriptionTypes.find(
			({ isActive, isRecurring, isTrail, nextSubscriptionTypeId }) =>
				isActive && !isTrail && !isRecurring && nextSubscriptionTypeId === userSubscriptionTypeId
		);
		dispatch(fetchCountries(subType?.id as number));
		const elem = ref.current;
		if (elem) {
			const i = elem.querySelector("input");
			i?.focus();
		}
	}, [dispatch, subscriptionTypes, userSubscriptionTypeId, ref?.current]);

	return (
		<Container maxWidth="sm">
			<Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" height="100vh">
				<Card>
					<Formik
						initialValues={initialFormValues}
						validationSchema={BillingAddressSchema}
						onSubmit={values => {
							handleSubmit(values);
						}}
					>
						{({ isValid, values, dirty }) => {
							return (
								<>
									<Box display="flex" justifyContent="space-between" alignItems="center" mb={5}>
										<ModalTitleText>Change Billing Address</ModalTitleText>
										<IconButton onClick={closeModal}>
											<CloseIcon color="disabled" />
										</IconButton>
									</Box>
									<Form>
										<Box mt={3}>
											<Box>
												<Grid container spacing={3}>
													<Grid item xs={12}>
														<Field inputRef={ref} hiddenLabel component={CountrySelect} name="countryId" fullWidth />
													</Grid>
													<Grid item xs={12}>
														<Field
															hiddenLabel
															component={TextField}
															name="fullName"
															placeholder="Full Name"
															fullWidth
														/>
													</Grid>
													<Grid item xs={12}>
														<Field
															hiddenLabel
															component={TextField}
															name="address1"
															placeholder="Address Line 1"
															fullWidth
														/>
													</Grid>
													<Grid item xs={12}>
														<Field hiddenLabel component={TextField} name="address2" placeholder="Apt #" fullWidth />
													</Grid>
													<Grid item xs={12} sm={5}>
														<Field hiddenLabel component={TextField} name="city" placeholder="City" fullWidth />
													</Grid>
													<Grid item xs={6} sm={3}>
														<Field hiddenLabel component={TextField} name="state" placeholder="State" fullWidth />
													</Grid>
													<Grid item xs={6} sm={4}>
														<Field
															hiddenLabel
															component={TextField}
															name="zipCode"
															inputProps={{ maxLength: 10 }}
															placeholder="Zip code"
															onKeyPress={(event: React.KeyboardEvent) => {
																if (!(event.key.length === 1 && validZipCodeCharacter(event.key))) {
																	event.preventDefault();
																}
															}}
															fullWidth
														/>
													</Grid>
												</Grid>
											</Box>
										</Box>
									</Form>

									{error && (
										<Box mt={3}>
											<Typography variant="caption" style={{ color: "red" }}>
												{error}
											</Typography>
										</Box>
									)}
									<Box
										display="flex"
										justifyContent="flex-end"
										flexDirection={isMobile ? "column-reverse" : "row"}
										mt={3}
									>
										<Button
											className={classes.cancelButton}
											fullWidth={isMobile}
											color="secondary"
											variant="contained"
											onClick={closeModal}
										>
											Cancel
										</Button>
										{isMobile && <Box width="100%" mt={1} />}
										<Button
											color="primary"
											disabled={!isValid || !dirty || isLoading}
											variant="contained"
											fullWidth={isMobile}
											onClick={() => handleSubmit(values)}
										>
											{isLoading ? (
												<Box width={50}>
													<CircularProgress size="1rem" color="inherit" thickness={2} variant="indeterminate" />
												</Box>
											) : (
												"Save"
											)}
										</Button>
									</Box>
								</>
							);
						}}
					</Formik>
				</Card>
			</Box>
		</Container>
	);
};
export default ChangeBillingAddress;

import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { ExternalIntegrationIds, UserPaymentType } from "@remar/shared/dist/constants";
import { IBaseState, PaymentHistory } from "@remar/shared/dist/models";
import { fulfilledReducer, pendingReducer, rejectReducer } from "@remar/shared/dist/utils/reducerHelpers";
import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js";
import isEmpty from "lodash/isEmpty";
import { RootState } from "store";
import { UpdateBillingAddressDto, usersService } from "store/services";
import Stripe from "stripe";

import { getUserInfo } from "../MyAccount/myAccountSlice";

import { emit } from "../notifications/notifications.slice";

export interface CardPaymentMethod extends Stripe.PaymentMethod.Card {
	id: string;
}

interface BillingState extends IBaseState {
	paymentMethods?: CardPaymentMethod;
	paymentHistory: PaymentHistory;
}
const initialState: BillingState = {
	isLoading: false,
	error: "",
	paymentMethods: undefined,
	paymentHistory: {
		isLoading: false,
		payments: null,
		errorMessage: "",
		page: 1,
		perPage: 10,
		totalItems: 0
	}
};

interface PaymentUpdateThunk {
	CardElement: typeof CardElement;
	elements: ReturnType<typeof useElements>;
	stripe: ReturnType<typeof useStripe>;
	// eslint-disable-next-line no-unused-vars
	sideEffect: (error: string) => void;
}

export const getPaymentDetails = createAsyncThunk("billing/payment-methods", async (_, { rejectWithValue }) => {
	return await usersService
		.getCurrentSubscriptionPaymentMethodData({
			paymentSourceExternalIntegrationId: ExternalIntegrationIds.Stripe, // this is the Stripe integration ID, which is 1
			withPaymentSource: true // this lets the BE know we want the Stripe card data
		})
		.catch(e => rejectWithValue(e.message));
});

export const getPaymentHistory = createAsyncThunk(
	"billing/payment-history",
	async ({ page: optPage }: { page?: number }, { rejectWithValue, getState }) => {
		const {
			paymentHistory: { page }
		} = (getState() as { billing: BillingState }).billing;
		return await usersService
			.getPaymentHistory({
				page: optPage || page,
				orderBy: { createdAt: "ASC" }
			})
			.catch(e => rejectWithValue(e.message));
	}
);

export const updatePaymentMethod = createAsyncThunk(
	"billing/update-payment-method",
	async (
		{ CardElement, elements, stripe, sideEffect }: PaymentUpdateThunk,
		{ dispatch, rejectWithValue }
	): Promise<unknown | void> => {
		dispatch(setError(""));
		const cardElement = elements?.getElement(CardElement);

		if (stripe && cardElement) {
			const { error, paymentMethod } = await stripe.createPaymentMethod({ type: "card", card: cardElement });

			if (error) {
				sideEffect("Please check your card details");
			} else if (paymentMethod) {
				try {
					await usersService.updatePaymentMethod({
						paymentProviderPaymentMethodIdentifier: paymentMethod!.id
					});
					sideEffect("");
				} catch (e) {
					sideEffect(e.message);
					return rejectWithValue(e.message);
				}
				return {};
			}
		}
	}
);

export const updateBillingAddress = createAsyncThunk(
	"billing/update-billing-details",
	async (
		{ payload, sideEffect = () => {} }: { payload: UpdateBillingAddressDto; sideEffect: () => void },
		{ rejectWithValue, dispatch }
	): Promise<void> => {
		dispatch(setError(""));
		await usersService
			.updateBillingAddress(payload)
			.then(() => {
				dispatch(
					emit({
						message: "Billing Address Updated",
						color: "success"
					})
				);
				dispatch(getUserInfo());
			})
			.catch(e => {
				dispatch(emit({ message: e.message, color: "error" }));
				return rejectWithValue(e.message);
			})
			.finally(sideEffect);
	}
);

export const BillingSlice = createSlice({
	name: "billing",
	initialState,
	reducers: {
		setError: (state, action: PayloadAction<string>) => {
			state.error = action.payload;
		}
	},
	extraReducers: {
		[getPaymentDetails.pending.type]: pendingReducer,
		[getPaymentDetails.fulfilled.type]: (state, { payload: { paymentSource } }) => {
			state.paymentMethods = paymentSource;
			state.isLoading = false;
		},
		[getPaymentDetails.rejected.type]: rejectReducer,
		[getPaymentHistory.pending.type]: state => {
			state.paymentHistory.isLoading = true;
		},
		[getPaymentHistory.fulfilled.type]: (state, { payload: { items, page, perPage, totalItems } }) => {
			state.paymentHistory.payments = items.map(
				({
					amount,
					book,
					courseCATConfiguration,
					type,
					userPayment: { billingDate, invoiceUrl, shippingData },
					userPaymentTypeId
				}) => ({
					billingDate,
					invoiceUrl,
					amount,
					shippingDetails: isEmpty(shippingData) ? "Digital Book" : `order# - ${shippingData?.value?.id}`,
					type: {
						name:
							userPaymentTypeId === UserPaymentType.Subscription //todo: Make helper because it's used several times in the project
								? type?.allowedCourses[0].name ?? "Subscription"
								: userPaymentTypeId === UserPaymentType.CAT
								? courseCATConfiguration.course.name
								: userPaymentTypeId === UserPaymentType.Book || UserPaymentType.InvitedUser
								? book?.data?.name ?? "-"
								: "-"
					}
				})
			);

			state.paymentHistory.page = page;
			state.paymentHistory.perPage = perPage;
			state.paymentHistory.totalItems = totalItems;
			state.paymentHistory.isLoading = false;
		},
		[getPaymentHistory.rejected.type]: (state, { payload }) => {
			state.paymentHistory.errorMessage = payload;
			state.paymentHistory.isLoading = false;
		},
		[updatePaymentMethod.pending.type]: pendingReducer,
		[updatePaymentMethod.fulfilled.type]: fulfilledReducer,
		[updatePaymentMethod.rejected.type]: rejectReducer,
		[updateBillingAddress.pending.type]: pendingReducer,
		[updateBillingAddress.fulfilled.type]: fulfilledReducer,
		[updateBillingAddress.rejected.type]: rejectReducer
	}
});

export const getFullState = (state: RootState): BillingState => state.billing;

export const { setError } = BillingSlice.actions;

export default BillingSlice.reducer;

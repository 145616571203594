import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";

import { ActionCreatorWithPayload } from "@reduxjs/toolkit";
import { QuestionTypes } from "@remar/shared/dist/constants";
import { useTimer } from "@remar/shared/dist/hooks/useTimer";
import { UserQuestionAnswerDto } from "@remar/shared/dist/services/userQuestionAnswers/dto";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import {
	createUserQuestionAttempt,
	createUserQuizAttempt,
	getQuizQuestionAnswers,
	updateUserQuestionAttempt
} from "store/features/Test/testAttempt.slice";

import MultipleChoiceQuestion from "modules/Lesson/MultipleChoiceQuestion";
import SingleChoiceQuestion from "modules/Lesson/SingleChoiceQuestion";
import {
	CaseStudyAlertContainer,
	CaseStudyAlertTitle,
	CaseStudyColumn,
	CaseStudyRow,
	CaseStudyTab,
	CaseStudyTabContent,
	CaseStudyTabs,
	Page,
	Pages,
	StyledError
} from "modules/Lesson/style";

import { CSQuestionTitle, TestQuestionStyledContainer, TestQuizQuestionText } from "modules/QuestionBank/styles";

import {
	ClozeDropDownQuestion,
	DragAndDropQuestion,
	DropDownTableQuestion,
	HighlightTableQuestion,
	HotspotHighlightQuestion,
	MatrixMultipleChoiceQuestion,
	MatrixSingleChoiceQuestion,
	MultipleResponseGroupQuestion
} from "./index";

interface IProps {
	question;
	questionIndex: number;
	csRef: React.MutableRefObject<{}>;
	attemptId?: number;
	setCSQuestions: ActionCreatorWithPayload<{ questionIndex: number; id: number }, string>;
	loading: boolean;
	setIsNextDisabled: React.Dispatch<React.SetStateAction<boolean>>;
}

const CaseStudyQuestionForTests = ({
	question,
	questionIndex: currentQuestionIndex,
	csRef,
	attemptId = undefined,
	setCSQuestions,
	loading,
	setIsNextDisabled
}: IProps) => {
	const {
		data: { tabs },
		caseStudyQuestions: questions = [],
		description
	} = question;

	const orderedQuestions = useMemo(() => [...questions].sort((a, b) => a.order - b.order), [questions]);

	const dispatch = useDispatch();
	const [activeTab, setActiveTab] = useState(0);
	const [activeQuestionIndex, setActiveQuestionIndex] = useState(0);
	const [activeQuestionForChange, setActiveQuestionForChange] = useState(0);
	const currentQuestion = orderedQuestions[activeQuestionIndex];
	const currentTest = questions[activeQuestionIndex];
	const savedUserAnswers = useSelector(getQuizQuestionAnswers);

	const [userAnswers, setUserAnswers] = useState<UserQuestionAnswerDto[]>(
		savedUserAnswers![currentQuestion.id]?.answers ?? []
	);
	const [timeSpentOnLatestQuestion, setTimeSpentOnLatestQuestion] = useState(0);

	const [asyncUserAnswers, setAsyncUserAnswers] = useState<
		| {
				argument: UserQuestionAnswerDto[];
		  }
		| undefined
	>(undefined);

	const { id: testId } = useParams<{ id: string }>();

	const { seconds, startOrResetTimer } = useTimer();

	const asyncUserAnswersQueue = useRef<
		{
			argument: UserQuestionAnswerDto[];
			isUsed: boolean;
		}[]
	>([]);

	useEffect(() => {
		dispatch(
			setCSQuestions({
				questionIndex: currentQuestionIndex,
				id: currentQuestion?.id
			})
		);
	}, [activeQuestionIndex]);

	useEffect(() => {
		if (testId) {
			const questionAttemptTimeSpent =
				(savedUserAnswers && savedUserAnswers[currentQuestion.id]?.timeSpent) || timeSpentOnLatestQuestion;
			startOrResetTimer(questionAttemptTimeSpent);
		}
	}, [activeQuestionIndex, savedUserAnswers, currentQuestion.id, testId]);

	useEffect(() => {
		setUserAnswers(csRef.current[`${activeQuestionIndex}-${question.id}`] ?? []);
	}, [activeQuestionIndex, question.id]);

	useEffect(() => {
		setActiveTab(0);
		setActiveQuestionIndex(0);
	}, [question.id]);

	useEffect(() => {
		csRef.current[`${activeQuestionIndex}-${question.id}`] = userAnswers;
		csRef.current["questionId"] = `${activeQuestionIndex}-${question.id}`;
	}, [userAnswers]);

	useEffect(() => {
		if (currentQuestion.id && userAnswers.length === 0) {
			//  gets initial value from savedUserAnswers, should be set initially only
			//  as "setUserAnswers" is set when user changes answer in "handleOnChange"
			setUserAnswers(savedUserAnswers![currentQuestion.id]?.answers || []);
		}
	}, [currentQuestion.id, setUserAnswers, savedUserAnswers]);

	useEffect(() => {
		const findIndex = questions.findIndex(({ id }) => !savedUserAnswers?.[id]);
		setActiveQuestionIndex(findIndex > -1 ? findIndex : questions.length - 1);
	}, []);

	useEffect(() => {
		const findIndex = questions.findIndex(({ id }) => !savedUserAnswers?.[id]);
		setIsNextDisabled(findIndex > -1);
	}, [questions, savedUserAnswers, setIsNextDisabled, currentQuestion]);

	useEffect(() => {
		if (asyncUserAnswers?.argument.length) {
			handleChangeAnswers(asyncUserAnswers.argument);
		}
	}, [asyncUserAnswers]);

	useEffect(() => {
		if (activeQuestionIndex !== activeQuestionForChange && !loading) {
			setUserAnswers([]);
			setActiveQuestionIndex(activeQuestionForChange);
		}
	}, [loading, activeQuestionForChange]);

	const removeQueueItem = () => {
		// clears queue
		if (asyncUserAnswersQueue.current.length === 1) {
			asyncUserAnswersQueue.current = asyncUserAnswersQueue.current.filter(f => !f.isUsed);
			asyncUserAnswersQueue.current.shift();
		} else if (asyncUserAnswersQueue.current.length !== 0) {
			// if queue has more than one item, the last item will be considered and set `setAsyncUserAnswers` which will
			// trigger a useEffect and calls `handleChangeAnswers` with the last option chosen by the user
			// old options chosen by the user will be ignored
			asyncUserAnswersQueue.current[asyncUserAnswersQueue.current.length - 1].isUsed = true;
			setAsyncUserAnswers(asyncUserAnswersQueue.current[asyncUserAnswersQueue.current.length - 1]);
			asyncUserAnswersQueue.current = [];
		}
	};

	const handleChangeAnswers = useCallback(
		(answers: UserQuestionAnswerDto[]) => {
			if (answers.length > 0) {
				if (savedUserAnswers![currentQuestion?.id]) {
					if (currentTest?.id) {
						dispatch(
							updateUserQuestionAttempt({
								userQuizAttemptId: savedUserAnswers![currentQuestion.id]?.id,
								userAnswers: answers,
								timeSpent: seconds,
								caseStudySideEffect: removeQueueItem
							})
						);
					} else if (attemptId) {
						dispatch(
							createUserQuestionAttempt({
								userQuizAttemptId: savedUserAnswers![currentQuestion?.id].id,
								userAnswers: answers,
								timeSpent: seconds,
								caseStudySideEffect: removeQueueItem,
								questionId: question.id
							})
						);
					}
				} else {
					setTimeSpentOnLatestQuestion(0);
					if (attemptId) {
						dispatch(
							createUserQuestionAttempt({
								userQuizAttemptId: attemptId,
								questionId: question.id,
								subQuestionId: currentQuestion.id,
								userAnswers: answers,
								timeSpent: seconds,
								caseStudySideEffect: removeQueueItem
							})
						);
					} else if (!attemptId) {
						testId && dispatch(createUserQuizAttempt({ quizId: testId }));
					}
				}
			}
		},
		[attemptId, currentQuestion.id, currentTest?.id, dispatch, question.id, savedUserAnswers, seconds, testId]
	);

	const handleOnChange = useCallback(
		answers => {
			if (answers.length > 0) {
				setUserAnswers(answers);
				asyncUserAnswersQueue.current.push({
					argument: answers as UserQuestionAnswerDto[],
					isUsed: false
				});
				// "asyncUserAnswersQueue" is a queue that stops the execution of another change until
				// the response of the first change arrives from the  API
				if (asyncUserAnswersQueue.current.filter(d => !d.isUsed).length === 1) {
					handleChangeAnswers(asyncUserAnswersQueue.current[0].argument);
				}
			}
		},
		[handleChangeAnswers]
	);

	const getQuestion = useCallback(
		question => {
			const uniqueIdentifier = `case-study-question-${question.id}`;

			switch (question.typeId) {
				case QuestionTypes.MatrixSingleChoice:
					return (
						<MatrixSingleChoiceQuestion
							question={question}
							onChange={handleOnChange}
							userAnswers={userAnswers}
							key={uniqueIdentifier}
						/>
					);
				case QuestionTypes.MultipleChoiceSN:
				case QuestionTypes.MultipleChoiceSATA:
					return (
						<MultipleChoiceQuestion
							question={question}
							onChange={handleOnChange}
							userAnswers={userAnswers}
							key={uniqueIdentifier}
						/>
					);
				case QuestionTypes.MultipleResponseGroup:
					return (
						<MultipleResponseGroupQuestion
							question={question}
							onChange={handleOnChange}
							userAnswers={userAnswers}
							key={uniqueIdentifier}
						/>
					);
				case QuestionTypes.DragAndDrop:
				case QuestionTypes.RationalScoringDragAndDrop:
					return (
						<DragAndDropQuestion
							question={question}
							onChange={handleOnChange}
							userAnswers={userAnswers}
							answersRef={csRef}
							key={uniqueIdentifier}
						/>
					);
				case QuestionTypes.DropDownTable:
					return (
						<DropDownTableQuestion
							question={question}
							onChange={handleOnChange}
							userAnswers={userAnswers}
							key={uniqueIdentifier}
						/>
					);
				case QuestionTypes.MatrixMultipleChoice:
					return (
						<MatrixMultipleChoiceQuestion
							question={question}
							onChange={handleOnChange}
							userAnswers={userAnswers}
							key={uniqueIdentifier}
						/>
					);
				case QuestionTypes.ClozeDropDown:
				case QuestionTypes.RationalScoringDropDown:
					return (
						<ClozeDropDownQuestion
							question={question}
							onChange={handleOnChange}
							userAnswers={userAnswers}
							key={uniqueIdentifier}
						/>
					);
				case QuestionTypes.SingleChoice:
					return (
						<SingleChoiceQuestion
							question={question}
							onChange={handleOnChange}
							userAnswers={userAnswers}
							key={uniqueIdentifier}
						/>
					);
				case QuestionTypes.HotspotHighlight:
					return (
						<HotspotHighlightQuestion
							question={question}
							onChange={handleOnChange}
							key={uniqueIdentifier}
							userAnswers={userAnswers}
						/>
					);
				case QuestionTypes.HighlightTable:
					return (
						<HighlightTableQuestion
							question={question}
							onChange={handleOnChange}
							key={uniqueIdentifier}
							userAnswers={userAnswers}
						/>
					);
				default:
					return null;
			}
		},
		[userAnswers, handleOnChange]
	);

	const checkAllSelectionHandle = (question, options) => {
		const filteredGroups = question.data?.groups?.filter(group => group[options]) || [];
		return filteredGroups.length !== userAnswers.length;
	};

	const getQuestionTypeValidation = id => {
		switch (id) {
			case QuestionTypes.ClozeDropDown:
			case QuestionTypes.RationalScoringDropDown:
			case QuestionTypes.DropDownTable:
				return checkAllSelectionHandle(currentQuestion, "answerOptions");
			case QuestionTypes.DragAndDrop:
			case QuestionTypes.RationalScoringDragAndDrop:
			case QuestionTypes.MatrixSingleChoice:
				return checkAllSelectionHandle(currentQuestion, "selectedAnswerOptions");
			case QuestionTypes.MatrixMultipleChoice:
				return !currentQuestion.data.groups.every(group => userAnswers.some(answer => answer.groupId === group.id));
			case QuestionTypes.HotspotHighlight:
				return userAnswers.length <= 0;
			case QuestionTypes.MultipleResponseGroup:
			case QuestionTypes.HighlightTable:
				return !currentQuestion.data?.groups?.every(group => userAnswers.some(answer => answer.groupId === group.id));
			default:
				return userAnswers.length === 0;
		}
	};

	const handleChangeQuestion = questionIndex => {
		const isDisabled = getQuestionTypeValidation(currentQuestion.typeId);
		if ((isDisabled || asyncUserAnswersQueue.current.length !== 0) && questionIndex > activeQuestionIndex) {
			return;
		}

		!loading && setActiveQuestionForChange(questionIndex);
	};

	return (
		<>
			<CaseStudyRow>
				<CaseStudyColumn>
					<TestQuizQuestionText dangerouslySetInnerHTML={{ __html: description }}></TestQuizQuestionText>

					<CaseStudyTabs>
						{tabs.map(({ title, id }, tabIndex) => (
							<CaseStudyTab key={`tab-${id}`} active={tabIndex === activeTab} onClick={() => setActiveTab(tabIndex)}>
								{title}
							</CaseStudyTab>
						))}
					</CaseStudyTabs>
					{activeTab !== undefined && (
						<CaseStudyTabContent dangerouslySetInnerHTML={{ __html: tabs[activeTab]?.text }}></CaseStudyTabContent>
					)}
				</CaseStudyColumn>
				<CaseStudyColumn>
					<TestQuestionStyledContainer>
						<CSQuestionTitle>
							Case Study Screen {activeQuestionIndex + 1} of {orderedQuestions.length}
						</CSQuestionTitle>
					</TestQuestionStyledContainer>
					{currentQuestion && getQuestion(currentQuestion)}
				</CaseStudyColumn>
			</CaseStudyRow>
			<Pages>
				<Page isArrow onClick={() => handleChangeQuestion(Math.max(activeQuestionIndex - 1, 0))}>
					&lt;
				</Page>
				{orderedQuestions?.map((_, questionIndex) => (
					<Page
						active={activeQuestionIndex === questionIndex}
						key={`question-${questionIndex}`}
						onClick={() => handleChangeQuestion(questionIndex)}
					>
						{questionIndex + 1}
					</Page>
				))}
				<Page
					isArrow
					onClick={() => handleChangeQuestion(Math.min(activeQuestionIndex + 1, orderedQuestions.length - 1))}
				>
					&gt;
				</Page>
			</Pages>
		</>
	);
};

export const Alert = () => (
	<CaseStudyAlertContainer>
		<CaseStudyAlertTitle>
			<StyledError />
			Case Study Questionaire
		</CaseStudyAlertTitle>
		This is a case study question… you need to complete 6 questions before moving forward.
	</CaseStudyAlertContainer>
);

export default CaseStudyQuestionForTests;

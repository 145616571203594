import React, { useCallback, useEffect, useRef, useState } from "react";

import {
	Accordion,
	AccordionDetails,
	AccordionSummary,
	Box,
	Button,
	Card,
	CircularProgress,
	Grid,
	IconButton,
	InputAdornment,
	TextField,
	Typography,
	useMediaQuery,
	useTheme
} from "@material-ui/core";
import { ChevronLeft, FaceRounded, PlayArrow, Search } from "@material-ui/icons";
import { Wrapper } from "@remar/shared/dist/layouts";
import { IExtendedTheme } from "@remar/shared/dist/theme/default";
import { getVideoPlayer } from "@remar/shared/dist/utils/serviceUtils/helpers";
import { validatePhoneNumber } from "@remar/shared/dist/utils/serviceUtils/validators";
import { sanitize as sanitizeHtml } from "dompurify";
import { Field, FieldInputProps, FieldMetaProps, Form, Formik, FormikProps } from "formik";
import { TextField as MuiTextField } from "formik-material-ui";
import handlebars from "handlebars";
import debounce from "lodash/debounce";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import { RootState } from "store";
import { getIntroLessonVideo } from "store/features/Auth/authSlice";
import {
	getFaqs,
	getTopics,
	selectFaqTopics,
	selectFaqs,
	selectHelpIsLoading,
	sendHelpRequest
} from "store/features/Help/helpSlice";

import { selectUserInfo } from "store/features/MyAccount/myAccountSlice";

import { routes } from "core/constants";

import { ContactFormSchema } from "./schemas";

import { ExplainerVideo, useStyles } from "./styles";

import { GLOBAL_CONSTANTS } from "../../constants";

const FormTextField = ({
	...props
}: {
	field: FieldInputProps<unknown>;
	form: FormikProps<unknown>;
	meta: FieldMetaProps<unknown>;
	[fieldName: string]: unknown;
}) => <MuiTextField InputProps={{ disableUnderline: true }} variant="filled" {...props} />;

const Help = () => {
	const theme = useTheme<IExtendedTheme>();
	const dispatch = useDispatch();
	const classes = useStyles();
	const history = useHistory();
	const isMobile = useMediaQuery(theme.breakpoints.down("xs"));

	const { introVideo } = useSelector((state: RootState) => state.auth);
	const [searchText, setSearchText] = useState("");
	const [selectedTopic, setSelectedTopic] = useState<{ id: number | null; name: string }>({ id: null, name: "" });
	const userInfo = useSelector(selectUserInfo);
	const isLoading = useSelector(selectHelpIsLoading);
	const topics = useSelector(selectFaqTopics);
	const faqs = useSelector(selectFaqs);
	const videoRef = useRef(null);

	const debouncedSearch = useCallback(
		debounce((searchText, id) => dispatch(getFaqs({ searchText, ...(id && { id }) })), 1000),
		[]
	);

	useEffect(() => {
		dispatch(getTopics());
		dispatch(getFaqs({}));
	}, [dispatch]);

	useEffect(() => {
		if (!introVideo) {
			dispatch(getIntroLessonVideo());
			return;
		}
		getVideoPlayer(videoRef!.current!, introVideo.videoUrl, {
			muted: false,
			loop: true,
			autoplay: false,
			controls: false
		});
	}, [dispatch, introVideo]);

	const handleSubmit = values => {
		dispatch(sendHelpRequest(values));
	};

	const feedbackFormInitialValues = {
		firstName: userInfo?.firstName,
		lastName: userInfo?.lastName,
		phoneNumber: userInfo?.phoneNumber,
		email: userInfo?.email
	};

	if (isLoading) {
		return (
			<Box display="flex" alignItems="center" justifyContent="center" height={500} width="100%">
				<CircularProgress size="7rem" color="primary" thickness={5} variant="indeterminate" />
			</Box>
		);
	}

	const sanitize = dirty => {
		const template = handlebars.compile(dirty);
		const compiledHtml = template({ url: GLOBAL_CONSTANTS.APP_URL });

		return {
			__html: sanitizeHtml(compiledHtml)
		};
	};

	return (
		<Wrapper heading="Help center">
			<Grid container spacing={isMobile ? 0 : 2}>
				<Grid item xs={12} sm={6}>
					<Card>
						<Box display="flex" justifyContent="center" alignItems="center" flexDirection="column">
							<Box display="flex" justifyContent="center" alignItems="center" flexDirection="row">
								{
									<IconButton
										onClick={() => {
											setSelectedTopic({ id: null, name: "" });
											setSearchText("");
											dispatch(getFaqs({}));
										}}
									>
										<ChevronLeft style={{ width: "30px", height: "30px", fill: "#898f9e" }} />
									</IconButton>
								}
								<Typography className={classes.title}>{selectedTopic.name ? selectedTopic.name : "FAQ"}</Typography>
							</Box>
							<TextField
								hiddenLabel
								InputProps={{
									autoFocus: true,
									style: { color: theme.palette.text.primary, backgroundColor: theme.palette.background.default },
									disableUnderline: true,
									startAdornment: (
										<InputAdornment position="start">
											<IconButton size="small">
												<Search style={{ width: "20px", height: "20px", fill: "#898f9e" }} />
											</IconButton>
										</InputAdornment>
									)
								}}
								color="primary"
								onChange={({ target: { value } }) => {
									setSearchText(value);
									debouncedSearch(value, selectedTopic.id);
								}}
								variant="filled"
								size="small"
								placeholder="Type your question or search keyword"
								value={searchText}
								className={classes.searchField}
							/>
							{!(selectedTopic.id || searchText.length) && (
								<Box mb={4}>
									<Typography className={classes.topicTitle}>Popular Topics</Typography>
									<Grid container spacing={4}>
										{topics.map(({ name, id }) => {
											return (
												<Grid item xs={12} sm={6} key={name}>
													<Card
														className={classes.topicCard}
														onClick={() => {
															setSelectedTopic({ id, name });
															dispatch(getFaqs({ id }));
														}}
													>
														<Grid direction="row" justifyContent="center" alignItems="center" container spacing={1}>
															<Grid item xs={3}>
																{/*todo should be changed to smile icon after upgrading material icons*/}
																<FaceRounded style={{ width: "40px", height: "40px", fill: "#898f9e" }} />
															</Grid>
															<Grid item xs={9}>
																<Typography className={classes.topicName}>{name}</Typography>
															</Grid>
														</Grid>
													</Card>
												</Grid>
											);
										})}
									</Grid>
								</Box>
							)}
							<Box>
								<Typography className={classes.questionTitle}>Popular Questions</Typography>
								{faqs.length ? (
									faqs.map(({ question, answer }) => (
										<Accordion key={question} className={classes.questionCard}>
											<AccordionSummary
												expandIcon={
													<ChevronLeft
														style={{
															width: "30px",
															height: "30px",
															fill: "#898f9e",
															transform: "rotate(-90deg)"
														}}
													/>
												}
											>
												<Typography>{question}</Typography>
											</AccordionSummary>
											<AccordionDetails className={classes.answerDetail}>
												<Box dangerouslySetInnerHTML={sanitize(answer)} />
											</AccordionDetails>
										</Accordion>
									))
								) : (
									<Box width="100%">
										<Typography style={{ fontWeight: 400, fontSize: `"${theme.typography.pxToRem(20)}"` }}>
											Nothing found please try something else
										</Typography>
									</Box>
								)}
							</Box>
						</Box>
					</Card>
				</Grid>

				<Grid item xs={12} sm={6} direction="column" style={{ height: "100%" }}>
					<ExplainerVideo>{introVideo && <div className="question-head">Explainer video</div>}</ExplainerVideo>
					<Card className={classes.videoContainer}>
						{introVideo && <video ref={videoRef} className="video-js vjs-big-play-centered" />}{" "}
						<div className="questions-overlay">
							<Box display="flex" flexDirection="column" className={classes.videoQuestionContainer}>
								<Box display="flex" alignItems="center" justifyContent="center" flexGrow="1">
									{!introVideo && <Typography className={classes.title}> Tutorial not found </Typography>}
									{introVideo && (
										<Box display="flex" width="50px" height="45px">
											<IconButton
												onClick={() =>
													introVideo &&
													history.push(`${routes.lesson.getPath()}/${introVideo.interactiveBlocks![0].lessonId}/0`)
												}
											>
												<PlayArrow style={{ width: "30px", height: "30px", fill: "white" }} />
											</IconButton>
										</Box>
									)}
								</Box>
							</Box>
						</div>
					</Card>
					<Card>
						<Box display="flex" flexDirection="column" height={isMobile ? "100vh" : "auto"}>
							<Formik
								initialValues={feedbackFormInitialValues}
								validationSchema={ContactFormSchema}
								validateOnChange
								onSubmit={values => handleSubmit(values)}
							>
								{({ isValid, dirty, values }) => {
									return (
										<>
											<Box display="flex" justifyContent="space-between">
												<Typography className={classes.questionTitle}>Let’s connect</Typography>
											</Box>
											<Form>
												<Box className={classes.root}>
													<Grid container spacing={3}>
														<Grid item xs={6}>
															<Field component={FormTextField} name="firstName" placeholder="First Name" fullWidth />
														</Grid>
														<Grid item xs={6}>
															<Field component={FormTextField} name="lastName" placeholder="Last Name" fullWidth />
														</Grid>
														<Grid item xs={12}>
															<Field component={FormTextField} name="email" placeholder="Email" fullWidth />
														</Grid>
														<Grid item xs={12}>
															<Field
																component={FormTextField}
																name="phoneNumber"
																onKeyPress={(event: React.KeyboardEvent) => {
																	if (!validatePhoneNumber(event.key)) {
																		event.preventDefault();
																	}
																}}
																placeholder="Phone Number"
																fullWidth
															/>
														</Grid>
														<Grid item xs={12}>
															<Field
																component={FormTextField}
																name="text"
																placeholder="Type Your Message Here"
																fullWidth
																multiline
																rows={5}
															/>
														</Grid>
													</Grid>
												</Box>
											</Form>

											<Box mt={3} mx="auto">
												<Button
													color="primary"
													disabled={!isValid || !dirty}
													variant="contained"
													onClick={() => handleSubmit(values)}
												>
													Submit
												</Button>
											</Box>
										</>
									);
								}}
							</Formik>
						</Box>
					</Card>
				</Grid>
			</Grid>
		</Wrapper>
	);
};
export default Help;

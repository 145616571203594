import React, { useEffect, useMemo, useState } from "react";

import { Box, Button, Container, Theme, Typography, useMediaQuery, useTheme } from "@material-ui/core";
import Stepper from "@remar/shared/dist/components/Stepper";
import { Form, Formik } from "formik";

import ReactPixel from "react-facebook-pixel";

import { useDispatch, useSelector } from "react-redux";

import { selectIsTrial, setError, setSelectedCountry } from "store/features/Auth/authSlice";
import { getFullState, getPaymentDetails } from "store/features/Billing/BillingSlice";
import { selectFullState } from "store/features/MyAccount/myAccountSlice";

import FormActions from "modules/Auth/components/FormActions";
import { PaymentForm, ShippingForm } from "modules/Auth/components/Forms";
import { GuestSignUpError as UpgradeError } from "modules/Auth/components/GuestSignUpError";
import Summary from "modules/Auth/components/Summary";

import { getValidValue } from "modules/Auth/components/utils";
import PaymentMethods from "modules/Billings/PaymentMethods";

import { PixelEvent, setTiktokEvent } from "modules/utils";

import { UpgradeSubscriptionCard, useStyles } from "./styles";
const steps = [{ label: "Shipping" }, { label: "Payment" }];
const UpgradeSubscription = ({
	activeStep,
	setActiveStep,
	initialFormValues,
	validationSchema,
	handleSubmit,
	error,
	subcriptionIsLoading,
	handleBack,
	handleNext,
	disabled = false,
	closeModal
}) => {
	const theme = useTheme<Theme>();
	const classes = useStyles();
	const isMobileAndTablet = useMediaQuery(theme.breakpoints.down("sm"));
	const { userInfo } = useSelector(selectFullState);

	const { paymentMethods } = useSelector(getFullState);
	const isTrial = useSelector(selectIsTrial);
	const dispatch = useDispatch();

	const [showPrefilledInfo, setShowPrefilledInfo] = useState(false);
	const [showCardPrefilledInfo, setShowCardPrefilledInfo] = useState(false);
	const [prefilledValues, setPrefilledValues] = useState({});
	const [modal, setModal] = useState(false);
	const [startDate, setStartDate] = useState(new Date());

	const values = useMemo(
		() => ({
			address1: userInfo?.shippingDetails?.address1,
			address2: userInfo?.shippingDetails?.address2,
			city: userInfo?.shippingDetails?.city,
			phoneNumber: userInfo?.shippingDetails?.phoneNumber,
			state: userInfo?.shippingDetails?.state,
			zip: userInfo?.shippingDetails?.zipCode,
			countryId: userInfo?.shippingDetails?.countryId
		}),
		[userInfo?.shippingDetails]
	);

	useEffect(() => {
		if (isTrial && userInfo?.shippingDetails) {
			setShowPrefilledInfo(true);
			showPrefilledInfo && setPrefilledValues({ ...values });
			showPrefilledInfo && dispatch(setSelectedCountry(values.countryId!));
		}
		if (paymentMethods?.hasOwnProperty("brand")) {
			setShowCardPrefilledInfo(true);
		}
	}, [dispatch, isTrial, paymentMethods, showPrefilledInfo, userInfo?.shippingDetails, values]);

	useEffect(() => {
		if (activeStep === 0) {
			setTiktokEvent(PixelEvent.InitiateCheckout);
			ReactPixel.track(PixelEvent.InitiateCheckout);
		}
		if (activeStep === 1) {
			setTiktokEvent(PixelEvent.AddPaymentInfo);
			ReactPixel.track(PixelEvent.AddPaymentInfo);
		}

		if (isTrial && userInfo?.shippingDetails && !paymentMethods?.hasOwnProperty("brand")) {
			dispatch(getPaymentDetails());
		}
	}, [activeStep, dispatch, isTrial, paymentMethods, userInfo?.shippingDetails]);

	return (
		<Container maxWidth="md" className={classes.container}>
			<Box
				display="flex"
				flexDirection="column"
				alignItems="center"
				justifyContent="center"
				height={isMobileAndTablet ? "100%" : "100vh"}
			>
				<Box className={classes.cardsContainer}>
					<UpgradeSubscriptionCard>
						<Box>
							<Box px={isMobileAndTablet ? 0 : 9} pt={isMobileAndTablet ? 0 : 2}>
								<Stepper activeStep={activeStep} steps={steps} />
								<Formik
									enableReinitialize
									initialValues={showPrefilledInfo ? prefilledValues : initialFormValues}
									validationSchema={validationSchema}
									validateOnChange
									onSubmit={values => {
										handleSubmit({ ...values, startDate });
									}}
								>
									{({ isValid, values, touched, setFieldValue, setTouched }) => {
										const neverTouched = showPrefilledInfo ? true : Object.keys(touched).length === 0;
										let valid = isValid && showPrefilledInfo ? neverTouched : !neverTouched;
										if ((activeStep === 0 || activeStep === 1) && valid && !showPrefilledInfo) {
											valid = getValidValue(Object.keys(touched)) ? isValid : false;
										}
										return (
											<>
												<Form>
													{activeStep === 0 && <ShippingForm />}
													{activeStep === 1 && showCardPrefilledInfo ? (
														<>
															<Box mb={2}>
																<Typography variant="h1" className={classes.filledFormTitle}>
																	Payment
																</Typography>
															</Box>
															<PaymentMethods
																showRecurringInfo
																onEdit={() => {
																	dispatch(setError(""));
																	setModal(true);
																}}
																modal={modal}
																setModal={setModal}
																showPrefilledInfo={showPrefilledInfo}
															/>
														</>
													) : activeStep === 1 && !showCardPrefilledInfo ? (
														<PaymentForm
															setFieldValue={setFieldValue}
															touched={touched}
															setTouched={setTouched}
															termValue={values.terms}
															showTerms
														/>
													) : activeStep === 2 ? (
														<UpgradeError errorMessage={error} />
													) : (
														<></>
													)}
												</Form>
												{error && (
													<Box mt={3}>
														<Typography variant="caption" style={{ color: "red" }}>
															{error}
														</Typography>
													</Box>
												)}

												{activeStep !== 2 ? (
													<FormActions
														back={() => handleBack()}
														next={() => handleNext({ ...values, paymentMethodId: paymentMethods?.id, startDate })}
														valid={valid}
														loading={subcriptionIsLoading}
														step={activeStep}
														disabled={disabled}
														firstStep={0}
														lastStep={1}
														upgradeSubscription={true}
													/>
												) : activeStep === 2 ? (
													<Box display="flex" justifyContent="center" mt={6}>
														<Button
															color="primary"
															variant="contained"
															onClick={() => {
																dispatch(setError(""));
																setActiveStep(1);
															}}
														>
															{"Try Again"}
														</Button>
													</Box>
												) : null}
											</>
										);
									}}
								</Formik>
							</Box>
						</Box>
					</UpgradeSubscriptionCard>
					<Summary startDate={startDate} setStartDate={setStartDate} closeModal={closeModal} />
				</Box>
			</Box>
		</Container>
	);
};

export default UpgradeSubscription;

import React, { useEffect, useState } from "react";

import { Box, Button, Card, CircularProgress, Theme, useTheme } from "@material-ui/core";

import { SimpleModal } from "@remar/shared/dist/components/SimpleModal";
import classnames from "classnames";

import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import { deleteAccount, selectFullState } from "store/features/MyAccount/myAccountSlice";

import { routes } from "core/constants";

import { BodyText, ConfirmPasswordField, useStyles } from "./styles";

const DeleteAccountConfirmation = ({ showDeleteModal, setShowDeleteModal }) => {
	const theme = useTheme<Theme>();
	const classes = useStyles();
	const [isLoading, setIsLoading] = useState(false);
	const [password, setPassword] = useState("");
	const accountState = useSelector(selectFullState);
	const history = useHistory();

	const signInRoute = routes.signIn.getPath();

	const { accountDeleted, isDeleteError } = accountState;

	useEffect(() => {
		if (!isDeleteError && accountDeleted) {
			setShowDeleteModal(false);

			history.push(signInRoute);
		}
	}, [accountDeleted, isDeleteError]);

	const dispatch = useDispatch();
	const onClose = () => {
		if (!isLoading) {
			setShowDeleteModal(false);
		}
	};

	const onConfirm = () => {
		setIsLoading(true);
		dispatch(
			deleteAccount({
				password,
				sideEffect: () => {
					setIsLoading(false);
				}
			})
		);
	};

	return (
		<Box className={classes.userInfoContainer}>
			<Card>
				<SimpleModal
					theme={theme}
					title="Delete Account"
					open={showDeleteModal}
					onClose={onClose}
					text={""}
					extraContent={
						<>
							<BodyText>Please enter your password to delete your account.</BodyText>
							<ConfirmPasswordField
								onChange={e => setPassword(e.target.value)}
								type="password"
								placeholder="Password"
							/>
						</>
					}
					footer={
						<>
							<Button
								className={classnames(classes.noCancel, classes.button)}
								size="medium"
								disabled={isLoading}
								onClick={onClose}
							>
								{isLoading ? (
									<Box width={50}>
										<CircularProgress size="1rem" color="inherit" thickness={2} variant="indeterminate" />
									</Box>
								) : (
									<>No, Cancel</>
								)}
							</Button>
							<Button
								className={classnames(classes.cancelBtn, classes.button)}
								disabled={isLoading || !password}
								size="medium"
								onClick={() => onConfirm()}
							>
								{isLoading ? (
									<Box width={50}>
										<CircularProgress size="1rem" color="inherit" thickness={2} variant="indeterminate" />
									</Box>
								) : (
									<>Yes, Delete Account</>
								)}
							</Button>
						</>
					}
				/>
			</Card>
		</Box>
	);
};

export default DeleteAccountConfirmation;

import { createStyles, makeStyles } from "@material-ui/core";
import { IExtendedTheme } from "@remar/shared/dist/theme/default";

export const useStyles = makeStyles((theme: IExtendedTheme) =>
	createStyles({
		card: {
			display: "flex",
			flexDirection: "column",
			alignItems: "center",
			justifyContent: "center",
			padding: 0,
			width: "auto",
			height: "auto",
			"&.intro-card": {
				width: "80%",
				border: "none"
			}
		},
		error: {
			color: "#f44336",
			marginTop: "3px",
			textAlign: "left",
			fontWeight: 400,
			fontSize: `"${theme.typography.pxToRem(12)}"`,
			marginLeft: "14px",
			height: "0px"
		},
		form: {
			display: "flex",
			alignItems: "center",
			justifyContent: "center",
			marginBottom: "10px"
		},
		formFooter: {
			display: "flex",
			width: "100%",
			justifyContent: "flex-end",
			borderTopWidth: "1px",
			borderTopColor: "rgb(42, 46, 55)",
			borderTopStyle: "solid",
			padding: "17px 8px",
			[theme.breakpoints.down("xs")]: {
				justifyContent: "center",
				padding: "24px"
			}
		},
		formHeader: {
			display: "flex",
			flexDirection: "column",
			marginBottom: "40px",
			marginTop: "40px",
			[theme.breakpoints.down("md")]: {
				margin: 0
			}
		},
		inputContainer: {
			display: "flex",
			alignItems: "center",
			marginBottom: "30px",
			flexWrap: "wrap",
			[theme.breakpoints.down("xs")]: {
				flexBasis: "100%",
				gap: "8px"
			}
		},
		inputField: {
			flex: 4,
			display: "flex",
			alignItems: "center",
			[theme.breakpoints.down("xs")]: {
				flexBasis: "100%"
			}
		},
		labelField: {
			flex: 2,
			paddingRight: "20px",
			[theme.breakpoints.down("xs")]: {
				flexBasis: "100%"
			}
		},
		subTitle: {
			fontSize: theme.typography.pxToRem(13),
			textAlign: "center",
			marginBottom: theme.spacing(3),
			color: "rgba(255, 255, 255, 0.6)",
			[theme.breakpoints.down("xs")]: {
				margin: 0
			}
		},
		title: {
			margin: theme.spacing(2),
			fontSize: theme.typography.pxToRem(18),
			fontWeight: "bold",
			textAlign: "center",
			fontStretch: "normal",
			fontStyle: "normal",
			color: "rgba(253, 253, 253)"
		},
		videoContainer: {
			height: "100%",
			width: "100%",
			overflow: "hidden",
			position: "relative",
			minHeight: "270px",
			"& .questions-overlay": {
				zIndex: 1500,
				height: "100%"
			},
			"& .vjs-fluid": {
				height: "100%"
			},
			"& .vjs-fullscreen-control": {
				marginLeft: "auto"
			},
			"& .vjs-has-started.vjs-user-inactive.vjs-playing .vjs-control-bar": {
				opacity: "1 !important",
				visibility: "visible !important"
			},
			"& .vjs-big-play-button": {
				display: "none"
			},
			"& .video-js": {
				padding: 0,
				height: "100%",
				"& .vjs-control-bar": {
					display: "flex"
				},
				"& .vjs-tech": {
					width: "100%",
					height: "100%"
				}
			}
		},
		videoQuestionContainer: {
			pointerEvents: "all",
			padding: "10%",
			height: "100%",
			background:
				"linear-gradient(90deg, rgba(47, 50, 59, 1) 0%, rgba(47, 50, 59, 0.7) 75%, rgba(47, 50, 59, 0.2) 100%)",
			"& .question-head": {
				margin: "0 0 16px",
				opacity: "0.87",
				fontSize: `"${theme.typography.pxToRem(15)}"`,
				fontWeight: 600,
				lineHeight: 1.33,
				color: "#ffffff"
			}
		},
		videoQuestionOption: {
			width: "362px",
			height: "auto",
			margin: "16px 28px 0 0",
			padding: "16px",
			borderRadius: "4px",
			fontSize: `"${theme.typography.pxToRem(15)}"`,
			backgroundColor: "rgba(34, 37, 45, 0.6)",
			cursor: "pointer",
			color: "#ffffff",
			"&:hover": {
				backgroundColor: "#346bae",
				opacity: 0.76
			}
		}
	})
);

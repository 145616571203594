import React, { useMemo } from "react";

import { Themes } from "@remar/shared/dist/models/theme.types";
import { useSelector } from "react-redux";

import { getFullState } from "store/features/Theme/theme.slice";

import { ReactComponent as IconMotivationCard } from "assets/icons/icon-motivation-card.svg";
import { ReactComponent as IconMotivationCardBlue } from "assets/icons/icon-motivation-card_blue.svg";
import { ReactComponent as IconMotivationCardGreen } from "assets/icons/icon-motivation-card_green.svg";
import { ReactComponent as IconMotivationCardOrange } from "assets/icons/icon-motivation-card_orange.svg";
import { ReactComponent as IconMotivationCardPurple } from "assets/icons/icon-motivation-card_purple.svg";
import { ReactComponent as IconMotivationCardRed } from "assets/icons/icon-motivation-card_red.svg";
import { ReactComponent as IconMotivationCardYellow } from "assets/icons/icon-motivation-card_yellow.svg";

import { QuotesList } from "./QuotesList";
import { MotivationalQuoteContainer, MotivationalQuoteText, MotivationalQuoteTextContainer } from "./styles";

const getThemedSvg = (colorShade: Themes) => {
	switch (colorShade) {
		case Themes.DARK_CYAN_BLUE:
		case Themes.LIGHT_CYAN_BLUE:
			return <IconMotivationCardBlue />;
		case Themes.DARK_RED:
		case Themes.LIGHT_RED:
			return <IconMotivationCardRed />;
		case Themes.DARK_YELLOW:
		case Themes.LIGHT_YELLOW:
			return <IconMotivationCardYellow />;
		case Themes.DARK_ORANGE:
		case Themes.LIGHT_ORANGE:
			return <IconMotivationCardOrange />;
		case Themes.DARK_GREEN:
		case Themes.LIGHT_GREEN:
			return <IconMotivationCardGreen />;
		case Themes.DARK_BLUE:
		case Themes.LIGHT_BLUE:
			return <IconMotivationCardBlue />;
		case Themes.DARK_PURPLE:
		case Themes.LIGHT_PURPLE:
			return <IconMotivationCardPurple />;
		default:
			return <IconMotivationCard />;
	}
};

const MotivationalQuotes = () => {
	const { colorShade } = useSelector(getFullState);
	const randomNumber = useMemo(() => Math.floor(Math.random() * (29 + 1)), []);
	return (
		<MotivationalQuoteContainer>
			{getThemedSvg(colorShade)}
			<MotivationalQuoteTextContainer display="flex" alignItems="center" ml={7}>
				<MotivationalQuoteText>{QuotesList[randomNumber]}</MotivationalQuoteText>
			</MotivationalQuoteTextContainer>
		</MotivationalQuoteContainer>
	);
};

export default MotivationalQuotes;

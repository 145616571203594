import React, { useState } from "react";

import { Box, Button, Card, Modal } from "@material-ui/core";
import { ErrorSharp } from "@material-ui/icons";
import { format } from "date-fns";
import { useUpgradeForm } from "hooks/useUpgradeForm";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { RootState, useAppSelector } from "store";
import { getUserData, selectInactiveSubscription } from "store/features/Auth/authSlice";

import { getSubscriptionInfo, getUserInfo } from "store/features/MyAccount/myAccountSlice";

import { routes } from "core/constants";

import { ManageText, PaymentBlock, PaymentDescription, PaymentTitle } from "./styles";

import UpgradeSubscription from "../MyAccount/UpgradeSubscription";

const NextBilling = () => {
	const history = useHistory();
	const dispatch = useDispatch();
	const [modal, setModal] = useState(false);
	const { subscriptionInfo } = useAppSelector((store: RootState) => store.myAccount);
	const { isRenewEnabled } = useSelector(selectInactiveSubscription);

	const onSubmit = () => {
		setModal(false);
		dispatch(getSubscriptionInfo());
		dispatch(getUserData());
		dispatch(getUserInfo());
		history.push(`${routes.course.getPath()}?refetch=true`);
	};

	const { closeModal, ...upgradeFormProps } = useUpgradeForm({ setModal, onSubmit, dispatch });

	const getNextBillingDate = () => {
		if (subscriptionInfo) {
			const date = subscriptionInfo!.upcomingInvoice!.billingDate!;
			return date && format(new Date(date), "MM.dd.yyyy");
		}
		return "";
	};

	return (
		<Card>
			<PaymentTitle>Your Next Billing</PaymentTitle>
			<PaymentBlock>
				{subscriptionInfo?.subscription.type?.isTrial ? (
					<>
						<Box display="flex" mb={1}>
							<ErrorSharp style={{ fill: "#898f9e" }} />
							<PaymentDescription>Your current subscription is trial</PaymentDescription>
						</Box>
						{!isRenewEnabled && (
							<Button onClick={() => setModal(true)}>
								<ManageText>Upgrade</ManageText>
							</Button>
						)}
					</>
				) : (
					<>
						<Box display="flex" mb={1}>
							<ErrorSharp style={{ fill: "#898f9e" }} />
							<PaymentDescription>
								{`Your Next Payment will take place on ${getNextBillingDate()} and a total of ${
									subscriptionInfo?.upcomingInvoice.price
								}$.`}
							</PaymentDescription>
						</Box>
						<Button onClick={() => history.push(routes.myaccount.getPath())}>
							<ManageText>Manage</ManageText>
						</Button>
					</>
				)}
				<Modal open={modal} onClose={closeModal}>
					<UpgradeSubscription {...upgradeFormProps} closeModal={closeModal} />
				</Modal>
			</PaymentBlock>
		</Card>
	);
};
export default NextBilling;

import React, { useState } from "react";

import { Box, Button, CircularProgress, Modal, Theme, useTheme } from "@material-ui/core";

import { SimpleModal } from "@remar/shared/dist/components/SimpleModal";

import classnames from "classnames";

import { useUpgradeForm } from "hooks/useUpgradeForm";
import { useDispatch, useSelector } from "react-redux";

import { useHistory } from "react-router-dom";

import { getUserData, selectInactiveSubscription } from "store/features/Auth/authSlice";
import { getSubscriptionInfo, getUserInfo, resumeSubscription } from "store/features/MyAccount/myAccountSlice";

import { routes } from "core/constants";

import UpgradeSubscription from "./UpgradeSubscription";
import { useStyles } from "./styles";

const ResumeAndRenewSubscriptionButton = ({
	id,
	sideEffect = () => {},
	className
}: {
	id: number;
	sideEffect: () => void;
	className?: string;
}) => {
	const history = useHistory();
	const theme = useTheme<Theme>();
	const classes = useStyles();
	const [modal, setModal] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	const dispatch = useDispatch();
	const { isRenewEnabled, subscriptionId } = useSelector(selectInactiveSubscription);

	const onSubmit = () => {
		setModal(false);
		dispatch(getSubscriptionInfo());
		dispatch(getUserData());
		dispatch(getUserInfo());
		history.push(`${routes.course.getPath()}?refetch=true`);
	};
	const { closeModal, ...upgradeFormProps } = useUpgradeForm({
		setModal,
		onSubmit,
		dispatch,
		isRenewSubscription: true
	});
	const btnText = isRenewEnabled ? "Renew Subscription" : "Resume Subscription";
	const onClose = () => {
		if (!isLoading) {
			setModal(false);
		}
	};
	const onConfirm = () => {
		setIsLoading(true);
		dispatch(
			resumeSubscription({
				id: isRenewEnabled ? (subscriptionId as number) : id,
				sideEffect: () => {
					sideEffect();
					setModal(false);
					setIsLoading(false);
				}
			})
		);
	};
	return (
		<>
			<Button
				variant={"contained"}
				color={"primary"}
				className={className || classes.resumeSubscriptionBtn}
				onClick={() => setModal(true)}
			>
				{btnText}
			</Button>
			{isRenewEnabled ? (
				<Modal open={modal} onClose={closeModal}>
					<UpgradeSubscription {...upgradeFormProps} closeModal={closeModal} />
				</Modal>
			) : (
				<SimpleModal
					theme={theme}
					title={btnText}
					open={modal}
					onClose={onClose}
					text="Are you sure you want to proceed?"
					footer={
						<>
							<Button
								className={classnames(classes.keepBtn, classes.button)}
								size="medium"
								disabled={isLoading}
								onClick={onClose}
							>
								{isLoading ? (
									<Box width={50}>
										<CircularProgress size="1rem" color="inherit" thickness={2} variant="indeterminate" />
									</Box>
								) : (
									<>No</>
								)}
							</Button>
							<Button
								className={classnames(classes.cancelBtn, classes.button)}
								disabled={isLoading}
								size="medium"
								onClick={() => onConfirm()}
							>
								{isLoading ? (
									<Box width={50}>
										<CircularProgress size="1rem" color="inherit" thickness={2} variant="indeterminate" />
									</Box>
								) : (
									<>Yes</>
								)}
							</Button>
						</>
					}
				/>
			)}
		</>
	);
};

export default ResumeAndRenewSubscriptionButton;

import { Dispatch, SetStateAction, useCallback, useEffect, useMemo, useState } from "react";

import { ExternalIntegrationIds } from "@remar/shared/dist/constants";

import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js";

import ReactPixel from "react-facebook-pixel";

import { useSelector } from "react-redux";

import { useHistory } from "react-router";
import { RootState, useAppSelector } from "store";

import {
	renewSubscription,
	selectSubcriptionIsLoading,
	upgradeSubscription
} from "store/features/MyAccount/myAccountSlice";

import { PaymentSchema, ShippingSchema } from "modules/Auth/components/schemas";
import { PixelEvent, getCallbackUrl, setTiktokEvent } from "modules/utils";

import { changeSubscriptionType, selectInactiveSubscription, setError } from "../store/features/Auth/authSlice";

interface InType {
	setModal: Dispatch<SetStateAction<boolean>>;
	onSubmit: () => void;
	dispatch: Dispatch<unknown>;
	isRenewSubscription?: boolean;
}

interface OutType {
	closeModal;
	handleSubmit;
	handleNext;
	handleBack;
	validationSchema;
	initialFormValues;
	subcriptionIsLoading;
	error;
	activeStep;
	setActiveStep;
}

export const useUpgradeForm = ({ setModal, onSubmit, dispatch, isRenewSubscription }: InType): OutType => {
	const stripe = useStripe();
	const elements = useElements();
	const [activeStep, setActiveStep] = useState(0);
	const subcriptionIsLoading = useSelector(selectSubcriptionIsLoading);
	const { subscriptionId } = useSelector(selectInactiveSubscription);
	const state: RootState = useAppSelector((state: RootState) => state);
	const { errorMessage: error } = state.auth;
	const history = useHistory();
	const callBackUrl = useMemo(() => getCallbackUrl(history.location.search), [history.location.search]);
	const initialFormValues = {
		countryId: "",
		address1: "",
		address2: "",
		city: "",
		state: "",
		zip: "",
		phoneNumber: ""
	};

	const { subscriptionInfo, userInfo } = useAppSelector((store: RootState) => store.myAccount);
	const { subscriptionTypes }: RootState["auth"] = useAppSelector((store: RootState) => store.auth);

	const activeSubscription = useMemo(
		() => subscriptionTypes?.find(({ id }) => id === subscriptionInfo?.subscription?.nextTypeId),
		[subscriptionTypes, subscriptionInfo]
	);
	useEffect(() => {
		if (activeSubscription) {
			dispatch(changeSubscriptionType(activeSubscription?.id as number));
		}
	}, [activeSubscription, dispatch]);

	const validationSchema = useMemo(() => {
		switch (activeStep) {
			case 0:
				return ShippingSchema;
			case 1:
				return PaymentSchema;
		}
	}, [activeStep]);

	const handleNext = (values: typeof initialFormValues) => {
		if (activeStep === 0) {
			setActiveStep(1);
		} else if (activeStep === 1) {
			handleSubmit(values);
		}
	};

	const upgradeSideEffect = useCallback(
		values => {
			const priceData = activeSubscription?.subTypeEIDItems?.find(
				item => item.integrationId == ExternalIntegrationIds.Stripe
			)?.data;
			const { price } = priceData;
			ReactPixel.track(PixelEvent.Purchase, { ...values, value: price, currency: "USD" });
			setTiktokEvent(PixelEvent.CompletePayment, { ...values, value: price, currency: "USD" });
			if (callBackUrl) {
				window.location.replace(callBackUrl);
			} else {
				onSubmit();
			}
		},
		[activeSubscription?.subTypeEIDItems, callBackUrl, onSubmit]
	);

	const renewSideEffect = useCallback(() => {
		if (callBackUrl) {
			window.location.replace(callBackUrl);
		} else {
			onSubmit();
		}
	}, [callBackUrl, onSubmit]);

	const handleBack = () => {
		setActiveStep(prevActiveStep => (prevActiveStep >= 1 ? prevActiveStep - 1 : 0));
	};

	const handleSubmit = (values: typeof initialFormValues) => {
		dispatch(setError(""));
		if (isRenewSubscription) {
			dispatch(
				renewSubscription({
					id: subscriptionId as number,
					subscriptionBody: {
						...values,
						zipCode: values.zip,
						userId: userInfo?.shippingDetails?.userId,
						fullName: userInfo?.shippingDetails?.fullName || userInfo!.firstName + userInfo!.lastName || ""
					},
					dispatch,
					sideEffect: renewSideEffect,
					handleError: setError,
					CardElement,
					elements,
					stripe
				})
			);
		} else {
			// eslint-disable-next-line @typescript-eslint/ban-ts-comment
			// @ts-ignore
			dispatch(upgradeSubscription({ CardElement, elements, stripe, values, sideEffect: upgradeSideEffect })).then(
				r => {
					if (r.error) {
						dispatch(setError(r.error.message));
						setActiveStep(2);
					}
				}
			);
		}
	};

	const closeModal = () => {
		setModal(false);
		setActiveStep(0);
	};

	return {
		closeModal,
		handleSubmit,
		handleNext,
		handleBack,
		validationSchema,
		initialFormValues,
		subcriptionIsLoading,
		error,
		activeStep,
		setActiveStep
	};
};

import React from "react";

import { Box, Radio } from "@material-ui/core";

import { concat } from "lodash";

import { StyledText } from "modules/Lesson/style";
import { MatrixSingleChoiceGroupText, TestQuizQuestionText } from "modules/QuestionBank/styles";

import { MatrixTableBody, MatrixTableHeader, RadioWrapper, TestQuestionStyledContainer } from "../../styles";

const MatrixSingleChoiceQuestion = ({
	question: {
		data: { answerOptions, tableLabel, groups },
		text
	},
	onChange,
	userAnswers,
	key: uniqueIdentifier
}) => {
	const handleChangeAnswer = (e, gId) => {
		const optId = e.target.value;
		let arr: Record<string, unknown>[];
		const answerExist = userAnswers.some(answer => answer.groupId === gId);
		if (!answerExist) {
			arr = concat(userAnswers, { id: optId, groupId: gId });
		} else {
			arr = userAnswers.map(answer => {
				if (answer.groupId === gId) {
					return { ...answer, id: optId };
				}
				return answer;
			});
		}
		onChange([...arr]);
	};

	return (
		<TestQuestionStyledContainer>
			<TestQuizQuestionText>{text}</TestQuizQuestionText>
			<MatrixTableHeader display="flex" maxWidth={"595px"} flexDirection="row">
				<Box display="flex" flexDirection="row" alignItems="center" width="100%">
					<MatrixSingleChoiceGroupText>
						<StyledText>{tableLabel}</StyledText>
					</MatrixSingleChoiceGroupText>

					<Box height={"100%"} display="flex" flexDirection="row" width="50%">
						{answerOptions.map(({ text }, answerOptionIndex) => (
							<Box
								key={answerOptionIndex}
								justifyContent="center"
								display="flex"
								alignItems="center"
								height={"100%"}
								flexDirection="row"
								p="0 8px"
								width={`${100 / answerOptions.length}%`}
								{...(answerOptionIndex === 0 && {
									borderRight: "solid 1px rgba(151, 151, 151, 0.3)"
								})}
							>
								<StyledText>{text}</StyledText>
							</Box>
						))}
					</Box>
				</Box>
			</MatrixTableHeader>
			<MatrixTableBody>
				{groups.map(({ text, id: gId }, gIndex) => (
					<Box
						key={gIndex}
						borderBottom="solid 1px rgba(151, 151, 151, 0.3)"
						height="auto"
						minHeight="51px"
						display="flex"
						flexDirection="row"
						width="100%"
					>
						<Box display="flex" flexDirection="row" alignItems="center" width="100%">
							<MatrixSingleChoiceGroupText>
								<StyledText>{text}</StyledText>
							</MatrixSingleChoiceGroupText>
							<Box display="flex" flexDirection="row" width="50%" height="100%">
								<RadioWrapper
									onChange={e => handleChangeAnswer(e, gId)}
									value={userAnswers.find(({ groupId }) => groupId === gId)?.id ?? ""}
								>
									{answerOptions.map(({ id: optId }, _index) => (
										<Box
											justifyContent="center"
											alignItems="center"
											key={_index}
											height="auto"
											minHeight="46px"
											display="flex"
											width={`${100 / answerOptions.length}%`}
											{...(_index === 0 && {
												borderRight: "solid 1px rgba(151, 151, 151, 0.3)"
											})}
										>
											<Radio color="default" key={uniqueIdentifier} value={optId} />
										</Box>
									))}
								</RadioWrapper>
							</Box>
						</Box>
					</Box>
				))}
			</MatrixTableBody>
		</TestQuestionStyledContainer>
	);
};

export default MatrixSingleChoiceQuestion;

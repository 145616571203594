import React from "react";

import { Box, Theme, useMediaQuery, useTheme } from "@material-ui/core";

import { getSanitizedHtmlText } from "@remar/shared/dist/utils/serviceUtils/helpers";

import { AnswerOptionRow, AnswerOptionsIndication, AnswerOptionsText, useStyles } from "../styles";

const DragAndDropResult = ({ result, question }) => {
	const theme = useTheme<Theme>();
	const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
	const classes = useStyles();

	const {
		description,
		data: { groups, answerOptions }
	} = question;

	const selectedAnswers = result?.selectedAnswers || [];

	return (
		<>
			<Box className={classes.questionText} mb={1}>
				{getSanitizedHtmlText(description)}
			</Box>
			<Box>
				{groups?.map(({ id: groupId, text, selectedAnswerOptions }, gIndex) => {
					return (
						<Box key={gIndex} display="flex" width="100%" mb={8}>
							<Box alignItems="center" width="100%">
								<Box width="100%">
									<Box className={classes.text} mb={1}>
										{`${text} ${selectedAnswerOptions ? " ..." : ""}`}
									</Box>
								</Box>
								<Box width="100%">
									{answerOptions &&
										selectedAnswerOptions &&
										answerOptions.map((item, _index) => {
											const theCorrectAnswer = selectedAnswerOptions.includes(item.id);
											const yourAnswer = selectedAnswers.some(
												ans => ans.questionAnswerOptionId === item.id && ans.questionGroupId === groupId
											);
											let color = "";
											let bgColor = "";
											let answerLabel = " ";
											if (yourAnswer && theCorrectAnswer) {
												answerLabel = "Correct Answer";
												color = "#23b34a";
												bgColor = "#C4E3D2";
											} else {
												if (theCorrectAnswer && yourAnswer) {
													answerLabel = "Correct Answer";
													color = "#264fae";
													bgColor = "#c0c9e5";
												} else if (theCorrectAnswer && !yourAnswer) {
													answerLabel = "Unmarked correct answer";
													color = "#264fae";
													bgColor = "#c0c9e5";
												} else if (yourAnswer) {
													answerLabel = "Incorrect answer";
													color = "#772a34";
													bgColor = "#faccd0";
												}
											}

											return (
												<Box
													className={`${theCorrectAnswer ? classes.correct : ""} ${yourAnswer ? classes.your : ""}`}
													key={_index}
													display="flex"
													justifyContent="start"
													alignItems="start"
													flex={1}
												>
													<AnswerOptionRow bgColor={bgColor} isMobile={isMobile}>
														<AnswerOptionsIndication
															color={color}
															ml={1}
															className={classes.openSansFontFamily}
															display="flex"
															isMobile={isMobile}
															theCorrectAnswer={theCorrectAnswer}
															yourAnswer={yourAnswer}
														>
															{answerLabel}&#160;
														</AnswerOptionsIndication>
														<AnswerOptionsText className={classes.openSansFontFamily}>{item.text}</AnswerOptionsText>
													</AnswerOptionRow>
												</Box>
											);
										})}
								</Box>
							</Box>
						</Box>
					);
				})}
			</Box>
		</>
	);
};

export default DragAndDropResult;

import { Box, Button, Typography, darken, makeStyles } from "@material-ui/core";

import {
	CheckCircleOutline,
	ChevronLeft,
	Description as DescriptionIcon,
	ErrorOutline,
	Lock,
	PlayArrow
} from "@material-ui/icons";
import Error from "@material-ui/icons/Error";

import { ReactComponent as Feedback } from "@remar/shared/dist/assets/icons/icon-comment-filled.svg";
import { IExtendedTheme } from "@remar/shared/dist/theme/default";
import styled, { css, keyframes } from "styled-components";

import { ReactComponent as Calculator } from "assets/icons/calculator.svg";
import { ReactComponent as Countdown } from "assets/icons/icon-clock-filled.svg";

export const LessonWrapper = styled(Box)`
	display: flex;
	margin-top: 25px;
	flex-direction: column;

	${({ theme }) => theme.breakpoints.down("sm")} {
		padding: 0 0 0 16px;
	}
`;

export const useStyles = makeStyles((theme: IExtendedTheme) => ({
	lessonScreenContainer: {
		display: "flex",
		alignItems: "flex-start",
		flexDirection: "row",
		width: "97%",
		[theme.breakpoints.down("xs")]: {
			flexWrap: "wrap",
			width: "100%"
		}
	},
	video: {
		width: "100% !important",
		display: "block",
		height: "auto !important",
		paddingTop: "0 !important",
		"& > video": {
			width: "100%",
			display: "block"
		}
	},
	back: {
		fontWeight: 800,
		backgroundColor: theme.palette.lightButtonColors.basic.backgroundColor,
		color: theme.palette.lightButtonColors.basic.color
	},
	breadcrumb: {
		color: `${theme.palette.colors.basic[100]}99`,
		fontSize: theme.typography.pxToRem(13)
	},
	breadcrumbActive: {
		color: `${theme.palette.colors.basic[100]}99`,
		fontSize: theme.typography.pxToRem(13)
	},
	expandableLesson: {
		cursor: "pointer"
	},
	lessonAttachmentsItem: {
		display: "flex",
		flexDirection: "row"
	},
	lessonAttachmentsItemColumn: {
		display: "flex",
		flexDirection: "column",
		width: "100%"
	},
	lessonAttachmentsItemRow: {
		paddingLeft: "10px",
		paddingRight: "15px",
		paddingTop: "4px"
	},
	lessonDescription: {
		display: "block",
		flexDirection: "row",
		fontSize: theme.typography.pxToRem(13),
		paddingLeft: "13px",
		paddingTop: "15px",
		paddingRight: "15px",
		paddingBottom: "10px"
	},
	lessonDetails: {
		display: "flex",
		flexDirection: "row",
		alignContent: "center",
		alignItems: "center",
		fontSize: theme.typography.pxToRem(12),
		color: theme.palette.colors.basic[600],
		paddingLeft: "10px",
		paddingRight: "15px",
		paddingTop: "5px",
		paddingBottom: "10px"
	},
	lessonLocked: {
		margin: "0 0 5px 0",
		fontWeight: 500,
		lineHeight: 1.33,
		letterSpacing: "normal",
		color: `${theme.palette.colors.basic[100]}61`
	},
	lessonImage: {
		width: "100%",
		height: "100%",
		objectFit: "cover",
		filter: "blur(4px)"
	},
	lessonSection: {
		backgroundColor: theme.palette.background.paper,
		borderRadius: "3px",
		marginBottom: "15px"
	},
	lessonSectionControl: {
		fontSize: theme.typography.pxToRem(40)
	},
	lessonSectionControlContainer: {
		display: "flex",
		flexDirection: "column",
		marginLeft: "auto",
		marginRight: "15px"
	},
	cancelButton: {
		position: "fixed",
		right: "16vw",
		top: "8vh"
	},
	trailerVideoDiv: {
		[theme.breakpoints.down("sm")]: {
			margin: "20px"
		}
	},
	lessonSectionIndex: {
		flexShrink: 0,
		flexGrow: 0,
		color: theme.palette.text.primary,
		backgroundColor: theme.palette.background.paper,
		fontSize: theme.typography.pxToRem(18),
		fontWeight: "bold",
		borderRadius: "50%",
		marginLeft: "10px",
		marginRight: "10px",
		width: "35px",
		height: "35px",
		display: "flex",
		alignItems: "center",
		justifyContent: "center"
	},
	lessonSectionLocked: {
		fontSize: theme.typography.pxToRem(14)
	},
	lessonSectionTitle: {
		color: theme.palette.colors.basic[700],
		backgroundColor: darken(theme.palette.background.paper, 0.1),
		borderRadius: "3px",
		fontSize: theme.typography.pxToRem(16),
		fontWeight: 540,
		display: "flex",
		flexDirection: "row",
		alignContent: "center",
		alignItems: "center",
		paddingTop: "8px",
		paddingBottom: "8px"
	},
	lessonSectionTitleSelected: {
		color: theme.palette.colors.basic[500]
	},
	playableLesson: {
		cursor: "pointer",
		"& > div[data-vjs-player='true']": {
			position: "relative !important"
		}
	},
	title: {
		fontSize: theme.typography.pxToRem(18)
	},
	unlockedInfoBox: {
		color: theme.palette.colors.basic[300],
		position: "absolute",
		top: "calc(50% - 50px);",
		left: "calc(50% - 50px)"
	},
	notesHeader: {
		backgroundColor: theme.palette.colors.primary[600],
		color: theme.palette.colors.basic[100],
		backdropFilter: "blur(16px)",
		padding: 8,
		borderRadius: "4px 4px 0 0"
	},
	notesBody: {
		backdropFilter: "blur(16px)",
		border: "solid 1px rgba(77, 80, 88, 0.16)",
		backgroundColor: theme.palette.background.default,
		padding: 10,
		borderRadius: "0 0 4px 4px"
	},
	infoBoxIcon: {
		color: theme.palette.text.primary,
		width: "100px",
		height: "100px"
	},
	trailerProgress: {
		fontSize: `"${theme.typography.pxToRem(16)}"`,
		fontWeight: "bold",
		fontStretch: "normal",
		fontStyle: "normal",
		lineHeight: "1.25",
		letterSpacing: "normal",
		textAlign: "center",
		color: theme.palette.text.primary,
		float: "right",
		margin: "4px 3px 0 0"
	},
	trailerSubscription: {
		fontSize: `"${theme.typography.pxToRem(16)}"`,
		fontWeight: "bold",
		fontStretch: "normal",
		fontStyle: "normal",
		lineHeight: "1.25",
		letterSpacing: "normal",
		textAlign: "center",
		color: theme.palette.text.primary
	},
	dragItem: {
		color: theme.palette.common.black,
		fontSize: `"${theme.typography.pxToRem(15)}"`,
		opacity: 0.87,
		fontFamily: "var(--poppins)",
		fontWeight: 600,
		lineHeight: 1.6
	},
	discussionBoard: {
		width: "100%",
		marginTop: "20px !important",
		backgroundColor: theme.palette.background.paper,
		color: theme.palette.text.primary,
		marginBlock: "16px",
		borderRadius: "6px",
		padding: "10px",
		"&:before": {
			height: 0
		}
	},
	commentPost: {
		padding: "8px 16px 5px",
		"& a": {
			textDecoration: "underline"
		}
	},
	avatar: {
		margin: "12px",
		marginTop: "28px"
	},
	commentThumbUp: {
		width: "0.6em",
		height: "0.6em",
		marginLeft: "4px",
		cursor: "pointer",
		fill: props =>
			`${props["totalLike"] === 0 ? "#586179" : props["isLiked"] && props["totalLike"] > 0 ? "#4a99f9" : "#898f9e"}`
	},
	commentThumbDown: {
		width: "0.6em",
		height: "0.6em",
		marginLeft: "4px",
		cursor: "pointer",
		fill: props =>
			`${
				props["totalDislike"] === 0
					? "#586179"
					: props["isDisliked"] && props["totalDislike"] > 0
					? "#4a99f9"
					: "#898f9e"
			}`
	},
	selectCommentFilter: {
		"& > div": {
			fontSize: `${theme.typography.pxToRem(14)}`,
			opacity: "0.87",
			fontWeight: "normal",
			color: theme.palette.text.primary
		}
	},
	iconCommentExclamation: {
		margin: "12px",
		marginTop: "28px"
	},
	avatarUserReplied: {
		margin: "12px"
	}
}));

export const LockIcon = styled(Lock)`
	color: ${({ theme }) => theme.palette.colors.primary[400]};
	width: 100px;
	height: 100px;

	${({ theme }) => theme.breakpoints.down("sm")} {
		display: none;
	}}
`;

export const InfoText = styled.div`
		font-size: ${props => props.theme.typography.pxToRem(18)};
		margin-top: 5px;
		text-align: center;

	${({ theme }) => theme.breakpoints.down("sm")} {
		opacity: 0.87;
		font-family: var(--openSans);
		font-size: ${props => props.theme.typography.pxToRem(15)};
		line-height: 1.33;
		color: ${({ theme }) => theme.palette.text.primary};
		align-self: start;
		text-align: left;
	}}
`;

export const InfoHeader = styled.div`
	opacity: 0.87;
	font-family: var(--poppins);
	font-size: ${props => props.theme.typography.pxToRem(18)};
	font-weight: bold;
	align-self: start;
	line-height: 1.33;
	color: ${({ theme }) => theme.palette.text.primary};

	${({ theme }) => theme.breakpoints.up("sm")} {
			font-size: ${props => props.theme.typography.pxToRem(28)};
			font-weight: bolder;
			margin-top: 30px;
			margin-bottom: 5px;
			align-self: auto;
	}}
`;

export const LessonPreviewInfo = styled.div`
	padding: 24px;
	color: ${({ theme }) => theme.palette.colors.basic[300]};
	${({ theme }) => theme.breakpoints.up("sm")} {
		padding: 0;
		width: 350px;
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
	}}
`;

export const VITLessonContainer = styled.div`
	position: relative;
	width: 100%;
`;

export const LessonPreviewContainer = styled.div`
	position: relative;
	width: 100%;

	${props => props.theme.breakpoints.up("md")} {
		min-height: 450px;
	}
`;

export const SequenceAnswerOptionContainer = styled.div`
	display: flex;
	flex-flow: row nowrap;
	margin: 8px 0;

	${prop => prop.isMobile} {
		width: 50%;
	}
`;

export const ButtonContainer = styled(Box)`
	display: flex;
	flex-direction: row;
	justify-content: center;
	gap: 16px;

	${props => props.theme.breakpoints.down("sm")} {
		flex-direction: column;
		justify-content: center;
		display: flex;
		align-items: center;
		padding-left: 24px;
		padding-right: 24px;

		> button {
			width: 100%;
		}
	}
`;

export const SequenceIndex = styled.div`
	width: 48px;
	height: 48px;
	display: flex;
	align-items: center;
	justify-content: center;
	font-family: var(--openSans);
	font-size: ${props => props.theme.typography.pxToRem(15)};
	font-weight: 600;
	color: ${({ theme }) => theme.palette.colors.basic[1450]};
	background: ${({ theme }) => theme.palette.colors.basic[100]};
	margin-right: 8px;
`;

export const SequenceText = styled.div`
	height: 48px;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	font-family: var(--openSans);
	display: flex;
	flex-grow: 1;
	padding-left: 16px;
	align-items: center;
	font-size: ${props => props.theme.typography.pxToRem(15)};
	font-weight: 600;
	color: ${({ theme }) => theme.palette.colors.basic[1450]};
	background: ${({ theme }) => theme.palette.colors.basic[100]};
`;

export const SequenceDrag = styled.div`
	width: 48px;
	height: 48px;
	display: flex;
	align-items: center;
	justify-content: center;
	font-family: var(--openSans);
	font-size: ${props => props.theme.typography.pxToRem(34)};
	font-weight: 600;
	color: ${({ theme }) => theme.palette.colors.basic[1450]};
	background: ${({ theme }) => theme.palette.colors.basic[100]};
	cursor: grabbing;
`;

export const NotesContainer = styled.div`
	font-family: var(--openSans);
	font-size: ${props => props.theme.typography.pxToRem(13)};
	line-height: 1.23;
	color: hsl(224, 7%, 32%);
	border-top: 1px solid hsl(218, 27%, 94%);
	display: flex;
	align-items: center;
	padding-top: 15px;
	margin-top: 15px;
	padding-left: 4px;
	cursor: pointer;
	gap: 15px;
`;

export const BackgroundImage = styled.div`
	background: ${props => `linear-gradient(rgba(19, 22, 30, 0.7),rgba(19, 22, 30, 0.7)), url(${props.imgUrl})`};
	background-size: cover;
	display: block;
	filter: blur(4px);
	-webkit-filter: blur(4px);
	height: 500px;
	left: 0;
	right: 0;
	z-index: 1;
`;

export const ContentContainer = styled.div`
	border-radius: 3px;
	top: 50%;
	transform: translateY(-50%);
	position: absolute;
	left: 0;
	right: 0;
	z-index: 2;
	flex-direction: column;
	align-items: center;
	padding: 0 25px;
	${({ theme }) => theme.breakpoints.up("md")} {
		padding: 0 20%;
	}
`;

export const FullWidthContainer = styled.div`
	width: 100%;
	position: relative;
`;

export const QuizLessonContainer = styled.div`
	width: 100%;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	cursor: auto;
`;

export const StartLessonBtnContainer = styled(Box)`
	display: flex;
	align-items: center;
	justify-content: center;
`;

export const QuizLessonTitle = styled.h1`
	opacity: 0.87;
	font-family: var(--poppins);
	font-size: ${props => props.theme.typography.pxToRem(18)};
	font-weight: bold;
	line-height: 1.33;
	letter-spacing: normal;
	color: ${({ theme }) => theme.palette.text.primary};
	margin: 0 0 16px;
`;

export const VITQuizLessonTitle = styled(QuizLessonTitle)`
	font-family: var(--openSans);
	margin: 0 0 8px;
	justify-content: center;
	display: flex;
`;

export const QuizLessonDescription = styled.div`
	opacity: 0.87;
	font-family: var(--openSans);
	font-size: ${props => props.theme.typography.pxToRem(15)};
	line-height: 1.33;
	letter-spacing: normal;
	text-align: center;
	color: ${({ theme }) => theme.palette.text.primary};
	max-width: 50%;
	margin: 0 0 32px;
`;

export const VITLessonDescription = styled(QuizLessonDescription)`
	margin: 0 0 26px;
	justify-content: center;
	display: flex;
	max-width: 100%;
	line-height: 15px;
	max-height: 120px;
	overflow: hidden;
`;

export const QuizLessonTestTitle = styled.div`
	width: 100%;
	padding: 16px 16px;
	color: ${({ theme }) => theme.palette.common.white};
	background: ${({ theme }) => theme.palette.colors.primary[600]};
	opacity: 0.87;
	font: bold 18px/1.33 var(--poppins);
`;

export const QuizLessonNavigationBar = styled.div`
	display: flex;
	align-items: center;
	flex-wrap: nowrap;
	background: ${({ theme }) => theme.palette.colors.primary[400]};
	width: 100%;
	padding: 0 8px;

	${props => props.theme.breakpoints.down("sm")} {
		flex-direction: column;
	}

	> div {
		width: 50%;
		${props => props.theme.breakpoints.down("sm")} {
			width: 100%;
		}
	}
`;

export const NavigationBarLeftContainer = styled(Box)`
	display: flex;
	color: ${({ theme }) => theme.palette.common.white};
	${props => props.theme.breakpoints.down("sm")} {
		justify-content: space-between;
		padding-top: 10px;
	}
`;

export const NavigationBarRightContainer = styled(Box)`
	display: flex;
	justify-content: space-between;
	${props => props.theme.breakpoints.down("sm")} {
		justify-content: space-evenly;
	}
`;

export const QuizLessonLessonNumber = styled.div`
	text-align: center;
	font: bold 15px/1.6 var(--openSans);
	color: ${({ theme }) => theme.palette.colors.basic[100]};
	padding: 10px 0;

	span {
		opacity: 0.6;
	}
`;

export const QuizQuestionContainer = styled.div<{ minHeight: boolean }>`
	display: flex;
	flex-flow: row;
	width: 100%;
	${props => props.theme.breakpoints.down("sm")} {
		flex-direction: column;
	}
	${({ minHeight }) =>
		minHeight &&
		css`
			min-height: 400px;
		`}
`;

export const QuizQuestionContent = styled.div<{ offWhiteBg: boolean }>`
	width: 100%;
	flex-grow: 1;
	background: #eceff4;
	padding: 16px 16px 0 16px;
	color: #4d5058;
	display: flex;
	flex-flow: column;
	font-weight: 600;
	font-size: ${props => props.theme.typography.pxToRem(15)};
	line-height: 1.6;
	${({ offWhiteBg }) =>
		offWhiteBg &&
		css`
			background: #dbdee4;
		`}
`;

export const QuizQuestionTitle = styled.h1`
	padding: 14px 14px 8px;
	margin: 0;
	flex: 0;
	font-family: var(--openSans);
	font-size: ${props => props.theme.typography.pxToRem(15)};
	font-weight: 600;
	line-height: 1.6;
	color: #4d5058;
`;
export const QuizQuestionText = styled.div`
	font-family: var(--openSans);
	font-size: ${props => props.theme.typography.pxToRem(13)};
	font-weight: 600;
	line-height: 1.85;
	color: #4d5058;
	letter-spacing: normal;
	padding: 0 14px 14px;
`;
export const QuizeSequenceInCorrectContainer = styled.div`
	width: 100%;
	padding: 30px 0;
`;
export const QuizQuestionAnswerOption = styled.div`
	width: 100%;
	padding: 5px 16px;
	display: flex;
	align-items: center;
	font-family: var(--openSans);
	flex-flow: ${({ wrap }) => (wrap ? "wrap" : "nowrap")};
	${props => props.theme.breakpoints.down("sm")} {
		padding: 0;
	}
	${({ isChecked, isCorrect }) =>
		isCorrect && isChecked
			? css`
	background: rgba(35, 179, 74, .32);
	margin: 2px 0;
	> div:first-child  { color: ${({ theme }) => theme.palette.colors.success[600]}; {}
	`
			: isCorrect
			? css`
					background: ${({ theme }) => theme.palette.colors.primary[100]};
					margin: 2px 0;
					> div:first-child {
						color: ${({ theme }) => theme.palette.colors.primary[500]};
					}
			  `
			: isChecked
			? `
	margin: 2px 0;
	background: ${({ theme }) => theme.palette.colors.basic[200]};
	> div:first-child  { color: ${({ theme }) => theme.palette.colors.basic[1450]}; }
			`
			: ""}
`;

export const QuizButtonsRow = styled.div<{ bgColor: string }>`
	padding: 72px 16px 16px;
	background: ${({ theme }) => theme.palette.colors.basic[1500]};
	width: 100%;
	gap: 16px;
	display: flex;
	justify-content: flex-end;
`;

export const QuizResultHeader = styled.div`
	width: 100%;
	background: ${({ theme }) => theme.palette.background.paper};
	color: ${({ theme }) => theme.palette.text.primary};
	opacity: 0.87;
	font-size: ${props => props.theme.typography.pxToRem(15)};
	font-weight: 600;
	line-height: 1.6;
	padding: 20px 24px;
	font-family: var(--poppins);
	justify-content: center;
	display: flex;
`;

export const QuizResultTitle = styled.div`
	opacity: 0.87;
	font-family: var(--poppins);
	font-size: ${props => props.theme.typography.pxToRem(18)};
	font-weight: bold;
	line-height: 1.33;
	letter-spacing: normal;
	text-align: center;
	color: ${({ theme }) => theme.palette.text.primary};
	margin-bottom: 38px;
`;

const CommonQuizStyle = styled(Box)`
	background: ${({ theme }) => theme.palette.background.paper};
	width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
`;

export const QuizResult = styled(CommonQuizStyle)`
	padding: 0 16px;
`;

export const QuizStart = styled(CommonQuizStyle)`
	height: 500px;
	justify-content: center;
`;

export const QuizResultSummary = styled.div`
	background: ${({ theme }) => theme.palette.background.default};
	border-radius: 4px;
	max-width: 560px;
	width: 100%;
	padding: 50px;
	display: flex;
	flex-flow: row;
	flex-wrap: nowrap;
	margin-bottom: 42px;
	justify-content: space-around;
	font-family: var(--openSans);

	${props => props.theme.breakpoints.down("sm")} {
		flex-flow: column;
		justify-content: center;
		display: flex;
		align-items: center;
		padding: 50px 5px;

		> div:last-child {
			width: 165px;
			margin-top: 25px;
		}
	}
`;

export const AutoplayContainer = styled.div`
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	background: rgba(0, 0, 0, 0.5);
`;

export const NextLessonTimer = styled.div`
	opacity: 0.87;
	font-family: var(--poppins);
	font-size: ${props => props.theme.typography.pxToRem(18)};
	font-weight: bold;
	line-height: 1.33;
	color: #fff;
	position: absolute;
	bottom: 26px;
	right: 45px;
	${props => props.theme.breakpoints.down("sm")} {
		${props => !props.isVideoOnlyLesson && "bottom: 0px;"}
		right: 0px;
		font-size: ${props => props.theme.typography.pxToRem(12)};
	}
`;

const autoplayAnimation = keyframes`
	from {
		stroke-dashoffset: 450px;
	}
	to {
		stroke-dashoffset: 0;
	}
`;
export const Progress = styled.svg`
	background: transparent;
	fill: none;
	width: 136px;
	height: 136px;
	display: block;
	stroke-dasharray: 450px;
	stroke-dashoffset: -450px;
	stroke-linecap: round;
	stroke-width: 2px;
	stroke: #fff;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%) rotate(-90deg);
	animation: ${autoplayAnimation} 5s linear;
`;

export const ProgressCircle = styled.circle.attrs({
	cx: "68",
	cy: "68"
})``;

export const PlayNextLesson = styled.div`
	color: #fff;
	width: 136px;
	height: 136px;
	border-radius: 50%;
	background: rgba(0, 0, 0, 0.5);
	${props => props.theme.breakpoints.down("sm")} {
		height: 104px;
		width: 104px;
	}
`;

export const Play = styled(PlayArrow)`
	font-size: ${props => props.theme.typography.pxToRem(136)};
	width: 136px;
	height: 136px;
	${props => props.theme.breakpoints.down("sm")} {
		height: 104px;
		width: 104px;
	}
`;

export const QuizCalculatorButton = styled.div`
	flex-grow: 0;
	opacity: 0.87;
	font: 12px/1.33 var(--poppins);
	font-weight: 600;
	line-height: 1.6;
	color: ${({ theme }) => theme.palette.colors.basic[100]};
	display: flex;
	align-items: center;
	padding: 0 8px;
	cursor: pointer;
`;

export const CalculatorIcon = styled(Calculator)`
	margin-right: 5px;
`;

export const CountdownIcon = styled(Countdown)`
	margin-right: 5px;
`;

export const QuizPercentage = styled(Box)`
	color: #${({ passed, test, theme }) => (test ? theme.palette.text.primary : passed ? "#30d14d" : "#ed5465")};
	font-family: var(--poppins);
	font-size: ${props => props.theme.typography.pxToRem(40)};
	font-weight: 600;
	line-height: 1.2;
	display: flex;
	flex-flow: column;
	align-items: center;
	justify-content: center;

	> div {
		font-size: ${props => props.theme.typography.pxToRem(13)};
		font-weight: 600;
		font-style: normal;
		line-height: 1.85;
		color: ${({ theme }) => theme.palette.text.primary};
		opacity: 0.87;
		font-family: var(--poppins);
	}

	> span {
		font-family: var(--poppins);
		font-size: ${props => props.theme.typography.pxToRem(40)};
		font-weight: 600;
		font-stretch: normal;
		font-style: normal;
		line-height: 1.2;
		letter-spacing: normal;
		text-align: center;
		color: #4a99f9;
	}
`;

export const QuizResultSummaryRow = styled.div<{ mt: boolean }>`
	opacity: 0.87;
	font-size: ${props => props.theme.typography.pxToRem(13)};
	line-height: 1.23;
	color: ${({ theme }) => theme.palette.text.primary};
	display: flex;
	align-items: center;
	padding: 2px 0;
	font-family: var(--poppins);
	width: 100%;

	${props => props.theme.breakpoints.down("sm")} {
		justify-content: flex-start;
	}

	${({ mt }) =>
		mt &&
		css`
			margin-top: 5px;
		`}
`;

export const CorrectAnswersIcon = styled(CheckCircleOutline)`
	color: #23b34a;
	margin-right: 5px;
	width: 32px;
	height: 32px;
`;
export const IncorrectAnswersIcon = styled(ErrorOutline)`
	color: #ed5465;
	margin-right: 5px;
	width: 32px;
	height: 32px;
`;

export const QuizResultButtons = styled.div`
	width: 100%;
	text-align: center;
	margin-bottom: 50px;
`;

export const QuizResultLabel = styled.div`
	flex: 0.3;
	font-weight: bold;
	font-family: var(--poppins);
`;

export const QuizResultItem = styled.div`
	display: flex;
	width: 100%;
	&:not(:first-child) {
		margin-top: 26px;
	}
	${props => props.theme.breakpoints.down("sm")} {
		flex-direction: column;
	}
`;
export const QuizResultItemQuestion = styled.div`
	flex-grow: 1;
	border-bottom: 1px solid #b1b6c3;
	padding-bottom: 80px;
`;

export const QuizResultRationaleGrid = styled.div`
	margin: 25px 17px;
	display: flex;
	flex-flow: row nowrap;
`;

export const QuizResultRationaleText = styled.div`
	flex: 1 1 0;
	max-width: 100%;
	overflow: hidden;
`;

export const QuizResultRationale = styled.div`
	margin-left: 5px;
`;

export const QuizResultRationaleResult = styled.div`
	font-family: var(--openSans);
	font-size: 15px;
	font-weight: 600;
	font-stretch: normal;
	font-style: normal;
	margin-bottom: 12px;
	display: flex;
	align-items: center;
	line-height: 1.6;
	letter-spacing: normal;
	color: #4d5058;
`;

export const Zoom = styled.div`
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: rgba(0, 0, 0, 0.7);
	z-index: 100;
	padding: 5vh 5vw;

	> img {
		display: block;
		margin: 0 auto;
		max-height: 90vh;
		max-width: 90vw;
	}
`;
export const QuizResultRationaleImg = styled.div`
	flex-grow: 0;
	flex-shrink: 0;
	width: 200px;
	margin-left: 32px;
	position: relative;
	border-radius: 8px;
	overflow: hidden;

	> img {
		display: block;
		max-width: 100%;
	}

	> .overlay {
		display: none;
		background: rgba(0, 0, 0, 0.7);
		font-family: var(--openSans);
		font-size: ${props => props.theme.typography.pxToRem(12)};
		font-weight: 600;
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		text-align: center;
		padding-top: 40%;
		flex-flow: column;
		color: #fff;
		cursor: pointer;

		> svg {
			display: block;
			margin: 0 auto;
		}
	}

	&:hover {
		> .overlay {
			display: block;
		}
	}
`;

export const QuizResultItemRationale = styled.div`
	width: 436px;
	flex-grow: 0;
	flex-shrink: 0;
	background: #fff;
	border-radius: 4px;
	margin-left: 40px;
	font-size: ${props => props.theme.typography.pxToRem(13)};
	overflow: hidden;

	> h1 {
		display: flex;
		align-items: center;
		font-size: ${props => props.theme.typography.pxToRem(15)};
		font-weight: 600;
		line-height: 1.6;
		letter-spacing: normal;
		color: #4d5058;
	}

	${props => props.theme.breakpoints.down("sm")} {
		width: auto;
		margin-left: 0;
		margin-top: 10px;
	}
`;

export const NotesBtn = styled.div`
	display: flex;
	align-items: center;
	font: 12px/1.33 var(--poppins);
	opacity: ${({ disabled }) => (disabled ? 0.44 : 0.87)};
	font-weight: 600;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.6;
	letter-spacing: normal;
	cursor: pointer;
	svg {
		margin-right: 5px;
	}
`;

export const FeedbackIcon = styled(Feedback)`
	margin-right: 5px;
`;

export const FeedbackBtn = styled.div`
	display: flex;
	align-items: center;
	font: 12px/1.33 var(--poppins);
	opacity: ${({ disabled }) => (disabled ? 0.44 : 0.87)};
	font-weight: 600;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.6;
	letter-spacing: normal;
	cursor: pointer;
	div {
		display: flex;
		align-items: center;
	}
	* {
		opacity: ${({ disabled }) => (disabled ? 0.8 : 1)};
	}
`;

export const QuizCountdown = styled.div`
	display: flex;
	align-items: center;
	margin: 0 8px;
	font: 13px/1.33 var(--poppins);
	font-weight: 600;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.6;
	letter-spacing: normal;
	color: #fff;
`;

export const QuizCountdownText = styled.span`
	margin: 0 8px 0 0;
	opacity: 0.6;
	font: 13px/1.33 var(--poppins);
	font-weight: 600;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.6;
	letter-spacing: normal;
	color: ${({ theme }) => theme.palette.colors.basic[100]};
`;

export const StyledText = styled.span`
	word-break: break-all;
	font-family: var(--poppins);
	cursor: default;
	display: flex;
	font-size: ${props => props.theme.typography.pxToRem(13)};
	font-stretch: normal;
	font-style: normal;
	line-height: 1.85;
	letter-spacing: normal;
`;

export const StyledOptText = styled.span`
	word-break: break-word;
	opacity: 0.87;
	font-family: var(--poppins);
	font-size: ${props => props.theme.typography.pxToRem(13)};
	font-weight: 600;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.85;
	letter-spacing: normal;
	color: #4d5058;
`;

export const StyledGroupText = styled.div`
	border-right: solid 1px rgba(151, 151, 151, 0.3);
	padding: 13px 0 0 16px;
	word-break: break-word;
	opacity: 0.87;
	font-family: var(--poppins);
	font-size: ${props => props.theme.typography.pxToRem(13)};
	height: 100%;
	font-weight: 600;
	line-height: 1.85;
	color: #4d5058;
	max-width: 50%;
	flex: ${({ $flex }) => $flex && 1};

	&:last-child {
		border-right: none;
	}
`;

export const QuizResultTimeSpent = styled.div`
	display: flex;
	justify-content: flex-start;
	align-items: center;
`;

export const QuizResultTimeSpentText = styled.span`
	margin-left: 8px;
	font-family: var(--openSans);
	font-weight: normal;
`;

export const QuizResultPreviewNotes = styled.span`
	font-family: var(--openSans);
	font-size: 13px;
	font-weight: normal;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.23;
	letter-spacing: normal;
	color: #4d5058;
`;

export const TrailerContainer = styled.div`
	position: absolute;
	top: 100px;
	left: 50px;
	z-index: 999;
	${props => props.theme.breakpoints.down("sm")} {
		position: static;
		display: flex;
		flex-direction: column;
		align-items: center;
		padding: 8px;
		margin-top: 10px;
	}
`;

export const TrailerHeading = styled.div`
	margin: 0 121px 8px 0;
	opacity: 0.87;
	font-family: var(--poppins);
	font-size: ${props => props.theme.typography.pxToRem(18)};
	font-weight: bold;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.33;
	letter-spacing: normal;
	color: ${({ theme }) => theme.palette.text.primary};
`;

export const TrailerText = styled.div`
	margin-top: 10px;
	font-size: ${props => props.theme.typography.pxToRem(20)};
	width: 255px;
	height: 40px;
	opacity: 0.87;
	font-size: ${props => props.theme.typography.pxToRem(15)};
	font-weight: normal;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.33;
	letter-spacing: normal;
	color: #fff;
`;

export const DragEqualDiv = styled.div`
	color: ${({ theme }) => theme.palette.colors.basic[1300]};
	font-size: ${props => props.theme.typography.pxToRem(40)};
	width: 32px;
	line-height: 48px;
	cursor: grabbing;
	text-align: center;
`;

export const DragContainer = styled.div`
	-webkit-user-select: none; /* Safari */
	-ms-user-select: none; /* IE 10 and IE 11 */
	user-select: none; /* Standard syntax */
`;

export const TrailerUpgradeSubscription = styled(Button)`
	margin-top: 20px;
	font-size: ${props => props.theme.typography.pxToRem(14)} !important;
	width: 255px;
	height: 48px;
	border-radius: 4px;
	background-color: #264fae !important;

	${props => props.theme.breakpoints.down("sm")} {
		span:last-child {
			font-size: 100%;
			padding-right: 5px;
		}
	}
`;

export const TrailerWatchAgain = styled(Button)`
	width: 255px;
	height: 48px;
	margin-top: 20px;
	font-size: ${props => props.theme.typography.pxToRem(14)} !important;
	border: solid 1px #94979f !important;
	border-radius: 4px;

	> span {
		font-size: ${props => props.theme.typography.pxToRem(16)};
		font-weight: bold;
		font-stretch: normal;
		line-height: 1.25;
		letter-spacing: normal;
		text-align: center;
		color: rgba(255, 255, 255, 0.6);
	}
`;

export const CompletionCertificateText = styled(Typography)`
	opacity: 0.87;
	font-size: ${props => props.fontSize};
`;

export const CompletionCertificateLink = styled(Typography)`
	opacity: 0.87;
	text-align: center;
	margin-top: 20px;
`;
export const LessonMediaContainer = styled(Box)`
	display: none;

	${({ theme }) => theme.breakpoints.down("sm")} {
		margin-top: 16px;
		width: 100%;
		display: block;
	}
`;

export const DiscussionBoardContainer = styled.div`
	width: 100%;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	cursor: auto;
`;

export const LessonContainer = styled.div`
	display: flex;
	flex-direction: row;
	align-items: flex-start;
	width: 97%;
	${props => props.theme.breakpoints.down("sm")} {
		flex-direction: column;

		.vjs-fluid {
			padding: 0 !important;
		}

		.vjs-tech {
			position: static !important;
		}
	}
`;

export const LessonVideoContainer = styled(Box)`
	margin-right: 2%;
	${props => props.theme.breakpoints.down("sm")} {
		width: 100%;
		> div[data-vjs-player="true"] {
			height: 100%;

			> video {
				top: -120px;
			}
		}
	}
`;

export const MRTableHeader = styled(Box)`
	min-height: 64px;
	border-radius: 3px;
	background-color: #fff;
`;

export const MRTableBody = styled(Box)`
	max-width: 595px;
	background-color: #edeef1;
	border-right: solid 1px rgba(151, 151, 151, 0.3);
	border-left: solid 1px rgba(151, 151, 151, 0.3);
`;

export const CheckBoxWrapper = styled(Box)`
	flex-direction: row;
	width: 100%;
	height: 100%;
	display: flex;
`;

export const CaseStudyTabs = styled.div`
	display: flex;
	flex-flow: row wrap;
	align-items: start;
	margin: 0 -2px;
`;

export const CaseStudyTab = styled.div`
	height: 48px;
	flex-flow: row wrap;
	opacity: 0.87;
	font-size: ${props => props.theme.typography.pxToRem(15)};
	font-weight: 600;
	line-height: 1.6;
	color: #4d5058;
	background: ${({ active }) => (active ? "#fff" : "#ccc")};
	border-radius: 4px 4px 0 0;
	margin: 0 2px;
	cursor: pointer;
	display: inline-block;
	max-width: 200px;
	white-space: nowrap;
	overflow: hidden !important;
	text-overflow: ellipsis;
	padding: 12px 11px 0;
`;

export const CaseStudyTabContent = styled.div`
	background: #fff;
	padding: 15px;
	border-radius: 0 4px 4px 4px;

	> p {
		margin: 8px 0;
	}
`;

export const CaseStudyRow = styled.div`
	display: flex;
	flex-flow: row nowrap;
	margin-bottom: 50px;
	${props => props.theme.breakpoints.down("sm")} {
		flex-direction: column;
	}
`;

export const CaseStudyColumn = styled.div`
	flex: 50% 0 0;
	padding: 0 10px 0 0;
	flex-direction: column;
	&:first-child {
		padding-right: 18px;
		border-right: 2px solid rgba(151, 151, 151, 0.23);
		${props => props.theme.breakpoints.down("sm")} {
			padding: 0 0 18px 0;
			border-right: none;
			border-bottom: 2px solid rgba(151, 151, 151, 0.23);
			margin-bottom: 15px;
		}
	}

	&:last-child {
		padding-left: 18px;
		${props => props.theme.breakpoints.down("sm")} {
			padding-left: 0;
			padding-right: 0;
		}
	}
`;

export const CaseStudyAlertContainer = styled.div`
	width: 50%;
	background: rgba(255, 255, 255);
	opacity: 0.74;
	padding: 16px 8px 16px 35px;
	border-radius: 4px;
	color: rgba(77, 80, 88, 0.6);
	${props => props.theme.breakpoints.down("sm")} {
		width: 100%;
	}
`;
export const CaseStudyAlertTitle = styled.div`
	opacity: 0.7;
	font-family: var(--openSans);
	font-size: ${props => props.theme.typography.pxToRem(15)};
	line-height: 1.6;
	color: #4d5058;
	display: flex;
	align-items: center;
	margin-left: -27px;
`;

export const StyledError = styled(Error)`
	width: 18px;
	margin-right: 8px;
`;

export const Pages = styled.div`
	display: flex;
	flex-flow: row nowrap;
	justify-content: end;
	margin: 0 -2px;
`;

export const Page = styled.div`
	width: 32px;
	height: 32px;
	line-height: 32px;
	font-size: ${props => props.theme.typography.pxToRem(13)};
	font-weight: 600;
	margin: 0 2px;
	cursor: pointer;
	text-align: center;
	border-radius: 4px;

	${({ isArrow }) =>
		isArrow &&
		css`
			font-size: ${props => props.theme.typography.pxToRem(24)};
			background: #fff;
		`}

	${({ active }) =>
		active
			? css`
					background: #264fae;
					color: #fff;
			  `
			: css`
					color: #a6a7aa;
			  `}
`;

export const QuizResultAnswer = styled.div`
	display: flex;
	flex: 1;
	align-items: center;
	font-family: var(--poppins);
`;

export const BreadcrumbsContainer = styled.div`
	flex-shrink: 0;
`;

export const LessonInfoContainer = styled.div`
	width: 97%;
	display: flex;
	justify-content: space-between;
	margin-bottom: 24px;
`;

export const LessonsListContainer = styled.div`
	display: flex;
	flex-flow: row nowrap;
	gap: 16px;
	flex-shrink: 1;
	overflow-x: hidden;
	overflow-y: visible;
	position: relative;

	.swiper-button-prev,
	.swiper-button-next {
		position: absolute;
		top: 50%;

		&::after {
			display: none !important
		}
	}

	.swiper-button-prev {
		left: 0;
		transform: rotate(90deg);
	}
	.swiper-button-next {
		right: 0;
		transform: rotate(-90deg);
	}


	${({ theme }) => theme.breakpoints.down("sm")} {
		display: none;
	}}
	.swiper-wrapper {
		position: static !important;
		transition-property: margin-left;
		transform: none !important;
	}
	.swiper-container {
		overflow-y: visible;

		.swiper-slide {
			flex-shrink: 1;
		}




		.swiper-button-disabled {
			display: none !important;
	}

`;

export const LessonNumber = styled(Box)`
	border-radius: 4px;
	background: ${({ theme }) => darken(theme.palette.background.default, 0.1)};
	height: 56px;
	min-width: 56px;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-flow: row nowrap;
	color: ${({ current, theme }) => (current ? theme.palette.colors.basic[100] : theme.palette.colors.basic[500])};
	position: relative;
	font-weight: bold;
	cursor: pointer;

	span {
		z-index: 1;
	}

	${({ current, theme }) =>
		`
		&::before {
			content: ' ';
			position: absolute;
			top: 50%;
			right: 50%;
			color: ${current ? theme.palette.colors.basic[100] : theme.palette.text.primary};
			background: ${current ? theme.palette.primary.dark : theme.palette.background.paper};
			height: 32px;
			width: 32px;
			transform: translate(50%, -50%);
			border-radius: 50%;
		}
	`}

	${LockIcon} {
		color: ${({ theme }) => theme.palette.colors.basic[500]};
		position: absolute;
		top: 5px;
		right: 5px;
		height: 11px;
		width: 11px;
	}
`;

export const LessonInfoRightSide = styled.div`
	flex-flow: row nowrap;
	display: flex;
	gap: 40px;
	flex-shrink: 1;
	flex-grow: 0;
	overflow-x: hidden;
	overflow-y: visible;
	margin-right: 2%;
`;

export const LessonMaterials = styled.div`
	border-radius: 4px;
	width: 324px;
	flex-shrink: 1;
	flex-grow: 0;
	height: 56px;
	display: flex;
	background: ${({ theme }) => darken(theme.palette.background.default, 0.1)};
	//border: 2px solid #22252d;
	position: relative;

	&:hover {
		border-color: ${({ theme }) => theme.palette.text.primary};
	}

	${LessonNumber} {
		height: 52px;
	}
`;

export const LessonName = styled.div`
	flex-grow: 1;
	line-height: 56px;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	cursor: pointer;
`;

export const IconContainer = styled.div`
	display: flex;
	flex: 16px 0 0;
	align-items: center;

	cursor: pointer;
`;

export const SelectCherron = styled(ChevronLeft)`
	height: 32px;
	width: 32px;
	color: ${({ theme }) => theme.palette.colors.basic[1300]};
	transform: ${({ open }) => (open ? "rotate(90deg" : "rotate(-90deg")});

	transition: transform .2s;
`;

export const LessonsMaterialDropdown = styled(Box)`
	border-radius: 4px;
	background: ${({ theme }) => theme.palette.background.default};
	position: absolute;
	top: calc(100% + 5px);
	left: 0;
	padding: 20px 10px;
	color: ${({ theme }) => theme.palette.text.primary};
	z-index: 9999;
	transform: translateX(-50%);
	width: 328px;
`;

export const ContainTest = styled.div`
	display: flex;
	align-items: center;
	flex-flow: row nowrap;
	gap: 5px;
	margin-top: 15px;
`;

export const Description = styled(DescriptionIcon)`
	color: ${({ theme }) => theme.palette.colors.basic[1300]};
	height: 24px;
	width: 24px;
`;

export const DownloadsContainer = styled.div`
	margin-top: 15px;
`;

export const NextButton = styled(Button)`
	color: ${({ theme }) => theme.palette.common.white} !important;
	background: ${({ theme }) => theme.palette.colors.primary[500]};
	"&:hover": {
		background: ${({ theme }) => theme.palette.colors.primary[400]};
	}
`;

export const GroupingInfoText = styled.span`
	margin: 5px;
`;

export const GroupingContainer = styled.div`
	display: flex;
	width: 100%;
	padding: 0 14px 14px;

	${props => props.theme.breakpoints.down("sm")} {
		flex-direction: column;
	}

	> div:first-child {
		margin-right: 60px;

		${props => props.theme.breakpoints.down("sm")} {
			margin-right: 0;
			margin-bottom: 60px;
		}
	}
`;

export const GroupingCategoryContainer = styled.div`
	width: 100%;
	padding: 8px 8px 10px 5px;
	border-radius: 5px;
	background-color: ${({ theme }) => theme.palette.common.whiteShade1};
`;

export const GroupingInfoContainer = styled.div`
	display: flex;
	height: auto;
	padding: 4px 0 4px 14px;
	opacity: 0.3;
	font-family: var(--openSans);
	font-size: ${props => props.theme.typography.pxToRem(13)};
	font-weight: 600;
	line-height: 1.85;
	color: rgb(77, 80, 88);
	margin-bottom: 14px;
	align-items: center;

	${props => props.theme.breakpoints.down("sm")} {
		flex-flow: wrap;
	}
`;

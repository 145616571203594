import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { QuestionTypes } from "@remar/shared/dist/constants";
import {
	Question,
	QuestionAnswerOption,
	QuestionGroup,
	SavedAnswer,
	UserQuestionAttempt,
	UserQuizAttempt
} from "@remar/shared/dist/models";
import { getResetState } from "@remar/shared/dist/utils/stateUtils";
import { fromPairs, shuffle } from "lodash";

import { RootState } from "store";
import {
	UserQuestionAnswerDto,
	UserQuestionAttemptCreateDto,
	UserQuizAttemptCreateDto,
	userQuestionAttemptsService,
	userQuizAttemptsService
} from "store/services";

import { getQuestionCorrectGroups, getQuestionGroups, reorder } from "../Lesson/LessonUtils";

interface CSQuestions {
	questionIndex: number;
	id: number;
}
interface TestAttempt {
	isQuizResultLoading: boolean;
	isSelectedQuizProgressFetched: boolean;
	isLoading: boolean;
	isTestStartLoading: boolean;
	isNextLoading: boolean;
	optionsError: string;
	isAttemptLoading: boolean;
	errorMessage: string;
	quizQuestions?: Question[];
	quizAttemptId?: number;
	quizAttempt?: UserQuizAttempt;
	quizPassed: boolean;
	quizPercentage: number;
	quizResult: UserQuestionAttempt[];
	quizError: string;
	isQuizFinished: boolean;
	quizAnswers: {
		[key: string]: SavedAnswer;
	};
	attemptedCSQuestions: CSQuestions[];
	durationInMinutes: number;
	name: string;
	isRetake: boolean;
}
const initialState: TestAttempt = {
	isQuizResultLoading: false,
	isSelectedQuizProgressFetched: false,
	quizError: "",
	isLoading: false,
	isTestStartLoading: false,
	isAttemptLoading: false,
	errorMessage: "",
	optionsError: "",
	isNextLoading: false,
	quizPassed: false,
	isQuizFinished: false,
	quizPercentage: 0,
	quizAnswers: {},
	quizResult: [],
	attemptedCSQuestions: [],
	durationInMinutes: 0,
	name: "",
	isRetake: false
};

const utilsResetState = getResetState<TestAttempt>(initialState);

export const getUserQuizAttempt = createAsyncThunk(
	"quizAttempt/getUserQuizAttempt",
	async (data: { introQuizId?: number; quizId?: number; inProgress?: boolean }, { rejectWithValue }) => {
		try {
			const { ...filters } = data;
			return await userQuizAttemptsService.find({
				filters: { ...filters },
				findAll: true
			});
		} catch (error) {
			return rejectWithValue((error && (error as { message: string }).message) || "An error has occurred.");
		}
	}
);

export const createUserQuizAttempt = createAsyncThunk(
	"quizAttempt/createUserQuizAttempt",
	async (data: UserQuizAttemptCreateDto, { rejectWithValue }) => {
		return await userQuizAttemptsService.create({ ...data }).catch(error => rejectWithValue(error.message));
	}
);

export const completeUserQuizAttempt = createAsyncThunk(
	"quizAttempt/completeUserQuizAttempt",
	async (filters: { id: number }, { rejectWithValue }) => {
		return await userQuizAttemptsService
			.update({ data: { inProgress: false }, filters: { ...filters } })
			.catch(error => rejectWithValue(error.message));
	}
);

export const createUserQuestionAttempt = createAsyncThunk(
	"quizAttempt/createUserQuestionAttempt",
	async (data: UserQuestionAttemptCreateDto, { dispatch }) => {
		dispatch(setHandleNextLoading(false));
		dispatch(setOptionsError(""));
		const res = await userQuestionAttemptsService.create(data).catch(error => {
			if (error.name == "422") {
				dispatch(setOptionsError(error.message));
				dispatch(setHandleNextLoading(true));
			}
			data?.caseStudySideEffect && data.caseStudySideEffect();
			throw new Error(error);
		});
		dispatch(setHandleNextLoading(false));
		data.sideEffect && data.sideEffect();
		dispatch(setCreatePayload(res));
		data.caseStudySideEffect && data?.caseStudySideEffect();
		return res;
	}
);

export const updateUserQuestionAttempt = createAsyncThunk(
	"quizAttempt/updateUserQuestionAttempt",
	async (
		data: {
			userQuizAttemptId: number;
			userAnswers: UserQuestionAnswerDto[];
			timeSpent: number;
			sideEffect?: () => void;
			caseStudySideEffect?: () => void;
		},
		{ rejectWithValue, dispatch }
	) => {
		const res = await userQuestionAttemptsService
			.update({
				data: { userAnswers: data.userAnswers, timeSpent: data.timeSpent },
				filters: { id: data.userQuizAttemptId }
			})
			.catch(error => {
				if (error.name == "422") {
					dispatch(setOptionsError(error.message));
					dispatch(setHandleNextLoading(true));
				}
				rejectWithValue(error.message);
				data.caseStudySideEffect && data?.caseStudySideEffect();
				throw new Error(error.message);
			});
		dispatch(setHandleNextLoading(false));
		dispatch(setCreatePayload(res!.raw[0]));
		data.caseStudySideEffect && data.caseStudySideEffect();
		data.sideEffect && data.sideEffect();
		return res;
	}
);

export const retakeQuiz = createAsyncThunk(
	"quizAttempt/retakeQuiz",
	async ({ quizId }: { quizId: number }, { rejectWithValue }) => {
		return await userQuizAttemptsService.retakeQuiz(quizId).catch(rejectWithValue);
	}
);

export const takeTestSlice = createSlice({
	name: "takeTest",
	initialState,
	reducers: {
		clearQuizState: utilsResetState,
		retakeTest: state => {
			state.quizPassed = false;
			state.quizPercentage = 0;
			state.quizAnswers = {};
			state.quizResult = [];
			state.quizAttemptId = undefined;
			state.quizAttempt = undefined;
			state.isQuizFinished = false;
			state.attemptedCSQuestions = [];
			state.optionsError = "";
			state.isNextLoading = false;
			state.isRetake = true;
			state.isTestStartLoading = false;
		},
		moveGroupQuestionAnswerOption: (
			state,
			action: PayloadAction<{
				questionIndex: number;
				newGroupIndex: number;
				oldGroupIndex: number;
				oldOptionIndex: number;
				id: string;
				newOptionIndex: number;
				touchType?: string;
			}>
		) => {
			const { newGroupIndex, oldGroupIndex, oldOptionIndex, newOptionIndex, questionIndex, touchType, id } =
				action.payload;
			if (newGroupIndex === oldGroupIndex && touchType === "dragger") {
				const stateQuizQuestions = state?.quizQuestions;
				const findMatchedQuestion = stateQuizQuestions?.find((question, i) => i === questionIndex);
				const matchedGroup = findMatchedQuestion?.data.groups as QuestionGroup[];
				const findMatchedGroup = matchedGroup?.find((group, i) => i === oldGroupIndex);

				state.quizQuestions = findMatchedQuestion
					? stateQuizQuestions?.splice(questionIndex, 1, {
							...findMatchedQuestion,
							data: {
								groups: findMatchedGroup
									? matchedGroup.splice(oldGroupIndex, 1, {
											...findMatchedGroup,
											answerOptions: reorder(
												oldOptionIndex,
												newOptionIndex,
												matchedGroup[oldGroupIndex]?.answerOptions
											) as QuestionAnswerOption[]
									  })
									: findMatchedQuestion?.data.groups
							}
					  })
					: stateQuizQuestions;
			}
			if (newGroupIndex !== oldGroupIndex && touchType === "reorder") {
				const stateQuizQuestions = state?.quizQuestions;
				const findMatchedQuestion = stateQuizQuestions?.find((question, i) => i === questionIndex);
				const matchedGroup = findMatchedQuestion?.data.groups as QuestionGroup[];
				const findOldMatchedGroup = matchedGroup?.find((group, i) => i === oldGroupIndex);
				const findNewMatchedGroup = matchedGroup?.find((group, i) => i === newGroupIndex);
				const removed = matchedGroup[oldGroupIndex]?.answerOptions[oldOptionIndex];

				state.quizQuestions = findMatchedQuestion
					? stateQuizQuestions?.splice(questionIndex, 1, {
							...findMatchedQuestion,
							data: {
								groups: findOldMatchedGroup
									? matchedGroup.splice(oldGroupIndex, 1, {
											...findOldMatchedGroup,
											answerOptions: findOldMatchedGroup?.answerOptions?.filter(answerOption => answerOption.id !== id)
									  })
									: findNewMatchedGroup
									? matchedGroup.splice(newGroupIndex, 1, {
											...findNewMatchedGroup,
											answerOptions: findNewMatchedGroup.answerOptions?.splice(newOptionIndex, 0, removed)
									  })
									: findMatchedQuestion?.data.groups
							}
					  })
					: stateQuizQuestions;
			}
		},
		completeCaseStudyQuestion: (state, action: PayloadAction<{ questionId: number }>) => {
			state.quizAnswers![action.payload.questionId] = {
				id: action.payload.questionId,
				answers: [],
				timeSpent: 0
			};
		},
		setCSQuestions: (state, action: PayloadAction<{ questionIndex: number; id: number }>) => {
			state.attemptedCSQuestions.push({
				questionIndex: action.payload.questionIndex,
				id: action.payload.id
			});
			state.attemptedCSQuestions = [...new Map(state.attemptedCSQuestions.map(item => [item["id"], item])).values()];
		},
		setCreatePayload: (state, action) => {
			const attempt = action.payload;
			state.quizAnswers[attempt.subQuestionId || attempt.questionId] = {
				id: attempt.id,
				answers: attempt.selectedAnswers!.map(
					({ questionAnswerOptionId, questionGroupId, text, questionAnswerOptionOrder, questionId }) =>
						({
							text,
							order: questionAnswerOptionOrder,
							groupId: questionGroupId,
							id: questionAnswerOptionId,
							questionId,
							canAttachFiles: false
						} as QuestionAnswerOption)
				),
				timeSpent: attempt.timeSpent
			};
			if (state.quizQuestions && attempt.snapshot.data.answerOptions) {
				state.quizQuestions = state.quizQuestions.map(question => ({
					...question,
					data: {
						...question.data,
						answerOptions: question.data.answerOptions?.map(options => ({
							...options,
							isCorrect: attempt.snapshot.data.answerOptions.find(({ id }) => id === options.id)?.isCorrect
						}))
					}
				}));
			}
		},
		setOptionsError: (state, action) => {
			state.optionsError = action.payload;
		},
		setHandleNextLoading: (state, action) => {
			state.isNextLoading = action.payload;
		}
	},
	extraReducers: {
		[getUserQuizAttempt.pending.type]: state => {
			state.isLoading = true;
			state.isAttemptLoading = true;
			state.quizQuestions = [];
			state.quizAttemptId = undefined;
			state.quizAttempt = undefined;
			state.isQuizFinished = false;
			state.quizPassed = false;
			state.quizPercentage = 0;
			state.quizAnswers = {};
		},
		[getUserQuizAttempt.fulfilled.type]: (state, action: PayloadAction<{ items: UserQuizAttempt[] }>) => {
			if (!action.payload) {
				return;
			}
			const userQuizAttempts = action.payload?.items.sort((a, b) => a.createdAt!.localeCompare(b.createdAt!));
			const attempt =
				userQuizAttempts.find(ula => ula.inProgress) ||
				(userQuizAttempts.length > 0 ? userQuizAttempts[userQuizAttempts.length - 1] : undefined);
			const isRandomizeQuiz = attempt?.data.data.randomizeQuiz || false;
			const userAnswers = fromPairs(
				attempt?.userQuestionAttempts.map((questionAttempt: UserQuestionAttempt) => [
					questionAttempt.subQuestionId || questionAttempt.questionId,
					{
						id: questionAttempt.id,
						answers: questionAttempt.selectedAnswers!.map(
							({ questionAnswerOptionId, questionGroupId, text, questionAnswerOptionOrder, questionId }) =>
								({
									order: questionAnswerOptionOrder,
									text,
									groupId: questionGroupId,
									id: questionAnswerOptionId,
									questionId,
									canAttachFiles: false
								} as QuestionAnswerOption)
						),
						timeSpent: questionAttempt.timeSpent
					}
				])
			);
			let unAttemptedQuestion = attempt?.data.questions.filter(({ questionId }) => !userAnswers?.[questionId]);
			unAttemptedQuestion = isRandomizeQuiz ? shuffle(unAttemptedQuestion) : unAttemptedQuestion;

			const attemptedQuestion = attempt?.data.questions.filter(({ questionId }) => userAnswers?.[questionId]);

			const questions = !!attemptedQuestion?.length
				? [...attemptedQuestion, ...unAttemptedQuestion]
				: unAttemptedQuestion;

			if (attempt) {
				state.isQuizFinished = !attempt.inProgress;
				state.quizQuestions = questions?.map(q => {
					if ((q.question as Question).typeId === QuestionTypes.Grouping) {
						return {
							...(q.question as Question),
							data: { groups: getQuestionGroups(q.question) },
							correctGroups: getQuestionCorrectGroups(q.question)
						};
					} else
						return {
							...(q.question as Question),
							data: {
								...(q.question as Question).data,
								answerOptions:
									(q.question as Question).typeId === QuestionTypes.SingleChoice ||
									(q.question as Question).typeId === QuestionTypes.MultipleChoice
										? (q.question as Question).data.answerOptions
										: shuffle((q.question as Question).data.answerOptions)
							}
						};
				});
				state.quizAttemptId = attempt?.id;
				state.quizAttempt = attempt;
				state.quizAttempt.inProgress = attempt.inProgress;
				state.quizPassed = attempt?.passed ?? false;
				state.quizPercentage = Math.round((attempt?.percentageGrade ?? 0) * 100);
				state.quizResult = attempt.userQuestionAttempts;
				state.durationInMinutes = attempt.data.data.totalTimeLengthInMinutes;
				state.name = attempt.data.name;
				state.quizAnswers = userAnswers;
			}
			state.isAttemptLoading = false;
			state.isLoading = false;
			state.isSelectedQuizProgressFetched = true;
		},
		[createUserQuizAttempt.pending.type]: state => {
			state.isTestStartLoading = true;
		},
		[createUserQuizAttempt.fulfilled.type]: (state, action: PayloadAction<UserQuizAttempt>) => {
			const isRandomizeQuiz = action.payload?.data.data.randomizeQuiz || false;
			const questions = isRandomizeQuiz ? shuffle(action.payload?.data.questions) : action.payload?.data.questions;
			state.isTestStartLoading = false;
			state.quizError = "";
			state.quizQuestions = questions.map(q => {
				if ((q.question as Question).typeId === QuestionTypes.Grouping) {
					return {
						...q.question,
						data: { groups: getQuestionGroups(q.question) },
						correctGroups: getQuestionCorrectGroups(q.question)
					};
				} else
					return {
						...(q.question as Question),
						data: {
							...(q.question as Question).data,
							answerOptions:
								(q.question as Question).typeId === QuestionTypes.SingleChoice ||
								(q.question as Question).typeId === QuestionTypes.MultipleChoice
									? (q.question as Question).data.answerOptions
									: shuffle((q.question as Question).data.answerOptions)
						}
					};
			});
			state.name = action.payload.data.name;
			state.durationInMinutes = action.payload.data.data.totalTimeLengthInMinutes;
			state.quizAnswers = {};
			state.quizAttemptId = action.payload?.id;
			state.quizAttempt = action.payload;
			state.quizAttempt.inProgress = action.payload.inProgress;
			state.isQuizFinished = false;
		},
		[createUserQuizAttempt.rejected.type]: state => {
			state.isTestStartLoading = false;
		},
		[completeUserQuizAttempt.pending.type]: state => {
			state.isQuizResultLoading = true;
		},
		[completeUserQuizAttempt.rejected.type]: state => {
			state.isQuizResultLoading = false;
		},
		[completeUserQuizAttempt.fulfilled.type]: (state, action: PayloadAction<{ raw: UserQuizAttempt[] }>) => {
			const { passed, percentageGrade, userQuestionAttempts, inProgress } = action.payload.raw[0];
			state.isQuizFinished = !inProgress;
			state.quizAttempt!.inProgress = inProgress;
			state.quizPassed = passed;
			state.quizPercentage = Math.round(percentageGrade * 100);
			state.quizResult = userQuestionAttempts;
			state.isQuizResultLoading = false;
		},
		[retakeQuiz.pending.type]: state => {
			state.isTestStartLoading = true;
		},
		[retakeQuiz.fulfilled.type]: (state, { payload }: PayloadAction<UserQuizAttempt>) => {
			const isRandomizeQuiz = payload?.data.data.randomizeQuiz || false;
			const questions = isRandomizeQuiz ? shuffle(payload?.data.questions) : payload?.data.questions;
			state.isTestStartLoading = false;
			state.quizError = "";
			state.quizQuestions = questions.map(q => {
				if ((q.question as Question).typeId === QuestionTypes.Grouping) {
					return {
						...q.question,
						data: { groups: getQuestionGroups(q.question) },
						correctGroups: getQuestionCorrectGroups(q.question)
					};
				} else
					return {
						...(q.question as Question),
						data: {
							...(q.question as Question).data,
							answerOptions:
								(q.question as Question).typeId === QuestionTypes.SingleChoice ||
								(q.question as Question).typeId === QuestionTypes.MultipleChoice
									? (q.question as Question).data.answerOptions
									: shuffle((q.question as Question).data.answerOptions)
						}
					};
			});
			state.name = payload.data.name;
			state.durationInMinutes = payload.data.data.totalTimeLengthInMinutes;
			state.quizAnswers = {};
			state.quizAttemptId = payload?.id;
			state.quizAttempt = payload;
			state.quizAttempt.inProgress = payload.inProgress;
			state.quizAttempt.createdAt = "" + new Date();
			state.isQuizFinished = false;
			state.isRetake = false;
		},
		[retakeQuiz.rejected.type]: (state, { payload: { message } }: PayloadAction<Error>) => {
			state.isTestStartLoading = false;
			state.errorMessage = message;
		}
	}
});

export const {
	clearQuizState,
	retakeTest,
	completeCaseStudyQuestion,
	setCSQuestions,
	setCreatePayload,
	setOptionsError,
	setHandleNextLoading,
	moveGroupQuestionAnswerOption
} = takeTestSlice.actions;

export const quizIsLoading = (state: RootState): boolean => state.takeTest.isLoading;
export const isQuizProgressFetched = (state: RootState): boolean => state.takeTest.isSelectedQuizProgressFetched;

export const getQuizQuestions = (state: RootState): Question[] => state.takeTest.quizQuestions ?? [];
export const getQuizAttemptId = (state: RootState): number => state.takeTest.quizAttemptId ?? 0;
export const getQuizAttempt = (state: RootState): UserQuizAttempt | undefined => state.takeTest?.quizAttempt;
export const getQuizQuestionAnswers = (state: RootState): { [key: string]: SavedAnswer } | undefined =>
	state.takeTest.quizAnswers;

export const getQuizError = (state: RootState): string => state.takeTest.quizError ?? "";
export const getIsQuizPassed = (state: RootState): boolean => state.takeTest.quizPassed;
export const getQuizPercentage = (state: RootState): number => state.takeTest.quizPercentage;
export const getQuizResult = (state: RootState): UserQuestionAttempt[] => state.takeTest.quizResult;

export const getIsQuizFinished = (state: RootState): boolean => state.takeTest.isQuizFinished;
export const selectOptionsErrorState = (state: RootState): string => state.takeTest.optionsError;

export const getAttemptedCSQuestions = (state: RootState): CSQuestions[] => state.takeTest.attemptedCSQuestions ?? [];
export const getNextLoadingState = (state: RootState): boolean => state.takeTest.isNextLoading;

export default takeTestSlice.reducer;

import React from "react";

import { Box, CircularProgress, Theme, useMediaQuery, useTheme } from "@material-ui/core";
import { QuestionTypes } from "@remar/shared/dist/constants";

import { useSelector } from "react-redux";
import { getCaseStudyResult, getIsLoading, getQuestionResult } from "store/features/QuestionBank/Test/test.slice";

import BowTieResult from "./Components/BowTieResult";
import CaseStudyQuestionTutoredResult from "./Components/CaseStudyQuestionTutoredResult";
import ClozeDropDownResult from "./Components/ClozeDropDownResult";
import DragAndDropResult from "./Components/DragAndDropResult";
import DropDownTableResult from "./Components/DropDownTableResult";
import HighlightTableResult from "./Components/HighlightTableResult";
import HotspotHighlightResult from "./Components/HotspotHighlightResult";
import MatrixMultipleChoiceResult from "./Components/MatrixMultipleChoiceResult";
import MatrixSingleChoiceResult from "./Components/MatrixSingleChoiceResult";
import MultipleResponseGroupResult from "./Components/MultipleResponseGroupResult";
import SingleChoiceOrMultipleChoiceResult from "./Components/SingleChoiceOrMultipleChoiceResult";
import TutoredTestResultInfo from "./Components/TutoredResultInfo";
import { useStyles } from "./styles";

const TutoredTestResults = ({ question }) => {
	const theme = useTheme<Theme>();
	const questionResult = useSelector(getQuestionResult);
	const isLoading = useSelector(getIsLoading);
	const results = useSelector(getCaseStudyResult);
	const result = questionResult;
	const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
	const classes = useStyles({ isMobile });

	const getQuestionType = (result, question) => {
		switch (question.typeId) {
			case QuestionTypes.MatrixSingleChoice:
				return <MatrixSingleChoiceResult question={question} result={result} />;
			case QuestionTypes.MatrixMultipleChoice:
				return <MatrixMultipleChoiceResult question={question} result={result} />;
			case QuestionTypes.RationalScoringDropDown:
			case QuestionTypes.ClozeDropDown:
				return <ClozeDropDownResult question={question} result={result} />;
			case QuestionTypes.MultipleChoiceSN:
			case QuestionTypes.MultipleChoiceSATA:
				return <SingleChoiceOrMultipleChoiceResult question={question} result={result} />;
			case QuestionTypes.DragAndDrop:
			case QuestionTypes.RationalScoringDragAndDrop:
				return <DragAndDropResult question={question} result={result} />;
			case QuestionTypes.SingleChoice:
				return <SingleChoiceOrMultipleChoiceResult question={question} result={result} />;
			case QuestionTypes.CaseStudy:
				return <CaseStudyQuestionTutoredResult question={question} results={results} />;
			case QuestionTypes.DropDownTable:
				return <DropDownTableResult question={question} result={result} />;
			case QuestionTypes.BowTie:
				return <BowTieResult question={question} result={result} />;
			case QuestionTypes.MultipleResponseGroup:
				return <MultipleResponseGroupResult question={question} result={result} />;
			case QuestionTypes.HotspotHighlight:
				return <HotspotHighlightResult question={question} result={result} />;
			case QuestionTypes.HighlightTable:
				return <HighlightTableResult question={question} result={result} />;
			default:
				return null;
		}
	};
	return isLoading ? (
		<Box display="flex" alignItems="center" justifyContent="center" height={450} width="100%">
			<CircularProgress size="7rem" color="primary" thickness={5} variant="indeterminate" />
		</Box>
	) : (
		<div className={classes.questionContainer}>
			<div className={classes.row}>
				<div className={question.typeId === QuestionTypes.CaseStudy ? classes.fullCol : classes.tutoredTestResultCol}>
					{getQuestionType(result, question)}
				</div>
				{question.typeId !== QuestionTypes.CaseStudy && <TutoredTestResultInfo result={result} />}
			</div>
		</div>
	);
};

export default TutoredTestResults;

import React, { useEffect, useMemo, useRef, useState } from "react";

import { Box, Button, CircularProgress, Theme, useTheme } from "@material-ui/core";
import { SimpleModal } from "@remar/shared/dist/components/SimpleModal";
import { QuestionBankTestModes, QuestionTypes } from "@remar/shared/dist/constants";
import { useCountdown } from "@remar/shared/dist/hooks/useCountdown";
import { useTimer } from "@remar/shared/dist/hooks/useTimer";
import { Wrapper } from "@remar/shared/dist/layouts";
import { Question } from "@remar/shared/dist/models";
import { getTimeRemaining } from "@remar/shared/dist/utils/timeUtils";

import useAnalyticsEventTracker from "hooks/googleAnalytics";

import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";

import {
	clearNotes,
	completeCaseStudyQuestion,
	completeUserTestAttempt,
	createUserQuestionAttempt,
	fetchQuestionAttemptsCount,
	fetchTests,
	getFeedback,
	getFullState,
	getIsLoading,
	getIsTestFinished,
	getMarkedQuestion,
	getQuestionNotes,
	getQuizAttemptedQuestions,
	getQuizPercentage,
	getQuizQuestionAnswers,
	getQuizResult,
	getQuizTestAnswersAlreadyAttempted,
	handleNextLoadingState,
	markQuestion,
	optionsErrorState,
	resetState,
	retakeTest,
	selectFeedback,
	selectFeedbackAllowed,
	selectIsQuestionMarked,
	selectQuestionNotes,
	setQuestionFeedback,
	setQuestionNotes,
	updateQuestionNotes,
	updateUserQuestionAttempt
} from "store/features/QuestionBank/Test/test.slice";

import { UserAnswerQuestionIdDto, UserQuestionAnswerDto } from "store/services";

import { routes } from "core/constants";

import CustomTest from "./CustomTest";

import { ExtendedResults } from "./ExtendedResults";
import { CancelButton, OKButton } from "./styles";

import { useStyles } from "../../Lesson/style";

type VisibleUtility = "calculator" | "notes" | "feedback" | "marker" | null;

const getTestTime = (test, alreadyUsedTime = 0) =>
	test && test.data.timeLengthInSeconds ? (test.data.timeLengthInSeconds - alreadyUsedTime) / 60 : 1;

const Test = () => {
	const theme = useTheme<Theme>();
	const classes = useStyles();
	const history = useHistory();
	const dispatch = useDispatch();
	const { id: testId } = useParams<{ id: string }>();
	const [isQuizExit, setIsQuizExit] = useState(false);
	const [showTutoredResult, setShowTutoredResult] = useState(false);
	const { currentTest } = useSelector(getFullState);
	const isLoadingRetake = useSelector(getIsLoading);

	const { data: { questions = [], isTimed = false } = {}, isLoading } = currentTest;
	const isTestFinished = useSelector(getIsTestFinished);
	const [isStarted, setIsStarted] = useState(true);
	const [isFinished, setIsFinished] = useState(isTestFinished);

	const analytics = useAnalyticsEventTracker("Question Bank");

	const savedUserAnswers = useSelector(getQuizQuestionAnswers);
	const quizTestAnswersForRef = useSelector(getQuizTestAnswersAlreadyAttempted);

	const [questionIndex, setQuestionIndex] = useState(0);
	const questionId = questions[questionIndex]?.id;
	const ref = useRef<UserAnswerQuestionIdDto>({});
	const csRef = useRef<UserAnswerQuestionIdDto>({});
	const [caseStudyCurrentIndex, setCaseStudyCurrentIndex] = useState(0);
	const [userAnswers, setUserAnswers] = useState<UserQuestionAnswerDto[]>(ref.current[questionId] ?? []);
	const quizResult = useSelector(getQuizResult);

	const quizAttemptedQuestions = useSelector(getQuizAttemptedQuestions);

	const quizPercentage = useSelector(getQuizPercentage);
	const [timeSpentOnLatestQuestion, setTimeSpentOnLatestQuestion] = useState(0);

	const { text, questionId: notesQuestionId, id: notesId } = useSelector(selectQuestionNotes);

	const [notes, setNotes] = useState(text);
	const [visibleUtility, setVisibleUtility] = useState<VisibleUtility>(null);

	const _feedback = useSelector(selectFeedback);
	const isFeedbackAllowed = useSelector(selectFeedbackAllowed);
	const optionError = useSelector(optionsErrorState);
	const isNextLoading = useSelector(handleNextLoadingState);

	const [FailiureModal, setFailiureModal] = useState<boolean>(false);

	const isQuestionMarked = useSelector(selectIsQuestionMarked);
	const [feedback, setFeedback] = useState(_feedback);
	const [showFeedbackSuccess, setShowFeedbackSuccess] = useState(false);
	const [isResultsVisible, setIsResultsVisible] = useState(false);
	const [showRetakeTestModal, setShowRetakeTestModal] = useState(false);
	const [resetCountdown, setResetCountdown] = useState(false);

	const question: Question = questions[questionIndex];
	const typeId: number | null = question ? question.typeId : null;

	const alertUser = e => {
		e.preventDefault();
		e.returnValue = "";
	};

	const resetFeedback = () => {
		setShowFeedbackSuccess(false);
		setVisibleUtility(null);
	};

	const getTestType = test => {
		switch (test.typeId) {
			case QuestionBankTestModes.Test:
				return analytics({ eventName: "finish_taking_test", eventIdentifier: testId });
			case QuestionBankTestModes.Tutor:
				return analytics({ eventName: "finish_tutored_test", eventIdentifier: testId });
			case QuestionBankTestModes.CAT:
				return analytics({ eventName: "finished_cat_test", eventIdentifier: testId });
		}
	};

	useEffect(() => {
		if (
			quizTestAnswersForRef &&
			ref.current !== quizTestAnswersForRef &&
			Object.keys(quizTestAnswersForRef).length > 0
		) {
			const currentIndex = Object.keys(quizTestAnswersForRef).length;
			setQuestionIndex(currentIndex > 0 ? currentIndex - 1 : 0);
			for (let i = 0; i < questions.length; i++) {
				ref.current[questions[i].id] = quizTestAnswersForRef[questions[i].id] ?? [];
			}
			setUserAnswers(quizTestAnswersForRef[questionId] ?? []);
		}
	}, [quizTestAnswersForRef]);

	useEffect(() => {
		setIsFinished(isTestFinished);
		if (isTestFinished) {
			getTestType(currentTest);
		}
	}, [isTestFinished]);

	useEffect(() => {
		ref.current[questionId] = userAnswers;
	}, [userAnswers]);

	useEffect(() => {
		if (optionError !== "") {
			setFailiureModal(true);
		}
	}, [optionError]);

	useEffect(() => {
		dispatch(fetchTests({ id: +testId }));

		return () => {
			dispatch(resetState());
		};
	}, [dispatch]);

	useEffect(() => {
		isQuizExit && history.push(routes.questionBank.getPath());
		setIsQuizExit(false);
	}, [history, isQuizExit]);

	useEffect(() => {
		if (!isFinished && !resetCountdown) {
			window.onbeforeunload = alertUser;
			return () => {
				window.onbeforeunload = () => null;
			};
		} else {
			window.onbeforeunload = () => null;
		}
	}, [isFinished, resetCountdown]);

	useEffect(() => {
		if (resetCountdown && currentTest.data.timeLengthInSeconds) {
			window.onbeforeunload = alertUser;
			handleReset();
			setResetCountdown(resetCountdown => !resetCountdown);
		}
	}, [resetCountdown, currentTest.data.timeLengthInSeconds]);

	useEffect(() => {
		if (questionId) {
			setUserAnswers(ref.current[questionId] ?? []);
			resetFeedback();
			dispatch(getFeedback(+questionId));
			dispatch(getMarkedQuestion(+questionId));
		}
	}, [dispatch, questionId]);

	useEffect(() => {
		setFeedback(_feedback);
	}, [text, questionId]);

	useEffect(() => {
		setNotes(text);
	}, [text, notesQuestionId]);

	const { seconds, startOrResetTimer, stopTimer } = useTimer();

	const onExpire = () => {
		if (getTimeRemaining(currentTest?.createdAt, getTestTime(currentTest)) <= 0) {
			currentTest && currentTest.inProgress && !isFinished && dispatch(completeUserTestAttempt({ id: +testId }));
			setVisibleUtility(null);
			setIsFinished(true);
			stopTimer();
		} else {
			startCountdown();
		}
	};

	const { secondsToDisplay, minutesToDisplay, hoursToDisplay, startCountdown, stopCountdown, handleReset } =
		useCountdown({
			totalTimeInMinutes: getTestTime(currentTest),
			startTime: currentTest?.createdAt,
			onExpire
		});

	useEffect(() => {
		!resetCountdown && isTimed && startCountdown();
	}, [isTimed, testId, resetCountdown]);

	useEffect(() => {
		if (testId && !isStarted) {
			setIsStarted(true);
			if (typeId !== QuestionTypes.Grouping) {
				setQuestionIndex(questions.findIndex(({ id }) => !savedUserAnswers?.[id]));
			}
		}
	}, [testId, isStarted, typeId]);

	useEffect(() => {
		if (questions.length > 0 && currentTest.typeId === QuestionBankTestModes.Test) {
			const lastQuestionId = questions[questions.length - 1].id;
			if (testId && isStarted && savedUserAnswers![lastQuestionId]) {
				if (currentTest && currentTest.inProgress && !isFinished) {
					dispatch(completeUserTestAttempt({ id: +testId }));
				}
				setIsFinished(true);
				setVisibleUtility(null);
				stopCountdown();
				if (!isLoading && isFinished) {
					dispatch(fetchQuestionAttemptsCount(questions));
				}
			}
		}
	}, [questions, testId, isStarted, savedUserAnswers, isFinished]);

	useEffect(() => {
		if (questions.length && questionIndex >= 0 && visibleUtility === "notes") {
			dispatch(getQuestionNotes({ id: questions[questionIndex]?.id, customTestId: testId as unknown as number }));
		}
	}, [dispatch, questions, questionIndex, visibleUtility, isResultsVisible, showTutoredResult]);

	useEffect(() => {
		if (isFinished) {
			stopTimer();
		}
	}, [isFinished]);

	useEffect(() => {
		if (secondsToDisplay === 0 && isTimed) {
			stopCountdown();
		}
	}, [secondsToDisplay, isTimed]);

	useEffect(() => {
		if (testId) {
			const questionAttemptTimeSpent =
				(savedUserAnswers && savedUserAnswers[questionId]?.timeSpent) || timeSpentOnLatestQuestion;
			startOrResetTimer(questionAttemptTimeSpent);
		}
	}, [questionIndex, savedUserAnswers, questionId, testId]);

	useEffect(() => {
		if (currentTest.refetchTest) {
			setIsFinished(true);
			setVisibleUtility(null);
			stopCountdown();
			dispatch(fetchTests({ id: +testId }));
		}
	}, [dispatch, currentTest.refetchTest]);

	useEffect(() => {
		if (
			questions.length > 0 &&
			questionIndex < questions.length - 1 &&
			currentTest.typeId === QuestionBankTestModes.CAT &&
			question.typeId !== QuestionTypes.CaseStudy
		) {
			const nextQuestion = questions[questions.length - 1];

			if (nextQuestion) {
				setQuestionIndex(questions.length - 1);
				setUserAnswers([]);
			}
		}
	}, [questions.length]);

	const moveToNextQuestion = () => {
		if (questionIndex < questions.length - 1 && currentTest.typeId !== QuestionBankTestModes.Tutor) {
			setQuestionIndex(questionIndex + 1);
		}
	};
	const moveToTutoredNextQuestion = () => {
		if (questionIndex < questions.length - 1) {
			setQuestionIndex(questionIndex + 1);
		} else {
			const lastQuestionId = questions[questions.length - 1].id;
			if (testId && isStarted && savedUserAnswers![lastQuestionId]) {
				if (currentTest && currentTest.inProgress && !isFinished) {
					dispatch(completeUserTestAttempt({ id: +testId }));
				}
				setIsFinished(true);
				setVisibleUtility(null);
				stopCountdown();
				dispatch(fetchQuestionAttemptsCount(questions));
			}
		}
	};
	const handleNext = tutorTestSideEffect => {
		if (userAnswers.length > 0 || question.typeId === QuestionTypes.CaseStudy) {
			dispatch(clearNotes());
			if (question.typeId === QuestionTypes.CaseStudy) {
				const { caseStudyQuestions = [] } = question;
				const orderedQuestions = [...caseStudyQuestions].sort((a, b) => a.order - b.order);
				const lastQuestion = orderedQuestions[caseStudyCurrentIndex];
				const csLastQuestionAnser = csRef.current[`${caseStudyCurrentIndex}-${question.id}`];
				if (testId && !savedUserAnswers![lastQuestion.id]) {
					setTimeSpentOnLatestQuestion(0);
					dispatch(
						createUserQuestionAttempt({
							userCustomTestId: +testId,
							questionId: question.id,
							subQuestionId: lastQuestion.id,
							userAnswers: [...csLastQuestionAnser],
							timeSpent: seconds,
							caseStudySideEffect: (isError, questions) => {
								if (isError) return;
								questions[questions.length - 1].id === question.id &&
									dispatch(completeCaseStudyQuestion({ questionId: question.id }));
								if (questionIndex < questions.length - 1 && currentTest.typeId !== QuestionBankTestModes.Tutor) {
									setQuestionIndex(questionIndex + 1);
								}
								currentTest.typeId === QuestionBankTestModes.Tutor && tutorTestSideEffect();
							}
						})
					);
				} else if (testId) {
					dispatch(
						updateUserQuestionAttempt({
							questionUserAttemptId: savedUserAnswers![lastQuestion.id]?.id,
							userAnswers: [...csLastQuestionAnser],
							timeSpent: seconds,
							caseStudySideEffect: isError => {
								if (isError) return;
								questions[questions.length - 1].id === question.id &&
									dispatch(completeCaseStudyQuestion({ questionId: question.id }));
								if (questionIndex < questions.length - 1 && currentTest.typeId !== QuestionBankTestModes.Tutor) {
									setQuestionIndex(questionIndex + 1);
								}
								currentTest.typeId === QuestionBankTestModes.Tutor && tutorTestSideEffect();
							}
						})
					);
				}
			} else if (testId && !savedUserAnswers![questionId]) {
				setTimeSpentOnLatestQuestion(0);
				dispatch(
					createUserQuestionAttempt({
						userCustomTestId: +testId,
						questionId: questionId,
						userAnswers: [...userAnswers],
						timeSpent: seconds,
						sideEffect: currentTest.typeId === QuestionBankTestModes.Tutor ? tutorTestSideEffect : moveToNextQuestion
					})
				);
			} else if (testId) {
				dispatch(
					updateUserQuestionAttempt({
						questionUserAttemptId: savedUserAnswers![questionId]?.id,
						userAnswers: [...userAnswers],
						timeSpent: seconds,
						sideEffect: currentTest.typeId === QuestionBankTestModes.Tutor ? tutorTestSideEffect : moveToNextQuestion
					})
				);
			}
		}
	};

	const handlePrev = () => {
		if (savedUserAnswers![questionId]?.id) {
			dispatch(
				updateUserQuestionAttempt({
					questionUserAttemptId: savedUserAnswers![questionId]?.id,
					userAnswers: [...userAnswers],
					timeSpent: seconds
				})
			);
		} else {
			setTimeSpentOnLatestQuestion(seconds);
		}
		dispatch(clearNotes());
		setQuestionIndex(questionIndex - 1);
	};

	const handleSaveNotes = () => {
		if (notes?.length > 0)
			if (notesQuestionId === questions[questionIndex].id && text.length > 0) {
				dispatch(updateQuestionNotes({ data: { text: notes }, filters: { id: notesId } }));
				analytics({ eventName: "take_notes_during_test" });
			} else {
				dispatch(
					setQuestionNotes({
						text: notes,
						questionId: questions[questionIndex].id,
						customTestId: +testId
					})
				);
			}
	};

	const handleSaveFeedback = () => {
		setShowFeedbackSuccess(true);
		dispatch(setQuestionFeedback({ text: feedback, questionId }));
	};

	const handleQuestionMarking = () => {
		dispatch(markQuestion({ questionIds: [{ value: questionId, deleted: isQuestionMarked }] }));
	};

	const resultSummary: { correct: number; partiallyCorrect: number; incorrect: number } = useMemo(() => {
		const result = {
			correct: 0,
			partiallyCorrect: 0,
			incorrect: 0
		};
		for (const item of quizResult) {
			if (item.isCorrect) {
				result.correct += 1;
			} else if (item.percentageGrade > 0) {
				result.partiallyCorrect += 1;
			} else {
				result.incorrect += 1;
			}
		}
		return result;
	}, [quizResult]);

	const breadcrumbs = useMemo(
		() => [
			{ title: "Question Bank", key: 0, link: "/questionBank" },
			{ title: currentTest.name, key: 1 }
		],
		[currentTest.name]
	);

	const onShowResults = () => {
		setIsResultsVisible(true);
		dispatch(fetchQuestionAttemptsCount(questions));
	};

	const resetRefIds = (ids, currentRef) => {
		ids.forEach(questionId => {
			currentRef.current[questionId] = [];
		});
	};

	const resetUserAnswers = () => {
		const questionIds = Object.keys(ref.current);
		const csQuestionIds = Object.keys(csRef.current);
		setUserAnswers([]);
		resetRefIds(questionIds, ref);
		resetRefIds(csQuestionIds, csRef);
	};

	const resetTest = () => {
		analytics({ eventName: "retake_test_from_results", eventIdentifier: testId });
		dispatch(resetState());
		dispatch(fetchTests({ id: +testId }));

		resetUserAnswers();
		isTimed && setResetCountdown(resetCountdown => !resetCountdown);
		setIsStarted(true);
		setIsFinished(false);
		setQuestionIndex(0);
		setIsResultsVisible(false);
		setShowRetakeTestModal(false);
	};

	const checkAllSelection = (question, options, customUserAnswers) => {
		const {
			data: { groups }
		} = question;
		const filteredGroups = groups.filter(group => group[options]);
		return filteredGroups.length !== customUserAnswers.length;
	};

	const requiredProps = {
		isQuizExit,
		setIsQuizExit,
		question,
		setUserAnswers,
		userAnswers,
		isFinished,
		setVisibleUtility,
		questionIndex,
		secondsToDisplay,
		hoursToDisplay,
		minutesToDisplay,
		visibleUtility,
		notes,
		setNotes,
		handleSaveNotes,
		handlePrev,
		handleNext,
		quizPercentage,
		resultSummary,
		handleSaveFeedback,
		setFeedback,
		isFeedbackAllowed,
		feedback,
		showFeedbackSuccess,
		handleQuestionMarking,
		isQuestionMarked,
		onShowResults,
		isNextLoading,
		setShowRetakeTestModal,
		moveToTutoredNextQuestion,
		checkAllSelection,
		showTutoredResult,
		setShowTutoredResult,
		csRef,
		ref,
		setCaseStudyCurrentIndex
	};

	if (isLoading) {
		return (
			<Box display="flex" alignItems="center" justifyContent="center" height={450} width="100%">
				<CircularProgress size="7rem" color="primary" thickness={5} variant="indeterminate" />
			</Box>
		);
	}

	if (isStarted && questions.length === 0) {
		return null;
	}

	return (
		<>
			<Wrapper
				heading={currentTest.name}
				breadcrumb={breadcrumbs}
				actions={
					<Button
						variant="contained"
						className={classes.back}
						onClick={() => {
							analytics({ eventName: "click_back_button_from_short_results_page" });
							setIsQuizExit(true);
						}}
					>
						Back
					</Button>
				}
			>
				<Box display="flex" flexDirection="row" alignItems="flex-start">
					<Box
						display="flex"
						flexDirection="row"
						flexWrap="wrap"
						flex="100%"
						position="relative"
						alignItems="center"
						justifyContent="center"
					>
						{currentTest && currentTest.data && (
							<>
								<CustomTest test={currentTest} key={`${isStarted}-${testId}-${typeId}`} requiredProps={requiredProps} />
								{isResultsVisible && (
									<ExtendedResults
										userQuestion={quizAttemptedQuestions}
										result={quizResult}
										onClose={() => setIsResultsVisible(false)}
									/>
								)}
							</>
						)}
					</Box>
				</Box>
			</Wrapper>
			<SimpleModal
				theme={theme}
				title="Please select options again"
				open={FailiureModal}
				onClose={() => setFailiureModal(false)}
				text={optionError}
				footer={
					<OKButton size="medium" onClick={() => setFailiureModal(false)}>
						Ok
					</OKButton>
				}
			/>
			<SimpleModal
				theme={theme}
				title="Retake Test"
				open={showRetakeTestModal}
				onClose={() => setShowRetakeTestModal(false)}
				text="Are you sure you want to retake the test?"
				footer={
					<>
						<CancelButton variant="outlined" size="medium" onClick={() => setShowRetakeTestModal(false)}>
							Cancel
						</CancelButton>
						<Button
							size="medium"
							onClick={() => dispatch(retakeTest({ userCustomTestId: +testId, sideEffect: resetTest }))}
							variant="contained"
							color="primary"
							disabled={isLoadingRetake}
						>
							{isLoadingRetake && <CircularProgress size={20} color="inherit" />}
							Confirm
						</Button>
					</>
				}
			/>
		</>
	);
};

export default Test;

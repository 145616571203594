import React, { useCallback, useEffect, useMemo, useState } from "react";

import { Box, Button, ButtonBase, useMediaQuery, useTheme } from "@material-ui/core";
import { Delete, EditSharp } from "@material-ui/icons";
import { IBreadCrumb } from "@remar/shared/dist/components/HeaderContainer/HeaderContainer";

import { IColumn } from "@remar/shared/dist/components/MaterialTable";
import { EmptyState } from "@remar/shared/dist/layouts";
import WithTableContentLayout from "@remar/shared/dist/layouts/TableContentLayout";
import { Themes } from "@remar/shared/dist/models/theme.types";
import { format } from "date-fns";

import useAnalyticsEventTracker from "hooks/googleAnalytics";

import { useAppDispatch, useAppSelector } from "store";
import {
	createNote,
	deleteNote as deleteNoteAction,
	fetchNotes,
	getFullState,
	initialState,
	populateInputs,
	setStateValue,
	validateForm
} from "store/features/Note/Notes.slice";
import { NoteRawData } from "store/features/Note/models";
import { getFullState as getThemeState } from "store/features/Theme/theme.slice";

import { _emit } from "store/features/notifications/notifications.slice";

import { ReactComponent as IllustrationSvg } from "assets/icons/notes-illustration.svg";
import { ReactComponent as IllustrationSvgCyanBlue } from "assets/icons/notes-illustration_cyanBlue.svg";
import { ReactComponent as IllustrationSvgGreen } from "assets/icons/notes-illustration_green.svg";
import { ReactComponent as IllustrationSvgOrange } from "assets/icons/notes-illustration_orange.svg";
import { ReactComponent as IllustrationSvgPurple } from "assets/icons/notes-illustration_purple.svg";
import { ReactComponent as IllustrationSvgRed } from "assets/icons/notes-illustration_red.svg";
import { ReactComponent as IllustrationSvgYellow } from "assets/icons/notes-illustration_yellow.svg";

import {
	ButtonsRow,
	ColumnHeader,
	DeleteCancelButton,
	DeleteConfirmButton,
	DeleteConfirmContent,
	DeleteModalTitle,
	FieldLabel,
	FormModal,
	NoteTextContainer,
	StyledButtonTop,
	StyledDateTitleCellText,
	CustomInput as StyledInput,
	StyledTimeTitleCellText,
	useStyles
} from "./styles";

const getThemedSvg = (colorShade: Themes, isMobile: boolean) => {
	const width = isMobile ? "100%" : "auto";
	switch (colorShade) {
		case Themes.DARK_CYAN_BLUE:
		case Themes.LIGHT_CYAN_BLUE:
			return <IllustrationSvgCyanBlue width={width} />;
		case Themes.DARK_RED:
		case Themes.LIGHT_RED:
			return <IllustrationSvgRed width={width} />;
		case Themes.DARK_YELLOW:
		case Themes.LIGHT_YELLOW:
			return <IllustrationSvgYellow width={width} />;
		case Themes.DARK_ORANGE:
		case Themes.LIGHT_ORANGE:
			return <IllustrationSvgOrange width={width} />;
		case Themes.DARK_GREEN:
		case Themes.LIGHT_GREEN:
			return <IllustrationSvgGreen width={width} />;
		case Themes.DARK_BLUE:
		case Themes.LIGHT_BLUE:
			return <IllustrationSvg width={width} />;
		case Themes.DARK_PURPLE:
		case Themes.LIGHT_PURPLE:
			return <IllustrationSvgPurple width={width} />;
		default:
			return <IllustrationSvg width={width} />;
	}
};

const breadCrumbInitState: IBreadCrumb[] = [
	{ title: "Dashboard", key: 0, link: "/" },
	{ title: "My Notes", key: 0 }
];

const Notes = () => {
	const classes = useStyles();
	const theme = useTheme();
	const dispatch = useAppDispatch();
	const isMobile = useMediaQuery(theme.breakpoints.down("xs"));

	const { colorShade } = useAppSelector(getThemeState);
	const [showModal, setShowModal] = useState(false);
	const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
	const [deleteableNoteId, setDeleteableNoteId] = useState<number | null>(null);
	const [searchText, setSearchText] = useState("");
	const [breadCrumb, setBreadCrumb] = useState<IBreadCrumb[]>(breadCrumbInitState);
	const { noteForm, isLoading, notes, perPage, page, totalItems, totalCount } = useAppSelector(getFullState);
	const analytics = useAnalyticsEventTracker("Question Bank");

	const defaultCustomInputOptions = { _emit, dispatch, setStateValue, validateForm };

	useEffect(() => {
		dispatch(fetchNotes({}));
	}, [dispatch]);

	const editNoteHandle = useCallback(
		(name, id, text) => {
			setShowModal(true);
			dispatch(setStateValue({ key: "noteForm.valid", value: false }));
			dispatch(
				populateInputs({
					inputsStatePath: "noteForm.inputs",
					templatesStatePath: "noteForm.templates",
					values: { name, id, text }
				})
			);
		},
		[dispatch]
	);

	useEffect(() => {
		const doesBreadCrumbExist = breadCrumb.some(({ key }) => key === 1);
		if (showModal) {
			if (!doesBreadCrumbExist) {
				setBreadCrumb([
					{ title: "Dashboard", key: 0, link: "/" },
					{
						title: "My Notes",
						key: 1,
						onClick: () => {
							dispatch(setStateValue({ key: "noteForm", value: initialState.noteForm }));
							setShowModal(false);
						}
					},
					{
						title: noteForm?.inputs?.id?.value ? "Edit Note" : "Add New Note",
						key: 2
					}
				]);
			}
		} else if (doesBreadCrumbExist) {
			setBreadCrumb(breadCrumbInitState);
		}
	}, [breadCrumb, dispatch, noteForm?.inputs?.id?.value, showModal]);

	const handleDelete = id => {
		setDeleteableNoteId(id);
		setShowDeleteConfirm(true);
	};

	const handleSearchBarChange = useCallback(
		searchText => {
			return dispatch(fetchNotes({ searchText }));
		},
		[dispatch]
	);

	const tableColumns: Array<IColumn<NoteRawData>> = useMemo(
		() => [
			{
				alignment: "left",
				label: <ColumnHeader>Note Name</ColumnHeader>,
				Cell: ({ rowData: { name } }) => (
					<Box display="flex" flexDirection="column">
						<StyledDateTitleCellText>{name}</StyledDateTitleCellText>
					</Box>
				),
				dataKey: "name"
			},
			{
				alignment: "right",
				label: <ColumnHeader>{"Date & Time"}</ColumnHeader>,
				Cell: ({ rowData: { createdAt } }) => (
					<Box display="flex" flexDirection="column">
						<StyledDateTitleCellText>{format(new Date(createdAt as string), "dd LLLL yyyy")}</StyledDateTitleCellText>
						<StyledTimeTitleCellText>{format(new Date(createdAt as string), "hh:mm aa")}</StyledTimeTitleCellText>
					</Box>
				),
				dataKey: "createdAt"
			},
			{
				alignment: "center",
				label: <ColumnHeader>Options</ColumnHeader>,
				Cell: ({ rowData: { name, text, id } }) => {
					return (
						<Box className={classes.optionsIconsGap} display="flex" justifyContent="center">
							<ButtonBase>
								{" "}
								<EditSharp
									style={{ width: "18px", height: "18px", fill: "#d3d6e0" }}
									onClick={() => editNoteHandle(name, id, text)}
								/>
							</ButtonBase>
							<ButtonBase>
								<Delete style={{ width: "18px", height: "18px", fill: "#d3d6e0" }} onClick={() => handleDelete(id)} />
							</ButtonBase>
						</Box>
					);
				},
				dataKey: "menu"
			}
		],
		[classes.optionsIconsGap, editNoteHandle]
	);
	const width = isMobile && showModal ? "100%" : "50%";
	return (
		<>
			<WithTableContentLayout
				heading={"My Notes"}
				breadcrumb={breadCrumb}
				customActionStyle={{ width: width, display: "flex", justifyContent: "flex-end" }}
				actions={
					<Box display="flex" justifyContent="space-between">
						{showModal && (
							<StyledButtonTop
								variant={"contained"}
								onClick={() => {
									dispatch(setStateValue({ key: "noteForm", value: initialState.noteForm }));
									setShowModal(false);
								}}
							>
								Cancel
							</StyledButtonTop>
						)}
						<Button
							variant={"contained"}
							color="primary"
							onClick={() => {
								if (!showModal) {
									setShowModal(true);
									dispatch(setStateValue({ key: "noteForm.valid", value: false }));
								} else {
									analytics({ eventName: "start_creating_note" });
									dispatch(
										createNote({
											sideEffect: () => {
												dispatch(setStateValue({ key: "noteForm", value: initialState.noteForm }));
												setShowModal(false);
												analytics({ eventName: "finish_creating_note" });
											}
										})
									);
								}
							}}
							disabled={isLoading || (showModal && !noteForm?.valid)}
						>
							{noteForm?.inputs?.id?.value ? "Save Changes" : "Create New Note"}
						</Button>
					</Box>
				}
				hideTable={showModal}
				isLoading={isLoading}
				tableTitle="My Notes"
				emptyState={
					<EmptyState
						description="No notes yet"
						placeHolder={getThemedSvg(colorShade, isMobile)}
						buttonTitle="Create New Note"
						onButtonClick={() => {
							setShowModal(true);
							dispatch(setStateValue({ key: "noteForm.valid", value: false }));
						}}
					/>
				}
				onChangePage={page => dispatch(fetchNotes({ page }))}
				totalItems={totalItems}
				totalEntities={totalCount}
				perPage={perPage}
				page={page}
				searchText={searchText}
				setSearchText={setSearchText}
				handleSearchBarChange={handleSearchBarChange}
				tableColumns={tableColumns}
				data={notes}
			>
				{showModal && (
					<>
						<FieldLabel>
							Note Name
							<StyledInput
								width={150}
								mr={2}
								options={{ ...defaultCustomInputOptions, inputData: noteForm.inputs.name }}
							/>
						</FieldLabel>
						<Box mt={4} mr={isMobile ? 0 : 3}>
							<StyledInput
								width={150}
								mr={2}
								options={{ ...defaultCustomInputOptions, inputData: noteForm.inputs.text }}
							/>
						</Box>
					</>
				)}
			</WithTableContentLayout>
			<FormModal
				open={showDeleteConfirm}
				onClose={() => setShowDeleteConfirm(false)}
				disableEnforceFocus
				disableAutoFocus
			>
				<DeleteConfirmContent>
					<DeleteModalTitle>Delete Note</DeleteModalTitle>
					<NoteTextContainer>
						<div>Are you sure you want to delete this note?</div>
					</NoteTextContainer>
					<ButtonsRow>
						<DeleteCancelButton variant="contained" onClick={() => setShowDeleteConfirm(false)}>
							No, Cancel
						</DeleteCancelButton>
						<DeleteConfirmButton
							variant={"contained"}
							color={"primary"}
							onClick={() => {
								setShowDeleteConfirm(false);
								dispatch(deleteNoteAction(deleteableNoteId as number));
							}}
						>
							Yes, Delete
						</DeleteConfirmButton>
					</ButtonsRow>
				</DeleteConfirmContent>
			</FormModal>
		</>
	);
};

export default Notes;

import { useContext } from "react";

import { Analytics, logEvent } from "firebase/analytics";
import { GoogleAnalytics } from "index";

const useAnalyticsEventTracker = (contentType: string) => {
	const context = useContext(GoogleAnalytics);
	return ({
		eventName,
		eventIdentifier = undefined,
		contentIdentifier = undefined
	}: {
		eventName: string;
		contentIdentifier?: string;
		eventIdentifier?: string;
	}) => {
		if (context) {
			const id = `${contentIdentifier ?? localStorage.getItem("analyticsId")}`;
			logEvent(context as Analytics, eventName, {
				content_type: contentType,
				content_id: `${id}`,
				["event_identifier"]: `${!eventIdentifier ? "" : eventIdentifier}`
			});
		}
	};
};

export default useAnalyticsEventTracker;

import { Box } from "@material-ui/core";
import { ReactComponent as CrossSvg } from "@remar/shared/dist/assets/icons/icon-close-red.svg";
import { ReactComponent as TestSvg } from "@remar/shared/dist/assets/icons/icon-filled-test.svg";
import { ReactComponent as CheckSvg } from "@remar/shared/dist/assets/icons/icon-message-check-green.svg";
import styled, { css } from "styled-components";

export const styles = {
	modal: {
		display: "flex",
		justifyContent: "center",
		alignItems: "center"
	},
	card: {
		display: "flex",
		flexFlow: "column",
		justifyContent: "space-between",
		maxWidth: "588px",
		minHeight: "385px",
		width: "100%",
		borderRadius: "5px",
		padding: 0
	},
	title: {
		fontSize: "25px",
		fontWeight: 900
	},
	closeIcon: {
		marginTop: "-15px",
		marginRight: "-15px"
	},
	modalFooter: {
		display: "flex",
		alignItems: "center",
		justifyContent: "space-between",
		padding: "16px"
	}
};

export const Container = styled.div`
	min-height: 100vh;
	width: 100%;
	.breadcrum-container {
		margin-top: 6px;

		div {
			font-size: 13px;
			line-height: 1.23;
			color: ${({ theme }) => theme.palette.colors.basic[100]}60;
		}

		div:last-child {
			color: ${({ theme }) => theme.palette.colors.basic[100]};
		}
	}
`;

export const LoadingWrapper = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	height: 500px;
	width: 100%;
`;

export const StudentsBox = styled.div`
	${({ mr }) =>
		mr &&
		css`
			margin-right: ${mr}rem;
		`}

	${({ mt }) =>
		mt &&
		css`
			margin-top: ${mt}rem;
		`}

		${({ $isFlex }) =>
		$isFlex &&
		css`
			display: flex;
		`}

		${({ $alignItem }) =>
		$alignItem &&
		css`
			align-items: ${$alignItem};
		`}
`;

export const THeaderWrapper = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	background-color: ${({ theme }) => theme.palette.background.paper};
	padding: 20px;
	border-radius: 4px 4px 0 0;
`;

export const TakeTestButtonWrapper = styled(Box)`
	button {
		font-size: 10px;
	}
`;

export const IconWrapper = styled(Box)`
	background-color: ${({ $bgColor }) => $bgColor}60;
	border-radius: 50%;
	width: 50px;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
`;

export const TestSvgIcon = styled(TestSvg)`
	width: 27px;
	height: 27px;

	path:first-of-type {
		fill: ${({ theme }) => theme.palette.colors.primary[1300]};
	}
`;

export const CheckSvgIcon = styled(CheckSvg)`
	width: 27px;
	height: 27px;
`;

export const CrossSvgIcon = styled(CrossSvg)`
	width: 27px;
	height: 27px;
`;

export const QuizResultsContainer = styled(Box)`
	display: flex;
	flex-direction: column;
	margin: 50px 100px;
	${props => props.theme.breakpoints.down("sm")} {
		margin: 10px;
	}
`;

export const QuizResultsWrapper = styled(Box)`
	height: 800px;
	overflow-y: scroll;
	${props => props.theme.breakpoints.down("sm")} {
		height: 750px;
	}
`;

import React, { useEffect, useMemo, useState } from "react";

import { Box, Button, Card, Checkbox, CircularProgress, FormControlLabel, Typography } from "@material-ui/core";
import { FullScreenWithLogoLayout } from "@remar/shared/dist/layouts";
import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js";
import { Form, Formik } from "formik";

import ReactPixel from "react-facebook-pixel";

import { useDispatch, useSelector } from "react-redux";

import { useHistory, useParams } from "react-router-dom";

import { RootState, useAppSelector } from "store";
import {
	changeSubscriptionType,
	setSubscriptionTypesForSignup,
	setUserSubscriptionTypeId,
	signUp
} from "store/features/Auth/authSlice";

import AuthBackground from "assets/images/signup-background.png";
import { getCallbackUrl } from "modules/utils";

import { AccountFormDetails } from "../components/Forms";
import SignUpSuccessLeft from "../components/SignUpSuccessLeft";
import SignUpSuccessRight from "../components/SignUpSuccessRight";
import { SignupSchema } from "../components/schemas";
import { useStyles } from "../components/styles";

const initialFormValues = {
	firstName: "",
	lastName: "",
	email: "",
	password: "",
	confirmPassword: "",
	terms: false
};

const TrialSignUp = () => {
	const classes = useStyles();
	const stripe = useStripe();
	const elements = useElements();
	const dispatch = useDispatch();
	const { courseId } = useParams<{ courseId?: string }>();
	const [signUpCompleted, setSignUpCompleted] = useState(false);
	const { errorMessage: error, isLoading: loading } = useSelector((state: RootState) => state.auth);
	const { subscriptionTypes }: RootState["auth"] = useAppSelector((store: RootState) => store.auth);
	const history = useHistory();
	const callBackUrl = useMemo(() => getCallbackUrl(history.location.search), [history.location.search]);
	const activeSubscription = subscriptionTypes[0];

	useEffect(() => {
		ReactPixel.track("PageView");
		return () => {
			dispatch(setUserSubscriptionTypeId(0));
		};
	}, [dispatch]);

	useEffect(() => {
		dispatch(
			setSubscriptionTypesForSignup({
				courseId: courseId as unknown as number,
				isTrial: true
			})
		);
	}, [courseId, dispatch]);

	useEffect(() => {
		if (activeSubscription) {
			dispatch(changeSubscriptionType(activeSubscription?.id as number));
		}
	}, [activeSubscription, dispatch]);
	const handleSubmit = (values: typeof initialFormValues) => {
		// eslint-disable-next-line @typescript-eslint/ban-ts-comment
		// @ts-ignore
		dispatch(signUp({ CardElement, elements, stripe, values: { ...values } })).then(r => {
			if (!r.error) {
				setSignUpCompleted(true);
				if (callBackUrl) {
					window.location.replace(callBackUrl);
				}
			}
		});
	};

	if (signUpCompleted) {
		return (
			<FullScreenWithLogoLayout customBgImage={AuthBackground}>
				<Box display="flex" className={classes.trialSuccessContainer}>
					<Card className={classes.cardLeft}>
						<SignUpSuccessLeft trial={true} />
					</Card>
					<Card className={classes.cardRight}>
						<SignUpSuccessRight />
					</Card>
				</Box>
			</FullScreenWithLogoLayout>
		);
	}

	return (
		<FullScreenWithLogoLayout customBgImage={AuthBackground}>
			<Box maxWidth={570} width="100%" margin="auto">
				<Card className={classes.cardTrial}>
					<Formik
						initialValues={initialFormValues}
						validationSchema={SignupSchema}
						onSubmit={values => {
							handleSubmit(values);
						}}
					>
						{({ isValid, values, touched, setFieldValue, errors }) => {
							const { terms } = values;
							const neverTouched = Object.keys(touched).length === 0;
							const valid = isValid && !neverTouched && terms;

							return (
								<Box px={9} pt={6} pb={4} className={classes.stepperForm}>
									<Form>
										<AccountFormDetails title="Trial Sign Up" confirmPassword />
									</Form>
									{error && (
										<Box mt={3}>
											<Typography variant="caption" style={{ color: "red" }}>
												{error}
											</Typography>
										</Box>
									)}

									<Box mt={1}>
										<FormControlLabel
											control={
												<Checkbox
													checked={values.terms}
													onChange={() => setFieldValue("terms", !values.terms)}
													color="primary"
													name="terms"
												/>
											}
											label={
												<span className={classes.termsAndConditionsCheck}>
													I agree to the{" "}
													<a target="_blank" rel="noreferrer" href="https://study.remarnurse.com/vit/terms-conditions/">
														<span className={classes.termsLink}>Terms & Conditions</span>
													</a>
												</span>
											}
										/>
										{errors.terms && (
											<>
												<br />
												<Typography variant="caption" style={{ color: "red" }}>
													{errors.terms}
												</Typography>
											</>
										)}
									</Box>
									<Box mt={1} display="flex" justifyContent="flex-end">
										<Button
											size="large"
											color="primary"
											disabled={!valid || loading || !activeSubscription}
											startIcon={loading && <CircularProgress size={20} />}
											variant="contained"
											onClick={() => handleSubmit(values)}
										>
											Create Account
										</Button>
									</Box>
								</Box>
							);
						}}
					</Formik>
				</Card>
			</Box>
		</FullScreenWithLogoLayout>
	);
};

export default TrialSignUp;

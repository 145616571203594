import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
	CustomInputSelectOptionsItem,
	CustomInputType
} from "@remar/shared/dist/components/CustomInput/customInput.model";
import { QuestionBankTestModes } from "@remar/shared/dist/constants";
import { userCustomTestsService } from "@remar/shared/dist/services/userCustomTests";
import {
	createForm,
	getPopulateInputsAction,
	validateFormAction as utilsValidateFormAction
} from "@remar/shared/dist/utils/form/form.utils";

import { getSelectOptions } from "@remar/shared/dist/utils/serviceUtils/helpers";
import { getResetState, setStateValue as utilsSetStateValue } from "@remar/shared/dist/utils/stateUtils";

import { emit } from "store/features/notifications/notifications.slice";

import { difficultyLevelTiersService, genericService, userCustomTestTypesService, usersService } from "store/services";

import { RootState } from "../../../index";
import { QUESTION_BANK_CREATION_LIMIT } from "../constants";
import { CreateNewTestFormInputs, CreateNewTestFormRawData, CreateTestState } from "../models";

const initialState: CreateTestState = {
	isLoading: false,
	isPoolCountLoading: false,
	isTestCreationLoading: false,
	isCATAvailable: false,
	errorMessage: "",
	difficultyLevelTiers: [],
	userCustomTestTypes: [],
	subjects: [],
	totalQuestionsCount: 0,
	createNewTestForm: createForm<CreateNewTestFormInputs, CreateNewTestFormRawData, {}>({
		defaultValueGetters: {},
		statePath: "createNewTestForm",
		generateTemplatesFromPaths: ["subjectLessons"],
		inputs: {
			name: {
				label: "Enter Test Name",
				placeholder: "Enter Test Name",
				type: CustomInputType.Text,
				validations: { required: true, maxLength: 140 }
			},
			typeId: {
				label: "Test Mode",
				placeholder: "Test Mode",
				selectOptions: [],
				type: CustomInputType.Radio,
				validations: { isNumber: true, required: true }
			},
			allQuestions: {
				defaultValue: true,
				label: "",
				type: CustomInputType.Checkbox
			},
			unansweredQuestions: {
				defaultValue: true,
				label: "",
				type: CustomInputType.Checkbox
			},
			correctlyAnsweredQuestions: {
				defaultValue: true,
				label: "",
				type: CustomInputType.Checkbox
			},
			incorrectlyAnsweredQuestions: {
				defaultValue: true,
				label: "",
				type: CustomInputType.Checkbox
			},
			markedQuestions: {
				defaultValue: true,
				label: "",
				type: CustomInputType.Checkbox
			},
			caseStudyQuestions: {
				defaultValue: true,
				label: "",
				type: CustomInputType.Checkbox
			},
			difficultyLevelTierId: {
				selectOptions: [],
				label: "Select Difficulty Level",
				defaultValue: 0,
				placeholder: "Select Difficulty Level",
				type: CustomInputType.Radio,
				validations: { isNumber: true, required: true }
			},
			questionsQuantity: {
				placeholder: "Enter Quantity",
				type: CustomInputType.Number,
				validations: { isInteger: true, min: 1, required: true }
			},
			allSubjects: {
				defaultValue: true,
				label: "All Subjects",
				type: CustomInputType.Checkbox
			},
			subjectIds: {
				label: "",
				placeholder: "",
				selectOptions: [],
				defaultValue: [],
				value: [],
				type: CustomInputType.CheckboxArray,
				validations: { isArray: true }
			},
			subjectLessons: [
				{
					subjectId: { type: CustomInputType.Number },
					allLessons: {
						defaultValue: true,
						label: "All Lessons",
						type: CustomInputType.Checkbox
					},
					lessonIds: {
						label: "",
						placeholder: "",
						selectOptions: [],
						defaultValue: [],
						type: CustomInputType.CheckboxArray,
						validations: { isArray: true }
					}
				}
			],
			isTimed: {
				label: "",
				placeholder: "",
				selectOptions: [],
				type: CustomInputType.Radio,
				validations: { isNumber: true, required: true }
			}
		}
	})
};
const utilsPopulateInputs = getPopulateInputsAction<CreateTestState>();

export const fetchTestFormData = createAsyncThunk(
	"createTest/fetchLists",
	async ({ courseId, isTrial }: { courseId: number; isTrial: boolean }, { dispatch, getState, rejectWithValue }) => {
		try {
			const { isLoading } = (getState() as { createTest: CreateTestState }).createTest;
			if (!isLoading) {
				dispatch(setLoading(true));
			}
			const questionCountResult = await userCustomTestsService.getQuestionCount({
				courseId,
				questionPoolOptions: {
					unansweredQuestions: true,
					markedQuestions: true,
					correctlyAnsweredQuestions: true,
					incorrectlyAnsweredQuestions: true,
					caseStudyQuestions: true
				}
			});
			const userCustomTestTypes = await userCustomTestTypesService.find({ findAll: true });
			const difficultyLevelTiers = await difficultyLevelTiersService.find({ findAll: true });
			const testTime = [
				{ id: 1, name: "Untimed" },
				{ id: 2, name: "Timed" }
			];
			dispatch(
				setStateValue({
					key: "createNewTestForm.inputs.allQuestions.label",
					value: `All (${questionCountResult.totalQuestionsCount})`
				})
			);
			dispatch(
				setStateValue({
					key: "createNewTestForm.inputs.unansweredQuestions.label",
					value: `New Unanswered (${questionCountResult.unansweredQuestionsCount})`
				})
			);
			dispatch(
				setStateValue({
					key: "createNewTestForm.inputs.correctlyAnsweredQuestions.label",
					value: `Correct (${questionCountResult.correctlyAnsweredQuestionsCount})`
				})
			);
			dispatch(
				setStateValue({
					key: "createNewTestForm.inputs.incorrectlyAnsweredQuestions.label",
					value: `Incorrect (${questionCountResult.incorrectlyAnsweredQuestionsCount})`
				})
			);
			dispatch(
				setStateValue({
					key: "createNewTestForm.inputs.markedQuestions.label",
					value: `Marked (${questionCountResult.markedQuestionsCount})`
				})
			);
			dispatch(
				setStateValue({
					key: "createNewTestForm.inputs.caseStudyQuestions.label",
					value: `Case Study (${questionCountResult.caseStudyQuestionsCount})`
				})
			);
			dispatch(setStateValue({ key: "subjects", value: questionCountResult.subjects }));
			dispatch(
				setStateValue({
					key: "totalQuestionsCount",
					value: questionCountResult.totalQuestionsCount
				})
			);
			dispatch(setStateValue({ key: "difficultyLevelTiers", value: difficultyLevelTiers.items }));
			dispatch(
				setStateValue({
					key: "createNewTestForm.inputs.difficultyLevelTierId.selectOptions",
					value: [{ value: 0, text: "All" }, ...getSelectOptions(difficultyLevelTiers.items, "id", "name")]
				})
			);
			dispatch(setStateValue({ key: "userCustomTestTypes", value: userCustomTestTypes.items }));
			let opts = getSelectOptions(userCustomTestTypes.items, "id", "name");
			opts = opts.map(x => ({
				...x,
				...(x.value === QuestionBankTestModes.CAT && {
					text: `${x.text} - ${questionCountResult.catQuotaAvailable} CAT tests available`,
					disabled: isTrial || !questionCountResult.isCATAvailable
				})
			}));
			if (!questionCountResult.isCATAvailable && !isTrial) {
				opts = opts.filter(x => x.value !== QuestionBankTestModes.CAT);
			}
			dispatch(
				setStateValue({
					key: "catQuotaAvailable",
					value: questionCountResult.catQuotaAvailable
				})
			);
			dispatch(
				setStateValue({
					key: "createNewTestForm.inputs.typeId.selectOptions",
					value: [...opts]
				})
			);
			dispatch(
				setStateValue({
					key: "createNewTestForm.inputs.isTimed.selectOptions",
					value: getSelectOptions(testTime, "id", "name")
				})
			);
			const subjectsWithLessons = questionCountResult.subjects.filter(s => s.lessons.length > 0);
			const subjectOptions: CustomInputSelectOptionsItem[] = subjectsWithLessons.map(
				({ id, name, questionCount, isAvailableForTrial }) => ({
					text: `${name} (${questionCount})`,
					value: id,
					disabled: isTrial && !isAvailableForTrial
				})
			);
			dispatch(
				setStateValue({
					key: "createNewTestForm.inputs.allSubjects.label",
					value: `All Subjects (${subjectsWithLessons.length})`
				})
			);
			dispatch(
				setStateValue({
					key: "createNewTestForm.inputs.subjectIds.selectOptions",
					value: subjectOptions
				})
			);
			const allSubjectIds = subjectsWithLessons.reduce((acc, s) => {
				const disabled = isTrial && !s.isAvailableForTrial;
				if (!disabled) {
					acc.push(s.id);
				}
				return acc;
			}, [] as number[]);
			const subjectLessons = subjectsWithLessons.map(({ id, lessons }) => ({
				subjectId: id,
				allLessons: true,
				lessonIds: lessons.map(l => l.id)
			}));
			dispatch(
				populateInputs({
					inputsStatePath: "createNewTestForm.inputs",
					templatesStatePath: "createNewTestForm.templates",
					values: {
						subjectLessons,
						subjectIds: allSubjectIds
					}
				})
			);
			for (const index in subjectsWithLessons) {
				const subject = subjectsWithLessons[index];
				dispatch(
					setStateValue({
						key: `createNewTestForm.inputs.subjectLessons.${index}.lessonIds.selectOptions`,
						value: getSelectOptions(subject.lessons, "id", "name")
					})
				);
			}
		} catch (e) {
			dispatch(
				emit({
					message: "An error has occurred while fetching the data.",
					color: "error"
				})
			);
			return rejectWithValue(e.message);
		} finally {
			dispatch(setLoading(false));
		}
	}
);

export const buyExtraCatTests = createAsyncThunk(
	"createTest/buyExtraCatTests",
	async (
		{
			quantity,
			paymentProviderPaymentMethodIdentifier,
			catPaymentId,
			sideEffect
		}: {
			quantity: number;
			paymentProviderPaymentMethodIdentifier: string;
			catPaymentId: number;
			sideEffect: () => void;
		},
		{ dispatch, rejectWithValue }
	) => {
		try {
			const paymentResponse = await usersService.buyCATTests({
				quantity,
				paymentProviderPaymentMethodIdentifier,
				catPaymentId
			});

			dispatch(
				setStateValue({
					key: "catPaymentResult",
					value: { amount: paymentResponse.amount }
				})
			);
			sideEffect();
		} catch (e) {
			dispatch(
				setStateValue({
					key: "catPaymentResult",
					value: { amount: 0 }
				})
			);
			return rejectWithValue(e.message);
		}
	}
);

export const fetchCATPaymentInfo = createAsyncThunk(
	"createTest/fetchCATPaymentInfo",
	async ({ courseId }: { courseId: number }, { dispatch, rejectWithValue }) => {
		try {
			const { additionalPaymentPlan, id } = await genericService.getCATPaymentInfo({ courseId });

			dispatch(
				setStateValue({
					key: "catPaymentInfo",
					value: { ...additionalPaymentPlan, catPaymentId: id }
				})
			);
		} catch (e) {
			dispatch(
				emit({
					message: e.message,
					color: "error"
				})
			);
			return rejectWithValue(e.message);
		}
	}
);

export const fetchQuestionPoolCount = createAsyncThunk(
	"createTest/fetchQuestionPoolCount",
	async ({ courseId, isTrial }: { courseId: number; isTrial: boolean }, { dispatch, getState, rejectWithValue }) => {
		try {
			const {
				createNewTestForm: { rawData, inputs },
				isPoolCountLoading
			} = (getState() as { createTest: CreateTestState }).createTest;

			if (!isPoolCountLoading) {
				dispatch(
					setStateValue({
						key: "isPoolCountLoading",
						value: true
					})
				);
			}

			const { userCustomTestTypes, catPaymentResult } = (getState() as { createTest: CreateTestState }).createTest;

			const selectedSubjects = inputs.subjectLessons.filter(({ subjectId }) =>
				inputs.subjectIds.value?.includes(subjectId.value ?? 0)
			);
			const unflattenedLessonIds = selectedSubjects.map(({ lessonIds: { value } }) => value).flat() as number[];

			const payload = {
				courseId,
				lessonIds: unflattenedLessonIds,
				difficultyTierId: rawData.difficultyLevelTierId,
				questionPoolOptions: {
					unansweredQuestions: rawData.unansweredQuestions,
					markedQuestions: rawData.markedQuestions,
					correctlyAnsweredQuestions: rawData.correctlyAnsweredQuestions,
					incorrectlyAnsweredQuestions: rawData.incorrectlyAnsweredQuestions,
					caseStudyQuestions: rawData.caseStudyQuestions
				}
			};
			if (rawData.difficultyLevelTierId === 0) {
				// All is selected
				delete payload.difficultyTierId;
			}
			const questionCountResult = await userCustomTestsService.getQuestionCount(payload);
			dispatch(
				setStateValue({
					key: "createNewTestForm.inputs.allQuestions.label",
					value: `All (${questionCountResult.totalQuestionsCount})`
				})
			);
			dispatch(
				setStateValue({
					key: "createNewTestForm.inputs.unansweredQuestions.label",
					value: `New Unanswered (${questionCountResult.unansweredQuestionsCount})`
				})
			);
			dispatch(
				setStateValue({
					key: "createNewTestForm.inputs.correctlyAnsweredQuestions.label",
					value: `Correct (${questionCountResult.correctlyAnsweredQuestionsCount})`
				})
			);
			dispatch(
				setStateValue({
					key: "createNewTestForm.inputs.incorrectlyAnsweredQuestions.label",
					value: `Incorrect (${questionCountResult.incorrectlyAnsweredQuestionsCount})`
				})
			);
			dispatch(
				setStateValue({
					key: "createNewTestForm.inputs.markedQuestions.label",
					value: `Marked (${questionCountResult.markedQuestionsCount})`
				})
			);
			dispatch(
				setStateValue({
					key: "createNewTestForm.inputs.caseStudyQuestions.label",
					value: `Case Study (${questionCountResult.caseStudyQuestionsCount})`
				})
			);
			dispatch(
				setStateValue({
					key: "totalQuestionsCount",
					value: questionCountResult.totalQuestionsCount
				})
			);
			dispatch(
				setStateValue({
					key: "isCATAvailable",
					value: questionCountResult.isCATAvailable
				})
			);

			if (catPaymentResult?.amount) {
				let opts = getSelectOptions(userCustomTestTypes as unknown as Record<string, unknown>[], "id", "name");
				opts = opts.map(x => ({
					...x,
					...(x.value === QuestionBankTestModes.CAT && {
						text: `${x.text} - ${questionCountResult.catQuotaAvailable} CAT tests available`,
						disabled: isTrial || !questionCountResult.isCATAvailable
					})
				}));
				if (!questionCountResult.isCATAvailable && !isTrial) {
					opts = opts.filter(x => x.value !== QuestionBankTestModes.CAT);
				}
				dispatch(
					setStateValue({
						key: "catQuotaAvailable",
						value: questionCountResult.catQuotaAvailable
					})
				);

				dispatch(
					setStateValue({
						key: "createNewTestForm.inputs.typeId.selectOptions",
						value: [...opts]
					})
				);
			}
			const subjectsWithLessons = questionCountResult.subjects.filter(s => s.lessons.length > 0);
			const subjectOptions: CustomInputSelectOptionsItem[] = subjectsWithLessons.map(
				({ id, name, questionCount, isAvailableForTrial }) => ({
					text: `${name} (${questionCount})`,
					value: id,
					disabled: isTrial && !isAvailableForTrial
				})
			);
			dispatch(
				setStateValue({
					key: "createNewTestForm.inputs.allSubjects.label",
					value: `All Subjects (${subjectsWithLessons.length})`
				})
			);
			dispatch(
				setStateValue({
					key: "createNewTestForm.inputs.subjectIds.selectOptions",
					value: subjectOptions
				})
			);
		} catch (e) {
			dispatch(
				emit({
					message: "An error has occurred while creating the data.",
					color: "error"
				})
			);
			return rejectWithValue(e.message);
		} finally {
			dispatch(
				setStateValue({
					key: "isPoolCountLoading",
					value: false
				})
			);
		}
	}
);

export const populateCustomTestForm = createAsyncThunk(
	"createTest/populateCustomTestForm ",
	async (
		{ typeId, courseId, isTrial }: { typeId: number; courseId?: number; isTrial: boolean },
		{ dispatch, rejectWithValue }
	) => {
		try {
			if (typeId === QuestionBankTestModes.CAT) {
				dispatch(setStateValue({ key: "createNewTestForm.inputs.allQuestions.value", value: false }));
				dispatch(setStateValue({ key: "createNewTestForm.inputs.unansweredQuestions.value", value: false }));
				dispatch(
					setStateValue({
						key: "createNewTestForm.inputs.correctlyAnsweredQuestions.value",
						value: false
					})
				);
				dispatch(
					setStateValue({
						key: "createNewTestForm.inputs.incorrectlyAnsweredQuestions.value",
						value: false
					})
				);
				dispatch(setStateValue({ key: "createNewTestForm.inputs.markedQuestions.value", value: false }));
				dispatch(setStateValue({ key: "createNewTestForm.inputs.caseStudyQuestions.value", value: false }));
				dispatch(setStateValue({ key: "createNewTestForm.inputs.difficultyLevelTierId.value", value: 0 }));
				dispatch(setStateValue({ key: "createNewTestForm.inputs.allSubjects.value", value: false }));
				dispatch(
					setStateValue({
						key: "createNewTestForm.inputs.subjectIds.value",
						value: []
					})
				);
				dispatch(setStateValue({ key: "createNewTestForm.inputs.isTimed.value", value: 0 }));
				dispatch(
					setStateValue({
						key: "createNewTestForm.inputs.questionsQuantity.validations.required",
						value: false
					})
				);
			} else {
				dispatch(setTestFormToDefaultValue());
				dispatch(
					setStateValue({
						key: "createNewTestForm.inputs.questionsQuantity.validations.required",
						value: true
					})
				);
				dispatch(validateForm({ formStatePath: "createNewTestForm", markInputsAsDirty: true }));
			}
			courseId &&
				dispatch(
					fetchQuestionPoolCount({
						courseId,
						isTrial
					})
				);
		} catch (e) {
			return rejectWithValue(e.message);
		}
	}
);

export const setTestFormToDefaultValue = createAsyncThunk(
	"createTest/setTestFormToDefaultValue",
	async (_, { dispatch, getState, rejectWithValue }) => {
		try {
			const { inputs: createNewTestFormInputs } = (
				getState() as {
					createTest: CreateTestState;
				}
			).createTest.createNewTestForm;
			const allSubjectIds = createNewTestFormInputs?.subjectIds?.selectOptions?.map(option => option.value);
			dispatch(setStateValue({ key: "createNewTestForm.inputs.allQuestions.value", value: true }));
			dispatch(setStateValue({ key: "createNewTestForm.inputs.unansweredQuestions.value", value: true }));
			dispatch(
				setStateValue({
					key: "createNewTestForm.inputs.correctlyAnsweredQuestions.value",
					value: true
				})
			);
			dispatch(
				setStateValue({
					key: "createNewTestForm.inputs.incorrectlyAnsweredQuestions.value",
					value: true
				})
			);
			dispatch(setStateValue({ key: "createNewTestForm.inputs.markedQuestions.value", value: true }));
			dispatch(setStateValue({ key: "createNewTestForm.inputs.caseStudyQuestions.value", value: true }));
			dispatch(setStateValue({ key: "createNewTestForm.inputs.difficultyLevelTierId.value", value: 0 }));
			dispatch(setStateValue({ key: "createNewTestForm.inputs.allSubjects.value", value: true }));
			dispatch(
				setStateValue({
					key: "createNewTestForm.inputs.subjectIds.value",
					value: allSubjectIds
				})
			);
			dispatch(
				setStateValue({
					key: "createNewTestForm.inputs.isTimed.value",
					value: ""
				})
			);
		} catch (e) {
			return rejectWithValue(e.message);
		}
	}
);

export const save = createAsyncThunk(
	"createTest/save",
	async (options: { success: (id: number) => void; courseId }, { dispatch, getState, rejectWithValue }) => {
		try {
			const {
				rawData: {
					caseStudyQuestions,
					correctlyAnsweredQuestions,
					difficultyLevelTierId,
					incorrectlyAnsweredQuestions,
					isTimed,
					markedQuestions,
					name,
					questionsQuantity,
					subjectIds,
					subjectLessons,
					typeId,
					unansweredQuestions
				}
			} = (getState() as { createTest: CreateTestState }).createTest.createNewTestForm;
			const { totalQuestionsCount } = (getState() as { createTest: CreateTestState }).createTest;
			dispatch(setTestCreationLoading(true));
			let data;
			if (typeId === QuestionBankTestModes.CAT) {
				data = {
					name: name,
					typeId: typeId,
					courseId: options.courseId
				};
			} else {
				const selectedSubjects = subjectLessons.filter(sl => subjectIds.includes(sl.subjectId));
				const unflattenedLessonIds = selectedSubjects.map(subjectLesson => {
					return subjectLesson.lessonIds;
				});
				const _isTimed = isTimed === 2;
				if (totalQuestionsCount < questionsQuantity) {
					return dispatch(
						emit({
							message: "Questions quantity exceeds total number of questions",
							color: "error"
						})
					);
				} else if (QUESTION_BANK_CREATION_LIMIT < questionsQuantity) {
					return dispatch(
						emit({
							message: `Questions quantity should not be exceed ${QUESTION_BANK_CREATION_LIMIT}`,
							color: "error"
						})
					);
				}
				data = {
					name: name,
					typeId: typeId,
					courseId: options.courseId,
					difficultyTierId: difficultyLevelTierId,
					lessonIds: unflattenedLessonIds.flat(),
					questionPoolOptions: {
						unansweredQuestions: unansweredQuestions,
						markedQuestions: markedQuestions,
						correctlyAnsweredQuestions: correctlyAnsweredQuestions,
						incorrectlyAnsweredQuestions: incorrectlyAnsweredQuestions,
						caseStudyQuestions: caseStudyQuestions
					},
					questionsQuantity: questionsQuantity,
					isTimed: _isTimed
				};
			}
			if (difficultyLevelTierId === 0) {
				// if difficultyLevelTierId === 0, then "all" is selected then `difficultyTierId` should be omitted
				delete data.difficultyTierId;
			}

			const { id } = await userCustomTestsService.create(data);
			options.success(id);
			const testID = id + ""; // todo <- something strange. Do we need any text in "" ?
			localStorage.setItem("currentTestID", testID);
			dispatch(
				emit({
					message: "Test has been created successfully",
					color: "success"
				})
			);
		} catch (e) {
			dispatch(
				emit({
					message: "An error has occurred while creating the data.",
					color: "error"
				})
			);
			return rejectWithValue(e.message);
		} finally {
			dispatch(setTestCreationLoading(false));
			dispatch(
				setStateValue({
					key: "createNewTestForm.valid",
					value: false
				})
			);
		}
	}
);

const utilsResetState = getResetState<CreateTestState>(initialState);

export function getFullState(state: RootState): CreateTestState {
	return state.createTest;
}

export const createTestSlice = createSlice({
	name: "createTest",
	initialState,
	reducers: {
		setLoading: (state, { payload }: PayloadAction<boolean>) => {
			state.isLoading = payload;
		},
		setTestCreationLoading: (state, { payload }: PayloadAction<boolean>) => {
			state.isTestCreationLoading = payload;
		},
		failed: (state, { payload: { errorMessage } }: PayloadAction<{ errorMessage: string }>) => {
			state.errorMessage = errorMessage;
		},
		setStateValue: utilsSetStateValue,
		validateForm: utilsValidateFormAction,
		resetState: utilsResetState,
		populateInputs: utilsPopulateInputs
	}
});

export const { resetState, setStateValue, validateForm, setLoading, populateInputs, setTestCreationLoading } =
	createTestSlice.actions;
export default createTestSlice.reducer;

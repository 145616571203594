import React, { useCallback, useEffect, useMemo, useState } from "react";

import { Box, Button, CircularProgress, useMediaQuery, useTheme } from "@material-ui/core";
import { Star } from "@material-ui/icons";
import { ActionMenu } from "@remar/shared/dist/components/ActionMenu";
import InfoBlock from "@remar/shared/dist/components/InfoBlock";
import { IColumn, MaterialTable } from "@remar/shared/dist/components/MaterialTable";
import SearchBarComponent from "@remar/shared/dist/components/SearchBar";
import { SimpleModal } from "@remar/shared/dist/components/SimpleModal";

import {
	ColumnHeader,
	StyledCellText,
	StyledCellWrapper,
	THeaderTitle,
	THeaderWrapper
} from "@remar/shared/dist/components/Table/styles";
import { TablePagination } from "@remar/shared/dist/components/TablePagination";
import { QuestionBankTestModes, QuestionDifficultyLevelTiers } from "@remar/shared/dist/constants";
import { Wrapper } from "@remar/shared/dist/layouts";
import ContentLoader from "@remar/shared/dist/layouts/TableContentLayout/components/ContentLoader";
import { UserCustomTest } from "@remar/shared/dist/models";
import { Themes } from "@remar/shared/dist/models/theme.types";
import { IExtendedTheme } from "@remar/shared/dist/theme/default";
import { formatDate } from "@remar/shared/dist/utils/myAccountUtils";

import useAnalyticsEventTracker from "hooks/googleAnalytics";

import isEmpty from "lodash/isEmpty";

import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import { selectIsTrialUser } from "store/features/Auth/authSlice";
import {
	fetchTests as fetchSingleTest,
	getIsLoading,
	retakeTest,
	setCurrentTest
} from "store/features/QuestionBank/Test/test.slice";

import {
	deleteTest,
	fetchQuestionBankPerformance,
	fetchQuestionBankStats,
	fetchSubjects,
	fetchTests,
	getFullState,
	resetState
} from "store/features/QuestionBank/questionBank.slice";

import { getFullState as getThemeState } from "store/features/Theme/theme.slice";

import { ReactComponent as IconQuestionsRemaining } from "assets/icons/icon-questions-remaining.svg";
import { ReactComponent as IconQuestionsTaken } from "assets/icons/icon-questions-taken.svg";
import { ReactComponent as NoTestHistoryIconSvg } from "assets/icons/icon-tests-history-empty.svg";
import { ReactComponent as NoTestHistoryIconBlueSvg } from "assets/icons/icon-tests-history-empty_blue.svg";
import { ReactComponent as NoTestHistoryIconGreenSvg } from "assets/icons/icon-tests-history-empty_green.svg";
import { ReactComponent as NoTestHistoryIconOrangeSvg } from "assets/icons/icon-tests-history-empty_orange.svg";
import { ReactComponent as NoTestHistoryIconPurpleSvg } from "assets/icons/icon-tests-history-empty_purple.svg";
import { ReactComponent as NoTestHistoryIconRedSvg } from "assets/icons/icon-tests-history-empty_red.svg";
import { ReactComponent as NoTestHistoryIconYellowSvg } from "assets/icons/icon-tests-history-empty_yellow.svg";
import { ReactComponent as IconTotalQuestions } from "assets/icons/icon-total-questions.svg";
import { routes } from "core/constants";

import RadialBar from "./Charts/RadialBar";

import SemiRadialBar from "./Charts/SemiRadialBar";
import CreateTestBanner from "./CreateTestBanner";
import MotivationalQuotes from "./MotivationalQuotes";

import SubjectLessonStats from "./SubjectLessonStats";
import { ExtendedResults } from "./Test";
import {
	AnswersChart,
	AnswersContainer,
	AnswersProgress,
	CancelButton,
	CardSubInfo,
	DeleteButton,
	FooterContainer,
	NoTestHistoryButton,
	NoTestHistoryText,
	NoTestsContainer,
	PerformanceContainer,
	QuestionInfoContainer,
	ScoreDetailInfo,
	ScoreInfo,
	ScoreLevel,
	ScoreTitle,
	ScoreTitleContainer
} from "./styles";

const scoreCategories = {
	low: {
		title: "Low",
		color: "rgba(213, 75, 91, 0.87)",
		desc: "Your score demonstrates a low competency of the core nursing content for the NCLEX examination.  Prior to taking your state board exams you are encouraged to seek further remediation by reviewing the NCLEX Virtual Trainer to boost your content knowledge. Focusing more on the content will help you achieve a higher level of knowledge and improve your critical thinking skills and NCLEX readiness."
	},
	fair: {
		color: "rgba(219, 172, 49, 0.87)",
		title: "Fair",
		desc: " Your score is right on the borderline which indicates a basic competency of the core nursing content for the NCLEX examination, however there are some inconsistences. For improved results, use the NCLEX Virtual Trainer and Question Bank. Try to refocus on your weak areas by studying the content to improve your critical thinking and achieve a higher level of NCLEX readiness prior to testing."
	},
	good: {
		title: "Good",
		color: "rgba(96, 224, 66, 0.87)",
		desc: "Your score reflects a firm knowledge of the core nursing content for the NCLEX examination. Your overall performance is good and you should consider testing in the near future. With continued study with the NCLEX Virtual Trainer and Question Bank you can reach an excellent level of nursing content knowledge and critical thinking. You are on the right track, keep up the good work!"
	},
	excellent: {
		title: "Excellent",
		color: "rgba(70, 217, 68, 0.87)",
		desc: "Your score is reflective of an excellent knowledge of the core nursing content for the NCLEX examination! You are able to critically think and consistently choose safe outcomes. Try not to second guess yourself - your proficiency level will only increase with further study as you prepare to test.  Great job!"
	}
};

const getThemedSvg = (colorShade: Themes) => {
	switch (colorShade) {
		case Themes.DARK_CYAN_BLUE:
		case Themes.LIGHT_CYAN_BLUE:
			return <NoTestHistoryIconBlueSvg />;
		case Themes.DARK_RED:
		case Themes.LIGHT_RED:
			return <NoTestHistoryIconRedSvg />;
		case Themes.DARK_YELLOW:
		case Themes.LIGHT_YELLOW:
			return <NoTestHistoryIconYellowSvg />;
		case Themes.DARK_ORANGE:
		case Themes.LIGHT_ORANGE:
			return <NoTestHistoryIconOrangeSvg />;
		case Themes.DARK_GREEN:
		case Themes.LIGHT_GREEN:
			return <NoTestHistoryIconGreenSvg />;
		case Themes.DARK_BLUE:
		case Themes.LIGHT_BLUE:
			return <NoTestHistoryIconBlueSvg />;
		case Themes.DARK_PURPLE:
		case Themes.LIGHT_PURPLE:
			return <NoTestHistoryIconPurpleSvg />;
		default:
			return <NoTestHistoryIconSvg />;
	}
};

const QuestionBank = () => {
	const theme = useTheme<IExtendedTheme>();
	const history = useHistory();
	const dispatch = useDispatch();
	const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
	const analytics = useAnalyticsEventTracker("Question Bank");

	const { colorShade } = useSelector(getThemeState);
	const isTrial: boolean = useSelector(selectIsTrialUser);

	useEffect(() => {
		dispatch(fetchTests({}));
		if (!isTrial) {
			dispatch(fetchQuestionBankStats({}));
			dispatch(fetchQuestionBankPerformance());
			dispatch(fetchSubjects());
		}
		return () => {
			dispatch(resetState());
		};
	}, [isTrial, dispatch]);

	const [testPreviewData, setTestPreviewData] = useState({});
	const [showRetakeTestModal, setShowRetakeTestModal] = useState(false);
	const [deleteTestId, setDeleteTestId] = useState(0);

	const [currentTestId, setCurrentTestId] = useState(0);
	const loading: boolean = useSelector(getIsLoading);
	const {
		isLoadingTests,
		perPage,
		page,
		tests,
		totalItems,
		isLoadingStats,
		questionBankStats,
		userQuestionBankPerformance
	} = useSelector(getFullState);

	const [searchText, setSearchText] = useState("");

	const tableColumns: Array<IColumn<UserCustomTest>> = useMemo(
		() => [
			{
				alignment: "left",
				label: <ColumnHeader>Test Name</ColumnHeader>,
				width: isMobile ? 300 : 600,
				Cell: ({ rowData: { name } }) => (
					<StyledCellWrapper>
						<StyledCellText>{name}</StyledCellText>
					</StyledCellWrapper>
				),
				dataKey: "testName"
			},
			{
				alignment: "left",
				label: <ColumnHeader>Test Type</ColumnHeader>,
				Cell: ({ rowData: { typeId } }) => (
					<StyledCellWrapper>
						<StyledCellText>{QuestionBankTestModes[typeId]}</StyledCellText>
					</StyledCellWrapper>
				),
				dataKey: "testType"
			},
			{
				alignment: "left",
				label: <ColumnHeader>Questions Number</ColumnHeader>,
				Cell: ({
					rowData: {
						data: { questions },
						typeId,
						inProgress
					}
				}) => (
					<StyledCellWrapper center>
						<StyledCellText>
							{typeId === QuestionBankTestModes.CAT && inProgress && questions
								? questions?.length - 1
								: questions?.length || 0}
						</StyledCellText>
					</StyledCellWrapper>
				),
				dataKey: "questionsNumber"
			},
			{
				alignment: "left",
				label: <ColumnHeader>Difficulty</ColumnHeader>,
				Cell: ({ rowData: { difficultyTierId } }) => (
					<StyledCellWrapper>
						<StyledCellText>{QuestionDifficultyLevelTiers[difficultyTierId] ?? "-"}</StyledCellText>
					</StyledCellWrapper>
				),
				dataKey: "difficulty"
			},
			{
				alignment: "left",
				label: <ColumnHeader>Creation Date</ColumnHeader>,
				Cell: ({ rowData: { createdAt } }) => (
					<StyledCellWrapper>
						<StyledCellText>{formatDate(createdAt!)}</StyledCellText>
					</StyledCellWrapper>
				),
				dataKey: "createdAt"
			},
			{
				alignment: "right",
				label: "",
				width: 100,
				Cell: ({ rowData }) => {
					return (
						<ActionMenu
							customMenuItems={[
								{
									label: "Preview",
									onClick: () => {
										{
											dispatch(
												fetchSingleTest({
													id: rowData.id,
													sideEffect: testData => {
														if (!testData) return;
														analytics({ eventName: "preview_test", eventIdentifier: testData.id });
														dispatch(setCurrentTest(testData));
														setTestPreviewData({
															typeId: testData.typeId,
															quizResult: testData.userQuestionAttempts,
															quizAttemptedQuestions: testData.data.questions,
															...(testData.typeId === QuestionBankTestModes.CAT && {
																contentAreas: testData.data.result?.contentAreas
															}),
															...(testData.typeId === QuestionBankTestModes.CAT && {
																catAttemptedQuestionsCount: testData.userQuestionAttempts.length
															})
														});
													}
												})
											);
										}
									},
									visible: !rowData.inProgress,
									disabled: false
								},
								{
									label: "Retake",
									onClick: () => {
										localStorage.setItem("currentTestID", rowData.id);
										setCurrentTestId(rowData.id);
										setShowRetakeTestModal(true);
									},
									visible: !rowData.inProgress && rowData.typeId !== QuestionBankTestModes.CAT,
									disabled: false
								},
								{
									label: "Continue Test",
									onClick: () => {
										const { id } = rowData;
										localStorage.setItem("currentTestID", id);
										analytics({ eventName: "continue_test", eventIdentifier: rowData.id });
										history.push(`${routes.questionBank.getPath()}/test/${id}`);
									},
									visible: rowData.inProgress,
									disabled: false
								},
								{
									label: "Delete",
									onClick: () => setDeleteTestId(rowData.id),
									visible: true,
									disabled: false
								}
							]}
						/>
					);
				},
				dataKey: "menu"
			}
		],
		[analytics, dispatch, history, isMobile]
	);

	const handleSearchBarChange = useCallback(
		searchText => {
			return dispatch(fetchTests({ searchText, page: 1 }));
		},
		[dispatch]
	);

	const getAnswerPercentage = useCallback(
		answerCount => {
			const totalAnswers =
				questionBankStats.userAnswersCount.correctAnswers +
				questionBankStats.userAnswersCount.incorrectAnswers +
				questionBankStats.userAnswersCount.partialCorrectAnswers;
			const divisionResult = answerCount / totalAnswers;
			return isNaN(divisionResult) ? 0 : Math.round(divisionResult * 100);
		},
		[
			questionBankStats.userAnswersCount.correctAnswers,
			questionBankStats.userAnswersCount.incorrectAnswers,
			questionBankStats.userAnswersCount.partialCorrectAnswers
		]
	);

	const redirectToTest = () => {
		analytics({ eventName: "retake_test_from_list_view", eventIdentifier: `${currentTestId}` });
		history.push(`${routes.questionBank.getPath()}/test/${currentTestId}`);
	};

	const questionInfos = useMemo(
		() => [
			{
				bgColor:
					theme.palette.type === "dark" ? theme.palette.colors.primary[1200] : theme.palette.InfoCard.backgroundColor,
				title: "Total Questions",
				text: questionBankStats.userQuestionsCount?.totalquestions,
				IconSvg: IconTotalQuestions
			},
			{
				bgColor: theme.palette.type === "dark" ? "hsl(201,78%,12%)" : theme.palette.InfoCard.backgroundColor,
				title: "Questions Taken",
				text: questionBankStats.userQuestionsCount?.totalquestionAttempts,
				IconSvg: IconQuestionsTaken
			},
			{
				bgColor: theme.palette.type === "dark" ? "hsl(307,32%,15%)" : theme.palette.InfoCard.backgroundColor,
				title: "Questions Remaining",
				text: questionBankStats.userQuestionsCount?.totalRemainingQuestions,
				IconSvg: IconQuestionsRemaining
			}
		],
		[questionBankStats.userQuestionsCount]
	);

	const chartAnswers = useMemo(
		() => [
			{
				label: "Correct Answers",
				count: questionBankStats.userAnswersCount.correctAnswers,
				percentage: getAnswerPercentage(questionBankStats.userAnswersCount.correctAnswers),
				primaryColor: theme.palette.RadialChart.sucess.primaryColor,
				secondaryColor: theme.palette.RadialChart.sucess.secondaryColor
			},
			{
				label: "Partially Correct Answers",
				count: questionBankStats.userAnswersCount.partialCorrectAnswers,
				percentage: getAnswerPercentage(questionBankStats.userAnswersCount.partialCorrectAnswers),
				primaryColor: theme.palette.RadialChart.warning.primaryColor,
				secondaryColor: theme.palette.RadialChart.warning.secondaryColor
			},
			{
				label: "Incorrect Answers",
				count: questionBankStats.userAnswersCount.incorrectAnswers,
				percentage: getAnswerPercentage(questionBankStats.userAnswersCount.incorrectAnswers),
				primaryColor: theme.palette.RadialChart.danger.primaryColor,
				secondaryColor: theme.palette.RadialChart.danger.secondaryColor
			}
		],
		[
			getAnswerPercentage,
			questionBankStats.userAnswersCount.correctAnswers,
			questionBankStats.userAnswersCount.incorrectAnswers,
			questionBankStats.userAnswersCount.partialCorrectAnswers
		]
	);

	const getScoreLevel = useMemo(() => {
		const count = getAnswerPercentage(questionBankStats.userAnswersCount.correctAnswers);
		return count <= 49
			? "low"
			: count >= 50 && count <= 60
			? "fair"
			: count >= 61 && count <= 70
			? "good"
			: count >= 71 && count <= 100
			? "excellent"
			: "";
	}, [getAnswerPercentage, questionBankStats.userAnswersCount.correctAnswers]);

	return (
		<Wrapper
			heading="Question Bank"
			actions={
				<Button
					variant="contained"
					color={"primary"}
					onClick={() => {
						analytics({ eventName: "start_creating_test" });
						history.push(`${routes.questionBank.getPath()}/createTest`);
					}}
				>
					Create New Test
				</Button>
			}
		>
			{isTrial ? (
				<CreateTestBanner />
			) : (
				<>
					<QuestionInfoContainer>
						{isLoadingStats ? (
							<ContentLoader />
						) : (
							questionInfos.map(({ bgColor, title, text, IconSvg }, i) => (
								<InfoBlock key={i} customBgColor={bgColor} title={title} text={text || "0"} IconSvg={IconSvg} streth />
							))
						)}
					</QuestionInfoContainer>
					{!isLoadingStats ? (
						<AnswersContainer>
							<AnswersChart>
								{chartAnswers.map((x, i) => (
									<RadialBar key={i} data={x} />
								))}
							</AnswersChart>

							<AnswersProgress>
								<SemiRadialBar data={getAnswerPercentage(questionBankStats.userAnswersCount.correctAnswers)} />
								<ScoreInfo>
									<ScoreTitleContainer>
										<ScoreTitle> Your Total Score</ScoreTitle>
										<ScoreLevel color={scoreCategories[getScoreLevel].color}>
											{scoreCategories[getScoreLevel].title}&nbsp;(
											{getAnswerPercentage(questionBankStats.userAnswersCount.correctAnswers)}){" "}
										</ScoreLevel>
									</ScoreTitleContainer>
									<ScoreDetailInfo>{scoreCategories[getScoreLevel].desc}</ScoreDetailInfo>

									<PerformanceContainer>
										<Star style={{ width: "25px", height: "25px", fill: "#4a99f9" }} />
										<CardSubInfo>
											You are performing better than {userQuestionBankPerformance}% of your peers!
										</CardSubInfo>
									</PerformanceContainer>
								</ScoreInfo>
							</AnswersProgress>
						</AnswersContainer>
					) : null}

					<SubjectLessonStats />
					<MotivationalQuotes />
				</>
			)}
			<>
				<THeaderWrapper>
					<THeaderTitle>Test History</THeaderTitle>
					<SearchBarComponent
						inputValue={searchText}
						setInputValue={setSearchText}
						onChange={handleSearchBarChange}
						isDebounced={true}
						debounceTime={1000}
						placeHolder={"Type to filter by keywords"}
					/>
				</THeaderWrapper>
			</>
			<>
				{isLoadingTests ? (
					<Box display="flex" alignItems="center" justifyContent="center" height={500} width="100%">
						<CircularProgress size="7rem" color="primary" thickness={5} variant="indeterminate" />
					</Box>
				) : tests.length ? (
					<>
						<MaterialTable columns={tableColumns} data={tests} height={800} />
						<TablePagination
							count={totalItems}
							page={page}
							onChange={(_, page) =>
								dispatch(
									fetchTests({
										page,
										...(searchText && { searchText })
									})
								)
							}
							rowsPerPage={perPage}
						/>
					</>
				) : (
					<NoTestsContainer>
						{getThemedSvg(colorShade)}
						<NoTestHistoryText variant="h1">No tests history yet</NoTestHistoryText>
						<NoTestHistoryButton
							variant={"contained"}
							color={"primary"}
							onClick={() => history.push(`${routes.questionBank.getPath()}/createTest`)}
						>
							Create New Test
						</NoTestHistoryButton>
					</NoTestsContainer>
				)}
			</>

			{!isEmpty(testPreviewData) && (
				<ExtendedResults
					testModeType={testPreviewData["typeId"]}
					userQuestion={testPreviewData["quizAttemptedQuestions"]}
					result={testPreviewData["quizResult"]}
					contentAreas={testPreviewData["contentAreas"]}
					catAttemptedQuestionsCount={testPreviewData["catAttemptedQuestionsCount"]}
					onClose={() => setTestPreviewData({})}
				/>
			)}
			<SimpleModal
				theme={theme}
				open={!!deleteTestId}
				onClose={() => setDeleteTestId(0)}
				title="Delete Test"
				text="Are you sure you want to delete this this?"
				footer={
					<FooterContainer>
						<CancelButton variant={"contained"} size="medium" onClick={() => setDeleteTestId(0)}>
							No, Cancel
						</CancelButton>
						<DeleteButton
							variant={"contained"}
							size="medium"
							onClick={() => {
								analytics({ eventName: "delete_test", eventIdentifier: `${deleteTestId}` });
								dispatch(deleteTest(deleteTestId));
								setDeleteTestId(0);
							}}
						>
							Yes, Delete
						</DeleteButton>
					</FooterContainer>
				}
			/>
			<SimpleModal
				theme={theme}
				title={"Retake Test"}
				open={showRetakeTestModal}
				onClose={() => setShowRetakeTestModal(false)}
				text={"Are you sure you want to retake the test?"}
				footer={
					<>
						<CancelButton
							variant="outlined"
							size="medium"
							onClick={() => setShowRetakeTestModal(false)}
							disabled={loading}
						>
							Cancel
						</CancelButton>
						<Button
							size="medium"
							onClick={() => dispatch(retakeTest({ userCustomTestId: +currentTestId, sideEffect: redirectToTest }))}
							variant="contained"
							color="primary"
							disabled={loading}
						>
							{loading && <CircularProgress size={20} color="inherit" />}
							Confirm
						</Button>
					</>
				}
			/>
		</Wrapper>
	);
};

export default QuestionBank;

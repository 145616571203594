import React, { useEffect, useMemo, useState } from "react";

import { AccordionDetails, Box, Button, Modal, Theme, Typography, useTheme } from "@material-ui/core";
import { ChevronLeft } from "@material-ui/icons";
import { CustomInput } from "@remar/shared/dist/components/CustomInput/CustomInput";
import { QuestionBankTestModes } from "@remar/shared/dist/constants";
import { Wrapper } from "@remar/shared/dist/layouts";
import ContentLoader from "@remar/shared/dist/layouts/TableContentLayout/components/ContentLoader";
import { Course } from "@remar/shared/dist/models";

import useAnalyticsEventTracker from "hooks/googleAnalytics";

import { useDispatch, useSelector } from "react-redux";

import { useHistory } from "react-router-dom";
import {
	selectCurrentlyAllowedFullCourses,
	selectCurrentlyAllowedTrialCourses,
	selectIsTrialUser
} from "store/features/Auth/authSlice";

import {
	fetchQuestionPoolCount,
	fetchTestFormData,
	getFullState,
	populateCustomTestForm,
	save,
	setStateValue,
	validateForm
} from "store/features/QuestionBank/CreateTest/createTest.slice";

import { _emit } from "store/features/notifications/notifications.slice";

import CatTest from "assets/images/cet-test.svg";

import { routes } from "core/constants";

import { CATTestOrderPayment } from "./CATTestOrderPayment";
import { CreateTestLoader } from "./CreateTestLoader";

import {
	CatBuyButtons,
	CheckAllCheckBox,
	CheckBoxesGroup,
	HeaderTitle,
	HeaderWrapper,
	InputsWrapper,
	LessonTextContainer,
	LessonsAccordion,
	LessonsAccordionContainer,
	NewTextConfigContainer,
	NewTextFirstRow,
	StyledAccordionSummary,
	SubjectName,
	useStyles
} from "../styles";

const CreateTest = () => {
	const theme = useTheme<Theme>();
	const dispatch = useDispatch();
	const classes = useStyles();
	const history = useHistory();
	const {
		createNewTestForm,
		totalQuestionsCount,
		subjects,
		userCustomTestTypes,
		isLoading,
		isPoolCountLoading,
		isTestCreationLoading,
		catQuotaAvailable,
		isCATAvailable
	} = useSelector(getFullState);
	const currentlyAllowedFullCourses: Course[] | undefined = useSelector(selectCurrentlyAllowedFullCourses);
	const currentlyAllowedTrialCourses: Course[] | undefined = useSelector(selectCurrentlyAllowedTrialCourses);
	const isTrial: boolean = useSelector(selectIsTrialUser);
	const { inputs: createNewTestFormInputs, rawData: createNewTestFormRawData, valid } = createNewTestForm;
	const { subjectIds } = createNewTestFormInputs;
	const defaultCustomInputOptions = useMemo(() => ({ _emit, dispatch, setStateValue, validateForm }), [dispatch]);

	const [isCatModalVisible, setIsCatModalVisibe] = useState(false);
	const [isCatPaymentModalVisible, setIsCatPaymentModalVisible] = useState(false);
	const analytics = useAnalyticsEventTracker("Question Bank");

	useEffect(() => {
		return () => {
			dispatch(
				setStateValue({
					key: "createNewTestForm.inputs.typeId.value",
					value: ""
				})
			);
		};
	}, [dispatch]);

	useEffect(() => {
		if (
			createNewTestFormInputs.typeId?.value === QuestionBankTestModes.CAT &&
			catQuotaAvailable === 0 &&
			isCATAvailable
		) {
			setIsCatModalVisibe(true);
		}
	}, [catQuotaAvailable, createNewTestFormInputs.typeId?.value, isCATAvailable]);
	const courseId = useMemo(
		() =>
			(currentlyAllowedFullCourses && currentlyAllowedFullCourses[0]?.id) ||
			(currentlyAllowedTrialCourses && currentlyAllowedTrialCourses[0]?.id),
		[currentlyAllowedFullCourses, currentlyAllowedTrialCourses]
	);
	useEffect(() => {
		if (!isLoading && courseId) {
			dispatch(
				fetchTestFormData({
					courseId,
					isTrial
				})
			);
		}
	}, [courseId, isTrial, dispatch]);

	const success = (id: number) => {
		if (createNewTestForm.inputs.typeId?.value === QuestionBankTestModes.Test) {
			analytics({ eventName: "start_taking_test", eventIdentifier: `${id}` });
		} else if (createNewTestForm.inputs.typeId?.value === QuestionBankTestModes.Tutor) {
			analytics({ eventName: "take_tutored_test", eventIdentifier: `${id}` });
		} else if (createNewTestForm.inputs.typeId?.value === QuestionBankTestModes.CAT) {
			analytics({ eventName: "started_cat_test", eventIdentifier: `${id}` });
		}
		id && history.push(`${routes.questionBank.getPath()}/test/${id}`);
	};

	const handlePoolOptionChange = e => {
		if (courseId) {
			dispatch(
				fetchQuestionPoolCount({
					courseId,
					isTrial
				})
			);
			!e.target.checked &&
				dispatch(
					setStateValue({
						key: "createNewTestForm.inputs.allQuestions.value",
						value: false
					})
				);
		}
	};
	const handleAllPoolOptionChange = e => {
		if (e.target.checked && courseId) {
			dispatch(
				setStateValue({
					key: "createNewTestForm.inputs.unansweredQuestions.value",
					value: true
				})
			);
			dispatch(
				setStateValue({
					key: "createNewTestForm.rawData.unansweredQuestions",
					value: true
				})
			);
			dispatch(
				setStateValue({
					key: "createNewTestForm.inputs.correctlyAnsweredQuestions.value",
					value: true
				})
			);
			dispatch(
				setStateValue({
					key: "createNewTestForm.rawData.correctlyAnsweredQuestions",
					value: true
				})
			);
			dispatch(
				setStateValue({
					key: "createNewTestForm.inputs.markedQuestions.value",
					value: true
				})
			);
			dispatch(
				setStateValue({
					key: "createNewTestForm.rawData.markedQuestions",
					value: true
				})
			);

			dispatch(
				setStateValue({
					key: "createNewTestForm.inputs.incorrectlyAnsweredQuestions.value",
					value: true
				})
			);
			dispatch(
				setStateValue({
					key: "createNewTestForm.rawData.incorrectlyAnsweredQuestions",
					value: true
				})
			);
			dispatch(
				setStateValue({
					key: "createNewTestForm.inputs.caseStudyQuestions.value",
					value: true
				})
			);
			dispatch(
				setStateValue({
					key: "createNewTestForm.rawData.caseStudyQuestions",
					value: true
				})
			);
			dispatch(
				fetchQuestionPoolCount({
					courseId,
					isTrial
				})
			);
		}
	};
	const subjectLessonItems = useMemo(
		() =>
			createNewTestFormInputs.subjectLessons.map((subjectLesson, index) => {
				const subjectId = subjectLesson.subjectId.value;
				const selectedSubject = subjects.find(s => s.id == subjectId!);
				const isSelected = createNewTestFormRawData.subjectIds.includes(subjectId!);
				if (isSelected && selectedSubject) {
					return (
						<LessonsAccordionContainer key={subjectId!}>
							<LessonsAccordion>
								<StyledAccordionSummary
									expandIcon={
										<ChevronLeft
											style={{ width: "30px", height: "30px", fill: "#898f9e", transform: "rotate(-90deg)" }}
										/>
									}
								>
									<LessonTextContainer>
										<SubjectName>{selectedSubject!.name}</SubjectName>
										<CustomInput
											theme={theme}
											onChange={e => {
												const allLessonIds = e.target.checked
													? subjectLesson.lessonIds.selectOptions?.map(option => option.value)
													: [];
												dispatch(
													setStateValue({
														key: `${subjectLesson.lessonIds.statePath}.value`,
														value: allLessonIds
													})
												);
												setTimeout(() => {
													dispatch(
														fetchQuestionPoolCount({
															courseId: courseId as number,
															isTrial
														})
													);
												}, 100);
											}}
											onClick={e => {
												e.stopPropagation();
											}}
											options={{
												...defaultCustomInputOptions,
												inputData: subjectLesson.allLessons
											}}
										/>
									</LessonTextContainer>
								</StyledAccordionSummary>
								<AccordionDetails>
									<Box className={classes.container}>
										<CustomInput
											theme={theme}
											containerClassName={"lesson-accordion"}
											onChange={() => {
												dispatch(
													setStateValue({
														key: `createNewTestForm.inputs.subjectLessons.${index}.allLessons.value`,
														value: false
													})
												);
											}}
											options={{
												...defaultCustomInputOptions,
												inputData: subjectLesson.lessonIds
											}}
										/>
									</Box>
								</AccordionDetails>
							</LessonsAccordion>
						</LessonsAccordionContainer>
					);
				}
			}),
		[
			createNewTestFormInputs.subjectLessons,
			subjects,
			createNewTestFormRawData.subjectIds,
			theme,
			defaultCustomInputOptions,
			classes.container,
			dispatch,
			courseId,
			isTrial
		]
	);

	const isCatOnly = useMemo(
		() => createNewTestFormInputs?.typeId?.value === QuestionBankTestModes.CAT,
		[createNewTestFormInputs.typeId]
	);

	const handleClose = () => {
		courseId &&
			dispatch(
				fetchQuestionPoolCount({
					courseId,
					isTrial
				})
			);
		setIsCatPaymentModalVisible(false);
	};

	const handleStart = (courseId?: number) => {
		if (!isCATAvailable && createNewTestForm.inputs.typeId?.value === QuestionBankTestModes.CAT) {
			dispatch(_emit("CAT test is unavailable because of questions amount in database", "error"));
		} else if (courseId) {
			dispatch(
				save({
					success,
					courseId
				})
			);
		}
	};
	const breadcrumb = [
		{ title: "Question Bank", key: 0, link: routes.questionBank.getPath() },
		{ title: "Create New Test", key: 1, link: `${routes.questionBank.getPath()}/createTest` }
	];
	return (
		<>
			{isTestCreationLoading ? (
				<CreateTestLoader />
			) : (
				<Wrapper heading="Create New Test" breadcrumb={breadcrumb}>
					{isLoading ? (
						<ContentLoader />
					) : !isLoading && userCustomTestTypes.length === 0 ? null : (
						<NewTextConfigContainer>
							<HeaderWrapper>
								<HeaderTitle>New Test Configuration</HeaderTitle>
							</HeaderWrapper>
							<InputsWrapper>
								<NewTextFirstRow display="flex" alignItems="center" mt={3}>
									<Box width={120} ml={6} mr={4}>
										<Typography align="right">Test Name</Typography>
									</Box>
									<CustomInput
										width={600}
										theme={theme}
										options={{
											...defaultCustomInputOptions,
											inputData: createNewTestFormInputs.name
										}}
									/>
								</NewTextFirstRow>
								<NewTextFirstRow display="flex" alignItems="center" mt={3}>
									<Box width={120} ml={6} mr={4}>
										<Typography align="right">Test Mode</Typography>
									</Box>
									<Box className={`${classes.mobStyle} ${isPoolCountLoading ? classes.disabled : ""}`} display="flex">
										<CustomInput
											theme={theme}
											onChange={e => {
												const typeId = +e.target.value;
												dispatch(
													populateCustomTestForm({
														typeId,
														courseId,
														isTrial
													})
												);
											}}
											options={{
												...defaultCustomInputOptions,
												inputData: createNewTestFormInputs.typeId
											}}
										/>
									</Box>
									{isCATAvailable && !isTrial && (
										<div className={classes.getMoreCATTests} onClick={() => setIsCatModalVisibe(true)}>
											Get more CAT tests
										</div>
									)}
								</NewTextFirstRow>
								<NewTextFirstRow display="flex" alignItems="center" mt={3}>
									<Box width={120} ml={6} mr={4}>
										<Typography align="right">Question Pool</Typography>
									</Box>
									<CheckBoxesGroup className={isCatOnly || isPoolCountLoading ? classes.disabled : ""} display="flex">
										<CheckAllCheckBox
											theme={theme}
											mr={2}
											onChange={e => {
												handleAllPoolOptionChange(e);
											}}
											options={{
												...defaultCustomInputOptions,
												inputData: createNewTestFormInputs.allQuestions
											}}
										/>
										<CustomInput
											theme={theme}
											onChange={e => {
												handlePoolOptionChange(e);
											}}
											options={{
												...defaultCustomInputOptions,
												inputData: createNewTestFormInputs.unansweredQuestions
											}}
										/>
										<CustomInput
											theme={theme}
											onChange={e => {
												handlePoolOptionChange(e);
											}}
											options={{
												...defaultCustomInputOptions,
												inputData: createNewTestFormInputs.correctlyAnsweredQuestions
											}}
										/>
										<CustomInput
											theme={theme}
											onChange={e => {
												handlePoolOptionChange(e);
											}}
											options={{
												...defaultCustomInputOptions,
												inputData: createNewTestFormInputs.incorrectlyAnsweredQuestions
											}}
										/>
										<CustomInput
											theme={theme}
											onChange={e => {
												handlePoolOptionChange(e);
											}}
											options={{
												...defaultCustomInputOptions,
												inputData: createNewTestFormInputs.markedQuestions
											}}
										/>
										<CustomInput
											theme={theme}
											onChange={e => {
												handlePoolOptionChange(e);
											}}
											options={{
												...defaultCustomInputOptions,
												inputData: createNewTestFormInputs.caseStudyQuestions
											}}
										/>
									</CheckBoxesGroup>
								</NewTextFirstRow>
								<NewTextFirstRow display="flex" alignItems="center" mt={3}>
									<Box width={120} ml={6} mr={4}>
										<Typography align="right">Difficulty Level</Typography>
									</Box>
									<Box
										className={`${classes.mobStyle} ${isCatOnly || isPoolCountLoading ? classes.disabled : ""}`}
										display="flex"
									>
										<CustomInput
											width={600}
											theme={theme}
											onChange={() => {
												if (courseId) {
													dispatch(
														fetchQuestionPoolCount({
															courseId,
															isTrial
														})
													);
												}
											}}
											options={{
												...defaultCustomInputOptions,
												inputData: createNewTestFormInputs.difficultyLevelTierId
											}}
										/>
									</Box>
								</NewTextFirstRow>

								<NewTextFirstRow display="flex" alignItems="flex-start" mt={3}>
									<Box width={120} ml={6} mr={4} mt={"6px"}>
										<Typography align="right">Subjects</Typography>
									</Box>
									<Box
										display="flex"
										className={isCatOnly || isPoolCountLoading || isPoolCountLoading ? classes.disabled : ""}
										flexDirection="column"
										ml={"-11px"}
										flex={1}
									>
										<Box ml={"11px"}>
											<CustomInput
												theme={theme}
												onChange={e => {
													if (e.target.checked && subjectIds.selectOptions) {
														const allSubjectIds = subjectIds.selectOptions.reduce((acc, option) => {
															if (!option.disabled) {
																acc.push(option.value as number);
															}
															return acc;
														}, [] as number[]);
														dispatch(
															setStateValue({
																key: "createNewTestForm.inputs.subjectIds.value",
																value: allSubjectIds
															})
														);
														dispatch(
															setStateValue({
																key: "createNewTestForm.rawData.subjectIds",
																value: allSubjectIds
															})
														);
													} else if (!e.target.checked) {
														dispatch(
															setStateValue({
																key: "createNewTestForm.inputs.subjectIds.value",
																value: []
															})
														);
														dispatch(
															setStateValue({
																key: "createNewTestForm.rawData.subjectIds",
																value: []
															})
														);
													}
													if (courseId) {
														dispatch(
															fetchQuestionPoolCount({
																courseId,
																isTrial
															})
														);
													}
												}}
												options={{
													...defaultCustomInputOptions,
													inputData: createNewTestFormInputs.allSubjects
												}}
											/>
										</Box>
										<Box className={classes.container}>
											<CustomInput
												theme={theme}
												containerClassName={"test-container"}
												onChange={e => {
													if (!e.target.checked) {
														dispatch(
															setStateValue({
																key: "createNewTestForm.inputs.allSubjects.value",
																value: false
															})
														);
														dispatch(
															setStateValue({
																key: "createNewTestForm.rawData.allSubjects",
																value: false
															})
														);
													}
													// delay fetching count to give a chance for CustomInput to dispatch the new selected value in Redux
													setTimeout(() => {
														if (courseId) {
															dispatch(
																fetchQuestionPoolCount({
																	courseId,
																	isTrial
																})
															);
														}
													}, 500);
												}}
												options={{
													...defaultCustomInputOptions,
													inputData: createNewTestFormInputs.subjectIds
												}}
											/>
										</Box>
									</Box>
								</NewTextFirstRow>

								{subjectLessonItems.length > 0 && (
									<NewTextFirstRow display="flex" alignItems="flex-start" mt={3}>
										<Box width={120} ml={6} mr={4} mt={3.5}>
											<Typography align="right">Lessons</Typography>
										</Box>
										<Box
											display="flex"
											className={isCatOnly || isPoolCountLoading ? classes.disabled : ""}
											flexDirection="column"
											gridGap={"8px"}
											width="calc(100% - 200px)"
											pr={"48px"}
										>
											{subjectLessonItems}
										</Box>
									</NewTextFirstRow>
								)}
								<NewTextFirstRow display="flex" alignItems="center" mt={3}>
									<Box ml={"32px"} mr={"29px"}>
										<Typography align="right">Question Quantity</Typography>
									</Box>
									<Box display="flex" className={isCatOnly || isPoolCountLoading ? classes.disabled : ""}>
										<CustomInput
											width={145}
											theme={theme}
											mr={2}
											InputProps={{
												inputProps: {
													style: { padding: "10px 16px", paddingRight: 0 }
												}
											}}
											options={{
												...defaultCustomInputOptions,
												inputData: createNewTestFormInputs.questionsQuantity
											}}
										/>
										<Box alignItems="center" margin={0.5}>
											<Typography variant="subtitle1" align="left">
												out of {totalQuestionsCount}
											</Typography>
										</Box>
									</Box>
								</NewTextFirstRow>
								<NewTextFirstRow display="flex" alignItems="center" mt={3}>
									<Box width={120} ml={6} mr={4}>
										<Typography align="right">Test Time</Typography>
									</Box>
									<Box
										className={`${classes.mobStyle} ${isCatOnly || isPoolCountLoading ? classes.disabled : ""} ${
											classes.paddingTop
										}`}
									>
										<CustomInput
											theme={theme}
											options={{
												...defaultCustomInputOptions,
												inputData: createNewTestFormInputs.isTimed
											}}
										/>
									</Box>
								</NewTextFirstRow>
							</InputsWrapper>
							<Box className={classes.footer}>
								<Button
									variant={"contained"}
									className={classes.cancelBtn}
									size="medium"
									onClick={() => {
										history.push(routes.questionBank.getPath());
									}}
								>
									Cancel
								</Button>
								<Button
									variant={"contained"}
									color={"primary"}
									size="medium"
									disabled={!valid}
									onClick={() => {
										handleStart(courseId);
									}}
									className={classes.primaryActionBtn}
								>
									Start Test
								</Button>
							</Box>
						</NewTextConfigContainer>
					)}
				</Wrapper>
			)}
			<Modal
				className={classes.catBuyConfirmation}
				disableEnforceFocus
				disableAutoFocus
				open={isCatModalVisible}
				onClose={() => setIsCatModalVisibe(false)}
			>
				<div className={classes.catBuyConfirmationContent}>
					<div className={classes.catBuyText}>
						<img src={CatTest} alt="" />
						<div className={classes.catBuyHeader}>Buy Additional Self-Assessment CAT Tests</div>
						<div className={classes.catBuyDesc}>
							You have taken all available Self-Assessment CAT tests for this subscription. You need to buy additional
							Self-Assessment CAT tests to take it. Do you want to buy additional Self-Assessment CAT tests?
						</div>
					</div>
					<CatBuyButtons>
						<Button className={classes.cancelBtn} size="medium" onClick={() => setIsCatModalVisibe(false)}>
							No, Cancel
						</Button>
						<Button
							size="medium"
							onClick={() => {
								setIsCatModalVisibe(false);
								setIsCatPaymentModalVisible(true);
							}}
							className={classes.primaryActionBtn}
						>
							Buy CAT Test
						</Button>
					</CatBuyButtons>
				</div>
			</Modal>
			{isCatPaymentModalVisible && (
				<Modal
					className={classes.catBuyConfirmation}
					disableEnforceFocus
					disableAutoFocus
					open={isCatPaymentModalVisible}
					onClose={handleClose}
				>
					<CATTestOrderPayment courseId={courseId} onClose={handleClose} />
				</Modal>
			)}
		</>
	);
};

export default CreateTest;

import React, { FC, RefObject, useEffect, useRef, useState } from "react";

import {
	Box,
	Button,
	Checkbox,
	FormControlLabel,
	Grid,
	MenuItem,
	SvgIcon,
	Theme,
	Typography,
	useTheme
} from "@material-ui/core";

import { IValidation } from "@remar/shared/dist/components/PasswordMatchIndicator";
import { TextField } from "@remar/shared/dist/components/TextField";
import { Country } from "@remar/shared/dist/models";
import {
	charactersREGX,
	digitSpecialCharacterREGEX,
	lowerCaseREGX,
	passwordREGEX,
	upperCaseREGX
} from "@remar/shared/dist/utils/serviceUtils";
import { validZipCodeCharacter, validatePhoneNumber } from "@remar/shared/dist/utils/serviceUtils/validators";
import { CardElement } from "@stripe/react-stripe-js";
import { Field, FieldInputProps, FieldMetaProps, FormikProps, FormikTouched } from "formik";
import { TextField as MuiTextField } from "formik-material-ui";
import isEmpty from "lodash/isEmpty";
import { useDispatch, useSelector } from "react-redux";

import { RootState } from "store";

import { setSelectedCountry } from "store/features/Auth/authSlice";

import PasswordMatchBox from "./PasswordMatchBox";

import {
	CardName,
	ManageText,
	PaymentExpiredText,
	PaymentWrapper,
	StyledVisibilityOffOutlined,
	StyledVisibilityOutlined,
	useStyles
} from "./styles";
import { getCardIcon } from "./utils";

const CountrySelect = ({
	inputRef,
	setCountry,
	...props
}: {
	field: FieldInputProps<unknown>;
	form: FormikProps<unknown>;
	meta: FieldMetaProps<unknown>;
	[fieldName: string]: unknown;
	setCountry?: React.Dispatch<React.SetStateAction<Country | undefined>>;
}) => {
	const dispatch = useDispatch();
	const { countries, isLoading } = useSelector((state: RootState) => state.auth);
	return (
		<MuiTextField
			InputProps={{ disableUnderline: true, ref: inputRef as RefObject<unknown> }}
			variant="filled"
			select
			onChange={e => {
				const value = +e.target.value;
				dispatch(setSelectedCountry(value));
				setCountry && setCountry(countries.find(country => country.id === value) as Country | undefined);
				props.field.onChange(e);
			}}
			{...props}
			label={props.field.value ? "" : "Country"}
		>
			{isLoading || countries.length === 0 ? (
				<MenuItem key={""} value={""} disabled>
					{isLoading ? "Loading..." : "Countries"}
				</MenuItem>
			) : null}
			{countries.map(country => (
				<MenuItem key={country.code} value={country.id}>
					{country.name}
				</MenuItem>
			))}
		</MuiTextField>
	);
};

export const AgreeToTermsAndConditions = ({ classes, termValue, setFieldValue }) => (
	<Grid item xs={12}>
		<FormControlLabel
			control={
				<Checkbox
					checked={termValue}
					onChange={() => setFieldValue("terms", !termValue)}
					color="primary"
					name="terms"
				/>
			}
			label={
				<span className={classes.termsAndConditionsCheck}>
					I agree to the
					<span
						className={classes.termsLink}
						onClick={e => {
							e.preventDefault();
							window.open("https://study.remarnurse.com/vit/terms-conditions/", "_blank");
						}}
					>
						Terms & Conditions
					</span>
				</span>
			}
		/>
	</Grid>
);

interface AccountFormDetailsProps {
	title?: string;
	password?: boolean;
	showPasswordOption?: boolean;
	confirmPassword?: boolean;
	termValue?: boolean;
	showTermCheckBox?: boolean;
	disabledField?: boolean;
	setFieldValue?: (name, val) => void;
}

const passwordBoxInit: IValidation = {
	characters: false,
	lowerCase: false,
	upperCase: false,
	numberSpecialCharacter: false
};
export const AccountFormDetails: FC<AccountFormDetailsProps> = ({
	title = "Account Details",
	termValue,
	setFieldValue,
	showTermCheckBox = false,
	disabledField = false,
	password = true,
	showPasswordOption = false,
	confirmPassword
}) => {
	const classes = useStyles();
	const ref = useRef<HTMLDivElement>(null);
	const [showPassword, setShowPassword] = useState<boolean>(false);
	const [showPasswordErrorBox, setShowPasswordErrorBox] = useState<boolean>(false);
	const [passwordErrorBox, setPasswordErrorBox] = useState<IValidation>(passwordBoxInit);
	// we might need this in future
	// useEffect(() => {
	// 	const elem = ref.current;
	// 	if (elem) {
	// 		 const i = elem.querySelector("input");
	// 		 i?.focus();
	// 	}
	// }, [ref?.current]);

	return (
		<Box>
			<Typography variant="h1" className={classes.title}>
				{title}
			</Typography>

			<Box mt={3}>
				<Box>
					<Grid container spacing={3}>
						<Grid item xs={12} md={6}>
							<Field
								inputRef={ref}
								component={TextField}
								label="First Name"
								type="text"
								name="firstName"
								placeholder="First Name"
								fullWidth
							/>
						</Grid>
						<Grid item xs={12} md={6}>
							<Field label="Last Name" component={TextField} name="lastName" placeholder="Last Name" fullWidth />
						</Grid>
						<Grid item xs={12}>
							<Field
								label="Email"
								component={TextField}
								name="email"
								type="email"
								placeholder="Email"
								fullWidth
								disabled={disabledField}
								inputProps={{ className: disabledField ? classes.disabledInput : "" }}
							/>
						</Grid>
						{password && (
							<Grid item xs={12} style={{ position: "relative" }}>
								<Field
									label="Password"
									component={TextField}
									name="password"
									type={showPassword ? "text" : "password"}
									placeholder="Password"
									fullWidth
									InputProps={
										showPasswordOption
											? {
													endAdornment: (
														<SvgIcon
															fontSize="small"
															cursor="pointer"
															onClick={() => setShowPassword(prevState => !prevState)}
														>
															{showPassword ? <StyledVisibilityOutlined /> : <StyledVisibilityOffOutlined />}
														</SvgIcon>
													),
													disableUnderline: true
											  }
											: { disableUnderline: true }
									}
									validate={value => {
										if (value && !passwordREGEX.test(value)) {
											!showPasswordErrorBox && setShowPasswordErrorBox(true);
											setPasswordErrorBox({
												characters: charactersREGX.test(value),
												lowerCase: lowerCaseREGX.test(value),
												upperCase: upperCaseREGX.test(value),
												numberSpecialCharacter: digitSpecialCharacterREGEX.test(value)
											});
										} else {
											showPasswordErrorBox && setShowPasswordErrorBox(false);
										}
									}}
								/>
								{showPasswordErrorBox && <PasswordMatchBox passwordErrorBox={passwordErrorBox} />}
							</Grid>
						)}
						{confirmPassword && (
							<Grid item xs={12}>
								<Field
									label="Confirm Password"
									component={TextField}
									name="confirmPassword"
									type="password"
									placeholder="Confirm Password"
									fullWidth
								/>
							</Grid>
						)}

						{showTermCheckBox && (
							<AgreeToTermsAndConditions classes={classes} termValue={termValue} setFieldValue={setFieldValue} />
						)}
					</Grid>
				</Box>
			</Box>
		</Box>
	);
};

export const ShippingForm = ({ setCountry }) => {
	const classes = useStyles();

	const ref = useRef<HTMLDivElement>(null);
	useEffect(() => {
		const elem = ref.current;
		if (elem) {
			const i = elem.querySelector("input");
			i?.focus();
		}
	}, [ref?.current]);

	return (
		<Box>
			<Typography variant="h1" className={classes.title}>
				Shipping Details
			</Typography>

			<Box mt={3}>
				<Box>
					<Grid container spacing={3}>
						<Grid item xs={12}>
							<Field
								setCountry={setCountry}
								inputRef={ref}
								hiddenLabel
								component={CountrySelect}
								name="countryId"
								fullWidth
							/>
						</Grid>
						<Grid item xs={12}>
							<Field hiddenLabel component={TextField} name="address1" placeholder="Address Line 1" fullWidth />
						</Grid>
						<Grid item xs={12}>
							<Field hiddenLabel component={TextField} name="address2" placeholder="Apt #" fullWidth />
						</Grid>
						<Grid item xs={12} md={5}>
							<Field hiddenLabel component={TextField} name="city" placeholder="City" fullWidth />
						</Grid>
						<Grid item xs={6} md={3} className={classes.stateInput}>
							<Field hiddenLabel component={TextField} name="state" placeholder="State" fullWidth />
						</Grid>
						<Grid item xs={6} md={4}>
							<Field
								hiddenLabel
								component={TextField}
								name="zip"
								inputProps={{ maxLength: 10 }}
								placeholder="Zip code"
								onKeyPress={(event: React.KeyboardEvent) => {
									if (!(event.key.length === 1 && validZipCodeCharacter(event.key))) {
										event.preventDefault();
									}
								}}
								fullWidth
							/>
						</Grid>
						<Grid item xs={12}>
							<Field
								hiddenLabel
								component={TextField}
								name="phoneNumber"
								onKeyPress={(event: React.KeyboardEvent) => {
									if (!validatePhoneNumber(event.key)) {
										event.preventDefault();
									}
								}}
								placeholder="Phone Number"
								fullWidth
							/>
						</Grid>
					</Grid>
				</Box>
			</Box>
		</Box>
	);
};

export const GuestShippingForm = () => {
	const classes = useStyles();
	const ref = useRef<HTMLDivElement>(null);

	useEffect(() => {
		const elem = ref.current;
		if (elem) {
			const i = elem.querySelector("input");
			i?.focus();
		}
	}, [ref?.current]);

	return (
		<Box>
			<Typography variant="h1" className={classes.title}>
				{"Shipping Details"}
			</Typography>

			<Box mt={2}>
				<Box>
					<Grid container spacing={1}>
						<Grid item xs={12} md={6}>
							<Field
								inputRef={ref}
								component={TextField}
								label="First Name"
								type="text"
								name="firstName"
								placeholder="First Name"
								fullWidth
							/>
						</Grid>
						<Grid item xs={12} md={6}>
							<Field
								component={TextField}
								label="Last Name"
								type="text"
								name="lastName"
								placeholder="Last Name"
								fullWidth
							/>
						</Grid>
						<Grid item xs={12}>
							<Field label="Email" component={TextField} name="email" type="email" placeholder="Email" fullWidth />
						</Grid>
						<Grid item xs={12}>
							<Field hiddenLabel component={CountrySelect} name="countryId" fullWidth />
						</Grid>

						<Grid item xs={12}>
							<Field hiddenLabel component={TextField} name="address1" placeholder="Address Line 1" fullWidth />
						</Grid>
						<Grid item xs={12}>
							<Field hiddenLabel component={TextField} name="address2" placeholder="Apt #" fullWidth />
						</Grid>
						<Grid item xs={12} md={5}>
							<Field hiddenLabel component={TextField} name="city" placeholder="City" fullWidth />
						</Grid>
						<Grid item xs={6} md={3}>
							<Field hiddenLabel component={TextField} name="state" placeholder="State" fullWidth />
						</Grid>
						<Grid item xs={6} md={4}>
							<Field
								hiddenLabel
								component={TextField}
								name="zipCode"
								inputProps={{ maxLength: 10 }}
								placeholder="Zip code"
								onKeyPress={(event: React.KeyboardEvent) => {
									if (!(event.key.length === 1 && validZipCodeCharacter(event.key))) {
										event.preventDefault();
									}
								}}
								fullWidth
							/>
						</Grid>
						<Grid item xs={12}>
							<Field
								hiddenLabel
								component={TextField}
								name="phoneNumber"
								onKeyPress={(event: React.KeyboardEvent) => {
									if (!validatePhoneNumber(event.key)) {
										event.preventDefault();
									}
								}}
								placeholder="Phone Number"
								fullWidth
							/>
						</Grid>
					</Grid>
				</Box>
			</Box>
		</Box>
	);
};

interface PaymentFormProps {
	paymentSource?: {
		brand: string;
		last4: string;
		exp_month: number;
		exp_year: number;
	};
	setFieldValue?: (name, val) => void;
	touched?: FormikTouched<Record<string, boolean>>;
	setTouched?: (name) => void;
	termValue?: boolean;
	showTerms?: boolean;
}

export const PaymentForm: FC<PaymentFormProps> = ({
	paymentSource,
	setFieldValue,
	termValue,
	touched,
	setTouched,
	showTerms = false
}) => {
	const classes = useStyles();
	const theme = useTheme<Theme>();
	const ref = useRef<HTMLDivElement>(null);

	const [newCard, setNewCard] = useState(isEmpty(paymentSource));
	useEffect(() => {
		const elem = ref.current;
		if (elem) {
			const i = elem.querySelector("input");
			i?.focus();
			if (setTouched) {
				setTouched({ ...touched, ["nameOnCard"]: true });
			}
		}
	}, [ref?.current]);

	return (
		<Box>
			<Typography variant="h1" className={classes.title}>
				Payment
			</Typography>

			<Box mt={3}>
				<Box>
					<Grid container spacing={3}>
						{newCard && (
							<>
								<Grid item xs={12}>
									<Box mb={-2} mt={-1}>
										<Typography className={classes.formSubtitle}>Cardholder’s Name</Typography>
									</Box>
								</Grid>
								<Grid item xs={12}>
									<Field
										inputRef={ref}
										hiddenLabel
										component={TextField}
										name="nameOnCard"
										placeholder="Full Name"
										fullWidth
									/>
								</Grid>
							</>
						)}

						<Grid item xs={12}>
							<Box mb={-2} mt={-1}>
								<Typography className={classes.formSubtitle}>Billing Details</Typography>
							</Box>
						</Grid>
						{newCard && (
							<PaymentWrapper item xs={12}>
								<Box py={2} borderRadius={5}>
									<CardElement
										options={{
											style: {
												base: {
													// backgroundColor: "#2a2e37",
													fontSize: `${theme.typography.pxToRem(18)}`,
													color: "#c2c2c2",
													"::placeholder": {
														color: "#aab7c4"
													}
												},
												invalid: {
													color: "#9e2146"
												}
											}
										}}
										onChange={event => {
											if (setFieldValue) {
												const { complete } = event;
												setFieldValue("validCardDetails", complete);
											}
										}}
									/>
								</Box>
							</PaymentWrapper>
						)}
						{paymentSource && !isEmpty(paymentSource!) && !newCard && (
							<Grid item xs={12}>
								<Box bgcolor="#2a2e37" mt={2} p={2} borderRadius="4px">
									<Box display="flex" mb={2} alignItems="center">
										{getCardIcon(paymentSource.brand)}
										<Box ml={2}>
											<Box display="flex" flexWrap="wrap">
												<CardName>{paymentSource.brand}</CardName>
												<CardName>{`.... ${paymentSource.last4}`}</CardName>
											</Box>
											<PaymentExpiredText>{`Expiration date: ${paymentSource.exp_month}/${paymentSource.exp_year}`}</PaymentExpiredText>
										</Box>
									</Box>
									<Button onClick={() => setNewCard(true)}>
										<ManageText> Change Card </ManageText>
									</Button>
								</Box>
							</Grid>
						)}
						{showTerms && (
							<AgreeToTermsAndConditions classes={classes} termValue={termValue} setFieldValue={setFieldValue} />
						)}
					</Grid>
				</Box>
			</Box>
		</Box>
	);
};

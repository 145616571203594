/* eslint-disable react/no-unknown-property */
import React, { useCallback, useEffect, useRef, useState } from "react";

import { Box, Button, Typography, alpha, createStyles, makeStyles, useMediaQuery, useTheme } from "@material-ui/core";
import AccessTimeRounded from "@material-ui/icons/AccessTimeRounded";
import PlayIcon from "@material-ui/icons/PlayArrow";
import VolumeOffIcon from "@material-ui/icons/VolumeOff";
import VolumeUpIcon from "@material-ui/icons/VolumeUp";
import { videoOptions } from "@remar/shared/dist/config";
import { IExtendedTheme } from "@remar/shared/dist/theme/default";
import { useHistory } from "react-router-dom";
import styled from "styled-components";

import videojs from "video.js";

import "video.js/dist/video-js.min.css";

import { routes } from "core/constants";

export const useStyles = makeStyles((theme: IExtendedTheme) =>
	createStyles({
		topContainer: {
			marginLeft: -64,
			position: "relative",
			[theme.breakpoints.down("sm")]: {
				marginLeft: 0
			}
		},
		animationContainer: {},
		totalCourseTime: {
			fontFamily: "var(--openSans)",
			fontSize: `"${theme.typography.pxToRem(13)}"`,
			lineHeight: 1.23,
			letterSpacing: "normal",
			color: theme.palette.text.primary
		},
		timeBox: {
			display: "none",
			[theme.breakpoints.down("sm")]: {
				display: "flex",
				alignItems: "center",
				gap: "7px",
				margin: "10px 0"
			}
		},
		backdropBox: {
			height: "65vh",
			width: "100%",
			pointerEvents: "none",
			overflow: "hidden",
			display: "flex",
			flexWrap: "nowrap",
			alignItems: "center"
		},
		backdropOverflow: {
			background: `radial-gradient(circle, rgb(255 255 255 / 0%) 35%, ${theme.palette.background.default} 60%)`,
			height: "250%",
			width: "140%",
			marginLeft: -64,
			position: "absolute",
			left: 0,
			opacity: "0.85",
			bottom: 0,
			transition: "transform 300ms"
		},
		backdropOverflow2: {
			background: "radial-gradient(circle at -10% 40%, #13161e 30%, rgba(255,255,255,0) 55%)",
			height: "100%",
			width: "100%",
			position: "absolute",
			left: 0,
			top: 0
		},
		courseInfo: {
			transition: "all 0.3s",
			transformOrigin: "bottom left",
			"& .course-details": {
				transition: "all 0.3s",
				overflow: "hidden",
				maxHeight: 60
			}
		},
		nextLessonTrailer: {
			padding: "0 !important",
			width: "100% !important",
			height: "auto !important",
			[theme.breakpoints.down("sm")]: {
				display: "none"
			},
			"& > video": {
				display: "block",
				width: "100%"
			}
		},
		courseTitle: {
			fontFamily: "var(--poppins)",
			fontSize: theme.typography.pxToRem(26),
			lineHeight: 1.23,
			color: theme.palette.text.primary,
			whiteSpace: "nowrap",
			overflow: "hidden",
			textOverflow: "ellipsis"
		},
		courseSubtitle: {
			fontSize: theme.typography.pxToRem(18),
			lineHeight: 1.11,
			color: theme.palette.text.primary,
			fontWeight: 600,
			whiteSpace: "nowrap",
			overflow: "hidden",
			textOverflow: "ellipsis"
		},
		playPauseButton: {
			background: theme.palette.primary.main
		},
		courseDescription: {
			font: "normal 15px/20px var(--openSans)",
			color: alpha(theme.palette.text.primary, 0.6)
		},
		mute: {
			color: theme.palette.text.primary,
			background: "rgba(0,0,0, .24)",
			border: `solid 1px ${theme.palette.text.primary}}`,
			marginLeft: "16px"
		}
	})
);

const StyledCourseInfoContainer = styled(Box)`
	padding-top: 168px;
	max-width: 45%;
	${props => props.theme.breakpoints.down("sm")} {
		padding-top: 70px;
		padding-left: 8px;
		width: 100%;
		max-width: 100%;
	}
`;

const VideoActionButtons = styled(Box)`
	z-index: 1;
	position: relative;
	margin-top: 40px;
	${props => props.theme.breakpoints.down("sm")} {
		> button:first-child {
			width: calc(100% - 100px);
		}
	}
`;

const StyledPlayIcon = styled(PlayIcon)`
	path {
		fill: ${props => props.theme.palette.text.primary};
	}
`;
const CoursePreviewBackground = ({
	totalTimeLengthInMinutes,
	title,
	subtitle,
	description,
	trailer,
	lessonId,
	sectionId
}) => {
	const classes = useStyles();
	const theme = useTheme();
	const isDesktop = useMediaQuery(theme.breakpoints.up("md"));
	const videoRef = useRef<HTMLVideoElement>(null);
	const playing = useRef(false);
	const history = useHistory();

	const [muted, setMuted] = useState(true);

	const scrollListener = useCallback(() => {
		if (!videoRef.current) return;
		const player = videojs(videoRef.current);
		// here we really comparing vertical scroll position with some value depended of width.
		// It is caus of video has aspect ratio based on it width linked to body.clientWidth (47vh check styles upper)
		const scrollBorder = document.body.clientWidth * 0.235;

		if (window.scrollY > scrollBorder && !player.paused() && playing.current) {
			player.pause();
		} else if (window.scrollY <= scrollBorder && player.paused() && playing.current) {
			player.play();
		}
	}, []);

	const handleMute = () => {
		const player = videojs(videoRef.current);

		player.muted(!muted);
		setMuted(!muted);
	};

	useEffect(() => {
		if (trailer) {
			const videoPlayer = videojs(videoRef.current, {
				...videoOptions,
				muted,
				loop: true,
				autoplay: false,
				controls: false
			});
			videoPlayer.src([
				{
					src: trailer.videoUrl,
					type: "application/x-mpegURL",
					withCredentials: true
				}
			]);
		}
	}, [trailer]);

	useEffect(() => {
		if (videoRef.current && isDesktop) {
			const player = videojs(videoRef.current);
			player.play();

			window.addEventListener("scroll", scrollListener);
			playing.current = true;
			return () => {
				window.removeEventListener("scroll", scrollListener);
			};
		}
	}, [isDesktop, videoRef.current]);

	return (
		<Box className={classes.topContainer}>
			<Box className={classes.animationContainer}>
				<Box className={classes.backdropBox}>
					<div data-vjs-player>{trailer && <video ref={videoRef} className={classes.nextLessonTrailer}></video>}</div>
					<Box className={classes.backdropOverflow} />
					{/* <Box className={classes.backdropOverflow2} /> */}
				</Box>
			</Box>
			<StyledCourseInfoContainer pl={8} position="absolute" top="0" left="0">
				<Box className={classes.courseInfo}>
					<Box mb={1}>
						<Typography className={classes.courseSubtitle}>{subtitle}</Typography>
					</Box>
					<Typography variant="h1" className={classes.courseTitle}>
						{title}
					</Typography>
					<Box className={classes.timeBox} display={"flex"} alignItems="center">
						<AccessTimeRounded />
						<span className={classes.totalCourseTime}>{Math.round(totalTimeLengthInMinutes)} min.</span>
					</Box>

					<Box className="course-details">
						<Typography className={classes.courseDescription}>{description}</Typography>
					</Box>
					{trailer && (
						<VideoActionButtons>
							<Button
								variant="contained"
								size="large"
								className={classes.playPauseButton}
								onClick={() => {
									history.push(`${routes.lesson.getPath()}/${lessonId}/${sectionId}`);
								}}
							>
								{
									<>
										<StyledPlayIcon />
										<Typography>Play</Typography>
									</>
								}
							</Button>
							{isDesktop && (
								<Button size="large" onClick={handleMute} className={classes.mute}>
									{!muted ? <VolumeUpIcon /> : <VolumeOffIcon />}
								</Button>
							)}
						</VideoActionButtons>
					)}
				</Box>
			</StyledCourseInfoContainer>
		</Box>
	);
};

export default CoursePreviewBackground;

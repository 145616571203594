import React, { useEffect, useMemo, useState } from "react";

import { Box, Button, Card, Typography } from "@material-ui/core";

import Stepper from "@remar/shared/dist/components/Stepper";
import { ExternalIntegrationIds } from "@remar/shared/dist/constants";
import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js";
import { Form, Formik } from "formik";

import useAnalyticsEventTracker from "hooks/googleAnalytics";

import { useDispatch, useSelector } from "react-redux";

import { useParams } from "react-router-dom";

import { RootState, useAppSelector } from "store";
import {
	changeSubscriptionType,
	facebookSignUp,
	fetchCountries,
	getCourses,
	googleSignUp,
	setError,
	setSubscriptionTypesForSignup
} from "store/features/Auth/authSlice";
import { UserSignUpVerificationDto } from "store/services";

import SignUpLayout from "../SignUpLayout";
import FormActions from "../components/FormActions";
import { PaymentForm, ShippingForm } from "../components/Forms";
import { GuestSignUpError } from "../components/GuestSignUpError";
import SignUpSuccessLeft from "../components/SignUpSuccessLeft";
import SignUpSuccessRight from "../components/SignUpSuccessRight";
import Summary from "../components/Summary";
import { PaymentSchema, ShippingSchema } from "../components/schemas";
import { useStyles } from "../components/styles";
import { getValidValue } from "../components/utils";

const initialFormValues = {
	firstName: sessionStorage.getItem("firstName"),
	lastName: sessionStorage.getItem("lastName"),
	email: sessionStorage.getItem("email"),
	tokenId: sessionStorage.getItem("tokenId"),
	countryId: "",
	address1: "",
	address2: "",
	city: "",
	state: "",
	zip: "",
	phoneNumber: "",
	terms: false
};
const steps = [{ label: "Shipping" }, { label: "Payment" }];
const SocialSignUp = () => {
	const classes = useStyles();
	const dispatch = useDispatch();
	const elements = useElements();
	const stripe = useStripe();
	const { errorMessage, courses, guestSignUpData, selectedShippingPlan, shippingApplicable } = useSelector(
		(state: RootState) => state.auth
	);
	const { paymentProviderAccount } = guestSignUpData as UserSignUpVerificationDto;
	const { paymentSource } = paymentProviderAccount || {};
	const { subscriptionTypes, userSubscriptionTypeId, userSubscriptionTypeAddonIds, isLoading }: RootState["auth"] =
		useAppSelector((store: RootState) => store.auth);
	const [activeStep, setActiveStep] = useState(0);
	const [startDate, setStartDate] = useState(new Date());
	const [signupSuccessUserData, setSignupSuccessUserData] = useState({});
	const [, setFreeTrial] = useState(false);
	const { courseId } = useParams<{ courseId?: string }>();
	const activeSubscription = subscriptionTypes[0];
	const analytics = useAnalyticsEventTracker("User");

	useEffect(() => {
		if (activeSubscription) {
			dispatch(changeSubscriptionType(activeSubscription?.id as number));
			dispatch(fetchCountries(activeSubscription?.id as number));
			dispatch(getCourses());
		}
	}, [activeSubscription, dispatch]);
	useEffect(() => {
		dispatch(
			setSubscriptionTypesForSignup({
				courseId: courseId as unknown as number,
				isTrial: false
			})
		);
	}, [courseId, dispatch]);

	const validationSchema = useMemo(() => {
		if (activeStep === 0) return ShippingSchema;
		if (activeStep === 1) return PaymentSchema;
	}, [activeStep]);

	const setSignUpSuccessUser = response => {
		const mainSubTypeEIDItems = activeSubscription?.subTypeEIDItems!.find(
			i => i.integrationId == ExternalIntegrationIds.Stripe && i.parentId === null
		);
		const selectedCourse = subscriptionTypes.find(type => type.id === userSubscriptionTypeId)?.allowedCourses?.[0];
		const price = mainSubTypeEIDItems?.data["price"] || 0;
		const addOnsSub = activeSubscription?.subTypeEIDItems!.filter(
			item => item.parentId !== null && item.integrationId === ExternalIntegrationIds.Stripe
		);

		const _addOnsSub = addOnsSub
			.filter(x => userSubscriptionTypeAddonIds?.includes(x.id))
			.map(y => {
				return {
					name: y.data.name,
					price: (y.data["price"] || 0).toLocaleString("en-US", {
						currency: "USD",
						style: "currency"
					})
				};
			});

		setSignupSuccessUserData({
			email: response["payload"]["email"],
			fullName: `${response["payload"]["firstName"]} ${response["payload"]["lastName"]}`,
			courseName: selectedCourse?.name,
			price: price.toLocaleString("en-US", { currency: "USD", style: "currency" }),
			shippingApplicable,
			shipping: (selectedShippingPlan?.data["price"] || 0).toLocaleString("en-US", {
				currency: "USD",
				style: "currency"
			}),
			...(_addOnsSub.length && {
				addOnsSub: _addOnsSub
			})
		});
		sessionStorage.removeItem("firstName");
		sessionStorage.removeItem("lastName");
		sessionStorage.removeItem("email");
		sessionStorage.removeItem("tokenId");
		sessionStorage.removeItem("platform");
	};

	const handleSubmit = (values: typeof initialFormValues) => {
		if (sessionStorage.getItem("platform") === "facebook") {
			dispatch(
				facebookSignUp({
					CardElement,
					elements,
					stripe,
					values: { startDate, ...values }
				})
				// eslint-disable-next-line @typescript-eslint/ban-ts-comment
			) // @ts-ignore
				.then(r => {
					if (r.error) {
						dispatch(setError(r.error.message));
						setActiveStep(2);
					} else {
						analytics({
							eventName: `sign_up_${sessionStorage.getItem("platform")}_finished`
						});
						setSignUpSuccessUser(r);
						setActiveStep(3);
					}
				});
		} else {
			dispatch(
				googleSignUp({
					CardElement,
					elements,
					stripe,
					values: { startDate, ...values }
				})
				// eslint-disable-next-line @typescript-eslint/ban-ts-comment
			) // @ts-ignore
				.then(r => {
					if (r.error) {
						dispatch(setError(r.error.message));
						setActiveStep(2);
					} else {
						analytics({
							eventName: `sign_up_${sessionStorage.getItem("platform")}_finished`
						});
						setSignUpSuccessUser(r);
						setActiveStep(3);
					}
				});
		}
	};

	const handleBack = () => {
		setActiveStep(prevActiveStep => (prevActiveStep >= 1 ? prevActiveStep - 1 : 0));
	};

	const handleNext = (values: typeof initialFormValues, isTrial?: boolean) => {
		if (isTrial) return handleSubmit(values);
		if (activeStep === 0) {
			analytics({ eventName: `sign_up_${sessionStorage.getItem("platform")}_start` });
			return setActiveStep(1);
		}
		handleSubmit(values);
	};

	return (
		<SignUpLayout>
			{activeStep !== 3 && (
				<Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" mb={5} mt={5}>
					<Box className={classes.cardsContainer}>
						<Card className={classes.card}>
							<Box>
								<Box px={9} pt={2} className={classes.stepperForm}>
									<Stepper activeStep={activeStep} steps={steps} />

									<Formik
										initialValues={{
											...initialFormValues
										}}
										validationSchema={validationSchema}
										validateOnChange
										onSubmit={values => {
											handleSubmit(values);
										}}
									>
										{({ isValid, values, touched }) => {
											const neverTouched = Object.keys(touched).length === 0;
											let valid;
											if (activeStep === 0) {
												valid = getValidValue(Object.keys(touched)) ? isValid : false;
											} else {
												valid = isValid && !neverTouched;
											}
											return (
												<>
													<Form>
														{activeStep === 0 && <ShippingForm />}
														{activeStep === 1 && <PaymentForm paymentSource={paymentSource} />}
														{activeStep === 2 && <GuestSignUpError errorMessage={errorMessage} />}
													</Form>
													{errorMessage && (
														<Box mt={3}>
															<Typography variant="caption" style={{ color: "red" }}>
																{errorMessage}
															</Typography>
														</Box>
													)}

													{activeStep !== 2 ? (
														<FormActions
															back={() => handleBack()}
															next={isTrial => handleNext(values, isTrial)}
															valid={valid}
															disabled={!activeSubscription}
															loading={isLoading}
															step={activeStep}
															lastStep={1}
														/>
													) : activeStep === 2 ? (
														<Box display="flex" justifyContent="center" mt={6}>
															<Button
																color="primary"
																variant="contained"
																onClick={() => {
																	dispatch(setError(""));
																	setActiveStep(1);
																}}
															>
																{"Try Again"}
															</Button>
														</Box>
													) : null}
												</>
											);
										}}
									</Formik>
								</Box>
							</Box>
						</Card>
						<Summary
							setFreeTrial={setFreeTrial}
							courses={courses}
							startDate={startDate}
							setStartDate={setStartDate}
							showCrossButton={false}
						/>
					</Box>
				</Box>
			)}
			{activeStep === 3 && (
				<Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" height="100vh">
					<Box className={classes.successCardsContainer}>
						<Card className={classes.cardLeft}>
							<SignUpSuccessLeft signupSuccessUserData={signupSuccessUserData} trial={false} />
						</Card>
						<Card className={classes.cardRight}>
							<SignUpSuccessRight />
						</Card>
					</Box>
				</Box>
			)}
		</SignUpLayout>
	);
};

export default SocialSignUp;

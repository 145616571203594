import React, { useCallback, useEffect, useMemo, useState } from "react";

import { Button, Checkbox, CircularProgress } from "@material-ui/core";

import { useDispatch, useSelector } from "react-redux";

import { useHistory } from "react-router-dom";
import { getFullState as getFullBillingState } from "store/features/Billing/BillingSlice";

import {
	buyExtraCatTests,
	fetchCATPaymentInfo,
	getFullState,
	setStateValue
} from "store/features/QuestionBank/CreateTest/createTest.slice";

import GirlWithLaptop from "assets/images/guest-create-acc.svg";
import { routes } from "core/constants";

import PaymentMethods from "modules/Billings/PaymentMethods";

import { getCallbackUrl } from "modules/utils";

import {
	CATPaymentAmount,
	CATPaymentCardColumn,
	CATPaymentContent,
	CATPaymentOrderDetails,
	CATPaymentOrderHeader,
	CATPaymentOrderSeparator,
	CATPaymentOrderTotal,
	CATPaymentPrice,
	CatBuyButtons,
	CatPaymentAgreementRow,
	CatPaymentOrderSummary,
	ContinueButton,
	InvisibleButton,
	PaymentContainer,
	PaymentStatusDescription,
	PaymentStatusErrorIcon,
	PaymentStatusHeader,
	PaymentStatusImage,
	PaymentStatusSuccessIcon,
	PlusMinus,
	TryAgain,
	YourTotal
} from "../styles";

export const CATTestOrderPayment = ({ courseId, onClose }) => {
	const dispatch = useDispatch();
	const [isCardModalVisible, setIsCardModalVisible] = useState(false);

	const [isAgreed, setIsAgreed] = useState(false);

	const { catPaymentInfo, catPaymentResult } = useSelector(getFullState);
	const { paymentMethods } = useSelector(getFullBillingState);

	useEffect(() => {
		return () => {
			dispatch(
				setStateValue({
					key: "catPaymentResult",
					value: undefined
				})
			);
		};
	}, [dispatch]);

	useEffect(() => {
		if (!isCardModalVisible) {
			dispatch(fetchCATPaymentInfo({ courseId }));
		}
	}, [courseId, dispatch, isCardModalVisible]);

	const [isLoading, setIsLoading] = useState(false);

	const [quantity, setQuantity] = useState(1);

	const history = useHistory();
	const callBackUrl = useMemo(() => getCallbackUrl(history.location.search), [history.location.search]);
	const sideEffect = useCallback(() => {
		if (callBackUrl) {
			window.location.replace(callBackUrl);
		}
	}, [callBackUrl]);
	const handlePayment = () => {
		setIsLoading(true);

		dispatch(
			buyExtraCatTests({
				paymentProviderPaymentMethodIdentifier: paymentMethods?.id ?? "",
				quantity,
				catPaymentId: catPaymentInfo?.catPaymentId ?? 0,
				sideEffect
			})
		);
	};

	const handleTakeTest = () => {
		onClose();

		dispatch(
			setStateValue({
				key: "catPaymentResult",
				value: undefined
			})
		);
	};

	return (
		<CATPaymentContent>
			{catPaymentResult?.amount ?? 0 > 0 ? (
				<>
					<CATPaymentCardColumn>
						<PaymentStatusSuccessIcon />
						<PaymentStatusHeader>Payment Successful</PaymentStatusHeader>
						<PaymentStatusDescription>Congratulations on your purchase!</PaymentStatusDescription>
						<PaymentStatusDescription bold>
							{catPaymentInfo?.name}: {quantity} x ${catPaymentInfo?.price}
						</PaymentStatusDescription>
					</CATPaymentCardColumn>
					<CatPaymentOrderSummary>
						<PaymentStatusImage src={GirlWithLaptop} alt="" />
						<PaymentStatusDescription bold>
							You have {quantity} additional CAT tests. <br />
							Test your knowledge now!
						</PaymentStatusDescription>
						<Button variant={"contained"} color={"primary"} size="medium" onClick={handleTakeTest}>
							Take CAT Test
						</Button>
						<InvisibleButton
							variant={"contained"}
							color={"primary"}
							size="medium"
							onClick={() => history.push(routes.questionBank.getPath())}
						>
							Go to Homepage
						</InvisibleButton>
					</CatPaymentOrderSummary>
				</>
			) : (
				<PaymentContainer>
					<CATPaymentCardColumn>
						{catPaymentResult === undefined ? (
							<>
								<PaymentMethods
									onEdit={() => setIsCardModalVisible(true)}
									modal={isCardModalVisible}
									setModal={setIsCardModalVisible}
								/>
								<CatPaymentAgreementRow>
									<Checkbox checked={isAgreed} onChange={e => setIsAgreed(e.target.checked)} /> I agree to the Terms &
									Conditions
								</CatPaymentAgreementRow>
								<CatBuyButtons>
									<ContinueButton
										variant={"contained"}
										color={"primary"}
										size="medium"
										disabled={!isAgreed || isLoading}
										onClick={handlePayment}
										startIcon={isLoading && <CircularProgress size={20} />}
									>
										Complete Purchase
									</ContinueButton>
								</CatBuyButtons>
							</>
						) : (
							<>
								<PaymentStatusErrorIcon />
								<PaymentStatusHeader>Something Went Wrong</PaymentStatusHeader>
								<PaymentStatusDescription>
									We are not able to process your payment.
									<br />
									Please try again.
								</PaymentStatusDescription>
								<TryAgain
									size="medium"
									onClick={() => {
										setIsLoading(false);
										dispatch(
											setStateValue({
												key: "catPaymentResult",
												value: undefined
											})
										);
									}}
								>
									Try again
								</TryAgain>
							</>
						)}
					</CATPaymentCardColumn>
					<CatPaymentOrderSummary>
						<CATPaymentOrderHeader>Order Summary</CATPaymentOrderHeader>
						{catPaymentInfo?.name}
						<CATPaymentOrderDetails>
							<CATPaymentPrice>${catPaymentInfo?.price}</CATPaymentPrice>
							<PlusMinus size="tiny" onClick={() => !isLoading && setQuantity(Math.max(1, quantity - 1))}>
								-
							</PlusMinus>
							<CATPaymentAmount>{quantity}</CATPaymentAmount>
							<PlusMinus size="tiny" onClick={() => !isLoading && setQuantity(quantity + 1)}>
								+
							</PlusMinus>
						</CATPaymentOrderDetails>
						<CATPaymentOrderSeparator />
						<YourTotal>Your total</YourTotal>
						<CATPaymentOrderTotal>
							$ {quantity && catPaymentInfo?.price ? Math.round(quantity * catPaymentInfo?.price * 100) / 100 : 0}
						</CATPaymentOrderTotal>
					</CatPaymentOrderSummary>
				</PaymentContainer>
			)}
		</CATPaymentContent>
	);
};

import { Accordion, AccordionSummary, Box, Button, Grid, RadioGroup, Select, Typography } from "@material-ui/core";
import { alpha, createStyles, lighten, makeStyles } from "@material-ui/core/styles";

import CheckCircleOutline from "@material-ui/icons/CheckCircleOutline";
import ErrorOutline from "@material-ui/icons/ErrorOutline";
import { CustomInput } from "@remar/shared/dist/components/CustomInput/CustomInput";
import { Link as RouterLink } from "react-router-dom";
import styled, { css } from "styled-components";

import { ReactComponent as CheckMarkSvg } from "assets/icons/icon-checkmark.svg";
import { ReactComponent as CrossSvg } from "assets/icons/icon-cross.svg";
import { ReactComponent as HalfFilledCircleSvg } from "assets/icons/icon-half-filled-circle.svg";

import { ReactComponent as MarkIconFilledSvg } from "assets/icons/icon-marked-multicolor-filled.svg";
import { ReactComponent as MarkIconSvg } from "assets/icons/icon-marked-multicolor.svg";
import { ReactComponent as TimeSvg } from "assets/icons/icon-time.svg";

export const useStyles = makeStyles(theme =>
	createStyles({
		getMoreCATTests: {
			fontSize: `"${theme.typography.pxToRem(12)}"`,
			fontWeight: "bold",
			lineHeight: 1.33,
			color: "#4a99f9",
			marginTop: "2px",
			alignSelf: "center",
			cursor: "pointer",
			marginLeft: "4px",
			[theme.breakpoints.down("sm")]: {
				paddingLeft: "48px !important",
				paddingTop: "10px !important"
			}
		},
		container: {
			"&  .test-container > div, .lesson-accordion > div ": {
				display: "flex",
				flexDirection: "row",
				[theme.breakpoints.down("sm")]: {
					flexDirection: "column"
				},
				flexWrap: "wrap",
				width: "100%"
			},
			"& .test-container > div > label": {
				width: "50%",
				[theme.breakpoints.down("sm")]: {
					width: "100%"
				},
				margin: 0
			},
			"& .lesson-accordion > div > label": {
				flex: "1 0 21%",
				margin: 0,
				wordBreak: "normal"
			}
		},
		catBuyConfirmation: {
			display: "flex",
			alignItems: "center",
			justifyContent: "center"
		},
		catBuyConfirmationContent: {
			width: "555px",
			backgroundColor: theme.palette.background.paper,
			borderRadius: "4px",
			[theme.breakpoints.down("sm")]: {
				width: "450px"
			}
		},
		catBuyText: {
			display: "flex",
			flexDirection: "column",
			alignItems: "center",
			justifyContent: "center",
			borderBottom: "solid 1px #2a2e37",
			"& > img": {
				width: "118px",
				margin: "32px 0 44px"
			},
			[theme.breakpoints.down("sm")]: {
				padding: "0 24px"
			}
		},
		catBuyHeader: {
			opacity: 0.87,
			fontFamily: "var(--poppins)",
			fontSize: `"${theme.typography.pxToRem(18)}"`,
			fontWeight: "bold",
			lineHeight: 1.33,
			color: theme.palette.text.primary
		},
		catBuyDesc: {
			fontSize: `${theme.typography.pxToRem(13)}`,
			lineHeight: 1.54,
			width: "400px",
			margin: "12px 50px 40px",
			color: alpha(theme.palette.text.primary, 0.6),
			[theme.breakpoints.down("sm")]: {
				width: "100%",
				margin: "12px 0"
			}
		},
		disabled: {
			opacity: 0.5,
			pointerEvents: "none"
		},
		paddingTop: {
			paddingTop: "15px"
		},
		mobStyle: {
			[theme.breakpoints.down("sm")]: {
				"& div[role=radiogroup]": {
					flexDirection: "column"
				}
			}
		},
		cancelBtn: {
			borderRadius: "4px !important",
			borderColor: "#94979f !important",
			backgroundColor: "transparent !important",
			color: alpha(theme.palette.text.primary, 0.6) + " !important",
			borderWidth: "1px !important",
			borderStyle: "solid !important"
		},
		primaryActionBtn: {
			marginLeft: "15px",
			[theme.breakpoints.down("sm")]: {
				marginBottom: "15px",
				marginLeft: "0px"
			}
		},
		footer: {
			paddingTop: "25px",
			display: "flex",
			alignItems: "center",
			justifyContent: "flex-end",
			marginBottom: "18px",
			[theme.breakpoints.down("sm")]: {
				flexDirection: "column-reverse",
				"& > button": {
					width: "100%"
				}
			}
		}
	})
);

export const QuestionInfoContainer = styled.div`
	display: flex;
	justify-content: space-between;
	gap: 24px;
	margin-bottom: 24px;
	${props => props.theme.breakpoints.down("sm")} {
		justify-content: start;
		flex-direction: column;
	}
`;

export const NewTextConfigContainer = styled(Box)`
	margin-top: 16px;
	margin-right: 32px;
	${props => props.theme.breakpoints.down("sm")} {
		margin-right: 0;
	}
`;

export const NewTextFirstRow = styled(Box)`
	${props => props.theme.breakpoints.down("sm")} {
		flex-direction: column;
		align-items: flex-start;
		gap: 10px;
		margin-top: 35px;
		> div {
			margin: 0;
			padding: 0 16px;
			width: 100%;
			> p {
				text-align: start;
			}
		}
	}
`;

export const CheckBoxesGroup = styled(Box)`
	${props => props.theme.breakpoints.down("sm")} {
		flex-direction: column;
		> div:first-child {
			width: 100px;
		}
	}
`;

export const CheckAllCheckBox = styled(CustomInput)`
	border-right: 1px solid #586179;
	${props => props.theme.breakpoints.down("sm")} {
		border-right: none;
		border-bottom: 1px solid #586179;
	}
`;

export const ContinueButton = styled(Button)`
	&[disabled] {
		color: #fff !important;
	}
`;
export const InvisibleButton = styled(Button)`
	background: none !important;
	border: none !important;
	margin: 8px 0 64px;

	${props => props.theme.breakpoints.down("sm")} {
		margin: 8px 0 0 0;
	}
`;

export const Container = styled.div`
	min-height: 100vh;
	width: 100%;
	padding-right: 24px;

	${props => props.theme.breakpoints.down("sm")} {
		padding-right: 16px;
	}

	.breadcrum-container {
		margin-top: 6px;

		div {
			font-size: ${props => props.theme.typography.pxToRem(13)};
			line-height: 1.23;
			color: rgba(255, 255, 255, 0.6);
		}

		div:last-child {
			color: #ffffff;
		}
	}
`;

export const HeaderContainer = styled.div`
	margin-bottom: 24px;
	display: flex;
	margin-top: 24px;
	align-items: center;
	justify-content: space-between;
`;

export const AnswersChart = styled.div`
	background-color: ${({ theme }) => theme.palette.background.paper};
	border-radius: 4px;
	padding-bottom: 20px;
	display: flex;
	align-items: center;
	justify-content: space-around;

	${props => props.theme.breakpoints.down("sm")} {
		flex-direction: column;
	}
`;

export const AnswersProgress = styled.div`
	background-color: ${({ theme }) => theme.palette.background.paper};
	border-radius: 4px;
	display: flex;
	width: 100%;
	flex-direction: row;
	margin: 24px 0;
	padding: 24px 30px 25px 20px;
	gap: 16px;
	${props => props.theme.breakpoints.down("sm")} {
		flex-direction: column;
	}
`;

export const ScoreInfo = styled.div`
	border-radius: 4px;
	display: flex;
	flex-direction: column;
	width: 100%;
`;

export const ScoreDetailInfo = styled.div`
	font-family: var(--openSans);
	font-size: ${props => props.theme.typography.pxToRem(14)};
	font-weight: 400;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.6;
	letter-spacing: normal;
	color: ${({ theme }) => alpha(theme.palette.text.primary, 0.87)};
`;

export const PerformanceContainer = styled.div`
	margin-top: 10px;
	display: flex;
	flex-direction: row;
	align-items: center;
	gap: 10px;
`;

export const CardSubInfo = styled.div`
	font-family: var(--openSans);
	font-size: ${props => props.theme.typography.pxToRem(15)};
	font-weight: 400;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.6;
	letter-spacing: normal;
	color: #1998d5;
`;

export const ScoreTitle = styled.div`
	font-family: var(--poppins);
	font-size: ${props => props.theme.typography.pxToRem(16)};
	font-weight: bold;
	line-height: 1.33;
	letter-spacing: normal;
	color: ${({ theme }) => alpha(theme.palette.text.primary, 0.87)};
`;

export const ScoreTitleContainer = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	gap: 16px;
	margin-bottom: 7px;
`;

export const ScoreLevel = styled.div`
	font-family: var(--poppins);
	font-size: ${props => props.theme.typography.pxToRem(18)};
	font-weight: bold;
	line-height: 1.33;
	letter-spacing: normal;
	color: ${({ color }) => color};
`;

export const GraphLabel = styled.div`
	margin-bottom: 30px;
	opacity: 0.87;
	font-family: var(--openSans);
	font-size: ${props => props.theme.typography.pxToRem(15)};
	font-weight: 600;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.6;
	letter-spacing: normal;
	color: ${({ theme }) => theme.palette.text.primary};
	justify-content: center;
	display: flex;
`;

export const Header = styled(Typography)`
	font-size: ${props => props.theme.typography.pxToRem(18)};
	font-weight: bold;
	line-height: 1.33;
	color: #ffffff;
`;

export const HeaderTitle = styled(Typography)`
	font-size: ${props => props.theme.typography.pxToRem(15)};
	font-weight: bold;
	line-height: 1.33;
	color: ${({ theme }) => theme.palette.text.primary};
`;

export const HeaderWrapper = styled(Box)`
	display: flex;
	justify-content: space-between;
	align-items: center;
	background-color: ${({ theme }) => theme.palette.background.paper};
	padding: 20px;
	border-radius: 4px 4px 0 0;
`;

export const InputsWrapper = styled(Box)`
	display: flex;
	flex-direction: column;
	padding-bottom: 40px;
	background-color: ${({ theme }) => theme.palette.background.paper};
	border-radius: 0 0 4px 4px;
`;

export const Link = styled(RouterLink)`
	font-family: var(--openSans);
	font-size: ${props => props.theme.typography.pxToRem(14)};
	line-height: 1.23;
	color: #fff !important;
	opacity: 0.38;
`;

export const LessonsAccordionContainer = styled.div`
	width: 100%;
`;

export const LessonsAccordion = styled(Accordion)`
	width: 100%;
	background-color: ${({ theme }) => theme.palette.background.paper};
	color: ${({ theme }) => theme.palette.text.primary};
	margin-bottom: 8px !important;
	border-radius: 6px;
	padding: 10px;
`;

export const StyledAccordionSummary = styled(AccordionSummary)`
	border: none;
	> div:first-child {
		width: calc(100% - 32px);
	}
	${props => props.theme.breakpoints.down("sm")} {
		align-items: flex-start;
	}
`;

export const LessonTextContainer = styled(Box)`
	display: flex;
	flex-direction: row;
	width: 100%;
	align-items: center;
	justify-content: space-between;
	border: none;

	> div {
		display: flex;
		min-width: 130px;

		${props => props.theme.breakpoints.down("sm")} {
			margin-top: 5px;
		}
	}

	${props => props.theme.breakpoints.down("sm")} {
		flex-direction: column;
		align-items: flex-start;
	}
`;

export const TimeIcon = styled(TimeSvg)`
	margin-right: 10px;
	width: 24px;
	height: 24px;
`;

export const WrongAnswersIcon = styled(CrossSvg)`
	margin-right: 10px;
	width: 24px;
	height: 24px;
`;

export const CorrectAnswersFilledIcon = styled(CheckMarkSvg)`
	margin-right: 10px;
	width: 24px;
	height: 24px;
`;

export const PartiallyCorrectAnswersIcon = styled(HalfFilledCircleSvg)`
	margin-right: 10px;
	width: 24px;
	height: 24px;
`;

export const StyledDropdown = styled(Select)`
	color: #fff;
	width: ${({ inTable }) => (inTable ? "auto" : "220px")};
	line-height: 1.66;
	background: white;
	margin: 10px;

	${props => props.theme.breakpoints.down("sm")} {
		margin: 10px 0;
	}

	border-radius: 4px;
	font-size: ${props => props.theme.typography.pxToRem(15)};

	> div {
		background: white;
		border-radius: 4px;
		color: grey;
		font-weight: 500;
		margin-left: 6px;
	}

	> svg {
		color: grey;
	}
`;

export const TestQuizQuestionContainer = styled.div`
	display: flex;
	flex-flow: row wrap;
	width: 100%;
	background: #eceff4;
	${props => props.theme.breakpoints.down("sm")} {
		flex-direction: column;
	}
`;

export const TestQuizQuestionContent = styled.div<{ noPadding: boolean }>`
	flex-grow: 1;
	padding: 16px 16px 0 16px;
	color: #4d5058;
	display: flex;
	flex-flow: column;
	font-weight: 600;
	font-size: 0.85rem;
	line-height: 1.6;
	background: #eceff4;
	min-height: 400px;
	${props => props.theme.breakpoints.down("sm")} {
		padding: ${({ showTutoredResult }) => (showTutoredResult ? "10px 0px" : "16px 24px 0 24px")};
		${({ noPadding }) =>
			noPadding &&
			css`
				padding: 0;
			`}
	}
`;

export const TestQuizButtonsRow = styled.div`
	text-align: right;
	padding: 72px 16px 16px;
	background: #eceff4;
	width: 100%;
	display: flex;
	${props => props.theme.breakpoints.down("sm")} {
		padding: 30px 24px 16px;
		flex-direction: column-reverse;
		gap: 8px;
		> button {
			margin: 0;
		}
		> div:first-child {
			width: 100%;
			> button {
				margin-top: 14px;
				width: 100%;
				padding: 10px 20px;
			}
		}
	}
`;

export const TestQuizQuestionTitle = styled.h1<{ pl: boolean }>`
	padding: 14px 14px 0 ${props => (props.pl ? "14px" : 0)};
	margin: 0;
	flex: 0;
	font-size: ${props => props.theme.typography.pxToRem(15)};
	font-weight: 600;
	line-height: 1.6;
	color: #4d5058;

	${props => props.theme.breakpoints.down("sm")} {
		padding-left: ${({ showTutoredResult }) => (showTutoredResult ? "14px 14px 0px 14px" : "0")};
	}
`;

export const TestQuizQuestionText = styled.div`
	line-height: 1.85;
	font-size: ${props => props.theme.typography.pxToRem(15)};
	margin: 0 8px 0 0;
	cursor: default;
	font-family: var(--openSans);

	> p {
		margin: 0 0 8px;
		font-weight: 600;
		font-family: var(--openSans);
	}
`;

export const BowTieDropZone = styled.div`
	width: calc((100% - 50px) / 3);

	${props => props.theme.breakpoints.down("sm")} {
		width: 100%;
	}
`;

export const BowTieGroupDropZoneContainer = styled(Box)`
	flex-direction: row;
	${props => props.theme.breakpoints.down("sm")} {
		flex-direction: column;
	}
`;

export const BowTieContainer = styled(Box)`
	width: ${props => (props.isBowTieResult ? "100%" : "70%; min-width: 800px;")};
	margin-left: ${props => (props.isBowTieResult ? 0 : "16px")};
	-webkit-user-select: none; /* Safari */
	-ms-user-select: none; /* IE 10 and IE 11 */
	user-select: none; /* Standard syntax */
	${props => props.theme.breakpoints.down("md")} {
		width: 100%;
	}
	${props => props.theme.breakpoints.down("sm")} {
		width: 100%;
		margin-left: 0;
		min-width: 0;
	}
`;

export const TestQuestionPreviousButton = styled(Button)`
	margin-right: 10px;
	background: #98a6d6;
	font-weight: bold;
	color: #4350f7;
`;

export const TestQuestionExitButton = styled(Button)`
	margin-left: 10px;
	background: #898f9e;
	font-weight: bold;
	color: #fff;
	justify-content: center;
	${props => props.theme.breakpoints.down("sm")} {
		margin-left: 0;
	}
`;

export const MultipleResponseLeftHeaderText = styled(Box)`
	width: 50%;
	${props => props.theme.breakpoints.down("sm")} {
		width: 35%;
	}
`;

export const MultipleResponseRightHeaderText = styled(Box)`
	width: 50%;
	${props => props.theme.breakpoints.down("sm")} {
		width: 65%;
	}
`;

export const MultipleResponseLeftBodyText = styled(Box)`
	width: 50%;
	padding-left: 22px;
	${props => props.theme.breakpoints.down("sm")} {
		width: 35%;
		padding: 0 8px;
	}
`;

export const MultipleResponseRightBodyText = styled(Box)`
	width: 50%;
	${props => props.theme.breakpoints.down("sm")} {
		width: 65%;
	}
`;

export const MatrixTableHeader = styled(Box)`
	min-height: 64px;
	border-radius: 3px 3px 0 0;
	background-color: ${props => props.theme.palette.colors.basic[100]};
`;

export const MatrixTableBody = styled(Box)`
	max-width: 595px;
	border-radius: 0 0 3px 3px;
	background-color: #${({ isinresults }) => (isinresults ? "f5f7f9" : "edeef1")};
	${({ isinresults }) =>
		!isinresults
			? "border-right: solid 1px rgba(151, 151, 151, 0.3);	border-left: solid 1px rgba(151, 151, 151, 0.3);"
			: ""}
`;

export const RadioWrapper = styled(RadioGroup)`
	flex-direction: row;
	width: 100%;
	height: 100%;
	display: flex;
`;

export const NoTestHistoryButton = styled(Button)`
	margin-top: 50px;
`;

export const NoTestHistoryText = styled(Typography)`
	margin-top: 50px;
`;

export const SubjectName = styled(Typography)`
	white-space: nowrap;
	overflow: hidden !important;
	text-overflow: ellipsis;
	width: 100%;
`;

export const CustomTestBackground = styled.div`
	padding: 20px 30px;
	background: ${({ theme }) => theme.palette.common.white};
	border-radius: 4px;

	${props => props.theme.breakpoints.down("sm")} {
		padding: 8px;
	}
`;
export const AnswerDropZoneGapContainer = styled.div`
	${props => props.theme.breakpoints.down("sm")} {
		background: ${({ $isOver }) => ($isOver ? "grey" : "rgb(219, 222, 228)")};
		border: ${({ $isOver }) => ($isOver ? "2px dashed black" : "none")};
	}
`;

export const Gap = styled(Box)`
	width: 370px;
	height: 60px;
	margin: 0 10px 0 15px;

	${props => props.theme.breakpoints.down("sm")} {
		width: 250px;
		margin: 0 5px 0 5px;
		background-color: ${({ $isOver }) => ($isOver ? "grey" : "#dbdee4")};
	}
	border-radius: 4px;
	background-color: #dbdee4;
	display: flex;
	align-items: center;
	justify-content: center;

	&.filled {
		padding: 4px;
	}
`;

export const StyledGap = styled(Box)`
	border-radius: 4px;
	background-color: #dbdee4;
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;
`;

export const TestQuestionStyledContainer = styled(Box)`
	margin-left: 16px;
	${props => props.theme.breakpoints.down("sm")} {
		margin-left: 0;
	}
`;

export const CSQuestionTitle = styled.h1`
	margin: 0;
	flex: 0;
	font-size: ${props => props.theme.typography.pxToRem(15)};
	font-weight: 600;
	line-height: 1.6;
	color: #4d5058;
`;

export const MatrixSingleChoiceGroupText = styled(Box)`
	display: flex;
	flex-direction: row;
	border-right: solid 1px rgba(151, 151, 151, 0.3);
	height: 100%;
	width: 50%;
	padding-left: 22px;
	align-items: center;
	${props => props.theme.breakpoints.down("sm")} {
		padding: 0 8px;
	}
`;

export const DragOptionContainer = styled(Box)<{ type: string }>`
	height: 48px;
	width: 100%;
	padding: 12px 8px 12px 12px;
	border-radius: 4px;
	background-color: #fff;
	display: flex;
	flex-direction: row;
	max-width: 360px;
	-webkit-user-select: none; /* Safari */
	-ms-user-select: none; /* IE 10 and IE 11 */
	user-select: none; /* Standard syntax */

	${props => props.theme.breakpoints.down("sm")} {
		width: 100%;
		max-width: 100%;

		${({ type }) =>
			type === "standalone"
				? css`
						width: 228px;
				  `
				: ""}
	}
`;

export const AnswerDropZoneContainer = styled.div`
	display: flex;
	height: 100%;
	align-items: center;
	width: 100%;
	${props => props.theme.breakpoints.down("sm")} {
		background: ${({ $isOver }) => ($isOver ? "grey" : "rgb(219, 222, 228)")};
		border: ${({ $isOver }) => ($isOver ? "2px dashed black" : "none")};
	}
`;

export const DragOptionReadOnlyContainer = styled(Box)`
	height: 40px;
	width: 100%;
	padding: 12px 8px 12px 12px;
	border-radius: 4px;
	background-color: #fff;
	display: flex;
	flex-direction: row;
	align-items: center;
`;

export const DragOptionText = styled.span`
	width: 100%;
	opacity: 0.87;
	font-family: var(--openSans);
	font-size: ${props => props.theme.typography.pxToRem(15)};
	font-weight: 600;
	line-height: 1.6;
	color: #4d5058;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	letter-spacing: normal;
`;

export const DragOptionContainerText = styled(Typography)`
	height: 24px;
	width: 100%;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	opacity: 0.87;
	font-family: var(--openSans);
	font-size: ${props => props.theme.typography.pxToRem(15)};
	font-weight: 600;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.6;
	letter-spacing: normal;
	color: #4d5058;
`;

export const GroupContainer = styled.span`
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	background: white;
	border-radius: 4px;
	padding: 8px 16px;
	padding-right: 0;
	width: fit-content;
`;

export const MarkIcon = styled(MarkIconSvg)`
	margin-right: 5px;
`;

export const MarkFilledIcon = styled(MarkIconFilledSvg)`
	margin-right: 5px;
`;

export const MultipleChoiceOptionContainer = styled(Box)`
	min-height: 48px;
	width: 100%;
	padding: 8px 8px 8px 8px;
	border-radius: 4px;
	background-color: #fff;
	display: flex;
	margin-bottom: 6px;
	flex-direction: row;
	align-items: center;
`;

export const TableLabel = styled.span`
	font-size: ${props => props.theme.typography.pxToRem(16)};
`;

export const SubjectTableHeader = styled(Box)`
	display: flex;
	border-top-left-radius: 4px;
	//background-color: #2a2e37;
	width: 100%;
	justify-content: center;
`;

export const LessonTableHeader = styled(SubjectTableHeader)``;

export const StatsTableBody = styled(Box)`
	display: flex;
	margin-bottom: 32px;
	border-radius: 0 0 4px 4px;
	background-color: ${({ theme }) => theme.palette.background.paper};

	${props => props.theme.breakpoints.down("sm")} {
		flex-direction: column;
	}
`;

export const StyledAnswersBox = styled.div`
	display: flex;
	justify-content: space-between;
	width: 100%;
	margin-bottom: 15px;

	&:hover {
		background: transparent;
	}

	${props => props.theme.breakpoints.down("sm")} {
		flex-direction: column;
		justify-content: start;
		margin-bottom: 0;
	}
`;

export const StyledPartialAnswersBox = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	${({ margin }) => margin && "margin-bottom: 32px;"}
	${props => props.theme.breakpoints.down("sm")} {
		justify-content: start;
	}
`;

export const StatsTableBodyContent = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	width: 100%;
	${({ isMobile, borderLeft }) => !isMobile && borderLeft && "border-left: solid 1px rgba(57, 62, 75, 1);"}
`;

export const StackedBarGraph = styled<HTMLDivElement & { hasbgcolor: boolean }>(Box)`
	display: flex;
	height: 10px;
	width: 100%;
	border-radius: 2px;
	background-color: ${({ hasbgcolor }) => (hasbgcolor ? "#b1b6c3" : null)};
	margin: 32px 0;
`;
export const CorrectAnswerBar = styled(Box)`
	height: 10px;
	width: ${props => ` ${props.width}%`};
	border-radius: 2px;
	background-color: #0f7940;
`;

export const PartiallyCorrectAnswerBar = styled(Box)`
	height: 10px;
	width: ${props => ` ${props.width}%`};
	background-color: #dbac31;
`;

export const IncorrectAnswerBar = styled(Box)`
	height: 10px;
	width: ${props => ` ${props.width}%`};
	border-radius: 2px;
	background-color: #d54b5b;
`;

export const AnswerLabel = styled.span`
	margin: 0 8px;
	opacity: 0.87;
	font-size: ${props => props.theme.typography.pxToRem(15)};
	font-weight: normal;
	line-height: 1.33;
	color: ${({ theme }) => theme.palette.text.primary};
`;

export const CATPaymentContent = styled.div`
	width: 748px;
	background-color: ${({ theme }) => theme.palette.background.paper};
	border-radius: 4px;
	display: flex;
	flex-flow: row nowrap;
	${props => props.theme.breakpoints.down("sm")} {
		display: flex;
		flex-direction: column;
	}
`;

export const AnswerNumber = styled.span`
	opacity: 0.87;
	font-size: ${props => props.theme.typography.pxToRem(18)};
	font-weight: bold;
	line-height: 1.1;
	color: ${({ theme }) => theme.palette.text.secondary};
`;

export const AnswerPercentage = styled.span`
	margin-left: 4px;
	opacity: 0.6;
	font-size: ${props => props.theme.typography.pxToRem(15)};
	font-weight: 600;
	color: ${({ theme }) => theme.palette.text.secondary};
`;
export const QuestionsRemaining = styled(Typography)`
	opacity: 0.87;
	font-size: ${props => props.theme.typography.pxToRem(15)};
	font-weight: 600;
	margin-left: 8px;
	font-family: var(--openSans);
	font-style: normal;
	line-height: 1.6;
	letter-spacing: normal;
	color: ${({ theme }) => theme.palette.text.primary};
`;

export const MotivationalQuoteContainer = styled(Box)`
	display: flex;
	margin-bottom: 32px;
	padding: 24px 24px 24px 48px;
	border-radius: 4px;
	background-color: ${({ theme }) => theme.palette.background.paper};
	justify-content: center;
`;

export const MotivationalQuoteTextContainer = styled.div`
	display: flex;
	align-items: center;
	margin-left: 56px;
`;

export const MotivationalQuoteText = styled(Typography)`
	opacity: 0.87;
	font-family: var(--poppins);
	font-size: ${props => props.theme.typography.pxToRem(18)};
	font-weight: 600;
	font-stretch: normal;
	font-style: italic;
	line-height: 1.33;
	letter-spacing: normal;
	color: ${({ theme }) => theme.palette.text.secondary};
`;

export const CreateTestBannerGridContainer = styled(Grid)`
	justify-content: end;
`;

export const CreateTestBannerContainer = styled(Box)`
	display: flex;
	margin-bottom: 32px;
	padding: 24px 48px 24px 40px;
	border-radius: 4px;
	background-color: ${({ theme }) => theme.palette.background.paper};
	justify-content: center;
	align-items: center;
`;

export const CreateTestBannerTextContainer = styled.div`
	display: flex;
	align-items: start;
	flex-direction: column;
	margin-left: 32px;
	margin-right: 40px;
`;

export const CreateTestBannerHeading = styled(Typography)`
	opacity: 0.87;
	font-family: Poppins, serif;
	font-size: ${({ theme }) => theme.typography.pxToRem(18)};
	line-height: 1.33;
	color: ${({ theme }) => theme.palette.text.primary};
	font-weight: 700;
	font-style: normal;
	letter-spacing: 0;
	text-align: left;
`;

export const CreateTestBannerText = styled(Typography)`
	opacity: 0.87;
	font-size: ${({ theme }) => theme.typography.pxToRem(13)};
	color: ${({ theme }) => theme.palette.colors.basic[1200]};
	font-weight: 400;
	font-style: normal;
	letter-spacing: 0;
	text-align: left;
	line-height: 16px;
	margin-top: 12px;
`;

export const CATPaymentCardColumn = styled.div`
	flex: 424px 0 0;
	display: flex;
	flex-direction: column;
	background-color: ${({ theme }) => theme.palette.background.paper};
	margin-top: 0;
	> div {
		box-shadow: none;
		background-color: transparent;
		padding-bottom: 15px;

		> .MuiTypography-root {
			font-size: ${props => props.theme.typography.pxToRem(26)};
			font-weight: bold;
			line-height: 1.23;
			margin-top: 10px;
		}
	}
	${props => props.theme.breakpoints.down("sm")} {
		flex: 300px 0 0;
		max-height: 380px;
	}
`;

export const PaymentStatusSuccessIcon = styled(CheckCircleOutline)`
	width: 59px;
	height: 59px;
	align-self: center;
	margin: 107px 0 50px 0;
	color: #30d14d;
	${props => props.theme.breakpoints.down("sm")} {
		margin: 24px 0 32px 0;
	}
`;
export const PaymentStatusErrorIcon = styled(ErrorOutline)`
	width: 59px;
	height: 59px;
	align-self: center;
	margin: 107px 0 50px 0;
	color: #ed5465;
	${props => props.theme.breakpoints.down("sm")} {
		margin: 24px 0 32px 0;
	}
`;

export const PaymentStatusHeader = styled.h1`
	opacity: 0.87;
	font-size: ${props => props.theme.typography.pxToRem(26)};
	font-weight: bold;
	line-height: 1.23;
	text-align: center;
	color: #fff;
	margin: 0;
`;

export const PaymentContainer = styled(Box)`
	display: flex;
	width: 100%;
	${props => props.theme.breakpoints.down("sm")} {
		flex-direction: column-reverse;
	}
`;

export const PaymentStatusDescription = styled.p`
	margin: 16px 0;
	opacity: 0.87;
	font-size: ${props => props.theme.typography.pxToRem(15)};
	line-height: 1.33;
	text-align: center;
	color: #fff;
	${({ bold }) => bold && "font-weight: 600"}
`;

export const CatPaymentOrderSummary = styled.div`
	flex: 1;
	background: ${({ theme }) => theme.palette.background.default};
	padding: 24px;
	display: flex;
	flex-flow: column;
`;

export const PaymentStatusImage = styled.img`
	width: 160px;
	align-self: center;
	margin: 72px 0 8px;

	${props => props.theme.breakpoints.down("sm")} {
		margin: 0 0 8px;
	}
`;

export const CatBuyButtons = styled.div`
	display: flex;
	flex-flow: row nowrap;
	align-items: end;
	justify-content: end;
	padding: 16px 24px;
	${props => props.theme.breakpoints.down("sm")} {
		flex-direction: column-reverse;
		padding: 16px 24px;
		> button {
			width: 100%;
		}
	}
`;

export const CatPaymentAgreementRow = styled.div`
	padding: 0 24px 0 24px !important;
`;

export const TryAgain = styled(Button)`
	margin: 24px;
	flex: 0;
	max-width: 120px;
	align-self: center;
	${props => props.theme.breakpoints.down("sm")} {
		margin-top: 0;
	}
`;

export const CATPaymentOrderHeader = styled.h1`
	font-size: ${props => props.theme.typography.pxToRem(22)};
	font-weight: bold;
	line-height: 1.45;
	margin: 48px 0 32px;
	${props => props.theme.breakpoints.down("sm")} {
		margin: 0 0 15px 0;
	}
`;

export const CATPaymentOrderDetails = styled.div`
	display: flex;
	flex-flow: row nowrap;
	margin-top: 12px;
	align-items: center;
`;

export const CATPaymentPrice = styled.div`
	flex: 1;
	font-size: ${props => props.theme.typography.pxToRem(18)};
	font-weight: bold;
	line-height: 1.33;
`;

export const PlusMinus = styled(Button)`
	min-width: 0 !important;
	background: ${props => props.theme.palette.common.black} !important;
	color: ${props => props.theme.palette.common.white} !important;
	display: flex;
	width: 24px;
	height: 24px;
	align-items: center;
	justify-content: center;

	&:hover {
		background: ${props => lighten(props.theme.palette.common.black, 0.5)} !important;
	}
`;

export const CATPaymentAmount = styled.div`
	padding: 0 12px;
`;

export const CATPaymentOrderSeparator = styled.div`
	flex: 1;
`;

export const YourTotal = styled.p`
	font-size: ${props => props.theme.typography.pxToRem(15)};
	font-weight: 600;
	line-height: 1.6;
	text-align: right;
	margin: 0;
	color: ${props => alpha(props.theme.palette.text.primary, 0.6)};
};`;

export const CATPaymentOrderTotal = styled.p`
	font-size: ${props => props.theme.typography.pxToRem(30)};
	font-weight: bold;
	line-height: 1.33;
	text-align: right;
	color: ${props => props.theme.palette.text.primary};
	margin: 0 0 24px 0;
`;

export const FooterContainer = styled(Box)`
	display: flex;
	justify-content: flex-end;
	align-items: center;
	padding-top: 25px;
`;

export const CancelButton = styled(Button)`
	font-size: ${props => props.theme.typography.pxToRem(16)};
	background-color: transparent;
	color: ${props => alpha(props.theme.palette.text.primary, 0.6)};
	border-color: ${props => props.theme.palette.text.primary}
	border-width: 1px;
	border-style: solid;
	margin-right: 10px;

	&:hover {
		background: transparent;
	}
`;

export const DeleteButton = styled(Button)`
	font-size: ${props => props.theme.typography.pxToRem(16)};
	background-color: #46191e;
	color: hsl(353, 81%, 63%);
	&:hover {
		background: #5d1f26;
	}
`;

export const StatsContainer = styled.div`
	padding: 24px;
	display: flex;
	width: 100%;
	flex-direction: column;
`;

export const AnswersInfoContainer = styled.div`
	display: flex;
	align-items: center;
	${props => props.theme.breakpoints.down("sm")} {
		margin-bottom: 32px;
	}
`;

export const TableLabelContainer = styled.div`
	min-height: 64px;
	display: flex;
	align-items: center;
`;

export const NoTestsContainer = styled(Box)`
	background: ${props => props.theme.palette.background.paper};
	display: flex;
	align-items: center;
	flex-direction: column;
	justify-content: center;
	height: 55vh;
	width: 100%;
	//margin-top: 24px;
	margin-bottom: 16px;
`;

export const AnswersContainer = styled.div`
	display: flex;
	flex-direction: column;
`;

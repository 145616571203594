import React from "react";

import {
	Box,
	Button,
	Card,
	CircularProgress,
	Container,
	Grid,
	IconButton,
	Theme,
	Typography,
	makeStyles,
	useMediaQuery,
	useTheme
} from "@material-ui/core";
import { CloseRounded } from "@material-ui/icons";
import { UserAuthTypes } from "@remar/shared/dist/constants";
import { validatePhoneNumber } from "@remar/shared/dist/utils/serviceUtils/validators";
import { Field, FieldInputProps, FieldMetaProps, Form, Formik, FormikProps } from "formik";
import { TextField as MuiTextField } from "formik-material-ui";
import { useSelector } from "react-redux";

import { selectIsTrial, selectUser } from "store/features/Auth/authSlice";
import { selectError, selectMyAccountDetailsLoading } from "store/features/MyAccount/myAccountSlice";

import { EditProfileSchema, TrialEditProfileSchema } from "./schemas";
import { ModalSubTitleText, ModalTitleText, StyledBox, StyledLink } from "./styles";

const useStyles = makeStyles(theme => ({
	title: {
		fontWeight: "bolder"
	},
	card: {
		width: "calc(50%)",
		[theme.breakpoints.down("xs")]: {
			width: "100%",
			padding: 16
		}
	},
	root: {
		flexGrow: 1
	},
	paper: {
		padding: theme.spacing(2),
		textAlign: "center",
		color: theme.palette.text.secondary
	}
}));

const TextField = ({
	...props
}: {
	field: FieldInputProps<unknown>;
	form: FormikProps<unknown>;
	meta: FieldMetaProps<unknown>;
	[fieldName: string]: unknown;
}) => <MuiTextField InputProps={{ disableUnderline: true }} variant="filled" {...props} />;

const EditProfile = ({ initialFormValues, onClose, handleSubmit, openPwdModal }) => {
	const theme = useTheme<Theme>();
	const classes = useStyles();
	const error = useSelector(selectError);
	const loading = useSelector(selectMyAccountDetailsLoading);
	const user = useSelector(selectUser);
	const isTrial = useSelector(selectIsTrial);
	const isMobile = useMediaQuery(theme.breakpoints.down("xs"));

	const validate = value => {
		let errorMessage;
		if (!value.trim()) {
			errorMessage = "Invalid value";
		}
		return errorMessage;
	};

	return (
		<Container maxWidth="lg">
			<Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" height="100vh">
				<Card className={classes.card}>
					<Formik
						initialValues={initialFormValues}
						validationSchema={isTrial ? TrialEditProfileSchema : EditProfileSchema}
						validateOnChange
						onSubmit={values => {
							handleSubmit(values);
						}}
					>
						{({ isValid, values }) => {
							return (
								<>
									<Box display="flex" justifyContent="space-between" mb={5}>
										<ModalTitleText>Edit Profile</ModalTitleText>
										<IconButton onClick={onClose}>
											<CloseRounded style={{ width: "20px", height: "20px", fill: "#c5cee0" }} />
										</IconButton>
									</Box>
									<StyledBox>
										<ModalSubTitleText>Account Details</ModalSubTitleText>
										<Form>
											<Box className={classes.root}>
												<Grid container spacing={3}>
													<Grid item xs={12} md={6}>
														<Field
															component={TextField}
															validate={validate}
															name="firstName"
															placeholder="First Name"
															fullWidth
														/>
													</Grid>
													<Grid item xs={12} md={6}>
														<Field
															component={TextField}
															validate={validate}
															name="lastName"
															placeholder="Last Name"
															fullWidth
														/>
													</Grid>
													<Grid item xs={12}>
														<Field
															component={TextField}
															name="phoneNumber"
															onKeyPress={(event: React.KeyboardEvent) => {
																if (!validatePhoneNumber(event.key)) {
																	event.preventDefault();
																}
															}}
															placeholder="Phone Number"
															fullWidth
														/>
													</Grid>
												</Grid>
											</Box>
										</Form>
										{error && (
											<Box mt={3}>
												<Typography color="error" variant="caption">
													{error}
												</Typography>
											</Box>
										)}

										{user!.userAuthTypeId === UserAuthTypes.Normal && (
											<Box mt={3}>
												<StyledLink
													to={"#"}
													onClick={() => {
														openPwdModal(true);
														onClose();
													}}
												>
													Change Password
												</StyledLink>
											</Box>
										)}

										<Box display="flex" justifyContent="flex-end" mt={4}>
											<Button
												color="primary"
												disabled={!isValid || loading}
												startIcon={loading && <CircularProgress size={20} />}
												variant="contained"
												fullWidth={isMobile}
												onClick={() => handleSubmit(values)}
											>
												Save
											</Button>
										</Box>
									</StyledBox>
								</>
							);
						}}
					</Formik>
				</Card>
			</Box>
		</Container>
	);
};

export default EditProfile;

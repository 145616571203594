import React, { useState } from "react";

import { Box, Button, CircularProgress, Theme, Typography, useTheme } from "@material-ui/core";

import { SimpleModal } from "@remar/shared/dist/components/SimpleModal";
import { Wrapper } from "@remar/shared/dist/layouts";
import classnames from "classnames";
import isEmpty from "lodash/isEmpty";

import { useDispatch } from "react-redux";
import { RootState, useAppSelector } from "store";

import { getUserData } from "store/features/Auth/authSlice";
import { cancelSubscription } from "store/features/MyAccount/myAccountSlice";

import { routes } from "core/constants";

import DeleteAccountConfirmation from "./DeleteAccountConfirmation";

import { BodyText, CardWrapper, CircularBox, ContentWrapper, RowWrapper, StyledButton, useStyles } from "./styles";

const breadcrumbs = [
	{ title: "My Account", key: 0, link: routes.myaccount.getPath() },
	{ title: "Manage Account", key: 1 }
];
const ManageAccount = () => {
	const theme = useTheme<Theme>();
	const classes = useStyles();
	const { subscriptionInfo, isLoading: myAccLoading } = useAppSelector((store: RootState) => store.myAccount);

	const [deleteModalOpen, setDeleteModalOpen] = useState(false);
	const [cancelModalOpen, setCancelModalOpen] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	const [showDeleteModal, setShowDeleteModal] = useState(false);
	const dispatch = useDispatch();
	const disabledCancelButton = subscriptionInfo?.subscription.isCancelled;
	const isTrial = subscriptionInfo?.subscription.type?.isTrial;

	const sideEffect = () => {
		dispatch(getUserData());
	};
	const onCancelModalClose = () => {
		if (!isLoading) {
			setCancelModalOpen(false);
		}
	};
	const onCancelConfirm = () => {
		setIsLoading(true);
		dispatch(
			cancelSubscription({
				id: subscriptionInfo!.subscription.id,
				sideEffect: () => {
					sideEffect();
					setCancelModalOpen(false);
					setIsLoading(false);
				}
			})
		);
	};
	const onClose = () => {
		if (!isLoading) {
			setDeleteModalOpen(false);
		}
	};

	const onConfirm = () => {
		setShowDeleteModal(true);
		setDeleteModalOpen(false);
		setIsLoading(false);
	};

	if (myAccLoading) {
		return (
			<CircularBox>
				<CircularProgress size="7rem" color="primary" thickness={5} variant="indeterminate" />
			</CircularBox>
		);
	}

	return (
		<Wrapper heading="Manage Account" breadcrumb={breadcrumbs}>
			<CardWrapper>
				{!isTrial && !isEmpty(subscriptionInfo?.subscription) && (
					<RowWrapper>
						<ContentWrapper>
							<Typography className={classes.infoText}>Cancel Subscription</Typography>
							<Typography className={classes.infoName}>
								Your subscription will be cancelled. If you cancel a paid subscription, you can keep using the
								subscription until the next billing date.
							</Typography>
						</ContentWrapper>
						<StyledButton size="medium" disabled={disabledCancelButton} onClick={() => setCancelModalOpen(true)}>
							Cancel Subscription
						</StyledButton>
					</RowWrapper>
				)}
				<RowWrapper>
					<ContentWrapper>
						<Typography className={classes.infoText}>Delete Account</Typography>
						<Typography className={classes.infoName}>
							When you delete your ReMar account, you won&#39;t be able to retrieve the content of your account. All
							your account data, subscription, and courses progress will also be deleted.
						</Typography>
					</ContentWrapper>
					<StyledButton size="medium" onClick={() => setDeleteModalOpen(true)}>
						Delete Account
					</StyledButton>
				</RowWrapper>
			</CardWrapper>
			<SimpleModal
				theme={theme}
				title="Delete Account"
				open={deleteModalOpen}
				onClose={onClose}
				text={""}
				extraContent={
					<BodyText>
						Are you sure you want to delete this account? When you delete your ReMar account, you wont be able to
						retrieve the content of your account. All your account data, subscription and courses progress will also be
						deleted.
					</BodyText>
				}
				footer={
					<>
						<Button
							className={classnames(classes.noCancel, classes.button)}
							size="medium"
							disabled={isLoading}
							onClick={onClose}
						>
							{isLoading ? (
								<Box width={50}>
									<CircularProgress size="1rem" color="inherit" thickness={2} variant="indeterminate" />
								</Box>
							) : (
								<>No, Cancel</>
							)}
						</Button>
						<Button
							className={classnames(classes.cancelBtn, classes.button)}
							disabled={isLoading}
							size="medium"
							onClick={() => onConfirm()}
						>
							{isLoading ? (
								<Box width={50}>
									<CircularProgress size="1rem" color="inherit" thickness={2} variant="indeterminate" />
								</Box>
							) : (
								<>Yes, Delete Account</>
							)}
						</Button>
					</>
				}
			/>
			{showDeleteModal && (
				<DeleteAccountConfirmation showDeleteModal={showDeleteModal} setShowDeleteModal={setShowDeleteModal} />
			)}
			<SimpleModal
				theme={theme}
				title="Cancel Subscription"
				open={cancelModalOpen}
				onClose={onCancelModalClose}
				text="Are you sure you want to cancel your subscription?"
				footer={
					<>
						<Button
							className={classnames(classes.keepBtn, classes.button)}
							size="medium"
							disabled={isLoading}
							onClick={onCancelModalClose}
						>
							{isLoading ? (
								<Box width={50}>
									<CircularProgress size="1rem" color="inherit" thickness={2} variant="indeterminate" />
								</Box>
							) : (
								<>No, Keep Subscription</>
							)}
						</Button>
						<Button
							className={classnames(classes.cancelBtn, classes.button)}
							disabled={isLoading}
							size="medium"
							onClick={() => onCancelConfirm()}
						>
							{isLoading ? (
								<Box width={50}>
									<CircularProgress size="1rem" color="inherit" thickness={2} variant="indeterminate" />
								</Box>
							) : (
								<>Yes, Cancel</>
							)}
						</Button>
					</>
				}
			/>
		</Wrapper>
	);
};

export default ManageAccount;

import React, { useEffect, useState } from "react";

import { Box, Button, Modal } from "@material-ui/core";

import { YYYY_MM_DD } from "@remar/shared/dist/constants";
import { Wrapper } from "@remar/shared/dist/layouts";
import ContentLoader from "@remar/shared/dist/layouts/TableContentLayout/components/ContentLoader";
import { format } from "date-fns";

import useAnalyticsEventTracker from "hooks/googleAnalytics";

import { useUpgradeForm } from "hooks/useUpgradeForm";

import { useDispatch, useSelector } from "react-redux";

import { useHistory } from "react-router-dom";

import { getUserData, selectInactiveSubscription, selectIsTrial } from "store/features/Auth/authSlice";

import {
	changePassword as changePasswordSlice,
	changeSubscriptionDate,
	editMyAccountDetails,
	getSubscriptionInfo,
	getUserInfo,
	selectMyAccountIsLoading,
	selectSubscriptionInfo,
	selectUserInfo
} from "store/features/MyAccount/myAccountSlice";
import { UserUpdateDataDto } from "store/services";

import { UserChangePasswordDto } from "store/services/users/dto/users.changePassword.dto";

import { routes } from "core/constants";

import ChangePassword from "./ChangePassword";
import ChangeSubscription from "./ChangeSubscription";
import EditProfile from "./EditProfile";
import EditProfilePicture from "./EditProfilePicture";
import MyAccountCard from "./MyAccountCard";
import ResumeAndRenewSubscriptionButton from "./ResumeAndRenewSubscriptionButton";
import SubscriptionCard from "./SubscriptionCard";
import UpgradeSubscription from "./UpgradeSubscription";

import { useStyles } from "./styles";

const MyAccount = () => {
	const history = useHistory();
	const classes = useStyles();
	const dispatch = useDispatch();
	const userInfo = useSelector(selectUserInfo);
	const subscriptionInfo = useSelector(selectSubscriptionInfo);
	const isLoading = useSelector(selectMyAccountIsLoading);
	const [modal, setModal] = useState(false);
	const [editProfileModal, setEditProfileModal] = useState(false);
	const [changeSubscriptionModal, setChangeSubscriptionModal] = useState(false);
	const [changePwdModal, setChangePwdModal] = useState(false);
	const [editProfilePictureModal, setEditProfilePictureModal] = useState(false);
	const analytics = useAnalyticsEventTracker("User");
	const trial = useSelector(selectIsTrial);

	useEffect(() => {
		dispatch(getUserInfo());
	}, [dispatch]);

	const passwordChangeSuccessSideEffect = () => {
		analytics({ eventName: "password-change" });
	};
	const editProfileSuccessSideEffect = () => {
		analytics({ eventName: "profile-edit" });
	};

	const onSubmit = () => {
		setModal(false);
		dispatch(getSubscriptionInfo());
		dispatch(getUserData());
		dispatch(getUserInfo());
		history.push(`${routes.course.getPath()}?refetch=true`);
	};
	const { closeModal, ...upgradeFormProps } = useUpgradeForm({ setModal, onSubmit, dispatch });
	const { isRenewEnabled } = useSelector(selectInactiveSubscription);
	const editProfileInitialValues = {
		firstName: userInfo?.firstName,
		lastName: userInfo?.lastName,
		phoneNumber: userInfo?.phoneNumber
	};

	const updateSubscriptionDate = date => {
		const dateFormat = format(new Date(date), YYYY_MM_DD);
		const data = { subscriptionId: subscriptionInfo!.subscription.id, startDate: dateFormat };
		dispatch(
			changeSubscriptionDate({
				data,
				sideEffect: () => {
					setChangeSubscriptionModal(false);
				}
			})
		);
	};

	if (isLoading) {
		return <ContentLoader />;
	}

	return (
		<Wrapper
			heading="My Account"
			actions={
				<Box display="flex" justifyContent="space-between" gridGap="16px">
					{trial && !isRenewEnabled ? (
						<Button className={classes.manageSubscriptionBtn} onClick={() => setModal(true)}>
							Upgrade subscription
						</Button>
					) : (
						(subscriptionInfo?.subscription.isCancelled || isRenewEnabled) && (
							<ResumeAndRenewSubscriptionButton
								id={subscriptionInfo?.subscription?.id as number}
								sideEffect={() => {
									dispatch(getUserData());
									dispatch(getSubscriptionInfo());
								}}
								className={classes.manageSubscriptionBtn}
							/>
						)
					)}
					<Button
						variant={"contained"}
						color={"primary"}
						className={classes.editProfileBtn}
						onClick={() => setEditProfileModal(true)}
					>
						Edit Profile
					</Button>
				</Box>
			}
		>
			<Box className={classes.root}>
				<MyAccountCard
					setEditProfilePictureModal={setEditProfilePictureModal}
					profilePictureUrl={userInfo?.profileImageUrl}
					firstName={userInfo?.firstName}
					lastName={userInfo?.lastName}
					email={userInfo?.email}
					schoolName={userInfo?.schoolName}
					phoneNumber={userInfo?.phoneNumber}
					address={userInfo?.address}
				/>
				<SubscriptionCard
					id={subscriptionInfo?.subscription.id}
					isCancelled={subscriptionInfo?.subscription.isCancelled}
					subscriptionName={subscriptionInfo?.subscription?.subscriptionName}
					expiresOn={subscriptionInfo?.subscription?.expiresOn}
					billingDate={subscriptionInfo?.upcomingInvoice?.billingDate}
					price={!subscriptionInfo?.subscription?.type?.isTrial && subscriptionInfo?.upcomingInvoice?.price}
					subscriptionStartDate={subscriptionInfo?.subscription?.data?.subscriptionStartDate}
					changeSubscription={() => setChangeSubscriptionModal(true)}
				/>
			</Box>
			<Modal open={modal} onClose={closeModal}>
				<UpgradeSubscription {...upgradeFormProps} closeModal={closeModal} />
			</Modal>
			<Modal
				open={changeSubscriptionModal}
				onClose={() => {
					setChangeSubscriptionModal(false);
				}}
			>
				<ChangeSubscription
					onClose={() => setChangeSubscriptionModal(false)}
					subscriptionStartDate={subscriptionInfo?.subscription?.data?.subscriptionStartDate}
					changeSubscriptionDate={updateSubscriptionDate}
				/>
			</Modal>
			<Modal
				open={editProfileModal}
				onClose={() => {
					setEditProfileModal(false);
				}}
			>
				<EditProfile
					handleSubmit={(values: UserUpdateDataDto) =>
						dispatch(
							editMyAccountDetails({
								data: values,
								sideEffect: () => setEditProfileModal(false),
								successMsg: "User Updated Successfully",
								successSideEffect: editProfileSuccessSideEffect
							})
						)
					}
					openPwdModal={setChangePwdModal}
					initialFormValues={editProfileInitialValues}
					onClose={() => setEditProfileModal(false)}
				/>
			</Modal>
			<Modal
				open={changePwdModal}
				onClose={() => {
					setChangePwdModal(false);
				}}
			>
				<ChangePassword
					handleSubmit={(values: UserChangePasswordDto) => {
						const { ...rest } = values;
						delete rest.confirmPassword;
						dispatch(
							changePasswordSlice({
								...rest,
								sideEffect: () => setChangePwdModal(false),
								successSideEffect: passwordChangeSuccessSideEffect
							})
						);
					}}
					onClose={() => setChangePwdModal(false)}
				/>
			</Modal>
			<Modal
				open={editProfilePictureModal}
				onClose={() => {
					setEditProfilePictureModal(false);
				}}
			>
				<EditProfilePicture onClose={() => setEditProfilePictureModal(false)} />
			</Modal>
		</Wrapper>
	);
};

export default MyAccount;

import {
	Box,
	FormControlLabel,
	Grid,
	IconButton,
	Typography,
	alpha,
	createStyles,
	makeStyles
} from "@material-ui/core";

import { VisibilityOffOutlined, VisibilityOutlined } from "@material-ui/icons";

import { IExtendedTheme } from "@remar/shared/dist/theme/default";

import styled, { css } from "styled-components";

import AuthBackground from "assets/images/signup-background.png";

export const StyledVisibilityOutlined = styled(VisibilityOutlined)`
	fill: ${props => props.theme.palette.colors.basic[200]};
`;
export const StyledVisibilityOffOutlined = styled(VisibilityOffOutlined)`
	fill: ${props => props.theme.palette.colors.basic[200]};
`;
export const CardName = styled(Typography)`
	font-size: 15px;
	font-weight: bold;
	color: ${props => props.theme.palette.text.primary};
	text-transform: capitalize;
`;

export const FeatureName = styled.span`
	overflow: hidden;
	text-overflow: ellipsis;
	-webkit-line-clamp: 1;
	display: -webkit-box;
	-webkit-box-orient: vertical;
`;

export const PaymentExpiredText = styled(Typography)`
	font-size: 14px;
	line-height: 16px;
	margin-top: 2px;
	color: ${props => alpha(props.theme.palette.text.primary, 0.6)};
`;

export const ManageText = styled(Typography)`
	font-size: 15px;
	color: #4a99f9;
`;

export const CardsContainer = styled(Box)`
	width: 100%;
	display: flex;
	${props => props.theme.breakpoints.down("xs")} {
		flex-direction: ${props => props.$activeStep};
	}
`;

export const PaymentButtonsContainer = styled(Box)`
	display: flex;
	margin-top: 16px;
	margin-bottom: 16px;
	justify-content: flex-end;
	${props => props.theme.breakpoints.down("xs")} {
		flex-direction: column-reverse;
	}
`;

export const PaidShippingContainer = styled.div`
	margin-top: 15px;
`;

export const GuestPaidShippingContainer = styled.div`
	margin-top: 6px;
	margin-bottom: 8px;
`;

export const SummaryCloseButtonContainer = styled.div`
	display: flex;
	justify-content: flex-end;
`;

export const SummaryCloseButton = styled(IconButton)`
	padding: 0;
`;

export const PasswordMatchBoxWrapper = styled(Box)`
	background: #555862;
	position: absolute;
	right: -250px;
	top: -10px;
	padding: 10px;
	border-radius: 4px;
	:before {
		content: "";
		position: absolute;
		left: -15px;
		top: 35.5px;
		width: 0;
		height: 0;
		border-style: solid;
		border-width: 15px 15px 15px 0;
		border-color: transparent #555862 transparent transparent;
	}
	p {
		margin: 0;
		font-family: var(--openSans);
	}
	.heading {
		color: ${props => alpha(props.theme.palette.text.primary, 0.6)};
		font-weight: 600;
		font-size: 13px;
	}
	.validation-text {
		display: flex;
		justify-content: center;
		align-items: center;
		margin-right: 20px;
		img {
			width: 15px;
			height: 15px;
			margin-right: 5px;
		}
		p {
			color: ${({ theme }) => alpha(theme.palette.text.primary, 0.6)};
			font-size: 12px;
			font-weight: 400;
		}
	}
	${props => props.theme.breakpoints.down("sm")} {
		left: 8px;
		top: 90px;
		width: 95%;
		:before {
			top: -22px;
			left: 45%;
			transform: rotate(90deg);
		}
	}
`;

export const SubTotalWrapper = styled(Box)`
	display: flex;
	flex-direction: column;
	p {
		margin: 0;
	}
	.subTotal {
		display: flex;
		justify-content: space-between;
		font-family: var(--poppins);
		font-size: 18px;
		font-weight: 700;
		color: ${props => props.theme.palette.info.contrastText};
	}
	.discount {
		display: flex;
		justify-content: space-between;
		font-family: var(--openSans);
		font-size: 15px;
		font-weight: 700;
		color: ${props => alpha(props.theme.palette.info.contrastText, 0.5)};
	}
`;
export const TotalWrapper = styled(Box)`
	display: flex;
	justify-content: space-between;
	align-items: center;
	p {
		font-weight: 700;
		font-family: var(--poppins);
		color: ${props => props.theme.palette.info.contrastText};
		margin: 0;
	}
	& p:first-child {
		font-size: 18px;
	}
	& p:last-child {
		font-size: 30px;
	}
`;

export const Disclaimer = styled(Box)`
	p {
		font-size: 13px;
		margin: 0;
		font-family: var(--openSans);
		color: ${({ theme }) => alpha(theme.palette.info.contrastText, 0.5)};
		line-height: 1.3;
	}
`;
export const DelayStartDateBox = styled(Box)`
	background-color: ${({ theme }) => theme.palette.background.paper};

	.today-icon {
		margin-right: 10px;
	}

	.arrow-icon {
		margin-left: 8px;

		svg {
			width: 22px;
			height: 22px;
		}
	}
`;

export const useStyles = makeStyles((theme: IExtendedTheme) =>
	createStyles({
		root: {
			background: `no-repeat url(${AuthBackground})`,
			"background-size": "cover",
			"background-position": "center",
			position: "absolute",
			top: 0,
			left: 0,
			minWidth: "100%",
			minHeight: "100%"
		},
		disabledInput: {
			color: `${theme.palette.common.grey}`
		},
		successRightBgColor: {
			backgroundColor: `${theme.palette.background.default}`
		},
		signUpTitleFollowUp: {
			fontSize: "26px",
			fontWeight: 700,
			fontFamily: "var(--poppins)",
			textAlign: "center"
		},
		successTitleFollowUp: {
			color: `${theme.palette.colors.success[600]}`
		},
		errorTitleFollowUp: {
			color: `${theme.palette.colors.danger[600]}`
		},
		signUpSubTitleTextFollowUp: {
			fontSize: "15px",
			fontWeight: 600,
			fontFamily: "var(--openSans)",
			textAlign: "center",
			display: "block",
			marginTop: "10px",
			opacity: "0.7",
			width: "320px"
		},
		cursorPointer: {
			cursor: "pointer"
		},
		datepickerInputContainer: {
			"& .MuiPickersStaticWrapper-staticWrapperRoot": {
				width: "100%"
			},
			"& .MuiPickersBasePicker-container": {
				alignItems: "center"
			}
		},
		changeBook: {
			color: "#4a99f9",
			cursor: "pointer"
		},
		removeBook: {
			color: "#ed5465",
			cursor: "pointer"
		},
		addBook: {
			backgroundColor: "#393e4b",
			color: "#eceff4"
		},
		addBookCard: {
			backgroundColor: "#c0c9e5",
			color: "#6173fe"
		},
		removeBookIcon: {
			backgroundColor: "#393e4b"
		},
		cardsContainer: {
			display: "flex",
			width: "100%",
			[theme.breakpoints.down("xs")]: {
				flexFlow: "row wrap"
			}
		},
		card: {
			borderTopRightRadius: 0,
			borderBottomRightRadius: 0,
			minHeight: 630,
			overflow: "unset"
		},
		cardLeft: {
			borderTopRightRadius: 0,
			borderBottomRightRadius: 0,
			background: theme.palette.background.paper,
			flex: 2.5,
			[theme.breakpoints.down("xs")]: {
				overflow: "unset"
			}
		},
		cardRight: {
			borderTopLeftRadius: 0,
			borderBottomLeftRadius: 0,
			background: theme.palette.background.default,
			flex: 2,
			[theme.breakpoints.down("xs")]: {
				overflow: "unset"
			}
		},
		cardTrial: {
			background: "#191c24",
			minHeight: 500,
			overflow: "unset"
		},
		termsAndConditionsCheck: {
			fontSize: theme.typography.pxToRem(13)
		},
		termsLink: {
			color: "#4a99f9",
			textDecoration: "underline",
			cursor: "pointer",
			marginLeft: "5px"
		},
		stepper: {
			// width: 510
		},
		stepperForm: {
			[theme.breakpoints.down("sm")]: {
				padding: "16px 16px 0px"
			}
		},
		title: {
			fontSize: theme.typography.pxToRem(26),
			fontWeight: 700,
			fontFamily: "var(--poppins)"
		},
		orderSummary: {
			gap: "10px",
			display: "flex",
			alignItems: "center",
			justifyContent: "space-between",
			background: theme.palette.background.paper,
			padding: "10px",
			borderRadius: "5px"
		},
		addon: {
			display: "flex",
			justifyContent: "space-between",
			alignItems: "center",
			background: theme.palette.colors.basic[750],
			padding: "10px",
			borderRadius: "5px"
		},
		addonImage: {
			width: "35px",
			height: "40px",
			borderRadius: "5px",
			marginRight: "10px"
		},
		removeAddOnIcon: {
			color: theme.palette.colors.basic[1400],
			marginLeft: "10px",
			cursor: "pointer"
		},
		errorTitle: {
			fontFamily: "var(--poppins)",
			fontSize: `${theme.typography.pxToRem(24)}`,
			fontWeight: "bold",
			color: theme.palette.common.white,
			display: "flex",
			justifyContent: "center",
			marginTop: "30px",
			[theme.breakpoints.down("xs")]: {
				textAlign: "center"
			}
		},
		errorDescription: {
			fontFamily: "var(--poppins)",
			fontSize: `"${theme.typography.pxToRem(14)}"`,
			color: theme.palette.common.white,
			justifyContent: "center",
			display: "flex"
		},
		changeTheStartDate: {
			fontSize: theme.typography.pxToRem(22),
			fontWeight: 700,
			fontFamily: "var(--poppins)"
		},
		formSubtitle: {
			opacity: 0.6,
			fontSize: theme.typography.pxToRem(13)
		},
		bookImg: {
			objectFit: "cover",
			width: "100%",
			height: "100%"
		},
		summaryRoot: {
			background: theme.palette.customBackground.shade1,
			padding: theme.spacing(5, 3),
			flexGrow: 1,
			display: "flex",
			flexDirection: "column",
			justifyContent: "space-between"
		},
		invitedBy: {
			background: theme.palette.background.paper
		},
		goToHomePage: {
			fontFamily: "var(--poppins)",
			fontSize: `"${theme.typography.pxToRem(16)}"`,
			fontWeight: "bold",
			color: theme.palette.text.primary,
			textAlign: "center",
			marginTop: "20px",
			cursor: "pointer"
		},
		summaryText: {
			opacity: 0.8,
			fontSize: theme.typography.pxToRem(15),
			"& > span": {
				color: "#4a99f9",
				cursor: "pointer",
				display: "block"
			}
		},
		summaryTextLight: {
			opacity: 0.6,
			color: theme.palette.text.secondary,
			fontSize: theme.typography.pxToRem(15)
		},
		// summaryItemText: {},
		summaryCostSmall: {
			fontSize: theme.typography.pxToRem(18),
			fontWeight: 700,
			fontFamily: "var(--poppins)"
		},
		summaryCostBig: {
			fontSize: theme.typography.pxToRem(30),
			fontWeight: 700,
			fontFamily: "var(--poppins)"
		},
		quantityClass: {
			fontFamily: "var(--poppins)",
			fontSize: `"${theme.typography.pxToRem(15)}"`,
			fontWeight: 600,
			lineHeight: 1.6,
			color: theme.palette.text.primary,
			display: "flex",
			margin: "0 8px",
			minWidth: "19px",
			justifyContent: "center"
		},
		containerGap: {
			gap: "20px",
			[theme.breakpoints.down("xs")]: {
				flexDirection: "column",
				alignItems: "center",
				gap: "8px"
			}
		},
		successLeftContainerText: {
			fontFamily: "var(--poppins)",
			fontSize: `"${theme.typography.pxToRem(15)}"`,
			fontWeight: 600,
			textAlign: "right",
			width: "50%",
			color: alpha(theme.palette.text.primary, 0.6),
			[theme.breakpoints.down("xs")]: {
				textAlign: "center",
				width: "100%"
			}
		},
		successRightContainerText: {
			fontFamily: "var(--poppins)",
			fontSize: `"${theme.typography.pxToRem(15)}"`,
			fontWeight: 600,
			textAlign: "left",
			width: "50%",
			color: theme.palette.text.primary,
			opacity: 0.87,
			wordBreak: "break-all",
			[theme.breakpoints.down("xs")]: {
				textAlign: "center",
				width: "100%"
			}
		},
		changeDateText: {
			cursor: "pointer",
			lineHeight: "normal",
			display: "flex",
			fontSize: "15px",
			justifyContent: "center",
			alignItems: "center"
		},
		successDesc: {
			fontFamily: "var(--poppins)",
			fontSize: `"${theme.typography.pxToRem(15)}"`,
			textAlign: "center",
			color: theme.palette.text.primary,
			opacity: 0.87
		},
		stateInput: {
			[theme.breakpoints.up("md")]: {
				padding: "12px 5px !important"
			}
		},
		closeIcon: {
			padding: "0px"
		},
		trialSuccessContainer: {
			[theme.breakpoints.down("xs")]: {
				flexDirection: "column"
			}
		},
		successCardsContainer: {
			display: "flex",
			[theme.breakpoints.down("xs")]: {
				flexDirection: "column",
				overflow: "scroll",
				"&::-webkit-scrollbar": { display: "none" }
			}
		},
		stepLabel: {
			color: `${theme.palette.text.primary} !important`,
			opacity: 0.5
		},
		baseFlex: { flex: 1 },
		customFlex: { flex: 0.7 }
	})
);

export const useChangeCourseStyles = makeStyles((theme: IExtendedTheme) =>
	createStyles({
		changeCourseTitle: {
			fontWeight: "bold",
			color: theme.palette.text.primary,
			alignItems: "center",
			display: "flex",
			fontStretch: "normal",
			fontStyle: "normal",
			lineHeight: 1.33
		},
		bookImage: {
			width: "200px",
			height: "270px",
			objectFit: "contain"
		},
		changeCourseAmount: {
			margin: "12px 0px 0px 15px",
			opacity: "0.87",
			fontWeight: "bold",
			fontSize: `"${theme.typography.pxToRem(22)}"`,
			color: theme.palette.text.primary,
			alignItems: "center",
			display: "flex",
			fontStretch: "normal",
			fontStyle: "normal",
			lineHeight: 1.45,
			letterSpacing: "normal"
		},
		changeCourseName: {
			margin: "5px 0px 0px 15px",
			fontWeight: "bold",
			fontSize: `"${theme.typography.pxToRem(15)}"`,
			color: alpha(theme.palette.text.primary, 0.6),
			alignItems: "center",
			display: "flex",
			fontStretch: "normal",
			fontStyle: "normal",
			lineHeight: 1.6,
			letterSpacing: "normal"
		},
		changeCourseList: {
			background: "inherit",
			flexGrow: 1
		},
		changeCourseListItem: {
			opacity: "0.87",
			fontWeight: "normal",
			fontSize: `"${theme.typography.pxToRem(13)}"`,
			color: theme.palette.text.primary,
			alignItems: "center",
			fontStretch: "normal",
			fontStyle: "normal",
			lineHeight: 1.23,
			letterSpacing: "normal"
		},
		changeCourseTick: {
			marginRight: "4px",
			color: "#4a99f9"
		},
		changeCourseInfo: {
			margin: "0px 5px 0 2px"
		},
		selectCourse: {
			marginRight: "10px"
		},
		freeTrial: {
			color: "grey"
		},
		selectedCourse: {
			margin: "10px 15px 10px 10px",
			"& > span": {
				color: theme.palette.text.primary,
				opacity: 0.87,
				fontStretch: "normal",
				fontStyle: "normal",
				fontWeight: "bold",
				fontSize: `"${theme.typography.pxToRem(15)}"`,
				lineHeight: 1.3,
				letterSpacing: "normal",
				cursor: "none"
			}
		},
		course: {
			padding: "10px",
			flex: "1 0 21%",
			margin: "1%",
			borderRadius: "4px",
			backgroundColor: theme.palette.background.default,
			display: "flex",
			flexFlow: "row nowrap"
		},
		changeCourseCard: {
			width: "90vw"
		},
		courseWithBorder: {
			padding: "10px",
			flex: "1 0 21%",
			margin: "1%",
			borderRadius: "4px",
			backgroundColor: theme.palette.background.default,
			border: "1px solid #4a99f9"
		},
		itemClass: {
			width: "100%",
			flex: "48%",
			margin: "1%",
			backgroundColor: theme.palette.background.default,
			borderRadius: "4px"
		},
		containerClass: {
			display: "flex",
			flexWrap: "wrap",
			width: "80%",
			height: "80vh",
			overflow: "auto"
		},
		selectedBook: {
			border: "1px solid #4a99f9"
		},
		coursesContainer: {
			display: "flex",
			flexWrap: "wrap",
			height: "63vh",
			overflow: "auto",
			[theme.breakpoints.down("xs")]: {
				"&::-webkit-scrollbar": { display: "none" }
			}
		},
		modalContainer: {
			[theme.breakpoints.down("sm")]: {
				overflow: "auto",
				"&::-webkit-scrollbar": { display: "none" }
			}
		}
	})
);

export const CourseNameWrapper = styled(Box)`
	display: flex;
	justify-content: ${({ $spaceBetween }) => ($spaceBetween ? "space-between" : "flex-start")};
	align-items: center;
	background: ${({ theme }) => theme.palette.background.paper};
`;

export const BooksWrapper = styled(Box)`
	height: 200px;
	overflow-y: scroll;
`;
export const BoldText = styled(Typography)`
	font-size: 15px;
	font-weight: 700;
`;

export const DescriptionText = styled(Typography)`
	font-size: 12px;
	color: ${({ theme }) => theme.palette.colors.basic[400]};
`;

export const BookLabel = styled(Typography)`
	font-size: 12px;
	color: ${({ $isPhysical, theme }) =>
		$isPhysical ? theme.palette.colors.warning[700] : theme.palette.colors.primary[1000]};
	background-color: ${({ theme }) => theme.palette.background.default};
	border-radius: 12px;
	padding: 2px 7px;
	margin-top: 2px;
	margin-right: 4px;
	text-align: center;
	display: ${({ $hide }) => ($hide ? "none" : "block")};
`;

export const ShippingMessage = styled(Typography)`
	color: ${({ theme }) => theme.palette.colors.basic[400]};
	font-size: 13px;
`;

export const ShippingFormControlLabel = styled(FormControlLabel)`
	color: ${({ theme }) => theme.palette.colors.basic[100]};
	font-size: 13px;
`;

export const PaymentWrapper = styled(Grid)`
	${({ theme }) =>
		css`
			.StripeElement {
				background: ${theme.palette.inputField.background};
				padding: 18px 12px 19px;
				border-radius: 4px;
				&:hover {
					background: ${theme.palette.inputField.hoverBackground};
				}
				&:active {
					background: ${theme.palette.inputField.hoverBackground};
				}
			}
		`}
`;

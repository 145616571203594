import React, { useMemo } from "react";

import { Box, Typography } from "@material-ui/core";
import { Country, StripeIntegrationData, UserSubscriptionDataModel } from "@remar/shared/dist/models";

import { Integrations } from "@remar/shared/dist/models/package.model";
import { formatUSD } from "@remar/shared/dist/utils/formatUSD";
import { add, format } from "date-fns";

import { useSelector } from "react-redux";
import { getFullState } from "store/features/Theme/theme.slice";
import { InvitedBy } from "store/services";

import logo from "assets/images/logo.svg";

import {
	BoldText,
	BookLabel,
	BooksWrapper,
	CourseNameWrapper,
	DescriptionText,
	Disclaimer,
	SubTotalWrapper,
	TotalWrapper,
	useStyles
} from "../components/styles";

const titleStyle = { fontSize: "22px", fontWeight: 700 };
const Summary = ({
	invitedBy,
	subscriptionData,
	country
}: {
	invitedBy: InvitedBy | undefined;
	subscriptionData: UserSubscriptionDataModel | undefined;
	country: Country;
}) => {
	const { logoImageUrl, isLoading } = useSelector(getFullState);
	const { days = 0, months = 0 } = subscriptionData?.duration ?? {};
	const renewalDate = format(add(new Date(), { days, months }), "dd, MMM yyyy");

	const subTypeEIDItems = useMemo(
		() =>
			subscriptionData?.subTypeEIDItems?.find(
				item => item.integrationId === Integrations.StripeIntegrationId && item.parentId === null
			),
		[subscriptionData?.subTypeEIDItems]
	);

	const books = useMemo(() => subTypeEIDItems?.children, [subTypeEIDItems]);

	const haveAuctrackBook = useMemo(
		() => books?.some(book => book.integrationId === Integrations.AcutrackIntegrationId),
		[books]
	);

	const classes = useStyles();
	return (
		<Box className={`${classes.summaryRoot} ${classes.customFlex}`}>
			<Box>
				<Box mb={4}>
					<Typography style={titleStyle}>Invited by</Typography>
					<Box display="flex" className={classes.invitedBy} p={1} mt={2}>
						<Box borderRadius={5} overflow="hidden" width={40} height={40} mr={1}>
							{isLoading && <img alt="institution logo" className={classes.bookImg} src={logoImageUrl || logo} />}
						</Box>
						<Box display="flex" flexDirection={"column"}>
							<Typography
								style={{ fontSize: "15px", fontWeight: 700 }}
							>{`${invitedBy?.firstName} ${invitedBy?.lastName}`}</Typography>
							<Typography style={{ fontSize: "12px" }}>{invitedBy?.locationName}</Typography>
						</Box>
					</Box>
				</Box>

				<Box mb={2}>
					<Typography style={titleStyle}>Order Summary</Typography>
				</Box>
				<CourseNameWrapper $spaceBetween p={1} mt={2}>
					<Box display="flex" flexDirection={"column"}>
						<DescriptionText>Course Name</DescriptionText>
						<BoldText>{subscriptionData?.allowedCourses?.[0]?.name}</BoldText>
					</Box>
					<Box>
						<BoldText>${(subTypeEIDItems?.data as StripeIntegrationData).price}</BoldText>
					</Box>
				</CourseNameWrapper>
				<BooksWrapper>
					{books?.map((item, i) => (
						<CourseNameWrapper key={i} p={1} mt={2}>
							{item.mainImageUrl && (
								<Box mr={2}>
									<img width="50px" src={item.mainImageUrl} alt="book name" />
								</Box>
							)}
							<Box display="flex" flexDirection={"column"}>
								<BoldText>{item.data?.name}</BoldText>
								<Box display="flex">
									<BookLabel key={i} $isPhysical={item.integrationId === Integrations.AcutrackIntegrationId}>
										{item.integrationId === Integrations.AcutrackIntegrationId ? "Physical Book" : "Digital book"}
									</BookLabel>
								</Box>
							</Box>
						</CourseNameWrapper>
					))}
				</BooksWrapper>
				<SubTotalWrapper mt={3}>
					<Box className={"subTotal"} mb={0.5}>
						<p>Subtotal</p>
						<p>
							{subscriptionData?.priceItem.data.price.toLocaleString("en-US", {
								currency: "USD",
								style: "currency"
							})}
						</p>
					</Box>
					<Box className={"discount"}>
						<p>Invited student discount</p>
						<p>
							-{" "}
							{subscriptionData?.priceItem.data.price.toLocaleString("en-US", {
								currency: "USD",
								style: "currency"
							})}
						</p>
					</Box>

					{country && !country.freeShippingForInstitution && haveAuctrackBook && (
						<Box display="flex" justifyContent="space-between">
							<p>International shipping fee</p>
							<p>
								+{" "}
								{country?.shippingPlans?.[0].data.price.toLocaleString("en-US", {
									currency: "USD",
									style: "currency"
								})}
							</p>
						</Box>
					)}
				</SubTotalWrapper>
				<TotalWrapper mt={3}>
					<p>Your Total</p>
					<p>
						{(country?.shippingPlans?.[0].data.price && !country?.freeShippingForInstitution && haveAuctrackBook
							? formatUSD(country?.shippingPlans?.[0].data.price)
							: 0
						).toLocaleString("en-US", {
							currency: "USD",
							style: "currency"
						})}
					</p>
				</TotalWrapper>
				<Disclaimer mt={2}>
					<p>Course fee is covered by institution until renewal. Renewal of course starts on {renewalDate}.</p>
				</Disclaimer>
			</Box>
		</Box>
	);
};

export default Summary;

import React, { useEffect, useState } from "react";

import {
	Box,
	Button,
	Checkbox,
	CircularProgress,
	FormControlLabel,
	Grid,
	SvgIcon,
	Theme,
	Typography,
	createStyles,
	useMediaQuery,
	useTheme
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { ReactComponent as IconEyeSlash } from "@remar/shared/dist/assets/icons/icon-eye-slash.svg";
import { ReactComponent as IconEye } from "@remar/shared/dist/assets/icons/icon-eye.svg";
import { MaintenanceModeTypes } from "@remar/shared/dist/constants";
import { IExtendedTheme } from "@remar/shared/dist/theme/default";

import { Field, Form, Formik } from "formik";
import { TextField as MuiTextField } from "formik-material-ui";

import useAnalyticsEventTracker from "hooks/googleAnalytics";

import FacebookLogin from "react-facebook-login";

import GoogleLogin from "react-google-login";

import { useDispatch, useSelector } from "react-redux";

import { Link, useHistory, useLocation } from "react-router-dom";

import {
	SocialSignUpUser,
	facebookLogin,
	googleLogin,
	resendVerificationEmail,
	selectAuth,
	selectResendEmail,
	setError,
	setIsLoading,
	setResendEmail,
	signIn,
	signInByToken
} from "store/features/Auth/authSlice";
import { UserLoginDto } from "store/services";
import * as Yup from "yup";

import { ReactComponent as FacebookSvg } from "assets/icons/facebook-icon.svg";
import { ReactComponent as GoogleSVG } from "assets/icons/google-icon.svg";

import { routes } from "core/constants";

import EntryScreenLayout from "./EntryScreenLayout";

import { GLOBAL_CONSTANTS } from "../../constants";

const TextField = props => <MuiTextField {...props} variant="filled" hiddenLabel />;

const SignupSchema = Yup.object().shape({
	email: Yup.string().email("Invalid email").required("Required"),
	password: Yup.string().min(4).required("Required")
});
const initialFormValues: UserLoginDto = {
	email: "",
	password: "",
	rememberMe: true
};
const useStyles = makeStyles((theme: IExtendedTheme) =>
	createStyles({
		facebookButton: {
			display: "inline-flex",
			height: 48,
			padding: "16px 19px",
			borderRadius: 4,
			borderStyle: "none",
			backgroundColor: "#264fae",
			color: "white",
			fontFamily: "var(--poppins)",
			fontSize: 14,
			fontWeight: 600,
			lineHeight: 1.14,
			cursor: "pointer",
			width: "100%",
			justifyContent: "center",

			"& svg": {
				fontSize: 16,
				height: 16,
				width: 9,
				marginRight: 10
			}
		},
		googleButton: {
			flex: 1,
			color: "white !important",
			backgroundColor: "#d54b5b !important",
			justifyContent: "center",
			borderRadius: "4px !important",
			"& span": {
				fontWeight: 600,
				fontFamily: "var(--poppins)",
				fontSize: 14,
				lineHeight: 1.14,
				marginLeft: 7
			},
			"& svg": {
				fontSize: 16,
				height: 16,
				width: 16,
				position: "relative",
				top: 3
			},
			"& div": {
				display: "none"
			},
			[theme.breakpoints.down("xs")]: {
				flexBasis: "100%"
			}
		},
		spanLeft: {
			flex: 1,
			height: 1,
			borderBottom: "2px solid #b1b6c3",
			marginRight: 24
		},
		spanRight: {
			flex: 1,
			borderBottom: "2px solid #b1b6c3",
			height: 1,
			marginLeft: 24
		},
		loginButtonsBox: {
			"& span": {
				flex: 1
			},
			flexWrap: "wrap"
		},
		disabledButton: {
			color: `${theme.palette.text.secondary} !important`,
			background: `${theme.palette.disabledButtonColors.backgroundColor} !important`
		},
		inputField: {
			"&:hover": {
				backgroundColor: theme.palette.customBackground.shade1
			}
		},
		rememberMe: {
			color: theme.palette.common.white
		}
	})
);
const SignIn = () => {
	const theme = useTheme<Theme>();
	const dispatch = useDispatch();
	const history = useHistory();
	const classes = useStyles();
	const location = useLocation();
	const params = new URLSearchParams(location.search);

	const isMobile = useMediaQuery(theme.breakpoints.down("xs"));
	const { errorMessage, isLoading } = useSelector(selectAuth);
	const resendEmail = useSelector(selectResendEmail);
	const showResendMessage = !!resendEmail;
	const [showPass, setShowPass] = useState(false);
	const analytics = useAnalyticsEventTracker("User");
	const isMaintenanceModeOnOptional = GLOBAL_CONSTANTS.REACT_APP_MAINTENANCE_MODE === MaintenanceModeTypes.OPTIONAL;
	const isMaintenanceModeOnSevere = GLOBAL_CONSTANTS.REACT_APP_MAINTENANCE_MODE === MaintenanceModeTypes.SEVERE;
	const isAuthLogin = params.get("authLogin") == "true";
	const disableOnMaintenanceMode = (!isAuthLogin && isMaintenanceModeOnOptional) || isMaintenanceModeOnSevere;
	const token = params.get("token");
	const redirect = params.get("redirect");

	useEffect(() => {
		if (token) {
			dispatch(
				signInByToken({
					sessionId: token,
					sideEffect: () => {
						setTimeout(() => {
							history.push(`/${redirect || "course"}`);
						}, 100);
					}
				})
			);
		}
	}, [dispatch, token]);

	const handleSubmit = values => {
		// eslint-disable-next-line @typescript-eslint/ban-ts-comment
		// @ts-ignore
		dispatch(signIn({ ...values })).then(res => {
			if (res.error) {
				return;
			}
			localStorage.setItem("analyticsId", `${values.email}-normal`);
			analytics({ eventName: "login" });
			history.push(`${redirect || "/course"}`);
		});
	};

	const sideEffect = () => {
		history.push(`${routes.signUp.getPath()}/course`);
	};

	const successSideEffect = (email, platform) => {
		localStorage.setItem("analyticsId", `${email} - ${platform}`);
		analytics({ eventName: "login" });
	};

	const handleGoogleLogin = async googleData => {
		const googleUser = {
			accessToken: googleData.tokenObj.id_token,
			email: googleData.profileObj.email,
			first_name: googleData.profileObj.givenName,
			last_name: googleData.profileObj.familyName,
			name: googleData.profileObj.name
		};
		dispatch(googleLogin({ googleUser, sideEffect, successSideEffect }));
	};

	const responseFacebook = async (facebookData: SocialSignUpUser) => {
		dispatch(facebookLogin({ facebookData, sideEffect, successSideEffect }));
	};

	const handleResendEmail = () => {
		// eslint-disable-next-line @typescript-eslint/ban-ts-comment
		// @ts-ignore
		dispatch(resendVerificationEmail({ email: resendEmail })).then(r => {
			dispatch(setIsLoading(false));
			dispatch(setResendEmail(""));
			if (r.error) {
				dispatch(setError(r.error.message));
				return;
			}
			dispatch(setError("Now please confirm your email address"));
		});
	};

	const signInForm = (
		<Box>
			<Box mt={3}>
				<Box>
					<Grid container spacing={isMobile ? 1 : 2}>
						<Grid item xs={12}>
							<Field
								component={TextField}
								name="email"
								type="email"
								placeholder="Email"
								fullWidth
								InputProps={{ disableUnderline: true, classes: { root: classes.inputField } }}
								disabled={disableOnMaintenanceMode}
							/>
						</Grid>
						<Grid item xs={12}>
							<Field
								component={TextField}
								name="password"
								type={showPass ? "text" : "password"}
								placeholder="Password"
								fullWidth
								InputProps={{
									classes: { root: classes.inputField },
									endAdornment: (
										<SvgIcon fontSize="small" cursor="pointer" onClick={() => setShowPass(!showPass)}>
											{showPass ? <IconEye /> : <IconEyeSlash />}
										</SvgIcon>
									),
									disableUnderline: true
								}}
								disabled={disableOnMaintenanceMode}
							/>
						</Grid>
						<Grid item xs={12}>
							<FormControlLabel
								classes={{ label: classes.rememberMe }}
								label="Keep me logged in"
								control={
									<Field
										type="checkbox"
										component={Checkbox}
										name="rememberMe"
										value="rememberMe"
										// checked={rememberMe}
										// onChange={() => setRememberMe(!rememberMe)}
										color="primary"
									/>
								}
							/>
						</Grid>
					</Grid>
				</Box>
			</Box>
		</Box>
	);

	const socialBlock = (
		<Box
			mt={3}
			mb={3}
			textAlign="center"
			display={"none"}
			gridGap={isMobile ? 10 : 16}
			className={classes.loginButtonsBox}
		>
			<GoogleLogin
				clientId={GLOBAL_CONSTANTS.GOOGLE_KEY!}
				onSuccess={handleGoogleLogin}
				cookiePolicy={"single_host_origin"}
				className={classes.googleButton}
			>
				<GoogleSVG />
				<span>Login with Google</span>
			</GoogleLogin>
			<FacebookLogin
				appId={GLOBAL_CONSTANTS.FACEBOOK_APP_ID}
				fields="name,email,id,first_name,last_name"
				callback={responseFacebook}
				icon={<FacebookSvg />}
				cssClass={classes.facebookButton}
			/>
		</Box>
	);

	return (
		<EntryScreenLayout
			title="Welcome back!"
			disableOnMaintenanceMode={disableOnMaintenanceMode}
			isLeftBlockTransparent={true}
		>
			{socialBlock}
			<Box display="none" alignItems="center">
				<span className={classes.spanLeft} />
				<Typography variant={"body1"}>Or Login with Email</Typography>
				<span className={classes.spanRight} />
			</Box>
			<Formik initialValues={initialFormValues} validationSchema={SignupSchema} onSubmit={handleSubmit}>
				{({ isValid, values }) => (
					<>
						<Form
							onSubmit={e => {
								e.preventDefault();
								() => handleSubmit(values); // todo: what should this code do???
							}}
						>
							{signInForm}
							{errorMessage && (
								<Box mt={2}>
									<Typography variant="caption" style={{ color: "red" }}>
										{errorMessage}
									</Typography>
									{showResendMessage && (
										<>
											<Link
												href=""
												to=""
												onClick={() => handleResendEmail()}
												style={{
													color: "red",
													fontSize: "0.75rem",
													fontWeight: 400,
													lineHeight: 1.66,
													letterSpacing: "0.03333em",
													textDecoration: "underline"
												}}
											>
												resend
											</Link>
											<Typography variant="caption" style={{ color: "red" }}>
												?
											</Typography>
										</>
									)}
								</Box>
							)}
							<Box mt={1}>
								<Button
									fullWidth
									classes={{ disabled: classes.disabledButton }}
									color="primary"
									disabled={!isValid || isLoading || disableOnMaintenanceMode}
									startIcon={isLoading && <CircularProgress size={20} />}
									variant="contained"
									onClick={() => handleSubmit(values)}
									type="submit"
								>
									Log In
								</Button>
							</Box>
						</Form>

						{!disableOnMaintenanceMode && (
							<Box mt={isMobile ? 2 : 4} textAlign="center">
								<Link to={routes.forgotPassword.getPath()}>
									<Typography
										color={"primary"}
										variant={"subtitle1"}
										style={{
											textDecoration: "underline"
										}}
									>
										Forgot Password
									</Typography>
								</Link>
							</Box>
						)}
					</>
				)}
			</Formik>
		</EntryScreenLayout>
	);
};

export default SignIn;

import React, { FC, useCallback, useEffect, useState } from "react";

import {
	Box,
	Button,
	Card,
	CircularProgress,
	Container,
	IconButton,
	List,
	ListItem,
	Typography
} from "@material-ui/core";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import CloseIcon from "@material-ui/icons/Close";
import LogoComponent from "@remar/shared/dist/components/LogoComponent";
import { ExternalIntegrationIds, MAX_COURSE_ADDONS } from "@remar/shared/dist/constants";
import { Course, CourseData } from "@remar/shared/dist/models";
import { useDispatch } from "react-redux";

import { useHistory } from "react-router-dom";
import { RootState, useAppSelector } from "store";
import { changeSubscriptionType } from "store/features/Auth/authSlice";

import { UserSubscriptionTypeCategories } from "store/features/Auth/constants";

import { ReactComponent as InfoSvg } from "assets/icons/icon-info-circle.svg";

import { routes } from "core/constants";

import { FeatureName, useChangeCourseStyles } from "./styles";

interface Props {
	onClose: () => void;
	selectCourse?: boolean;
	courses?: Course[];
	accountClaimCode?: string;
}

const ChangeOrSelectCourse: FC<Props> = ({ onClose, selectCourse, courses, accountClaimCode }) => {
	const [isSelectedCourse, setIsSelectedCourse] = useState<boolean[]>([]);
	const [courseId, setCourseId] = useState<number>(0);
	const classes = useChangeCourseStyles({ isSelectedCourse });
	const { userSubscriptionTypeId, subscriptionTypes, isLoading } = useAppSelector((store: RootState) => store.auth);
	const history = useHistory();
	const dispatch = useDispatch();

	const courseSelected = useCallback(() => {
		const isSelected = courses!.map(
			course =>
				subscriptionTypes.find(type => type.id === userSubscriptionTypeId)?.allowedCourses?.[0]["id"] === course["id"]
		);
		setIsSelectedCourse(isSelected);
		const courseSelectedId = subscriptionTypes.find(type => type.id === userSubscriptionTypeId)?.allowedCourses?.[0][
			"id"
		] as number;
		setCourseId(courseSelectedId ?? 0);
	}, [courses, subscriptionTypes, userSubscriptionTypeId]);

	useEffect(() => {
		courseSelected();
	}, [courseSelected, userSubscriptionTypeId]);

	const getPrice = (course, subscriptionType) => {
		const initialOrRecurringPrice = course.allowedForUST
			?.find(type =>
				subscriptionType === "recurring" ? type.isRecurring && !type.isTrial : !type.isRecurring && !type.isTrial
			)
			?.subTypeEIDItems?.find(i => i.integrationId == ExternalIntegrationIds.Stripe && i.parentId === null);
		return (
			initialOrRecurringPrice.data["price"].toLocaleString("en-US", {
				currency: "USD",
				style: "currency"
			}) || 0
		);
	};

	const getSubscriptionTypeId = course => {
		const allowedUST = course.allowedForUST?.find(
			type =>
				!type.isRecurring &&
				!type.isTrial &&
				type.userSubscriptionTypeCategoryId == UserSubscriptionTypeCategories.Course
		);
		return allowedUST?.id;
	};

	const getTrialSubscriptionTypeId = course => {
		const allowedUST = course.allowedForUST?.find(
			type =>
				!type.isRecurring &&
				type.isTrial &&
				type.userSubscriptionTypeCategoryId == UserSubscriptionTypeCategories.Course
		);
		return allowedUST?.id;
	};

	return (
		<Container maxWidth="md">
			<Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" height="100vh">
				<Card className={classes.changeCourseCard}>
					<Box>
						<LogoComponent />
						<Box display="flex" alignItems="center" justifyContent={!selectCourse ? "space-between" : "center"}>
							<Typography className={classes.changeCourseTitle} variant={!selectCourse ? "h6" : "h5"}>
								{selectCourse ? "Select Course" : "Change Course"}
							</Typography>
							{!selectCourse && (
								<IconButton onClick={onClose}>
									<CloseIcon color="disabled" />
								</IconButton>
							)}
						</Box>
					</Box>
					{isLoading ? (
						<Box display="flex" alignItems="center" justifyContent="center" height="66vh" width="100%">
							<CircularProgress size="7rem" color="primary" thickness={5} variant="indeterminate" />
						</Box>
					) : (
						<>
							<Box className={classes.coursesContainer}>
								{courses?.map((course, i) => {
									const data: CourseData = course?.data as CourseData;
									const hasTrial = !!(course.allowedForUST || []).find(st => st.isTrial);
									return (
										<Box className={isSelectedCourse[i] ? classes.courseWithBorder : classes.course} key={course.id}>
											<Box ml="2%" height="100%" width="100%" display="flex" flexDirection="column">
												<Typography className={classes.changeCourseAmount} variant="h6">
													{getPrice(course, "initial")}
												</Typography>
												<Typography className={classes.changeCourseName} variant="h6">
													{course.name !== " " ? course.name : "N/A"}
												</Typography>
												<List className={classes.changeCourseList}>
													{data?.marketingPoints.slice(0, MAX_COURSE_ADDONS).map((marketingPoint, i) => (
														<ListItem className={classes.changeCourseListItem} key={i}>
															<CheckCircleIcon fontSize="small" className={classes.changeCourseTick} />
															<FeatureName title={marketingPoint}> {marketingPoint} </FeatureName>
														</ListItem>
													))}
												</List>
												<Box display="flex" justifyContent="flex-start">
													<InfoSvg className={classes.changeCourseInfo} />
													<span className={classes.changeCourseListItem}>
														{data?.additionalInformation ??
															`After the first payment will be ${getPrice(course, "recurring")}/month renewal.`}
													</span>
												</Box>
												<Box display="flex" justifyContent="center" mt={3} mr={1} mb={1}>
													{isSelectedCourse[i] ? (
														<Box className={classes.selectedCourse}>
															<span>Selected</span>
														</Box>
													) : (
														<Button
															variant="contained"
															color="primary"
															size="small"
															className={classes.selectCourse}
															onClick={() => {
																if (selectCourse) {
																	dispatch(changeSubscriptionType(getSubscriptionTypeId(course)));
																} else {
																	if (accountClaimCode) {
																		onClose();
																		history.push(
																			`${routes.signUp.getPath()}/course/${course.id}/claimAccount/${accountClaimCode}`
																		);
																	} else if (!!sessionStorage.getItem("platform")) {
																		onClose();
																		history.push(`${routes.signUp.getPath()}/oauth/course/${course.id}`);
																	} else {
																		onClose();
																		history.push(`${routes.signUp.getPath()}/course/${course.id}`);
																	}
																}
															}}
														>
															Select Course
														</Button>
													)}
													{hasTrial && !accountClaimCode && (
														<Button
															variant="outlined"
															className={classes.freeTrial}
															onClick={() => {
																dispatch(changeSubscriptionType(getTrialSubscriptionTypeId(course)));
																onClose();
																!!sessionStorage.getItem("platform")
																	? history.push(`${routes.signUp.getPath()}/oauth/course/${course.id}/trial`)
																	: history.push(
																			`${routes.signUp.getPath()}/course/${course.id}/trial${history.location.search}`
																	  );
															}}
														>
															Free Trial
														</Button>
													)}
												</Box>
											</Box>
										</Box>
									);
								})}
							</Box>
						</>
					)}
					<Box display="flex" justifyContent="flex-end" mt={2}>
						<Button
							variant="contained"
							color="primary"
							className={classes.selectCourse}
							onClick={() => {
								if (!selectCourse) {
									return onClose();
								}
								onClose();
								if (accountClaimCode) {
									return history.push(`${routes.signUp.getPath()}/course/${courseId}/claimAccount/${accountClaimCode}`);
								}
								if (!!sessionStorage.getItem("platform")) {
									return history.push(`${routes.signUp.getPath()}/oauth/course/${courseId}`);
								}
								history.push(`${routes.signUp.getPath()}/course/${courseId}${history.location.search}`);
							}}
							disabled={selectCourse && !isSelectedCourse.some(course => course)}
						>
							{selectCourse ? "Next" : "Close"}
						</Button>
					</Box>
				</Card>
			</Box>
		</Container>
	);
};

export default ChangeOrSelectCourse;

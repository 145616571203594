import React from "react";

import { Box, Card, Typography } from "@material-ui/core";
import { CalendarTodaySharp } from "@material-ui/icons";
import { ReactComponent as SubscriptionSvg } from "@remar/shared/dist/assets/icons/Subscription.svg";
import { ReactComponent as BadgeDollarSvg } from "@remar/shared/dist/assets/icons/badge-dollar.svg";
import { formatDate, getRemainingDays } from "@remar/shared/dist/utils/myAccountUtils";
import { useDispatch, useSelector } from "react-redux";

import { getUserData, selectInactiveSubscription, selectIsTrial } from "store/features/Auth/authSlice";

import { getSubscriptionInfo } from "store/features/MyAccount/myAccountSlice";

import CancelSubscriptionButton from "./CancelSubscriptionButton";

import ResumeAndRenewSubscriptionButton from "./ResumeAndRenewSubscriptionButton";
import { useStyles } from "./styles";
import { dateInPast, isToday } from "./utils";

const SubscriptionCard = ({
	id,
	isCancelled,
	subscriptionName,
	expiresOn,
	subscriptionStartDate,
	billingDate,
	price,
	changeSubscription
}) => {
	const classes = useStyles();
	const dispatch = useDispatch();
	const subscriptionEndDate =
		getRemainingDays(new Date(expiresOn)) > 1
			? `${getRemainingDays(new Date(expiresOn))} Days`
			: (expiresOn && "1 Day") || "-";
	const nextBillingDate = billingDate && formatDate(billingDate);
	const isCurrentSubscriptionTrial = useSelector(selectIsTrial);
	const { isRenewEnabled } = useSelector(selectInactiveSubscription);

	return (
		<Box className={classes.subscriptionInfoContainer}>
			<Card className={classes.subscriptionCard}>
				<Typography className={classes.subscriptionTitle}>My Subscription</Typography>
				<Box className={classes.infoContainer}>
					<SubscriptionSvg width={27} height={27} />
					<Box>
						<Typography className={classes.infoText}>{subscriptionName || "-"}</Typography>
						<Typography className={classes.infoName}>Your Subscription</Typography>
					</Box>
				</Box>

				{subscriptionStartDate && !isToday(subscriptionStartDate) && !dateInPast(subscriptionStartDate) && (
					<Box className={classes.infoContainer}>
						<CalendarTodaySharp style={{ width: "27px", height: "27px", fill: "#586179" }} />
						<Box>
							<Typography className={classes.infoText}>{formatDate(subscriptionStartDate)}</Typography>
							<Typography className={classes.infoName}>Subscription Starts on</Typography>
						</Box>
						<Box onClick={() => changeSubscription()}>
							<Typography className={classes.dateText}>Change Date</Typography>
						</Box>
					</Box>
				)}

				<Box className={classes.infoContainer}>
					<CalendarTodaySharp style={{ width: "27px", height: "27px", fill: "#586179" }} />
					<Box>
						<Typography className={classes.infoText}>{subscriptionEndDate}</Typography>
						<Typography className={classes.infoName}>Subscription Expires on</Typography>
					</Box>
				</Box>
				<Box className={classes.infoContainer}>
					<BadgeDollarSvg width={27} height={27} />
					<Box>
						{!isCancelled ? (
							<Typography className={classes.infoText}>
								{nextBillingDate || "-"} | ${price || "-"}
							</Typography>
						) : (
							<Typography className={classes.infoText}>- | -</Typography>
						)}
						<Typography className={classes.infoName}>Your next billing date</Typography>
					</Box>
				</Box>
				{/* isCancelled possibly undfined, that's why explicitly mentioned false*/}
				{isCancelled === false && !isCurrentSubscriptionTrial && (
					<CancelSubscriptionButton
						id={id}
						sideEffect={() => {
							dispatch(getUserData());
						}}
					/>
				)}

				{(isCancelled || isRenewEnabled) && !isCurrentSubscriptionTrial && (
					<ResumeAndRenewSubscriptionButton
						id={id}
						sideEffect={() => {
							dispatch(getUserData());
							dispatch(getSubscriptionInfo());
						}}
					/>
				)}
			</Card>
		</Box>
	);
};

export default SubscriptionCard;

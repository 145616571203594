import React, { useMemo, useState } from "react";

import { ChevronLeft, ChevronRight } from "@material-ui/icons";
import { QuestionTypes } from "@remar/shared/dist/constants";

import ClozeDropDownResult from "./ClozeDropDownResult";
import DragAndDropResult from "./DragAndDropResult";
import DropDownTableResult from "./DropDownTableResult";
import HighlightTableResult from "./HighlightTableResult";
import HotspotHighlightResult from "./HotspotHighlightResult";
import MatrixMultipleChoiceResult from "./MatrixMultipleChoiceResult";
import MatrixSingleChoiceResult from "./MatrixSingleChoiceResult";
import MultipleResponseGroupResult from "./MultipleResponseGroupResult";
import QuestionResultInfo from "./QuestionResultInfo";
import SingleChoiceOrMultipleChoiceResult from "./SingleChoiceOrMultipleChoiceResult";

import {
	CaseStudyColumn,
	CaseStudyRow,
	CaseStudyTab,
	CaseStudyTabContent,
	CaseStudyTabs,
	CurrentPageNum,
	IconsContainer,
	Pages,
	QuestionContainer,
	StyledIcon,
	TestQuizQuestionDesc,
	TestQuizQuestionText
} from "./styles";

import { useStyles } from "../styles";

const CaseStudyQuestionResult = ({
	results,
	question: {
		caseStudyQuestions: questions,
		data: { tabs },
		description,
		text
	}
}) => {
	const classes = useStyles();
	const [activeTab, setActiveTab] = useState(0);
	const [activeQuestion, setActiveQuestion] = useState(0);

	const currentQuestion = questions[activeQuestion];

	const result = useMemo(
		() => results.find(x => x.snapshot.id === currentQuestion?.id),
		[currentQuestion?.id, results]
	);

	const getQuestionType = (id, result, question) => {
		switch (id) {
			case QuestionTypes.MatrixSingleChoice:
				return <MatrixSingleChoiceResult question={question} result={result} />;
			case QuestionTypes.MatrixMultipleChoice:
				return <MatrixMultipleChoiceResult question={question} result={result} />;
			case QuestionTypes.RationalScoringDropDown:
			case QuestionTypes.ClozeDropDown:
				return <ClozeDropDownResult question={question} result={result} />;
			case QuestionTypes.DropDownTable:
				return <DropDownTableResult question={question} result={result} />;
			case QuestionTypes.MultipleChoiceSN:
			case QuestionTypes.MultipleChoiceSATA:
			case QuestionTypes.SingleChoice:
				return <SingleChoiceOrMultipleChoiceResult question={question} result={result} />;
			case QuestionTypes.RationalScoringDragAndDrop:
			case QuestionTypes.DragAndDrop:
				return <DragAndDropResult question={question} result={result} />;
			case QuestionTypes.MultipleResponseGroup:
				return <MultipleResponseGroupResult question={question} result={result} />;
			case QuestionTypes.HotspotHighlight:
				return <HotspotHighlightResult question={question} result={result} />;
			case QuestionTypes.HighlightTable:
				return <HighlightTableResult question={question} result={result} />;

			default:
				return null;
		}
	};

	return (
		<>
			<CaseStudyRow>
				<CaseStudyColumn className={classes.leftCol}>
					<TestQuizQuestionText dangerouslySetInnerHTML={{ __html: text }}></TestQuizQuestionText>
					<TestQuizQuestionDesc dangerouslySetInnerHTML={{ __html: description }}></TestQuizQuestionDesc>

					<CaseStudyTabs>
						{tabs.map(({ title, id }, tabIndex) => (
							<CaseStudyTab key={`tab-${id}`} active={tabIndex === activeTab} onClick={() => setActiveTab(tabIndex)}>
								{title}
							</CaseStudyTab>
						))}
					</CaseStudyTabs>
					{activeTab !== undefined && (
						<CaseStudyTabContent dangerouslySetInnerHTML={{ __html: tabs[activeTab]?.text }}></CaseStudyTabContent>
					)}
				</CaseStudyColumn>
				<CaseStudyColumn className={classes.rightCol}>
					<Pages>
						<CurrentPageNum>
							{" "}
							Question {activeQuestion + 1}/{questions.length}
						</CurrentPageNum>
						<IconsContainer>
							<StyledIcon
								onClick={() => setActiveQuestion(Math.max(activeQuestion - 1, 0))}
								left
								disabled={activeQuestion === 0}
							>
								<ChevronLeft style={{ height: "16px", width: "16px", fill: "#264fae" }} />
							</StyledIcon>
							<StyledIcon
								onClick={() => setActiveQuestion(Math.min(activeQuestion + 1, questions.length - 1))}
								right
								disabled={activeQuestion === 5}
							>
								<ChevronRight style={{ height: "16px", width: "16px", fill: "#264fae" }} />
							</StyledIcon>
						</IconsContainer>
					</Pages>
					<QuestionContainer>
						{currentQuestion && getQuestionType(currentQuestion.typeId, result, currentQuestion)}
					</QuestionContainer>
					<QuestionResultInfo result={result} />
				</CaseStudyColumn>
			</CaseStudyRow>
		</>
	);
};

export default CaseStudyQuestionResult;

import React, { useEffect, useMemo, useRef, useState } from "react";

import { Box, CircularProgress, Modal, Typography } from "@material-ui/core";
import { TermsAndConditions } from "@remar/shared/dist/modals/TermsAndConditions";
import { Course, CourseChapter } from "@remar/shared/dist/models";
import {
	ChapterImg,
	ChapterImgContainer,
	ChaptersRow,
	LessonSwiperWrapper,
	SectionTitle
} from "@remar/shared/dist/styles/swiper";

import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { RootState, useAppSelector } from "store";
import {
	confirmAcceptedTermsAndConditions,
	selectCurrentlyAllowedFullCourses,
	selectCurrentlyAllowedTrialCourses,
	selectIsTrial,
	selectUser
} from "store/features/Auth/authSlice";
import {
	fetchIntroLessons,
	loadTrialCourse,
	selectTrialCoursesInfo,
	setLoading
} from "store/features/Course/courseSlice";

import SwiperCore, { Mousewheel, Navigation } from "swiper/core";
import { Swiper, SwiperSlide } from "swiper/react";

import CourseLessonItem from "./CourseLessonItem";
import CoursePreviewBackground from "./CoursePreviewBackground";
import StudentForm from "./StudentForm";

import "swiper/swiper.min.css";
import "swiper/components/navigation/navigation.min.css";
import { getLastViewedSectionId } from "../../store/features/Lesson/lesson.slice";

SwiperCore.use([Mousewheel, Navigation]);

type TrialCoursesInfoSelection = { trialCourses: Course[]; isLoading: boolean };
type AllowedTrialCoursesSelection = Course[] | undefined;
type AllowedFullCoursesSelection = Course[] | undefined;

interface NavOptions {
	prevEl: HTMLDivElement | null;
	nextEl: HTMLDivElement | null;
}

const LessonsList = ({ section, sectionLessons, latestLesson }) => {
	const nextEl = useRef(null);
	const prevEl = useRef(null);

	return (
		<>
			<SectionTitle key={`name-${section.id}`}>{section.name}</SectionTitle>
			<LessonSwiperWrapper key={`lessons-${section.id}`}>
				<Swiper
					navigation={{
						prevEl: prevEl.current,
						nextEl: nextEl.current
					}}
					slidesPerView="auto"
					spaceBetween={0}
					mousewheel={{ releaseOnEdges: true }}
					virtualTranslate
					className="popout"
					onSetTranslate={(swiper, delta) => {
						swiper.$wrapperEl.css("margin-left", delta + "px");
					}}
					onBeforeInit={swiper => {
						if (swiper?.params?.navigation) {
							(swiper.params.navigation as NavOptions).prevEl = prevEl.current;
							(swiper.params.navigation as NavOptions).nextEl = nextEl.current;
						}
					}}
				>
					{sectionLessons.map((sectionLesson, i) => (
						<SwiperSlide key={sectionLesson.id}>
							<CourseLessonItem
								number={i + 1}
								section={section}
								sectionLesson={sectionLesson}
								latestLesson={latestLesson}
							/>
						</SwiperSlide>
					))}
					<span slot="wrapper-end">
						<div className="swiper-button-prev" ref={prevEl}></div>
						<div className="swiper-button-next" ref={nextEl}></div>
						<div className="swiper-button-next-overlay"></div>
						<div className="swiper-button-prev-overlay"></div>
					</span>
				</Swiper>
			</LessonSwiperWrapper>
		</>
	);
};

const StudentModal = ({ modal, closeModal }) => (
	<Modal
		style={{
			alignItems: "center",
			justifyContent: "center",
			display: "flex"
		}}
		disableEnforceFocus
		disableAutoFocus
		open={!!modal}
	>
		<StudentForm closeModal={closeModal} />
	</Modal>
);

const StudentFormModal = ({ modal, closeModal }) => (
	<Box pt={12}>
		<Typography align="center" variant="h1">
			Sorry, nothing here yet
		</Typography>
		<StudentModal modal={modal} closeModal={closeModal} />
	</Box>
);

const CourseView = () => {
	const dispatch = useDispatch();
	const history = useHistory();
	const { pathname, search } = useLocation();

	const chapterId = useMemo(() => new URLSearchParams(search).get("chapterId"), [search]);

	const { lastViewedSectionId } = useAppSelector((store: RootState) => store.lesson);
	const { introLessonsLoading } = useAppSelector((store: RootState) => store.courses);
	const { trialCourses, isLoading }: TrialCoursesInfoSelection = useAppSelector(selectTrialCoursesInfo);
	const currentlyAllowedTrialCourses: AllowedTrialCoursesSelection = useSelector(selectCurrentlyAllowedTrialCourses);
	const currentlyAllowedFullCourses: AllowedFullCoursesSelection = useSelector(selectCurrentlyAllowedFullCourses);
	const user = useSelector(selectUser);
	const subTrail = useSelector(selectIsTrial);

	const [modal, setModal] = useState(user && (!user.schoolId || !user.hasTakenIntro));
	const [selectedChapIndex, setSelectedChapIndex] = useState<number>(0);

	const chapters: CourseChapter[] | null = trialCourses?.length !== 0 ? trialCourses[0].chapters! : null;

	useEffect(() => {
		if (chapterId) {
			const newIndex = chapters?.findIndex(({ id }) => id === Number(chapterId)) ?? 0;

			setSelectedChapIndex(newIndex);
		}
	}, [chapterId, chapters]);
	const selectedChapter = chapters && chapters[selectedChapIndex];
	const refetch = useMemo(() => history.location.search.includes("refetch=true"), [history.location.search]);

	const closeModal = () => setModal(false);

	useEffect(() => {
		if (currentlyAllowedTrialCourses || currentlyAllowedFullCourses) {
			const courses = [...currentlyAllowedTrialCourses!, ...currentlyAllowedFullCourses!];
			const ids = courses.map(e => e.id);
			const isTrial = subTrail === true ? subTrail : !!currentlyAllowedTrialCourses?.length;
			if (ids && ids.length) {
				dispatch(loadTrialCourse({ ids, isTrial }));
			} else {
				dispatch(setLoading(false));
			}
		}
	}, [currentlyAllowedTrialCourses, currentlyAllowedFullCourses, refetch, subTrail, dispatch]);

	useEffect(() => {
		if (user && !introLessonsLoading && !user.hasTakenIntro) {
			dispatch(fetchIntroLessons());
		}
	}, [user]);

	useEffect(() => {
		if (user) setModal(!user.schoolId || !user.hasTakenIntro);
	}, [user]);

	useEffect(() => {
		// fetch last viewed lesson id
		dispatch(getLastViewedSectionId());
	}, [dispatch]);

	const latestLesson = useMemo(() => {
		if (lastViewedSectionId && chapters) {
			const foundLastView = chapters
				.flatMap(chapter => chapter.sections!)
				.flatMap(section => section.sectionLessons!)
				.find(lesson => lesson.id === lastViewedSectionId);
			if (foundLastView) {
				return foundLastView;
			}
		}

		const getLatestLessonWithSection = (chapter: CourseChapter) => {
			const allLessons = chapter.sections!.map(s => s.sectionLessons!).flat();

			const lockedLessonIndex = allLessons.findIndex(
				({ isLockedForCurrentUser, isLockedByPayment }) => !isLockedByPayment && isLockedForCurrentUser
			);
			const index = lockedLessonIndex === 0 ? lockedLessonIndex : lockedLessonIndex - 1;
			const latestLessonIndex = lockedLessonIndex === -1 ? allLessons.length - 1 : index;

			const latestLesson = allLessons[latestLessonIndex];
			const section = chapter.sections!.find(s => s.sectionLessons!.some(sl => sl.id == latestLesson.id));

			return { ...latestLesson, section };
		};

		return selectedChapter ? getLatestLessonWithSection(selectedChapter) : null;
	}, [chapters, lastViewedSectionId, selectedChapter]);

	if (isLoading) {
		return (
			<Box display="flex" alignItems="center" justifyContent="center" height={450} width="100%">
				<CircularProgress size="7rem" color="primary" thickness={5} variant="indeterminate" />
			</Box>
		);
	}

	if (!trialCourses.length) {
		return <StudentFormModal modal={modal} closeModal={closeModal} />;
	}

	if (isLoading) {
		return <CircularProgress color="primary" />;
	}

	if (!trialCourses.length || !latestLesson) {
		return <StudentFormModal modal={modal} closeModal={closeModal} />;
	}

	return (
		<Box>
			<CoursePreviewBackground
				title={latestLesson.lesson!.name}
				subtitle={latestLesson.section?.name}
				trailer={latestLesson.lesson!.trailer}
				lessonId={latestLesson.lessonId}
				sectionId={latestLesson.sectionId}
				totalTimeLengthInMinutes={latestLesson.lesson!.totalTimeLengthInMinutes}
				description={
					latestLesson.lesson!.description && latestLesson.lesson!.description !== "null"
						? latestLesson.lesson!.description
						: "No description provided yet"
				}
			/>
			<ChaptersRow>
				<LessonSwiperWrapper>
					<Swiper
						navigation
						slidesPerView="auto"
						spaceBetween={0}
						mousewheel={{ releaseOnEdges: true }}
						className="test"
					>
						{chapters?.map(({ id, mainImageUrl, mainImageThumbnailUrl }, i) => (
							<SwiperSlide key={id}>
								<ChapterImgContainer
									onClick={() => {
										setSelectedChapIndex(i);
										history.push(`${pathname}?chapterId=${id}`);
									}}
								>
									<ChapterImg
										selected={i === selectedChapIndex}
										src={mainImageThumbnailUrl?.small250 || mainImageUrl || "https://picsum.photos/200/300"}
									/>
								</ChapterImgContainer>
							</SwiperSlide>
						))}
					</Swiper>
				</LessonSwiperWrapper>
			</ChaptersRow>
			<Box mb={10}>
				{selectedChapter?.sections!.map(section => (
					<LessonsList
						key={section.id}
						latestLesson={latestLesson}
						section={section}
						sectionLessons={section.sectionLessons}
					/>
				))}
			</Box>
			<StudentModal modal={modal} closeModal={closeModal} />
			<TermsAndConditions
				open={!!user && !user.acceptedTermsAndConditions}
				onClose={isAgreed => {
					dispatch(confirmAcceptedTermsAndConditions(isAgreed));
				}}
			/>
		</Box>
	);
};

export default CourseView;

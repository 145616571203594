import React, { useEffect, useState } from "react";

import { FormControl, MenuItem, makeStyles } from "@material-ui/core";

import { concat } from "lodash";

import { QuizQuestionAnswerOption } from "modules/Lesson/style";

import { QuestionRationalInfo } from "./styles";

import { StyledDropdown } from "../../styles";

const useStyles = makeStyles(() => ({
	formControl: {
		verticalAlign: "middle"
	},
	list: {
		backgroundColor: "white",
		"& > li": {
			color: "#4d5058",
			fontSize: "0.9rem",
			fontWeight: "500"
		}
	}
}));

interface ClozeDropdownChoice {
	id: string;
	groupId: string;
}

const ClozeDropDown = ({ userAnswers, onChange, groupId, answerOptions }) => {
	const classes = useStyles();
	const [currentId, setCurrentId] = useState("");
	let localAnswers: ClozeDropdownChoice[] = [{ id: "", groupId: "" }];

	const currentAnswer = userAnswers.find(answer => answer.groupId === groupId);

	useEffect(() => {
		currentAnswer && setCurrentId(currentAnswer.id);
	}, [currentAnswer]);

	const handleChange = val => {
		setCurrentId(val.id);
		if (userAnswers.length === 0) {
			onChange(concat(userAnswers, { ...val }));
		} else {
			localAnswers = concat(userAnswers, { ...val });
			const uniqueAnswers = [...new Map(localAnswers.map(item => [item["groupId"], item])).values()];
			onChange(uniqueAnswers);
		}
	};

	return (
		<FormControl className={classes.formControl}>
			<StyledDropdown
				disableUnderline
				value={currentId}
				onChange={event => handleChange({ id: event.target.value, groupId })}
				displayEmpty
				MenuProps={{
					classes: { list: classes.list },
					anchorOrigin: {
						vertical: "bottom",
						horizontal: "left"
					},
					transformOrigin: {
						vertical: "top",
						horizontal: "left"
					},
					getContentAnchorEl: null
				}}
			>
				<MenuItem value="" disabled>
					Select
				</MenuItem>
				{answerOptions.map(({ id, text }) => (
					<MenuItem key={id} value={id}>
						{text}
					</MenuItem>
				))}
			</StyledDropdown>
		</FormControl>
	);
};

const ClozeDropDownQuestion = ({
	question: {
		data: { groups },
		description
	},
	onChange,
	userAnswers
}) => (
	<>
		<QuizQuestionAnswerOption>
			<QuestionRationalInfo
				dangerouslySetInnerHTML={{
					__html: description ?? ""
				}}
			></QuestionRationalInfo>
		</QuizQuestionAnswerOption>
		<QuizQuestionAnswerOption wrap>
			{groups?.map(({ id: groupId, text, answerOptions }) => (
				<span key={groupId}>
					{text}{" "}
					{answerOptions && answerOptions.length !== 0 && (
						<ClozeDropDown
							userAnswers={userAnswers}
							answerOptions={answerOptions}
							onChange={onChange}
							groupId={groupId}
						/>
					)}
				</span>
			))}
		</QuizQuestionAnswerOption>
	</>
);

export default ClozeDropDownQuestion;

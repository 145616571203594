import React from "react";

import { Box, Checkbox } from "@material-ui/core";

import { concat } from "lodash";

import { CheckBoxWrapper, MRTableBody, MRTableHeader, StyledText } from "modules/Lesson/style";
import {
	MatrixSingleChoiceGroupText,
	TestQuestionStyledContainer,
	TestQuizQuestionText
} from "modules/QuestionBank/styles";

const MatrixMultipleChoiceQuestion = ({
	question: {
		data: { answerOptions, tableLabel, groups },
		text
	},
	onChange,
	userAnswers
}) => {
	const handleChangeAnswer = (checked, gId, optId) => {
		let arr: Record<string, unknown>[];
		const userAnswersCopy = [...userAnswers];
		if (checked) {
			arr = concat(userAnswersCopy, { id: optId, groupId: gId });
		} else {
			const index = userAnswersCopy.findIndex(({ id, groupId }) => id === optId && groupId === gId);
			index > -1 && userAnswersCopy.splice(index, 1);
			arr = userAnswersCopy;
		}
		onChange([...arr]);
	};

	return (
		<TestQuestionStyledContainer>
			<TestQuizQuestionText>{text}</TestQuizQuestionText>
			<MRTableHeader display="flex" maxWidth={"595px"} flexDirection="row">
				<Box display="flex" flexDirection="row" alignItems="center" width="100%">
					<MatrixSingleChoiceGroupText>
						<StyledText>{tableLabel}</StyledText>
					</MatrixSingleChoiceGroupText>

					<Box height={"100%"} display="flex" flexDirection="row" width="50%">
						{groups.map(({ text }, answerOptionIndex) => (
							<Box
								key={answerOptionIndex}
								justifyContent="center"
								display="flex"
								alignItems="center"
								height={"100%"}
								flexDirection="row"
								p="0 8px"
								width={`${100 / groups.length}%`}
								{...(answerOptionIndex === 0 && {
									borderRight: "solid 1px rgba(151, 151, 151, 0.3)"
								})}
							>
								<StyledText>{text}</StyledText>
							</Box>
						))}
					</Box>
				</Box>
			</MRTableHeader>
			<MRTableBody>
				{answerOptions.map(({ text, id: optId }, gIndex) => (
					<Box
						key={gIndex}
						borderBottom="solid 1px rgba(151, 151, 151, 0.3)"
						height="auto"
						minHeight="51px"
						display="flex"
						flexDirection="row"
						width="100%"
					>
						<Box display="flex" flexDirection="row" alignItems="center" width="100%">
							<MatrixSingleChoiceGroupText>
								<StyledText>{text}</StyledText>
							</MatrixSingleChoiceGroupText>
							<Box display="flex" flexDirection="row" width="50%" height="100%">
								<CheckBoxWrapper>
									{groups.map(({ id: gId }, _index) => (
										<Box
											justifyContent="center"
											alignItems="center"
											key={_index}
											height="auto"
											minHeight="51px"
											display="flex"
											width={`${100 / groups.length}%`}
											{...(_index === 0 && {
												borderRight: "solid 1px rgba(151, 151, 151, 0.3)"
											})}
										>
											<Checkbox
												color="default"
												key={optId}
												checked={userAnswers.some(item => item.id === optId && item.groupId === gId)}
												onChange={({ target: { checked } }) => handleChangeAnswer(checked, gId, optId)}
											/>
										</Box>
									))}
								</CheckBoxWrapper>
							</Box>
						</Box>
					</Box>
				))}
			</MRTableBody>
		</TestQuestionStyledContainer>
	);
};

export default MatrixMultipleChoiceQuestion;
